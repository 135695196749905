import React, { useState } from "react";
import { Dropdown } from "antd";
import "./Tooltip.css";

export const ArrowTooltip = ({ title, children, onClick }) => {
  const [open, setOpen] = useState(false);
  const items = [
    {
      key: "drop",
      label: "DROP",
    },
  ];
  const handleClick = (e) => {
    onClick();
    setOpen(false);
  };
  return (
    <Dropdown
      menu={{ items, onClick: handleClick }}
      className={`sip-tooltip ${open ? "mint-btn" : ""}`}
      placement="bottom"
      trigger={"click"}
      onOpenChange={(event) => {
        setOpen(event);
      }}
      disabled={title ? false : true}
    >
      {children}
    </Dropdown>
  );
};
