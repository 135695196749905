import { useEffect, useState } from "react";

export const useScroll = (ref, depArr = []) => {
  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const scroll = !!(ref.current.offsetWidth - ref.current.clientWidth);
      setHasScroll(scroll);
      return;
    }
    setHasScroll(false);
  }, [ref.current, ...depArr]);

  return hasScroll;
};
