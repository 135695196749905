import React, { Fragment } from "react";
import Backdrop from "../Backdrop/Backdrop";
import "./Modal.css";

const Modal = (props) => {
  return (
    props.show && (
      <div className="modal-container-ui">
        <Backdrop show={props.show} clicked={props.modalClosed} />
        <div className={"" || props.classOuterBox}>
          <div
            className={["Modal", props.className].join(" ")}
            style={{
              transform: props.show ? "translateY(0)" : "translateY(-200vh)",
              opacity: props.show ? "1" : "0",
            }}
          >
            {props.children}
          </div>
        </div>
      </div>
    )
  );
};

export default React.memo(
  Modal,
  (prevProps, nextProps) =>
    nextProps.show === prevProps.show &&
    nextProps.children === prevProps.children
);
