const invertedArrow = {
  dropdownIndicator: (styles) => ({
    ...styles,
    rotate: "180deg",
    padding: "0px 0px",
    color: "inherit",
    transition: "none",
  }),
};

export default invertedArrow;
