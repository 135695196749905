import React from "react";
import Warning_icon from "../../../../assets/icons/Warning.svg";
import "./ConfirmationModalPopup.css";
import Backdrop from "../Backdrop/Backdrop";

export const ConfirmationModal = ({
  isModalOpen,
  message,
  cancelDelete,
  deleteRow,
}) => {
  return (
    <>
      <Backdrop show={isModalOpen} clicked={cancelDelete} />
      {isModalOpen && (
        <div className="popup-modal  trashInfo-container">
          <div className="warning-icon-content">
            <img
              className="warning-icon"
              src={Warning_icon}
              alt="Warning_icon"
            />
            <span className="warning-headline">Warning</span>
          </div>
          <div className="warning-content">{message}</div>
          <div className="warning-buttons">
            <button className="delete-cancel-btn" onClick={cancelDelete}>
              NO
            </button>
            <button
              className="duplicate-submit-btn"
              onClick={() => deleteRow()}
            >
              YES
            </button>
          </div>
        </div>
      )}
    </>
  );
};
