import React, { useEffect } from "react";
import Loader_img from "../../../../assets/images/loader.gif";
import "../../../shared/UI/Spinner/Spinner.css";

const Loader = ({ loading = false }) => {
  useEffect(() => {
    // Disable root container scrolling when modal is open
    const rootElm = document.getElementsByTagName("body")[0];
    if (loading) {
      rootElm.classList.add("hide-scroll");
    } else {
      rootElm.classList.remove("hide-scroll");
    }
  }, [loading]);
  return loading ? (
    <div className="loader">
      <img width="25" height="25" src={Loader_img} alt="loader" />
    </div>
  ) : null;
};

export default Loader;
