import React from "react";

const SignalErrorModal = (props) => {
  let totalMsg = "";
  if (props.signalInfo.length > 0) {
    totalMsg = props.signalInfo.map((SignalError, id) => {
      return SignalError.status === 202 && SignalError.control === "grab" ? (
        <p>{`Already Owned by ${SignalError.controlledByFullName} in ${SignalError.controlRoomCode}`}</p>
      ) : SignalError.status === 500 && SignalError.control === "take" ? (
        <p className="text-danger">{`CR Path: ${SignalError.crPathName} and Global Path: ${SignalError.globalPathName} has not been routed`}</p>
      ) : SignalError.status === 500 && SignalError.control === "grab" ? (
        <p className="text-danger">{SignalError.msg}</p>
      ) : SignalError.status === 409 && SignalError.control === "grab" ? (
        <p className="text-danger">{SignalError.msg}</p>
      ) : SignalError.status === 0 &&
        SignalError.control === "take duplicate" ? (
        SignalError.signals.map((field, idx) => {
          return (
            <p
              className="text-warning"
              key={idx}
            >{`Duplicate CR Path: ${field.crPathName} and Global Path: ${field.globalPathName} has not been routed`}</p>
          );
        })
      ) : SignalError.status === 500 && SignalError.control === "takeAll" ? (
        SignalError.signals.map((field, idx) => {
          let grabbed = field.status === 500 ? "not" : "";
          let text = field.liveshotSignalTypeId !== 1 ? "owned" : "routed";
          return (
            <p
              className={field.status === 500 ? "text-danger" : "taken"}
              key={idx}
            >{`CR Path: ${field.crPathName} and Global Path: ${field.globalPathName} has ${grabbed} been ${text}`}</p>
          );
        })
      ) : SignalError.status === 500 && SignalError.control === "grabAll" ? (
        SignalError.signals.map((field, idx) => {
          let grabbed = field.status === 500 ? "not" : "";
          let text = field.liveshotSignalTypeId !== 1 ? "owned" : "routed";
          return (
            <p
              className={field.status === 500 ? "text-danger" : "taken"}
              key={idx}
            >{`CR Path: ${field.crPathName} and Global Path: ${field.globalPathName} has ${grabbed} been ${text}`}</p>
          );
        })
      ) : (
        ""
      );
    });
  }
  let borderMsg = null;
  if (props.showBorderMsg) {
    borderMsg = (
      <p className="text-danger">Signal is not available for route</p>
    );
  }
  return (
    <div className="popup-modal">
      <div className="modal-content signal-msg-content-box">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={props.closeModal}
          >
            &times;
          </button>
        </div>
        <div className="modal-body signal-msg-body">
          {totalMsg} {borderMsg}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-default"
            onClick={props.closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignalErrorModal;
