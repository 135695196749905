import * as actionTypes from "../actions/actionTypes";

const initialState = {
  routerSources: [],
  routerDestinations: [],
  loading: false,
  signalTypeDetails: [],
  roomDetails: [],
  pinTableInfo: [],
  userSso: [],
  studioPinData: [],
  pinModalInfo: [],
  pinModalSuccess: [],
  signalMsg: "",
  formattedRouterSource: [],
};

const savePinModalDataSuccess = (state, action) => {
  return Object.assign({}, state, {
    studioPinData: action.studioPinData,
    pinModalSuccess: true,
  });
};

const sourceSuccess = (state, action) => {
  return Object.assign({}, state, {
    routerSources: action.routerSources,
  });
};

const destinatonSuccess = (state, action) => {
  return Object.assign({}, state, {
    routerDestinations: action.routerDestinations,
  });
};

const toggleLoaderSuccess = (state, action) => {
  return Object.assign({}, state, {
    loading: !state.loading,
  });
};

const showModal = (state, action) => {
  return Object.assign({}, state, {
    signalMsg: action.signalMsg,
    showModal: action.showModal,
  });
};

const hideModal = (state, action) => {
  return Object.assign({}, state, {
    showModal: action.showModal,
    signalMsg: action.signalMsg,
  });
};

const signalTypeSuccess = (state, action) => {
  return Object.assign({}, state, {
    signalTypeDetails: action.signalTypeDetails,
  });
};

const roomSuccess = (state, action) => {
  return Object.assign({}, state, {
    roomDetails: action.roomDetails,
  });
};

const pinTableSuccess = (state, action) => {
  return Object.assign({}, state, {
    pinTableInfo: action.pinInfo,
  });
};

const PinModalSuccess = (state, action) => {
  return Object.assign({}, state, {
    pinModalInfo: action.pinsInfo,
  });
};

const userSsoSuccess = (state, action) => {
  return Object.assign({}, state, {
    userSso: action.userSso,
  });
};

const getRouterSources = (res = []) => {
  let routerSourcesObj = [];
  res.forEach((rs) => {
    if (rs.namesetName.includes("SIP")) {
      if (!routerSourcesObj.includes(rs.namesetName)) {
        routerSourcesObj.push(rs.namesetName.replace("SIP ", ""));
      }
    }
  });
  return routerSourcesObj.filter((sourcesSip) => {
    return sourcesSip;
  });
};

const parseRouterSource = (state, action) => {
  return Object.assign({}, state, {
    formattedRouterSource: getRouterSources(action.routerSources),
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ROUTER_SOURCES:
      return sourceSuccess(state, action);
    case actionTypes.FETCH_ROUTER_DESTINATIONS:
      return destinatonSuccess(state, action);
    case actionTypes.TOGGLE_LOADER:
      return toggleLoaderSuccess(state, action);
    case actionTypes.SHOW_MODAL:
      return showModal(state, action);
    case actionTypes.HIDE_MODAL:
      return hideModal(state, action);
    case actionTypes.FETCH_SIGNAL_TYPE_DETAILS:
      return signalTypeSuccess(state, action);
    case actionTypes.FETCH_ROOM_DETAILS:
      return roomSuccess(state, action);
    case actionTypes.FETCH_PINS_TABLE_INFO:
      return pinTableSuccess(state, action);
    case actionTypes.FETCH_USER_INFO:
      return userSsoSuccess(state, action);
    case actionTypes.STUDIOPINDATASAVE_OBJECT_SUCCESS:
      return savePinModalDataSuccess(state, action);
    case actionTypes.FETCH_PIN_MODAL_INFO:
      return PinModalSuccess(state, action);
    case actionTypes.FORMATTED_ROUTER_SOURCES:
      return parseRouterSource(state, action);
    default:
      return state;
  }
};

export default reducer;
