import * as actionTypes from "./actionTypes";
import * as actions from "../../store/actions/index";
import axios from "../../axios";
import * as Constants from "../../components/shared/Constants";

export const saveLiveShotObjectSuccess = (resdata) => {
  return {
    type: actionTypes.LIVESHOTSAVE_OBJECT_SUCCESS,
    successMsg: resdata,
    showModal: false,
  };
};

export const showErrorModal = (resdata) => {
  return {
    type: actionTypes.SHOW_ERROR_MODAL,
    errorMsg: resdata.resultData,
    showErrorModal: true,
  };
};

export const updateLiveShotInfo = (resdata) => {
  return {
    type: actionTypes.UPDATE_LIVESHOT_INFO,
    LiveShotsInfo: resdata,
  };
};

export const hideErrorModal = () => {
  return {
    type: actionTypes.HIDE_ERROR_MODAL,
    showErrorModal: false,
    errorMsg: {},
  };
};

export const closeCommanModalResult = () => {
  return {
    type: actionTypes.CLOSE_COMMAN_MODAL,
    showModal: false,
  };
};

export const addLiveshotFromSearch = () => {
  return {
    type: actionTypes.LIVESHOT_ADDED_FROM_SEARCH,
  };
};

export const addNewLiveShotId = (id) => {
  return {
    type: actionTypes.NEW_LIVE_SHOT,
    liveShotId: id,
  };
};

export const resetAddedFromSearch = () => {
  return {
    type: actionTypes.RESET_ADDED_FROM_SEARCH,
  };
};

export const closeCommanModal = (currentCanvasValue, token) => {
  return (dispatch) => {
    dispatch(closeCommanModalResult());
    dispatch(actions.resetStudioTruckData());
    dispatch(actions.resetSuccessMsg());
    dispatch(actions.resetSignalsInfo());
    // dispatch(actions.getHostStudioLiveShots(currentCanvasValue, token));
  };
};

export const liveshotAddedFromSearch = () => {
  return (dispatch) => {
    dispatch(addLiveshotFromSearch());
  };
};

export const saveCurrentLiveShot = (
  currentCanvasValue,
  liveShotObj,
  token,
  room
) => {
  return async (dispatch) => {
    dispatch(resetAddedFromSearch());
    await axios
      .post(
        `liveshot-api/liveshot/?canvasId=${currentCanvasValue}`,
        liveShotObj,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        if (liveShotObj.fromSearch) {
          if (res.status === 200) {
            dispatch(actions.closeSearchModal());
            dispatch(
              saveLiveShotObjectSuccess({
                resultData: Constants.LIVESHOT_ADD_SUCCESS_MSG,
                statusCode: res.status,
              })
            );
            dispatch(
              actions.getLiveShotsWithOutLoading(currentCanvasValue, token)
            );
            dispatch(addLiveshotFromSearch());
            // console.log("livehost");
            // console.log(res);
            dispatch(addNewLiveShotId(res.data.liveshotId));
          }
        } else {
          if (res.status === 200) {
            let lsInfo = res.data;
            if (lsInfo.abstractLiveshot.liveshotTypeId === 6) {
              //if remote reporter
              dispatch(
                actions.createAbstractLiveShotSignal(
                  token,
                  lsInfo.liveshotId,
                  lsInfo.abstractLiveshot.abstractLiveshotId,
                  1,
                  lsInfo.createdBy,
                  room
                )
              );
              dispatch(
                actions.createAbstractLiveShotSignal(
                  token,
                  lsInfo.liveshotId,
                  lsInfo.abstractLiveshot.abstractLiveshotId,
                  5,
                  lsInfo.createdBy,
                  room
                )
              );
              // dispatch(
              //   actions.createLiveShotSignal(
              //     token,
              //     lsInfo.liveshotId,
              //     lsInfo.abstractLiveshot.abstractLiveshotId,
              //     8,
              //     lsInfo.createdBy,
              //     room
              //   )
              // );
            }
            if (lsInfo.abstractLiveshot.liveshotTypeId === 5) {
              //if remote Guest
              dispatch(
                actions.createAbstractLiveShotSignal(
                  token,
                  lsInfo.liveshotId,
                  lsInfo.abstractLiveshot.abstractLiveshotId,
                  1,
                  lsInfo.createdBy,
                  room
                )
              );
              dispatch(
                actions.createAbstractLiveShotSignal(
                  token,
                  lsInfo.liveshotId,
                  lsInfo.abstractLiveshot.abstractLiveshotId,
                  5,
                  lsInfo.createdBy,
                  room
                )
              );
            }
            if (lsInfo.abstractLiveshot.liveshotTypeId === 7) {
              //if remote event
              dispatch(
                actions.createAbstractLiveShotSignal(
                  token,
                  lsInfo.liveshotId,
                  lsInfo.abstractLiveshot.abstractLiveshotId,
                  1,
                  lsInfo.createdBy,
                  room
                )
              );
              dispatch(
                actions.createAbstractLiveShotSignal(
                  token,
                  lsInfo.liveshotId,
                  lsInfo.abstractLiveshot.abstractLiveshotId,
                  8,
                  lsInfo.createdBy,
                  room
                )
              );
            }
            if (lsInfo.abstractLiveshot.liveshotTypeId === 4) {
              //if remote host
              dispatch(
                actions.createAbstractLiveShotSignal(
                  token,
                  lsInfo.liveshotId,
                  lsInfo.abstractLiveshot.abstractLiveshotId,
                  1,
                  lsInfo.createdBy,
                  room
                )
              );
              dispatch(
                actions.createAbstractLiveShotSignal(
                  token,
                  lsInfo.liveshotId,
                  lsInfo.abstractLiveshot.abstractLiveshotId,
                  2,
                  lsInfo.createdBy,
                  room
                )
              );
              dispatch(
                actions.createAbstractLiveShotSignal(
                  token,
                  lsInfo.liveshotId,
                  lsInfo.abstractLiveshot.abstractLiveshotId,
                  2,
                  lsInfo.createdBy,
                  room
                )
              );
              dispatch(
                actions.createAbstractLiveShotSignal(
                  token,
                  lsInfo.liveshotId,
                  lsInfo.abstractLiveshot.abstractLiveshotId,
                  5,
                  lsInfo.createdBy,
                  room
                )
              );
              dispatch(
                actions.createAbstractLiveShotSignal(
                  token,
                  lsInfo.liveshotId,
                  lsInfo.abstractLiveshot.abstractLiveshotId,
                  9,
                  lsInfo.createdBy,
                  room
                )
              );
            }

            dispatch(
              saveLiveShotObjectSuccess({
                resultData: Constants.LIVESHOT_SAVE_SUCCESS_MSG,
                statusCode: res.status,
              })
            );
            dispatch(
              actions.getLiveShotsWithOutLoading(currentCanvasValue, token)
            );
            dispatch(actions.resetStudioTruckData());
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        if (liveShotObj.fromSearch) {
          dispatch(actions.closeSearchModal());
          dispatch(
            saveLiveShotObjectSuccess({
              resultData: Constants.LIVESHOT_ADD_FAILURE_MSG,
              statusCode: err.status,
            })
          );
          // dispatch(actions.getHostStudioLiveShots(currentCanvasValue, token));
        } else {
          dispatch(
            saveLiveShotObjectSuccess({
              resultData: Constants.LIVESHOT_SAVE_FAILURE_MSG,
              statusCode: err.status,
            })
          );
          //dispatch(actions.getHostStudioLiveShots(currentCanvasValue, token));
        }
      });
  };
};

export const createDuplicateLiveShot = (
  currentCanvasValue,
  liveShotObj,
  token
) => {
  return (dispatch) => {
    axios
      .post(
        `liveshot-api/liveshot/?canvasId=${currentCanvasValue}&isLsDuplicate=${true}`,
        liveShotObj,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addLiveshotFromSearch());
        dispatch(addNewLiveShotId(res.data.liveshotId));
        dispatch(actions.getLiveShotsWithOutLoading(currentCanvasValue, token));
        dispatch(actions.resetStudioTruckData());
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const updateLiveShot = (
  currentCanvas,
  liveshotId,
  liveShotObj,
  token
) => {
  return (dispatch) => {
    axios
      .patch(
        `liveshot-api/liveshot/${liveshotId}?roomId=${currentCanvas.roomId}`,
        liveShotObj,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            saveLiveShotObjectSuccess({
              resultData: Constants.LIVESHOT_UPDATE_SUCCESS_MSG,
              statusCode: res.status,
            })
          );
          dispatch(actions.searchLiveShot(token));
        }
        dispatch(
          actions.getLiveShotsWithOutLoading(currentCanvas.canvasId, token)
        );
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        dispatch(
          showErrorModal({
            resultData: err.response.data.message,
            statusCode: err.response.status,
          })
        );
        console.log("error", err);
        // dispatch(actions.getHostStudioLiveShots(currentCanvas.canvasId, token));
      });
  };
};

export const updateLiveShotOnChange = (liveshotId, liveShotObj, token) => {
  return (dispatch) => {
    axios
      .patch(`liveshot-api/liveshot/${liveshotId}`, liveShotObj, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(actions.updateLiveShotInfo(res.data));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        if (err.response.status === 500) {
          console.log("Liveshot does not have proper data, please try again.");
        }
        console.log("error", err);
      });
  };
};

export const updateStatus = (currentCanvas, liveshotId, liveShotObj, token) => {
  return (dispatch) => {
    axios
      .patch(
        `liveshot-api/liveshot/${liveshotId}?roomId=${currentCanvas.roomId}`,
        liveShotObj,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        if (res.data) {
          dispatch(actions.updateLiveshotStatus(res.data));
        }
      })
      .catch();
  };
};
export const patchLiveShotSignal = (
  liveshotSignalobj,
  token,
  liveShotSignalId,
  roomId
) => {
  return (dispatch) => {
    axios
      .patch(
        `liveshot-api/liveshotSignal/${liveShotSignalId}?roomId=${roomId}`,
        liveshotSignalobj,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          // dispatch(actions.fetchStudioTruckList(token));
          console.log(res);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};
