import React from "react";
import "./Blank.css";

const BlankShow = () => (
  <div className="blankbox">
    <h1>Begin</h1>
    <p>Click 'Select Show' at upper left to begin.</p>
  </div>
);

export default BlankShow;
