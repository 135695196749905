import React, { useEffect, useRef } from "react";
import "./HangUpMenu.css";

const HangUpMenu = (props) => {
  const { isVisibleDropdown, setIsVisibleDropdown, onClickHangUp } = props;

  const useOutsideClick = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsVisibleDropdown(!isVisibleDropdown);
        }
      };
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  const onClickHangUpCall = () => {
    setIsVisibleDropdown(!isVisibleDropdown);
    onClickHangUp();
  };

  const hangUpMenu = [{ id: 1, name: "Drop" }];

  return (
    <ul
      ref={wrapperRef}
      className={isVisibleDropdown ? "hangUp-menu" : "hide-hangUp"}
    >
      {hangUpMenu.map((list, index) => {
        return (
          <li onClick={onClickHangUpCall} key={list.id} value={list.id}>
            {list.name}{" "}
          </li>
        );
      })}
    </ul>
  );
};

export default HangUpMenu;
