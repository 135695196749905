import React from "react";
import { connect } from "react-redux";
import Spinner from "../../../components/shared/UI/Spinner/Spinner";
import Button from "../../../components/shared/UI/Button/Button";
import "./Login.css";

const Login = (props) => {
  const { loadingAuth } = props;
  const onLogin = () => {
    window.location = `${process.env.REACT_APP_AUTHORIZE_ENDPOINT_URL}?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_REDIRECTURI}&scope=${process.env.REACT_APP_SCOPE}`;
  };

  let loginButton = (
    <div className="login">
      <Button onClick={onLogin} disabled={loadingAuth}>
        {" "}
        SIGN IN WITH SSO{" "}
      </Button>
    </div>
  );
  if (loadingAuth) {
    loginButton = <Spinner />;
  }
  return loginButton;
};

const mapStateToProps = (state) => {
  return {
    loadingAuth: state.auth.loadingAuth,
  };
};

export default connect(mapStateToProps)(Login);
