import * as actionTypes from "../../store/actions/actionTypes";

const initialState = {
  socketMsg: null,
  nongrabMsg: null,
};

const socketUnsubscribe = (state, action) => {
  return Object.assign({}, state, {
    socketMsg: null,
    nongrabMsg: null,
  });
};

const socketSubscribe = (state, action) => {
  return Object.assign({}, state, {
    socketMsg: action.payload,
  });
};

const socketNonGrab = (state, action) => {
  return Object.assign({}, state, {
    nongrabMsg: action.payload,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SOCKJS_SUBSCRIBE:
      return socketSubscribe(state, action);
    case actionTypes.SOCKJS_UNSUBSCRIBE:
      return socketUnsubscribe(state, action);
    case actionTypes.SOCKJS_NON_GRAB:
      return socketNonGrab(state, action);
    default:
      return state;
  }
};

export default reducer;
