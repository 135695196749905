import * as actionTypes from "../../store/actions/actionTypes";

const initialState = {
  showList: [],
  networkList: [],
  showData: [],
  currentShow: "",
  facilityList: [],
};

const fetchShowListSuccess = (state, action) => {
  return Object.assign({}, state, {
    showList: action.showList,
  });
};

const fetchNetworkListSuccess = (state, action) => {
  return Object.assign({}, state, {
    networkList: action.networkList,
  });
};

const fetchShowDataSuccess = (state, action) => {
  return Object.assign({}, state, {
    showData: action.showData,
  });
};

const resetShowDataSuccess = (state, action) => {
  return Object.assign({}, state, {
    showData: [],
  });
};

const updateCurrentShow = (state, action) => {
  return Object.assign({}, state, {
    currentShow: action.currentShow,
  });
};

const fecthFacilityListSuccess = (state, action) => {
  return Object.assign({}, state, {
    facilityList: action.facilityList,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SHOW_LIST_SUCCESS:
      return fetchShowListSuccess(state, action);
    case actionTypes.FETCH_NETWORK_LIST_SUCCESS:
      return fetchNetworkListSuccess(state, action);
    case actionTypes.FETCH_SHOW_DATA_SUCCESS:
      return fetchShowDataSuccess(state, action);
    case actionTypes.UPDATE_SHOW:
      return updateCurrentShow(state, action);
    case actionTypes.RESET_SHOW_DATA:
      return resetShowDataSuccess(state, action);
    case actionTypes.FETCH_FACILITY_LIST_SUCCESS:
      return fecthFacilityListSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
