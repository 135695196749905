import axios from "../../axios";
import * as actions from "./index";
import * as actionTypes from "./actionTypes";
import { DateFormater, delay } from "../../components/shared/utility";

export const resourceTrackerSuccess = (dataList, allSignal) => {
  return {
    type: actionTypes.FETCH_RESOURCETRACKER_DATA,
    resourceTrackerInfo: dataList,
    allSourceDestination: allSignal,
  };
};

export const setSIPSuccess = (res) => {
  return {
    type: actionTypes.FETCH_ALL_SIP,
    sip: res,
  };
};

export const getResourceTrackerInfo = (token) => {
  return async (dispatch) => {
    dispatch(actions.toggleLoader());
    const scrSignalIndexMap = {};
    await axios
      .get("/liveshot-api/routerSignal/?kind=sources&distinct=true", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        response.data &&
          response.data.forEach((signal) => {
            const key =
              signal.namesetName +
              "_" +
              signal.controlRoom +
              "_" +
              signal.sourceType;

            if (!scrSignalIndexMap[key]) {
              scrSignalIndexMap[key] = signal;
            }
          });
      })
      .catch((err) => {
        console.log("error ", err);
      });
    await delay(500);
    const desSignalIndexMap = {};
    await axios
      .get("/liveshot-api/routerSignal/?kind=destinations&distinct=true", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        response.data.forEach((signal) => {
          const key =
            signal.namesetName +
            "_" +
            signal.controlRoom +
            "_" +
            signal.desType;

          if (!desSignalIndexMap[key]) {
            desSignalIndexMap[key] = signal;
          }
        });
      })
      .catch((err) => {
        console.log("error ", err);
      });
    await delay(500);
    let rowDataList = await processResourceTrackerInfo(
      token,
      dispatch,
      scrSignalIndexMap,
      desSignalIndexMap
    );
    dispatch(actions.toggleLoader());
    dispatch(
      resourceTrackerSuccess(rowDataList, {
        scrSignalIndexMap: scrSignalIndexMap,
        desSignalIndexMap: desSignalIndexMap,
      })
    );
  };
};

export const refreshResourceTrackerInfo = (token, allSourceDestination) => {
  return async (dispatch) => {
    dispatch(actions.toggleLoader());
    let rowDataList = await processResourceTrackerInfo(
      token,
      dispatch,
      allSourceDestination.scrSignalIndexMap,
      allSourceDestination.desSignalIndexMap
    );
    dispatch(resourceTrackerSuccess(rowDataList, allSourceDestination));
    dispatch(actions.toggleLoader());
  };
};

const processResourceTrackerInfo = async (
  token,
  dispatch,
  scrSignalIndexMap,
  desSignalIndexMap
) => {
  const resourceIndexMap = {};
  await axios
    .get("/liveshot-api/controlTracker/resources?used=true&owned=true", {
      withCredentials: true,
      headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
    })
    .then((response) => {
      response.data &&
        response.data.forEach((resource) => {
          const type = resource.sourceType || resource.desType;

          const roomIdkey =
            resource.namesetName + "_" + resource.roomId + "_" + type;
          const coreIdkey =
            resource.namesetName + "_" + resource.coreId + "_" + type;

          if (!resourceIndexMap[roomIdkey]) {
            resourceIndexMap[roomIdkey] = [resource];
          } else {
            resourceIndexMap[roomIdkey].push(resource);
          }
          if (!resourceIndexMap[coreIdkey]) {
            resourceIndexMap[coreIdkey] = [resource];
          } else {
            resourceIndexMap[coreIdkey].push(resource);
          }
        });
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(actions.getRefreshToken());
      }
    });

  let resourceInUse = [];
  let upCommingResources = [];
  let windowExpired = [];
  let otherResource = [];
  Object.entries(scrSignalIndexMap).forEach(([key, value]) => {
    let signal = value;
    let resourceKey =
      signal.namesetName + "_" + signal.controlRoom + "_" + signal.sourceType;
    let resourceList = resourceIndexMap[resourceKey];
    addRowData(
      signal,
      resourceList,
      resourceInUse,
      upCommingResources,
      windowExpired,
      otherResource
    );
  });

  Object.entries(desSignalIndexMap).forEach(([key, value]) => {
    let signal = value;
    let resourceKey =
      signal.namesetName + "_" + signal.controlRoom + "_" + signal.desType;

    let resourceList = resourceIndexMap[resourceKey];
    addRowData(
      signal,
      resourceList,
      resourceInUse,
      upCommingResources,
      windowExpired,
      otherResource
    );
  });

  return [
    ...resourceInUse,
    ...upCommingResources,
    ...windowExpired,
    ...otherResource,
  ];
};

const addRowData = (
  signal,
  resourceList,
  resourceInUse,
  upCommingResources,
  windowExpired,
  otherResource
) => {
  if (resourceList) {
    resourceList.forEach((resource) => {
      let rowData = {};
      rowData.coreName = signal.coreName;
      rowData.namesetName = signal.namesetName;
      rowData.liveshotSignalTypeName = signal.liveshotSignalType;
      rowData.roomCode = resource.roomId;
      rowData.controlTime = resource.controlTime;
      rowData.startTime = resource.windowStartTime;
      rowData.endTime = resource.windowEndTime;
      rowData.hitTime = resource.hitTime;
      rowData.controlTypeName = getControlType(resource);
      rowData.liveshotName = resource.liveshotName;
      rowData.controlledByFullName = resource.controlledByFullName;
      rowData.pin = resource.pin;
      if (rowData.controlTypeName && rowData.controlTypeName == "In Use") {
        resourceInUse.push(rowData);
      } else if (
        rowData.controlTypeName &&
        rowData.controlTypeName == "Upcoming"
      ) {
        upCommingResources.push(rowData);
      } else {
        windowExpired.push(rowData);
      }
    });
  } else {
    otherResource.push({
      coreName: signal.coreName,
      namesetName: signal.namesetName,
      liveshotSignalTypeName: signal.liveshotSignalType,
    });
  }
};

const getControlType = (resource) => {
  let windowStart = new Date(
    resource.showDate + " " + resource.windowStartTime
  );
  let windowEnd = new Date(resource.showDate + " " + resource.windowEndTime);
  let currentTime = new Date();

  if (
    // (windowStart.getTime() < currentTime.getTime() &&
    //   currentTime.getTime() < windowEnd.getTime()) ||
    resource.controlTime &&
    resource.controlTime != ""
  ) {
    return "In Use";
  } else if (windowStart.getTime() > currentTime.getTime()) {
    return "Upcoming";
  }
};

export const deleteGrabbedSignal = (token, trackerId, deletedBy) => {
  return (dispatch) => {
    axios
      .delete(
        `/liveshot-api/controlTracker/${trackerId}?deletedBySSO=${deletedBy}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((response) => {
        dispatch(getResourceTrackerInfo(token));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const getAllSIP = (token) => {
  return (dispatch) => {
    axios
      .get(`/liveshot-api/liveshotSignal/getSIP/`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(setSIPSuccess(res.data));
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };
};
