import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { connect } from "react-redux";
import { DebounceInput } from "react-debounce-input";
import Loader from "../../shared/UI/Loader/Loader.js";
import * as actions from "../../../store/actions/index";
import Tooltip from "../../shared/UI/Tooltip/Tooltip";
import { removeDuplicates } from "../../shared/utility";
import SignalSuggestions from "../../../utils/signalSuggestions/SignalSuggestions";
import AvailVcMenu from "../../AvailVcMenu/AvailVcMenu";
import "./LiveShotSignals.css";
import { ReactComponent as InboundPlusIcon } from "../../../assets/icons/inbound-add-outline.svg";
import { ReactComponent as ExtendIcon } from "../../../assets/icons/extend-plus-icon.svg";
import { ReactComponent as MinusIcon } from "../../../assets/icons/remove-icon-outline.svg";
import { ReactComponent as OutboundIcon } from "../../../assets/icons/outbound-icon.svg";
import {
  commsTypeArray,
  outBoundTypeArray,
} from "../../../constants/ColumnHeaderArray.js";
import { ReactComponent as InfoLiveShotLogo } from "../../../assets/icons/Info-liveshots-outline.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/commsPIcon.svg";
import pinCommsSyncIcon from "../../../assets/icons/pinCommsSyncIcon.svg";
import { getPathClassName } from "../../shared/utility";
import { liveshot_canvas_constants } from "../../../constants/PageConstants";
import { CustomInput } from "../../shared/UI/Input/CustomInput.js";
import ClickAwayListenerHoc from "../../shared/UI/ClickAwayListenerHoc.js";
import { AntdTooltip } from "../../../components/shared/UI/Tooltip/AntdTooltip";
import { ReactComponent as UpArrow } from "../../../assets/icons/UpArrow.svg";
import { ReactComponent as DownArrow } from "../../../assets/icons/dropdown-arrow.svg";
const LiveShotSignals = (props) => {
  const {
    isDisable,
    liveShotId,
    formInfo,
    abstractLiveshotId,
    isArmRouting,
    onFetchSelectedSignal,
    onCreateLiveShotSignal,
    onUpdateLiveShotSignals,
    onUpdateNormalLiveShotSignals,
    onCreateNormalSignal,
    onDeleteLiveShotSignal,
    onCreateExtendLiveShotSignal,
    inBoundCRPath,
    inBoundGlobalPath,
    inBoundExtendGlobalPath,
    liveShotSignalSelected,
    ifbCRPath,
    ifbGlobalPath,
    plGlobalPath,
    plCRPath,
    phoGlobalPath,
    triggerAvailVcInitState,
    phoCRPath,
    routerSources,
    availSignal,
    pinObj,
    phoTxCRPath,
    phoTxGlobalPath,
    outBoundCRPath,
    outBoundGlobalPath,
    userList,
    token,
    currentUser,
    currentCanvasId,
    currentCanvas,
    controlRoomList,
    commCapabilityType,
    onChangeSignal,
    onTakeAndGrab,
    globalPins,
    grabbedSignals,
    readOnly,
    liveshotStatus,
    onCreateLiveShotSignalWithValue,
    onRemoveRedBorder,
    onCheckAvailableSignals,
    onAssignAvailableSignals,
    onCheckOwnership,
    redBorder,
    sharedInBoundGlobalPath,
    sharedOutBoundGlobalPath,
    sharedIFBGlobalPath,
    sharedPLGlobalPath,
    sharedPHOGlobalPath,
    sharedPHOTXGlobalPath,
    availInBoundSharedSignals,
    availOutBoundSharedSignals,
    availIFBSharedSignals,
    availPLSharedSignals,
    availPHOSharedSignals,
    loading,
    onUpdateUniLateral,
    fetchRouterDestinations,
    ifbPin,
    plPin,
    phoPin,
    onChangePinFromSignals,
    onSetAccessPin,
    onResetAllPin,
    stDefaultPinInfo,
    onResetSTDefaultPin,
    onCreateLiveShotSignalFromStudioTruck,
    defaultCommSignals,
    resetDefaultCommSignal,
    studioTruckTypes,
    handlePinCommsSync,
    onPinCommsSignalSync,
    onFetchPinBySipNumber,
    liveShotVenueType,
    showDuplicateLocalPathModal,
    unOwnLiveshotSignals,
  } = props;
  let tg_classes = [];
  let inbound_classes = [];
  let inbound_glpath_classes = [];
  let outBound_crPath_classes = [];
  let outBound_glPath_classes = [];
  let comms_ifbcrPath_classes = [];
  let comms_plcrPath_classes = [];
  let comms_phocrPath_classes = [];
  let comms_IfbGLPath_classes = [];
  let comms_PlGLPath_classes = [];
  let comms_PhoGLPath_classes = [];
  let comms_phoTxGLPath_classes = [];
  let comms_phoTxcrPath_classes = [];
  let comms_sipPath_classes = [];
  let userFullName = currentUser.firstName + " " + currentUser.lastName;
  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userFullName =
      process.env.REACT_APP_AUTH_OVERRIDE_FIRSTNAME +
      " " +
      process.env.REACT_APP_AUTH_OVERRIDE_LASTNAME;
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }
  let ifbFlag = true;
  let plFlag = true;
  let phoFlag = true;

  const [isUpdating, setIsUpdating] = useState(false);
  const [inBoundFields, setInBoundFields] = useState([]);
  const [inBoundFieldsNormal, setInBoundFieldsNormal] = useState([]);
  const [outBoundFields, setOutBoundFields] = useState([]);
  const [outBoundFieldsNormal, setOutBoundFieldsNormal] = useState([]);
  const [commsFields, setCommsFields] = useState([]);
  const [commsFieldsNormal, setCommsFieldsNormal] = useState([]);
  const [autoAddInBoundSignal, setAutoAddInBoundSignal] = useState(true);
  const [autoAddOutBoundSignal, setAutoAddOutBoundSignal] = useState(true);
  const [autoAddCommSignal, setAutoAddCommSignal] = useState(true);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [plButton, setPlButton] = useState(true);
  const [ifbForPl, setIfbForPl] = useState("");
  const [availCheckDisable, setAvailCheckDisable] = useState(true);
  const [availTitle, setAvailTitle] = useState(null);
  const [outBoundButton, setOutBoundButton] = useState(true);
  const [outBoundForInBound, setOutBoundForInBound] = useState("");
  const [customIfbPin, setCustomIfbPin] = useState(null);
  const [customPlPin, setCustomPlPin] = useState(null);
  const [customPhoPin, setCustomPhoPin] = useState(null);
  const [customIndexNum, setCustomIndexNum] = useState(null);
  const [type, setStype] = useState(0);
  const [isOwnSelected, setIsOwnSelected] = useState(false);

  const [rows, setRows] = useState([]);
  const [outboundRows, setOutboundRows] = useState([]);
  const [commsRows, setCommsRows] = useState([]);

  const venueTypeMenu = useRef([]);
  const outBoundTypeMenu = useRef([]);
  const commsTypeMenu = useRef([]);
  const availVCTypeMenu = useRef([]);

  let availVcOptions = [
    { id: 1, name: "SKYPE", globalPath: "SKYPE" },
    { id: 2, name: "QUICKLINK", globalPath: "QLINK" },
    { id: 3, name: "WEB / OTHER", globalPath: "WEB" },
  ];

  useEffect(() => {
    setCustomIfbPin(ifbPin);
  }, [ifbPin]);

  useEffect(() => {
    setCustomPlPin(plPin);
  }, [plPin]);

  useEffect(() => {
    setCustomPhoPin(phoPin);
  }, [phoPin]);

  useEffect(() => {
    fetchRouterDestinations(token);
  }, [token, fetchRouterDestinations]);

  useEffect(() => {
    const commsValues = [...commsFields];
    if (pinObj && pinObj.pinNumber) {
      if (
        (pinObj.pinNumber.accessPin || pinObj.pinNumber.accessPin === "") &&
        customIndexNum !== null
      ) {
        onUpdateLiveShotSignals(
          token,
          commsValues[customIndexNum],
          { pin: pinObj.pinNumber.accessPin },
          currentRoom
        );
      }
    }
  }, [pinObj]);

  useEffect(() => {
    let updatedRows = [...rows];
    if (customIndexNum !== null && availSignal) {
      triggerAvailVcInitState();
      updatedRows[customIndexNum].globalPathName = availSignal.signal;
      updatedRows[customIndexNum] = {
        ...updatedRows[customIndexNum],
        isShowVenueMenu: false,
        globalPathName: availSignal.signal,
        showAvailVcMenu: false,
      };
      setRows(updatedRows);
    }
  }, [availSignal]);

  useEffect(() => {
    const inboundArray = [];
    const outboundArray = [];
    const commsArray = [];
    const inbound = removeDuplicates(liveShotSignalSelected).filter(
      (signal) =>
        signal.abstractLiveshotId === abstractLiveshotId &&
        signal.liveshotId === liveShotId &&
        signal.liveshotSignalTypeId === 1
    );
    for (let key in inbound) {
      inboundArray.push(inbound[key].liveshotSignalId);
    }
    setInBoundFields(inbound);
    updateRows(inbound);
    setInBoundFieldsNormal(
      removeDuplicates(liveShotSignalSelected).filter(
        (signal) =>
          signal.liveshotId === liveShotId &&
          signal.abstractLiveshotId === 0 &&
          inboundArray.includes(signal.abstractLiveshotSignalId)
      )
    );

    const outbound = removeDuplicates(liveShotSignalSelected).filter(
      (signal) =>
        signal.abstractLiveshotId === abstractLiveshotId &&
        signal.liveshotSignalTypeId === 2 &&
        signal.liveshotId === liveShotId
    );
    setOutBoundFields(outbound);
    updateOutBoundRows(outbound);
    for (let key in outbound) {
      outboundArray.push(outbound[key].liveshotSignalId);
    }
    setOutBoundFieldsNormal(
      removeDuplicates(liveShotSignalSelected).filter(
        (signal) =>
          signal.liveshotId === liveShotId &&
          signal.abstractLiveshotId === 0 &&
          outboundArray.includes(signal.abstractLiveshotSignalId)
      )
    );

    const comms = removeDuplicates(liveShotSignalSelected).filter(
      (signal) =>
        signal.abstractLiveshotId === abstractLiveshotId &&
        signal.liveshotId === liveShotId &&
        (signal.liveshotSignalTypeId === 5 ||
          signal.liveshotSignalTypeId === 7 ||
          signal.liveshotSignalTypeId === 8 ||
          signal.liveshotSignalTypeId === 9)
    );
    setCommsFields(comms);
    updateCommsRows(comms);
    for (let key in comms) {
      commsArray.push(comms[key].liveshotSignalId);
    }
    setCommsFieldsNormal(
      removeDuplicates(liveShotSignalSelected).filter(
        (signal) =>
          signal.liveshotId === liveShotId &&
          signal.abstractLiveshotId === abstractLiveshotId &&
          commsArray.includes(signal.abstractLiveshotSignalId)
      )
    );
  }, [liveShotSignalSelected, liveShotId, abstractLiveshotId]);

  useEffect(() => {
    if (
      inBoundFields.length > 0 &&
      formInfo &&
      formInfo.abstractLiveshot.windowEndTime &&
      !isUpdating
    ) {
      for (let key in inBoundFields) {
        const signal = inBoundFields[key];
        if (
          signal.globalPathName &&
          sharedInBoundGlobalPath.includes(signal.globalPathName)
        ) {
          onCheckOwnership(token, signal, formInfo, currentCanvas);
        } else {
          onRemoveRedBorder(signal.liveshotSignalId);
        }
      }
    }
  }, [
    token,
    inBoundFields,
    sharedInBoundGlobalPath,
    onCheckOwnership,
    formInfo,
    onRemoveRedBorder,
    currentCanvas,
    isUpdating,
  ]);

  useEffect(() => {
    if (
      outBoundFields.length > 0 &&
      formInfo &&
      formInfo.abstractLiveshot.windowEndTime &&
      !isUpdating
    ) {
      for (let key in outBoundFields) {
        const signal = outBoundFields[key];
        if (
          signal.globalPathName &&
          sharedOutBoundGlobalPath.includes(signal.globalPathName)
        ) {
          onCheckOwnership(token, signal, formInfo, currentCanvas);
        } else {
          onRemoveRedBorder(signal.liveshotSignalId);
        }
      }
    }
  }, [
    token,
    outBoundFields,
    sharedOutBoundGlobalPath,
    onCheckOwnership,
    formInfo,
    onRemoveRedBorder,
    currentCanvas,
    isUpdating,
  ]);

  useEffect(() => {
    if (
      commsFields.length > 0 &&
      formInfo &&
      formInfo.abstractLiveshot.windowEndTime &&
      !isUpdating
    ) {
      for (let key in commsFields) {
        const signal = commsFields[key];
        if (
          signal.globalPathName &&
          sharedIFBGlobalPath.includes(signal.globalPathName)
        ) {
          onCheckOwnership(token, signal, formInfo, currentCanvas);
        } else {
          onRemoveRedBorder(signal.liveshotSignalId);
        }
      }
    }
  }, [
    token,
    commsFields,
    sharedIFBGlobalPath,
    onCheckOwnership,
    formInfo,
    onRemoveRedBorder,
    currentCanvas,
    isUpdating,
  ]);

  useEffect(() => {
    if (
      commsFields.length > 0 &&
      formInfo &&
      formInfo.abstractLiveshot.windowEndTime &&
      !isUpdating
    ) {
      for (let key in commsFields) {
        const signal = commsFields[key];
        if (
          signal.globalPathName &&
          sharedPLGlobalPath.includes(signal.globalPathName)
        ) {
          onCheckOwnership(token, signal, formInfo, currentCanvas);
        } else {
          onRemoveRedBorder(signal.liveshotSignalId);
        }
      }
    }
  }, [
    token,
    commsFields,
    sharedPLGlobalPath,
    onCheckOwnership,
    formInfo,
    onRemoveRedBorder,
    currentCanvas,
    isUpdating,
  ]);

  useEffect(() => {
    if (
      commsFields.length > 0 &&
      formInfo &&
      formInfo.abstractLiveshot.windowEndTime &&
      !isUpdating
    ) {
      for (let key in commsFields) {
        const signal = commsFields[key];
        if (
          signal.globalPathName &&
          sharedPHOGlobalPath.includes(signal.globalPathName)
        ) {
          onCheckOwnership(token, signal, formInfo, currentCanvas);
        } else {
          onRemoveRedBorder(signal.liveshotSignalId);
        }
      }
    }
  }, [
    token,
    commsFields,
    sharedPHOGlobalPath,
    onCheckOwnership,
    formInfo,
    onRemoveRedBorder,
    currentCanvas,
    isUpdating,
  ]);

  useEffect(() => {
    if (
      commsFields.length > 0 &&
      formInfo &&
      formInfo.abstractLiveshot.windowEndTime &&
      !isUpdating
    ) {
      for (let key in commsFields) {
        const signal = commsFields[key];
        if (
          signal.globalPathName &&
          sharedPHOTXGlobalPath.includes(signal.globalPathName)
        ) {
          onCheckOwnership(token, signal, formInfo, currentCanvas);
        } else {
          onRemoveRedBorder(signal.liveshotSignalId);
        }
      }
    }
  }, [
    token,
    commsFields,
    sharedPHOTXGlobalPath,
    onCheckOwnership,
    formInfo,
    onRemoveRedBorder,
    currentCanvas,
    isUpdating,
  ]);

  useEffect(() => {
    if (
      formInfo &&
      formInfo.abstractLiveshot.windowEndTime &&
      formInfo.abstractLiveshot.windowStartTime
    ) {
      setAvailTitle(null);
      setAvailCheckDisable(false);
    } else {
      setAvailTitle(
        "Set Window start-time and end-time to check available shared resources"
      );
      setAvailCheckDisable(true);
    }
  }, [formInfo]);

  useEffect(() => {
    onFetchSelectedSignal(token, liveShotId, currentCanvasId);
  }, [onFetchSelectedSignal, token, liveShotId]);

  useEffect(() => {
    if (currentCanvas && controlRoomList.length > 0) {
      let room = controlRoomList.find(
        (list) => list.roomId === currentCanvas.roomId
      );
      if (room) {
        setCurrentRoom(room);
      }
    }
  }, [controlRoomList, currentCanvas, currentRoom]);

  useEffect(() => {
    let abSignals = [];
    inBoundFields.forEach((fields) => {
      let crPathNameNormal = inBoundFieldsNormal.find(
        (inboundNormal) =>
          inboundNormal.abstractLiveshotSignalId === fields.liveshotSignalId
      );
      if (crPathNameNormal === undefined) {
        abSignals.push(fields);
      }
    });
    if (abSignals.length > 0 && autoAddInBoundSignal && isDisable) {
      setAutoAddInBoundSignal(false);
    }
  }, [
    token,
    isDisable,
    inBoundFieldsNormal,
    inBoundFields,
    liveShotId,
    userId,
    onCreateNormalSignal,
    autoAddInBoundSignal,
    currentRoom,
  ]);

  useEffect(() => {
    let abSignals = [];
    outBoundFields.forEach((fields) => {
      let crPathNameNormal = outBoundFieldsNormal.find(
        (inboundNormal) =>
          inboundNormal.abstractLiveshotSignalId === fields.liveshotSignalId
      );
      if (crPathNameNormal === undefined) {
        abSignals.push(fields);
      }
    });
    if (abSignals.length > 0 && autoAddOutBoundSignal && isDisable) {
      setAutoAddOutBoundSignal(false);
    }
  }, [
    token,
    isDisable,
    outBoundFieldsNormal,
    outBoundFields,
    liveShotId,
    userId,
    onCreateNormalSignal,
    autoAddOutBoundSignal,
    currentRoom,
  ]);

  useEffect(() => {
    if (commsFieldsNormal.length > 0 && commsFields.length > 0) {
      commsFields.forEach((fields) => {
        let filteredNormalSignal = commsFieldsNormal.filter(
          (signal) =>
            signal.abstractLiveshotSignalId === fields.liveshotSignalId
        );
        if (filteredNormalSignal.length > 1) {
          console.log(
            "2 Normalsignals pointed to same abstsignalId",
            filteredNormalSignal
          );
        }
      });
    }
    let abSignals = [];
    commsFields.forEach((fields) => {
      let crPathNameNormal = commsFieldsNormal.find(
        (inboundNormal) =>
          inboundNormal.abstractLiveshotSignalId === fields.liveshotSignalId
      );
      if (crPathNameNormal === undefined) {
        abSignals.push(fields);
      }
    });
    if (abSignals.length > 0 && autoAddCommSignal && isDisable) {
      setAutoAddCommSignal(false);
    }
  }, [
    token,
    isDisable,
    commsFieldsNormal,
    commsFields,
    liveShotId,
    userId,
    onCreateNormalSignal,
    autoAddCommSignal,
    currentRoom,
  ]);

  const splitSignalValue = (pathName) => {
    var elements = [];
    if (pathName !== null) {
      if (pathName.includes("IFB")) {
        let words = pathName.split(" ");
        for (let i = 0; i < words.length; i++) {
          if (words[i] === "IFB") {
            let ifb = words[i + 1];
            elements.push(ifb);
          }
        }
      } else if (pathName.includes("PL")) {
        var words = pathName.split(" ");
        for (var i = 0; i < words.length; i++) {
          if (words[i] === "PL") {
            var pl = words[i + 1];
            elements.push(pl);
          }
        }
      }
    }
    return elements;
  };

  const splitOutBoundSignalValue = (pathName) => {
    var typicalPatt = /\d\d\d\d?/i;
    var elements = [];
    if (pathName !== null) {
      if (pathName.match(typicalPatt)) {
        elements.push(pathName.match(typicalPatt)[0].trim());
        elements.push(pathName.replace(typicalPatt, ""));
      }
    }
    return elements;
  };

  useEffect(() => {
    if (commsFieldsNormal) {
      let values = commsFieldsNormal.filter((signal) => {
        return signal.liveshotSignalTypeId === 0 && signal.crPathName;
      });
      if (values.length > 0) {
        setPlButton(false);
        let pathName = values[0].crPathName;
        let IfbValue = splitSignalValue(pathName);
        setIfbForPl(IfbValue[0]);
      } else {
        setPlButton(true);
        setIfbForPl("");
      }
    }
  }, [plButton, commsFieldsNormal]);

  useEffect(() => {
    if (inBoundFields) {
      let values = inBoundFields.filter((signal) => {
        return (
          signal.liveshotSignalTypeId === 1 &&
          inBoundGlobalPath.includes(signal.globalPathName)
        );
      });
      if (values.length > 0) {
        setOutBoundButton(false);
        let pathName = values[0].globalPathName;
        let outBoundValue = splitOutBoundSignalValue(pathName);
        setOutBoundForInBound(outBoundValue[0]);
      } else {
        setOutBoundButton(true);
        setOutBoundForInBound("");
      }
    }
  }, [inBoundFields]);

  useEffect(() => {
    if (stDefaultPinInfo && stDefaultPinInfo.liveShotId === liveShotId) {
      onResetSTDefaultPin();
      if (commsFields.length > 0) {
        for (let key in commsFields) {
          const signal = commsFields[key];
          onDeleteLiveShotSignal(
            token,
            signal.liveshotSignalId,
            null,
            currentRoom
          );
        }
      }
      onCreateLiveShotSignalFromStudioTruck(
        token,
        liveShotId,
        abstractLiveshotId,
        5,
        userId,
        currentRoom,
        stDefaultPinInfo.studioDefaultIfb
          ? stDefaultPinInfo.studioDefaultIfb
          : ""
      );
      onCreateLiveShotSignalFromStudioTruck(
        token,
        liveShotId,
        abstractLiveshotId,
        8,
        userId,
        currentRoom,
        stDefaultPinInfo.studioDefaultPl ? stDefaultPinInfo.studioDefaultPl : ""
      );
      onCreateLiveShotSignalFromStudioTruck(
        token,
        liveShotId,
        abstractLiveshotId,
        9,
        userId,
        currentRoom,
        stDefaultPinInfo.studioDefaultPho
          ? stDefaultPinInfo.studioDefaultPho
          : ""
      );
    }
  }, [
    token,
    userId,
    abstractLiveshotId,
    stDefaultPinInfo,
    liveShotId,
    commsFields,
    onDeleteLiveShotSignal,
    onResetSTDefaultPin,
    onCreateLiveShotSignalFromStudioTruck,
    currentRoom,
  ]);

  const createDefaultCommSignals = useCallback(
    (stDefaultPinInfo) => {
      if (commsFields.length > 0) {
        for (let key in commsFields) {
          const signal = commsFields[key];
          onDeleteLiveShotSignal(
            token,
            signal.liveshotSignalId,
            null,
            currentRoom
          );
        }
      }
      onCreateLiveShotSignalFromStudioTruck(
        token,
        liveShotId,
        abstractLiveshotId,
        5,
        userId,
        currentRoom,
        stDefaultPinInfo.studioDefaultIfb
          ? stDefaultPinInfo.studioDefaultIfb
          : ""
      );
      onCreateLiveShotSignalFromStudioTruck(
        token,
        liveShotId,
        abstractLiveshotId,
        8,
        userId,
        currentRoom,
        stDefaultPinInfo.studioDefaultPl ? stDefaultPinInfo.studioDefaultPl : ""
      );
      onCreateLiveShotSignalFromStudioTruck(
        token,
        liveShotId,
        abstractLiveshotId,
        9,
        userId,
        currentRoom,
        stDefaultPinInfo.studioDefaultPho
          ? stDefaultPinInfo.studioDefaultPho
          : ""
      );
    },
    [
      abstractLiveshotId,
      commsFields,
      currentRoom,
      liveShotId,
      onDeleteLiveShotSignal,
      token,
      userId,
      onCreateLiveShotSignalFromStudioTruck,
    ]
  );

  useEffect(() => {
    if (defaultCommSignals) {
      resetDefaultCommSignal();
      createDefaultCommSignals(defaultCommSignals);
    }
  }, [defaultCommSignals, createDefaultCommSignals, resetDefaultCommSignal]);
  const checkForDuplicateLocalPaths = (field, crPath, signalList, key) => {
    let isDuplicate = null;
    isDuplicate = signalList.find(
      (obj) =>
        obj[key] === field[key] &&
        obj.liveshotSignalId !== field.liveshotSignalId &&
        obj.routed
    );
    return isDuplicate;
  };

  const getSignalList = (signalIds = [2, 5, 7, 8, 9]) => {
    let signalList = [];
    signalList = removeDuplicates(liveShotSignalSelected).filter((signal) =>
      signalIds.includes(signal.liveshotSignalTypeId)
    );
    return signalList;
  };

  const handleUseRadioClick = (idx, field, crPath) => {
    let updatedRows = [...rows];
    let inboundSignalList = getSignalList([1]);
    let isDuplicate = checkForDuplicateLocalPaths(
      field,
      crPath,
      inboundSignalList,
      "crPathName"
    );
    if (updatedRows.length > 0) {
      if (!isDuplicate) {
        onTakeAndGrab(
          token,
          "take",
          userId,
          userFullName,
          field,
          crPath,
          liveShotId,
          currentCanvas.roomId,
          currentRoom
        );
      } else {
        field.isDuplicate = true;
        field.confirmDuplicateRoute = confirmDuplicateRoute;
        field.crPath = crPath;
        field.btnType = "take";
        field.isSkipDuplicate = false;
        showDuplicateLocalPathModal(field);
      }
    }
  };
  const confirmDuplicateRoute = (field, crPath, btnType) => {
    let duplicateField = null;
    let signalType = "";
    if (btnType === "take") {
      duplicateField = checkForDuplicateLocalPaths(
        field,
        crPath,
        getSignalList([1]),
        "crPathName"
      );
    } else if (btnType === "grabOutbound") {
      duplicateField = checkForDuplicateLocalPaths(
        field,
        crPath,
        getSignalList([2]),
        "globalPathName"
      );
      signalType = "outbound";
    } else if (btnType === "grabComms") {
      duplicateField = checkForDuplicateLocalPaths(
        field,
        crPath,
        getSignalList([5, 7, 8, 9]),
        "localPathName"
      );
      signalType = "comms";
    }
    if (duplicateField) {
      if (btnType === "take") {
        onUpdateLiveShotSignals(
          token,
          duplicateField,
          { routed: false },
          currentRoom,
          false,
          true
        );
        onTakeAndGrab(
          token,
          btnType,
          userId,
          userFullName,
          field,
          crPath,
          liveShotId,
          currentCanvas.roomId,
          currentRoom
        );
      } else {
        let grabParams = {
          btnType: btnType,
          userId: userId,
          userFullName: userFullName,
          field: field,
          crPath: crPath,
          liveShotId: liveShotId,
          roomId: currentCanvas.roomId,
          currentRoom: currentRoom,
        };
        unOwnLiveshotSignals(
          token,
          duplicateField.liveshotSignalId,
          duplicateField,
          signalType,
          currentRoom,
          true,
          grabParams
        );
      }
    }
  };
  const handleOwnRadioClick = () => {
    setIsOwnSelected(!isOwnSelected);
  };

  const handleOwnOutboundRadioClick = (idx, field, crPath) => {
    let updatedRows = [...outboundRows];
    let isRouted = updatedRows[idx].routed;
    field.isGrabAll = false;
    field.isSkipOverride = false;

    if (isRouted) {
      unOwnLiveshotSignals(
        token,
        field.liveshotSignalId,
        field,
        "outbound",
        currentRoom
      );
    } else {
      let isDuplicate = checkForDuplicateLocalPaths(
        field,
        crPath,
        getSignalList([2]),
        "globalPathName"
      );
      if (updatedRows.length > 0) {
        if (!isDuplicate) {
          field.signalType = "outbound";
          onTakeAndGrab(
            token,
            "grab",
            userId,
            userFullName,
            field,
            crPath,
            liveShotId,
            currentCanvas.roomId,
            currentRoom
          );
        } else {
          field.isDuplicate = true;
          field.confirmDuplicateRoute = confirmDuplicateRoute;
          field.crPath = crPath;
          field.btnType = "grabOutbound";
          field.signalType = "outbound";
          field.isSkipDuplicate = false;
          showDuplicateLocalPathModal(field);
        }
      }
    }
  };
  const handleOwnCommsRadioClick = (idx, field, crPath) => {
    let updatedRows = [...commsRows];
    let isRouted = updatedRows[idx].routed;
    field.isGrabAll = false;
    field.isSkipOverride = false;

    if (isRouted) {
      unOwnLiveshotSignals(
        token,
        field.liveshotSignalId,
        field,
        "crPathName",
        currentRoom
      );
    } else {
      if (updatedRows.length > 0) {
        let isDuplicate = checkForDuplicateLocalPaths(
          field,
          crPath,
          getSignalList([5, 7, 8, 9]),
          "crPathName"
        );
        setCommsRows(updatedRows);
        if (!isDuplicate) {
          field.signalType = "comms";
          onTakeAndGrab(
            token,
            "grab",
            userId,
            userFullName,
            field,
            crPath,
            liveShotId,
            currentCanvas.roomId,
            currentRoom
          );
        } else {
          field.isDuplicate = true;
          field.confirmDuplicateRoute = confirmDuplicateRoute;
          field.crPath = crPath;
          field.btnType = "grabComms";
          field.isSkipDuplicate = false;
          showDuplicateLocalPathModal(field);
        }
      }
    }
  };
  const getAvailVC = (availVcName, field) => {
    let availVc = availVcName.toUpperCase();
    let filteredAvailValue = [];
    let sharedGlobalPath = [];
    if (field.liveshotSignalTypeId === 1) {
      sharedGlobalPath = sharedInBoundGlobalPath;
    } else if (field.liveshotSignalTypeId === 5) {
      sharedGlobalPath = sharedIFBGlobalPath;
    } else if (field.liveshotSignalTypeId === 7) {
      sharedGlobalPath = sharedPHOTXGlobalPath;
    } else if (field.liveshotSignalTypeId === 8) {
      sharedGlobalPath = sharedPLGlobalPath;
    } else {
      sharedGlobalPath = sharedPHOGlobalPath;
    }
    filteredAvailValue = sharedGlobalPath.filter((signal) =>
      signal.includes(availVc)
    );
    let selectedSignals = [];
    for (let key in inBoundFields) {
      const signal = inBoundFields[key];
      if (signal.globalPathName && signal.globalPathName !== "") {
        selectedSignals.push(signal.globalPathName);
      }
    }
    for (let key in outBoundFields) {
      const signal = outBoundFields[key];
      if (signal.globalPathName && signal.globalPathName !== "") {
        selectedSignals.push(signal.globalPathName);
      }
    }
    for (let key in commsFields) {
      const signal = commsFields[key];
      if (signal.globalPathName && signal.globalPathName !== "") {
        selectedSignals.push(signal.globalPathName);
      }
    }

    onAssignAvailableSignals(
      token,
      filteredAvailValue,
      formInfo,
      currentCanvas,
      "InBound",
      field,
      currentRoom,
      userId,
      selectedSignals
    );
  };
  const changeInBoundSignal = (i, event, normalLiveshotSignalId = null) => {
    setIsUpdating(true);
    const values = [...inBoundFields];
    const normalValues = [...inBoundFieldsNormal];
    values[i].updatedBy = parseInt(userId);
    values[i].roomId = currentRoom.roomId;
    values[i][event.target.name] = event.target.value;
    let updatedRows = [...rows];

    // Local path onchange event Inbound
    if (event.target.name === "crPathName") {
      if (event.target.value !== "") {
        values[i].crSuggest = "true";
        updatedRows[i] = {
          ...updatedRows[i],
          crPathName: event.target.value,
        };
      } else {
        values[i].crSuggest = "false";
        updatedRows[i] = {
          ...updatedRows[i],
          isShowVenueMenu: false,
          crPathName: event.target.value,
          routed: false,
        };
        onUpdateLiveShotSignals(
          token,
          values[i],
          { crPathName: event.target.value, routed: false },
          currentRoom
        );
      }
      setRows(updatedRows);
      setInBoundFields(values);
      values[i].crPathNameSelected = "false";
    }

    // Global path onchange event Inbound
    if (event.target.name === "globalPathName") {
      if (event.target.value !== "") {
        values[i].globalSuggest = "true";
        updatedRows[i] = {
          ...updatedRows[i],
          globalPathName: event.target.value,
        };
      } else {
        values[i].globalSuggest = "false";
        updatedRows[i] = {
          ...updatedRows[i],
          isShowVenueMenu: false,
          globalPathName: event.target.value,
          routed: false,
        };
        onUpdateLiveShotSignals(
          token,
          values[i],
          { globalPathName: event.target.value, routed: false },
          currentRoom
        );
      }
      setRows(updatedRows);
      setInBoundFields(values);
      values[i].globalPathNameSelected = "false";
    }

    if (event.target.name === "availcheck") {
      if (event.target.value === "false") {
        values[i].availCheck = "true";
        onCheckAvailableSignals(
          token,
          sharedInBoundGlobalPath,
          formInfo,
          currentCanvas,
          "InBound"
        );
      } else {
        values[i].availCheck = "false";
      }
    }

    if (
      event.target.name === "pathName" ||
      event.target.name === "transmissionPathName"
    ) {
      if (event.target.value !== "") {
        values[i].pathNameSelected = "true";
      }
      if (
        !values[i].uniLateral &&
        event.target.name === "transmissionPathName"
      ) {
        const normalObj = {};
        normalObj.liveshotSignalId = normalLiveshotSignalId;
        normalObj.transmissionPathName = event.target.value;
        normalObj.roomId = currentRoom.roomId;
        normalObj.updatedBy = parseInt(userId);
        onUpdateNormalLiveShotSignals(token, normalObj, currentRoom);
      } else {
        onUpdateLiveShotSignals(token, values[i], currentRoom);
      }
    }
    setInBoundFields(values);
    onChangeSignal(inBoundFields, outBoundFields, commsFields);
  };

  const updateInfo = (field = {}) => {
    let abstractLiveshot = formInfo.abstractLiveshot;
    let user,
      abstractUserName = null;

    if (userList.length > 0 && field !== "" && abstractLiveshot) {
      user = userList.find((usr) => usr.sso === field.updatedBy);
      abstractUserName = userList.find(
        (usr) => usr.sso === abstractLiveshot.updatedBy
      );
    }

    return (
      <div className="update-info-content">
        <div className="update-info-abstract-liveshotSignalId">
          <span>AbstractLiveshotSignal Id: </span>
          {abstractLiveshot.abstractLiveshotId
            ? abstractLiveshot.abstractLiveshotId
            : ""}
        </div>
        <div className="update-info-abstract-updated-by">
          <span>Updated By: </span>
          {abstractUserName ? abstractUserName.userName : "Unknown"}
        </div>
        <div className="update-info-abstract-updated-on">
          <span>Updated On: </span>
          {abstractLiveshot
            ? new Date(abstractLiveshot.updatedOn).toLocaleString([], {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: true,
              })
            : ""}
        </div>
        <div className="update-info-liveshotSignalId">
          <span>LiveshotSignal Id: </span>
          {field.liveshotSignalId ? field.liveshotSignalId : ""}
        </div>
        <div className="update-info-updated-by">
          <span>Updated By: </span> {user ? user.userName : "Unknown"}
        </div>
        <div className="update-info-updated-on">
          <span>Updated On: </span>
          {field.updatedOn
            ? new Date(field.updatedOn).toLocaleString([], {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: true,
              })
            : ""}
        </div>
      </div>
    );
  };

  const changeOutBoundSignal = (i, event, normalLiveshotSignalId = null) => {
    setIsUpdating(true);
    const values = [...outBoundFields];
    const normalValues = [...outBoundFieldsNormal];
    values[i].updatedBy = parseInt(userId);
    values[i].roomId = currentRoom.roomId;
    values[i][event.target.name] = event.target.value;
    let updatedRows = [...outboundRows];

    // Local path onchange event Outbound
    if (event.target.name === "crPathName") {
      if (event.target.value !== "") {
        values[i].crSuggest = "true";
        updatedRows[i] = {
          ...updatedRows[i],
          crPathName: event.target.value,
        };
      } else {
        values[i].crSuggest = "false";
        updatedRows[i] = {
          ...updatedRows[i],
          isShowVenueMenu: false,
          crPathName: event.target.value,
          routed: false,
        };
        onUpdateLiveShotSignals(
          token,
          values[i],
          { crPathName: event.target.value, routed: false },
          currentRoom
        );
      }
      setOutboundRows(updatedRows);
      setOutBoundFields(values);
      values[i].crPathNameSelected = "false";
    }

    // Set global path name outbound
    if (event.target.name === "globalPathName") {
      if (event.target.value !== "") {
        values[i].globalSuggest = "true";
        updatedRows[i] = {
          ...updatedRows[i],
          globalPathName: event.target.value,
        };
      } else {
        values[i].globalSuggest = "false";
        updatedRows[i] = {
          ...updatedRows[i],
          isShowVenueMenu: false,
          globalPathName: event.target.value,
          routed: false,
        };
        onUpdateLiveShotSignals(
          token,
          values[i],
          { globalPathName: event.target.value, routed: false },
          currentRoom
        );
      }
      setOutboundRows(updatedRows);
      setOutBoundFields(values);
      values[i].globalPathNameSelected = "false";
    }

    if (event.target.name === "availcheck") {
      if (event.target.value === "false") {
        values[i].availCheck = "true";
        onCheckAvailableSignals(
          token,
          sharedOutBoundGlobalPath,
          formInfo,
          currentCanvas,
          "OutBound"
        );
      } else {
        values[i].availCheck = "false";
      }
    }

    if (event.target.name === "uniLateral") {
      if (values[i].uniLateral === "true") {
        values[i].uniLateral = false;
        values[i].availCheck = "false";
      } else {
        values[i].uniLateral = true;
      }
      onUpdateUniLateral(token, values[i], currentRoom);
    }
    setOutBoundFields(values);
    onChangeSignal(inBoundFields, outBoundFields, commsFields);
  };

  const changeCommsSignal = (i, event, normalLiveshotSignalId = null) => {
    setIsUpdating(true);
    const normalObj = {};
    const values = [...commsFields];
    const commsValues = [...commsFieldsNormal];
    let updatedRows = [...commsRows];
    if (commsValues.length > 0) {
      commsValues[i][event.target.name] = event.target.value;
    }
    values[i].roomId = currentRoom.roomId;
    values[i].updatedBy = parseInt(userId);

    if (event.target.name === "pin") {
      values[i].pin = event.target.value;
      values[i].pintouched = true;
      if (values[i].globalPathName)
        onSetAccessPin(token, values[i].globalPathName, event.target.value);
    }
    if (event.target.name === "transmissionPathName") {
      onResetAllPin();
      if (event.target.value.includes("IFB")) {
        values[i].liveshotSignalTypeId = 5;
        setCustomIfbPin("");
        onChangePinFromSignals("", "IFB");
      }
      if (event.target.value.includes("PL")) {
        setPlButton(true);
        values[i].liveshotSignalTypeId = 8;
        setCustomPlPin("");
        onChangePinFromSignals("", "PL");
      }
      if (event.target.value.includes("PHO")) {
        setPlButton(true);
        values[i].liveshotSignalTypeId = 9;
        setCustomPhoPin("");
        onChangePinFromSignals("", "PHO");
      }
      values[i].crPathName = "";
      values[i].globalPathName = "";
      values[i].crPathNameSelected = "false";
      values[i].sip = "";
      values[i].sipPathNameSelected = "false";
    }
    values[i][event.target.name] = event.target.value;
    if (event.target.name === "sip") {
      const validPattern = /^[0-9\b]+$/;
      const validCheckedPattern = /^([0-9\b][0-9\b][0-9\b][0-9\b])+$/;
      if (event.target.value === "" || validPattern.test(event.target.value)) {
        if (event.target.value !== "") {
          values[i].sipSuggest = "true";
          updatedRows[i] = {
            ...updatedRows[i],
            sip: event.target.value,
          };
          if (
            event.target.value.length === 0 ||
            validCheckedPattern.test(event.target.value)
          ) {
            updatedRows[i] = {
              ...updatedRows[i],
              routed: false,
            };
            onUpdateLiveShotSignals(
              token,
              values[i],
              { sip: event.target.value, routed: false },
              currentRoom
            );
            onFetchPinBySipNumber(token, event.target.value);
          }
        } else {
          values[i].sipSuggest = "false";
          updatedRows[i] = {
            ...updatedRows[i],
            isShowVenueMenu: false,
            sip: event.target.value,
            routed: false,
          };
          values[i].updatedBy = parseInt(userId);
          commsRows[i].sip = event.target.value;

          onUpdateLiveShotSignals(
            token,
            values[i],
            { sip: event.target.value.toString(), routed: false },
            currentRoom
          );
        }
        setCommsRows(updatedRows);
        setCustomIndexNum(i);
        setCommsFields(commsValues, commsRows[i].sip);
        values[i].sipPathNameSelected = "false";
      }
    }

    if (event.target.name === "crPathName") {
      if (event.target.value !== "") {
        values[i].crSuggest = "true";
        updatedRows[i] = {
          ...updatedRows[i],
          crPathName: event.target.value,
        };
        values[i].crPathName = event.target.value;
      } else {
        setPlButton(true);
        values[i].crSuggest = "false";
        updatedRows[i] = {
          ...updatedRows[i],
          isShowVenueMenu: false,
          crPathName: event.target.value,
          routed: false,
        };
        values[i].updatedBy = parseInt(userId);
        commsRows[i].crPathName = event.target.value;
        values[i].crPathName = event.target.value;
        onUpdateLiveShotSignals(
          token,
          values[i],
          { crPathName: event.target.value, routed: false },
          currentRoom
        );
      }
      setCommsRows(updatedRows);
      setCommsFields(values);
      values[i].crPathNameSelected = "false";
    }
    if (event.target.name === "sipPathComms") {
      if (event.target.value !== "") {
        values[i].sipSuggest = "true";
      } else {
        values[i].sipSuggest = "false";
        values[i].sipPathNameSelected = "true";
        commsValues[i].updatedBy = parseInt(userId);
        commsRows[i].sip = event.target.value;
        commsValues[i].sip = event.target.value;
        setCommsFields(commsValues, commsRows[i].sip);
        setCommsRows(updatedRows);

        onUpdateLiveShotSignals(
          token,
          commsValues[i],
          { sip: commsRows[i].sip },
          currentRoom
        );
      }
    }

    if (event.target.name === "crPathComms") {
      if (event.target.value !== "") {
        values[i].crSuggest = "true";
        updatedRows[i] = {
          ...updatedRows[i],
          crPathName: event.target.value,
        };
      } else {
        setPlButton(true);
        values[i].crSuggest = "false";
        values[i].crPathNameSelected = "true";
        commsValues[i].updatedBy = parseInt(userId);
        commsRows[i].crPathName = event.target.value;
        commsRows[i].routed = false;
        commsValues[i].crPathName = event.target.value;
        normalObj.liveshotSignalId = normalLiveshotSignalId;
        normalObj.roomId = currentRoom.roomId;
        normalObj.updatedBy = parseInt(userId);
        normalObj.crPathName = "";
        onUpdateLiveShotSignals(
          token,
          commsValues[i],
          { crPathName: commsRows[i].crPathName, routed: false },
          currentRoom
        );
      }
      setCommsFields(commsValues);
      setCommsRows(updatedRows);
    }

    if (event.target.name === "availcheck") {
      if (event.target.value === "false") {
        values[i].availCheck = "true";
        if (values[i].liveshotSignalTypeId === 5) {
          onCheckAvailableSignals(
            token,
            sharedIFBGlobalPath,
            formInfo,
            currentCanvas,
            "IFB"
          );
        }
        if (values[i].liveshotSignalTypeId === 8) {
          onCheckAvailableSignals(
            token,
            sharedPLGlobalPath,
            formInfo,
            currentCanvas,
            "PL"
          );
        }
        if (values[i].liveshotSignalTypeId === 9) {
          onCheckAvailableSignals(
            token,
            sharedPHOGlobalPath,
            formInfo,
            currentCanvas,
            "PHO"
          );
        }
      } else {
        values[i].availCheck = "false";
      }
    }

    setCommsFields(values);
    if (
      event.target.name === "pathName" ||
      event.target.name === "transmissionPathName"
    ) {
      onUpdateLiveShotSignals(token, values[i], currentRoom);
      if (event.target.name === "transmissionPathName") {
        commsValues[i]["crPathName"] = "";
        normalObj.liveshotSignalId = normalLiveshotSignalId;
        normalObj.liveshotSignalTypeId = values[i].liveshotSignalTypeId;
        normalObj.roomId = currentRoom.roomId;
        normalObj.updatedBy = parseInt(userId);
        onUpdateNormalLiveShotSignals(token, normalObj, currentRoom);
      }
    }
    onChangeSignal(inBoundFields, outBoundFields, commsFields);
  };

  const setSignals = (signal, i, signalName) => {
    const outBoundValues = [...outBoundFields];
    const normalValuesOutBound = [...outBoundFieldsNormal];
    const inBoundValues = [...inBoundFields];
    const commsValues = [...commsFields];
    if (signalName === "crPathOutBound") {
      outBoundValues[i].crPathName = signal;
      outBoundValues[i].crSuggest = "false";
      outBoundValues[i].updatedBy = parseInt(userId);
      outBoundValues[i].crPathNameSelected = "true";
      outboundRows[i].crPathName = signal;
      outboundRows[i].routed = false;
      outBoundValues[i].routed = false;
      outBoundValues[i].crPathName = signal;
      outBoundValues[i].crKey = !!outboundRows[i].crKey;
      setOutBoundFields(outBoundValues, outboundRows[i].crPathName);
      setOutBoundFieldsNormal(outBoundValues);
      const normalObj = {};
      normalObj.liveshotSignalId = outBoundValues[i].liveshotSignalId;
      normalObj.roomId = currentRoom.roomId;
      normalObj.updatedBy = parseInt(userId);
      normalObj.crPathName = signal;
      onUpdateLiveShotSignals(
        token,
        outBoundValues[i],
        { crPathName: outboundRows[i].crPathName, routed: false },
        currentRoom
      );
    }
    if (signalName === "globalPathOutBound") {
      if (outBoundValues[i].uniLateral) {
        outBoundValues[i].updatedBy = parseInt(userId);
        outBoundValues[i].globalPathName = signal;
        outBoundValues[i].globalSuggest = "false";
        outBoundValues[i].globalPathNameSelected = "true";
        outBoundValues[i].roomId = currentRoom.roomId;
        outBoundValues[i].routed = false;
        outboundRows[i].globalPathName = signal;
        outboundRows[i].routed = false;
        outBoundValues[i].globalKey = !!outboundRows[i].globalKey;
        setOutBoundFields(outBoundValues, outboundRows[i].globalPathName);
        onUpdateLiveShotSignals(
          token,
          outBoundValues[i],
          { globalPathName: outboundRows[i].globalPathName, routed: false },
          currentRoom
        );
      } else {
        const normalObj = {};
        normalObj.liveshotSignalId = normalValuesOutBound[i].liveshotSignalId;
        normalObj.globalPathName = signal;
        normalObj.roomId = currentRoom.roomId;
        normalObj.updatedBy = parseInt(userId);
        onUpdateNormalLiveShotSignals(token, normalObj, currentRoom);
        outBoundValues[i].globalSuggest = "false";
        outBoundValues[i].globalPathNameSelected = "true";
        outBoundValues[i].globalKey = !!outboundRows[i].globalKey;
        setOutBoundFields(outBoundValues);
        normalValuesOutBound[i].globalPathName = signal;
        setOutBoundFieldsNormal(normalValuesOutBound);
      }
    }
    if (signalName === "crPathInBound") {
      inBoundValues[i].crPathName = signal;
      inBoundValues[i].updatedBy = parseInt(userId);
      inBoundValues[i].crSuggest = "false";
      inBoundValues[i].crPathNameSelected = "true";
      inBoundValues[i].crKey = !!inBoundValues[i].crKey;
      //signal local
      rows[i].crPathName = signal;
      inBoundValues[i].crPathName = signal;
      rows[i].routed = false;
      inBoundValues[i].routed = false;
      setInBoundFieldsNormal(inBoundValues);
      const normalObj = {};
      normalObj.liveshotSignalId = inBoundValues[i].liveshotSignalId;
      normalObj.roomId = currentRoom.roomId;
      normalObj.updatedBy = parseInt(userId);
      normalObj.crPathName = signal;
      setInBoundFields(inBoundValues, rows[i].crPathName);
      onUpdateLiveShotSignals(
        token,
        inBoundValues[i],
        { crPathName: rows[i].crPathName, routed: false },
        currentRoom
      );
    }
    if (signalName === "globalPathInBound") {
      inBoundValues[i].updatedBy = parseInt(userId);
      inBoundValues[i].globalPathName = signal;
      inBoundValues[i].globalSuggest = "false";
      inBoundValues[i].globalPathNameSelected = "true";
      inBoundValues[i].globalKey = !!inBoundValues[i].globalKey;
      inBoundValues[i].roomId = currentRoom.roomId;
      rows[i].globalPathName = signal;
      rows[i].routed = false;
      inBoundValues[i].routed = false;
      setInBoundFields(inBoundValues, rows[i].globalPathName);
      onUpdateLiveShotSignals(
        token,
        inBoundValues[i],
        { globalPathName: rows[i].globalPathName, routed: false },
        currentRoom
      );
    }
    if (signalName === "sipPathComms") {
      commsValues[i].sipSuggest = "false";
      commsValues[i].sipPathNameSelected = "true";
      commsValues[i].updatedBy = parseInt(userId);
      commsRows[i].sip = signal;
      commsValues[i].sip = signal;
      commsRows[i].routed = false;
      commsValues[i].routed = false;
      setCommsFields(commsValues);
      setCommsFieldsNormal(commsValues);
      onUpdateLiveShotSignals(
        token,
        commsValues[i],
        { sip: commsRows[i].sip, routed: false },
        currentRoom
      );
      setCustomIndexNum(i);
      setCommsRows(commsRows);
      onFetchPinBySipNumber(token, commsRows[i].sip);
    }
    if (signalName === "crPathComms") {
      commsValues[i].crSuggest = "false";
      commsValues[i].crPathNameSelected = "true";
      commsValues[i].updatedBy = parseInt(userId);
      commsRows[i].crPathName = signal;
      commsValues[i].crPathName = signal;
      commsRows[i].routed = false;
      commsValues[i].routed = false;
      commsValues[i].crKey = !!commsValues[i].crKey;
      setCommsFields(commsValues, commsRows[i].crPathName);
      setCommsFieldsNormal(commsValues);
      onUpdateLiveShotSignals(
        token,
        commsValues[i],
        { crPathName: commsRows[i].crPathName, routed: false },
        currentRoom
      );
    }
    if (signalName === "globalPathComms") {
      commsValues[i].updatedBy = parseInt(userId);
      commsValues[i].globalPathName = signal;
      commsValues[i].globalSuggest = "false";
      commsValues[i].crPathNameSelected = "true";
      commsValues[i].roomId = currentRoom.roomId;
      setCommsFields(commsValues);
      onUpdateLiveShotSignals(token, commsValues[i], currentRoom, true);
    }

    onChangeSignal(inBoundFields, outBoundFields, commsFields);
  };

  const onAddSignal = (
    token,
    liveShotId,
    abstractLiveshotId,
    signalTypeID,
    userId,
    room,
    key
  ) => {
    setAutoAddInBoundSignal(false);
    setAutoAddOutBoundSignal(false);
    setAutoAddCommSignal(false);
    if (key === "addInbound") {
      onCreateLiveShotSignal(
        token,
        liveShotId,
        abstractLiveshotId,
        signalTypeID,
        userId,
        room,
        liveShotVenueType
      );
    } else if (key === "addOutbound" || key === "addOutbutton") {
      let globalPathName = outBoundGlobalPath.filter((outBoundSignal) => {
        return (
          splitOutBoundSignalValue(outBoundSignal)[0] === outBoundForInBound &&
          key === "addOutbutton"
        );
      });
      onCreateLiveShotSignalWithValue(
        token,
        liveShotId,
        abstractLiveshotId,
        signalTypeID,
        userId,
        room,
        "",
        globalPathName[0]
      );
    } else if (key === "addComms") {
      setCommsRows(commsRows);
      onCreateLiveShotSignal(
        token,
        liveShotId,
        abstractLiveshotId,
        signalTypeID,
        userId,
        room,
        ""
      );
    } else {
      let ifbList = [];
      let existingPlList = [];
      if (commsRows) {
        commsRows
          .filter((comms) => comms.crPathName)
          .forEach((comms) => {
            if (comms.crPathName.slice(0, 2) === "PL") {
              let pl = comms.crPathName.slice(4, 7);
              let index = ifbList.indexOf(pl);
              existingPlList.push(pl);
              if (index > -1) {
                ifbList.splice(index, 1);
              }
            } else if (comms.crPathName.slice(0, 3) === "IFB") {
              let ifb = comms.crPathName.slice(5, 8);
              if (!existingPlList.includes(ifb)) {
                ifbList.push(ifb);
              }
            }
          });
      }
      let crPathName =
        ifbList.length > 0
          ? plCRPath.filter((plSignal) => {
              return plSignal.slice(4, 7) == ifbList[0];
            })
          : [];
      onCreateLiveShotSignalWithValue(
        token,
        liveShotId,
        abstractLiveshotId,
        signalTypeID,
        userId,
        room,
        crPathName[0]
      );
    }
  };

  const deleteSignal = (liveShotSignalId, normalLiveshotSignalId = null) => {
    onDeleteLiveShotSignal(
      token,
      liveShotSignalId,
      normalLiveshotSignalId,
      currentRoom
    );
  };

  let loader = <Loader loading={loading} />;

  const onChangeType = (e) => {
    const arrOptions = ["skype", "facetime", "zoom", "qlink"];
    let option = e.target.options[e.target.selectedIndex].text.toLowerCase();
    setStype(e.target.value);
  };
  const onChangeAvailVc = (e, idx) => {
    var a = document.querySelectorAll("li.ul-list-item");
    for (var i = 0; i < a.length; i++) {
      a[i].classList.remove("list-item-bg");
    }
    e.target.classList.add("list-item-bg");
    let availVcValue = e.target.innerHTML;

    let values = [...inBoundFields];
    values[idx].type = availVcValue;

    let updatedRows = [...rows];
    updatedRows[idx] = {
      ...updatedRows[idx],
      showAvailVcMenu: false,
    };
    setRows(updatedRows);
    onUpdateLiveShotSignals(
      token,
      values[idx],
      { type: availVcValue },
      currentRoom
    );
    setInBoundFields(values);
    triggerAvailVcInitState();
    setCustomIndexNum(idx);
  };
  const onChangeOutboundType = (e, idx) => {
    var a = document.querySelectorAll("li.ul-list-item");
    for (var i = 0; i < a.length; i++) {
      a[i].classList.remove("list-item-bg");
    }
    e.target.classList.add("list-item-bg");
    let outboundTypeValue = e.target.innerHTML;

    let values = [...outBoundFields];
    values[idx].type = outboundTypeValue;
    values[idx].updatedBy = parseInt(userId);
    let updatedRows = [...outboundRows];
    updatedRows[idx] = {
      ...updatedRows[idx],
      type: outboundTypeValue,
      isShowOutboundTypeMenu: false,
    };
    setOutboundRows(updatedRows);

    onUpdateLiveShotSignals(
      token,
      values[idx],
      { type: outboundTypeValue },
      currentRoom
    );
    setOutBoundFields(values);
    onChangeSignal(inBoundFields, values, commsFields);
  };
  const onChangeVenueType = (e, idx) => {
    var a = document.querySelectorAll("li.ul-list-item");
    for (var i = 0; i < a.length; i++) {
      a[i].classList.remove("list-item-bg");
    }
    e.target.classList.add("list-item-bg");
    let venuetypeValue = e.target.innerHTML;

    let values = [...inBoundFields];
    values[idx].venueType = venuetypeValue;
    values[idx].updatedBy = parseInt(userId);

    let payloadObj = { venueType: venuetypeValue };

    if (venuetypeValue !== "VIDEO CONFERENCE") {
      let availVc = "";
      payloadObj.type = availVc;
      values[idx].type = availVc;
    }

    let updatedRows = [...rows];
    updatedRows[idx] = {
      ...updatedRows[idx],
      isShowVenueMenu: false,
      venueTypePlaceholder: "",
      venueType: venuetypeValue,
    };
    setRows(updatedRows);

    onUpdateLiveShotSignals(token, values[idx], payloadObj, currentRoom);
    setInBoundFields(values);
    onChangeSignal(values, outBoundFields, commsFields);
  };
  const onChangeCommsType = (e, idx) => {
    const signalType = {
      PHO: 9,
      PL: 8,
      IFB: 5,
    };
    var a = document.querySelectorAll("li.ul-list-item");
    for (var i = 0; i < a.length; i++) {
      a[i].classList.remove("list-item-bg");
    }
    e.target.classList.add("list-item-bg");
    let commstypeValue = e.target.innerHTML;

    let values = [...commsFields];
    values[idx].transmissionPathName = commstypeValue;
    values[idx].liveshotSignalTypeId = signalType[commstypeValue];
    values[idx].updatedBy = parseInt(userId);
    values[idx].crPathName = "";

    let updatedRows = [...commsRows];
    updatedRows[idx] = {
      ...updatedRows[idx],
      isShowCommsTypeMenu: false,
      commsTypePlaceholder: "",
      transmissionPathName: commstypeValue,
    };
    setCommsRows(updatedRows);

    onUpdateLiveShotSignals(
      token,
      values[idx],
      {
        transmissionPathName: commstypeValue,
        liveshotSignalTypeId: signalType[commstypeValue],
        crPathName: "",
      },
      currentRoom
    );
    setCommsFields(values);
  };
  const onChangeInboundLabel = (e, idx) => {
    let values = [...inBoundFields];
    let targetValue = e.target.value;
    values[idx].updatedBy = parseInt(userId);
    values[idx].label = targetValue;

    let updatedRows = [...rows];
    updatedRows[idx] = {
      ...updatedRows[idx],
      isShowVenueMenu: false,
      label: targetValue,
    };
    setRows(updatedRows);
    onUpdateLiveShotSignals(
      token,
      values[idx],
      { label: targetValue },
      currentRoom
    );
    setInBoundFields(values);
  };

  const onChangeInboundTx = (e, idx) => {
    let values = [...inBoundFields];
    values[idx].tx = e.target.value;
    values[idx].updatedBy = parseInt(userId);
    let updatedRows = [...rows];
    updatedRows[idx] = {
      ...updatedRows[idx],
      isShowVenueMenu: false,
      tx: e.target.value,
    };
    setRows(updatedRows);
    onUpdateLiveShotSignals(
      token,
      values[idx],
      { tx: e.target.value },
      currentRoom
    );
    setInBoundFields(values);
    onChangeSignal(values, outBoundFields, commsFields);
  };

  const hideAvailVcMenu = (currentFieldAvailVc, idx) => {
    let updatedRows = [...rows];
    let availPlaceholder = "SELECT";

    let showAvailVcMenuValue = updatedRows[idx].showAvailVcMenu;

    updatedRows[idx] = {
      ...updatedRows[idx],
      showAvailVcMenu: !showAvailVcMenuValue,
      availPlaceholder: availPlaceholder,
    };

    setRows(updatedRows);
  };
  const hideVenueTypes = (idx) => {
    let updatedRows = [...rows];
    let venueTypePlaceholder = "SELECT";

    let isShowVenueMenuValue = updatedRows[idx].isShowVenueMenu;
    updatedRows[idx] = {
      ...updatedRows[idx],
      isShowVenueMenu: !isShowVenueMenuValue,
      venueTypePlaceholder: venueTypePlaceholder,
    };

    setRows(updatedRows);
  };

  const hideDropdownOnClickOutside = (data, setData, idx, updatedField) => {
    if (data.length > idx) {
      const tempData = [...data];
      tempData[idx] = {
        ...tempData[idx],
        ...updatedField,
      };
      setData(tempData);
    }
  };

  const hideOutboundTypes = (idx) => {
    let updatedRows = [...outboundRows];
    let outboundTypePlaceholder = "SELECT";

    let isShowOutboundTypeMenuValue = updatedRows[idx].isShowOutboundTypeMenu;
    updatedRows[idx] = {
      ...updatedRows[idx],
      isShowOutboundTypeMenu: !isShowOutboundTypeMenuValue,
      outboundTypePlaceholder: outboundTypePlaceholder,
    };
    setOutboundRows(updatedRows);
  };
  const hideCommsTypes = (idx) => {
    let updatedRows = [...commsRows];
    let commsTypePlaceholder = "SELECT";

    let isShowCommsTypeMenuValue = updatedRows[idx].isShowCommsTypeMenu;
    updatedRows[idx] = {
      ...updatedRows[idx],
      isShowCommsTypeMenu: !isShowCommsTypeMenuValue,
      commsTypePlaceholder: commsTypePlaceholder,
    };
    setCommsRows(updatedRows);
  };
  const addRow = () => {
    const newRow = {
      isShowVenueMenu: false,
      showAvailVcMenu: false,
      isUseSelected: false,
      isUseHovered: false,
      routed: false,
      availPlaceholder: "SELECT",
      venueTypePlaceholder: "SELECT",
    };
    setRows([...rows, newRow]);
  };
  const addOutboundRow = () => {
    const newRow = {
      isShowOutboundTypeMenu: false,
      isOwnSelected: false,
      routed: false,
      outboundTypePlaceholder: "SELECT",
      pathName: "",
      tx: "",
      uniLateral: false,
    };
    setOutboundRows([...outboundRows, newRow]);
  };
  const addCommsRow = (typeValue) => {
    const newRow = {
      commsTypePlaceholder: "SELECT",
      isShowCommsTypeMenu: false,
      pathName: "",
      commsPin: "",
      sip: "",
      localPathName: "",
      isOwnSelected: false,
      routed: false,
      transmissionPathName: typeValue,
    };
    setCommsRows([...commsRows, newRow]);
  };

  const removeInBoundRow = (idx) => {
    let updatedRows = [...rows];
    let inBoundFieldsRows = [...inBoundFields];

    updatedRows.splice(idx, 1);
    inBoundFieldsRows.splice(idx, 1);

    setInBoundFields(inBoundFieldsRows);
    setRows(updatedRows);
  };

  const removeOutboundRow = (idx) => {
    let updatedRows = [...outboundRows];
    let outBoundFieldsRows = [...outBoundFields];

    updatedRows.splice(idx, 1);
    outBoundFieldsRows.splice(idx, 1);

    setOutBoundFields(outBoundFieldsRows);
    setOutboundRows(updatedRows);
  };

  const removeCommsRow = (idx) => {
    let updatedRows = [...commsRows];
    let commsFieldsRows = [...commsFields];

    updatedRows.splice(idx, 1);
    commsFieldsRows.splice(idx, 1);

    setCommsFields(commsFieldsRows);
    setCommsRows(updatedRows);
  };

  const updateOutBoundRows = (outbound) => {
    let updatedRows = [...outboundRows];
    outbound &&
      outbound.map((item, index) => {
        let crPath = outBoundFieldsNormal.find(
          (outboundNormal) =>
            outboundNormal.abstractLiveshotSignalId === item.liveshotSignalId
        );

        let tx = "";
        let unilateralItem = item.uniLateral;

        if (item.uniLateral) {
          tx = item.tx ? item.tx : "";
        } else {
          tx = crPath ? (crPath.tx ? crPath.tx : "") : "";
        }
        updatedRows[index] = {
          ...updatedRows[index],
          isShowOutboundTypeMenu: false,
          outboundTypePlaceholder: "SELECT",
          pathName: item.pathName,
          globalPathName: item.globalPathName,
          crPathName: item.crPathName,
          tx: tx,
          uniLateral: item.uniLateral,
          routed: item.routed,
        };
      });

    setOutboundRows(updatedRows);
  };
  const updateCommsRows = (comms) => {
    let updatedRows = [...commsRows];
    comms &&
      comms.map((item, index) => {
        updatedRows[index] = {
          ...updatedRows[index],
          isShowCommsTypeMenu: false,
          commsTypePlaceholder: "SELECT",
          pathName: item.pathName,
          commsPin: item.pin || "",
          sip: item.sip || "",
          isOwnSelected: false,
          transmissionPathName: item.transmissionPathName,
          crPathName: item.crPathName || "",
          routed: item.routed,
        };
      });
    setCommsRows(updatedRows);
  };
  const updateRows = (inbound) => {
    let updatedRows = [...rows];
    inbound &&
      inbound.map((item, index) => {
        if (item.venueType) {
          updatedRows[index] = {
            ...updatedRows[index],
            isShowVenueMenu: false,
            availPlaceholder:
              rows[index] && rows[index].availVc
                ? rows[index].availVc
                : "SELECT",
            venueTypePlaceholder: "SELECT",
            label: item.label,
            tx: item.tx,
            globalPathName: item.globalPathName,
            crPathName: item.crPathName,
            transmissionPathName: item.transmissionPathName,
            routed: item.routed,
          };
        } else {
          updatedRows[index] = {
            ...updatedRows[index],
            isShowVenueMenu: false,
            venueTypePlaceholder: "SELECT",
            label: item.label,
            tx: item.tx,
            globalPathName: item.globalPathName,
            crPathName: item.crPathName,
            transmissionPathName: item.transmissionPathName,
            routed: item.routed,
          };
        }
      });

    setRows(updatedRows);
  };
  const onChangeOutboundLabel = (e, idx) => {
    let values = [...outBoundFields];
    values[idx].updatedBy = parseInt(userId);
    values[idx].pathName = e.target.value;
    let updatedRows = [...outboundRows];
    updatedRows[idx] = {
      ...updatedRows[idx],
      pathName: e.target.value,
    };
    onUpdateLiveShotSignals(
      token,
      values[idx],
      { pathName: e.target.value },
      currentRoom
    );
    setOutBoundFields(values);
    setOutboundRows(updatedRows);
    onChangeSignal(inBoundFields, values, commsFields);
  };
  const onChangeCommsLabel = (e, idx) => {
    let values = [...commsFields];
    values[idx].updatedBy = parseInt(userId);
    values[idx].pathName = e.target.value;
    let updatedRows = [...commsRows];
    updatedRows[idx] = {
      ...updatedRows[idx],
      pathName: e.target.value,
    };
    setCommsRows(updatedRows);
    onUpdateLiveShotSignals(
      token,
      values[idx],
      { pathName: e.target.value },
      currentRoom,
      false,
      true
    );
    setCommsFields(values);
  };
  const onChangeCommsPin = (e, idx) => {
    const { value, maxLength } = e.target;
    const validPattern = /^[0-9\b]+$/;
    const validCheckedPattern = /^([0-9\b][0-9\b][0-9\b][0-9\b])+$/;

    if (e.target.value === "" || validPattern.test(e.target.value)) {
      let values = [...commsFields];
      values[idx].updatedBy = parseInt(userId);
      let updatedRows = [...commsRows];
      updatedRows[idx] = {
        ...updatedRows[idx],
        commsPin: e.target.value,
      };
      setCommsRows(updatedRows);
      if (
        e.target.value.length === 0 ||
        validCheckedPattern.test(e.target.value)
      ) {
        onUpdateLiveShotSignals(
          token,
          values[idx],
          { pin: e.target.value },
          currentRoom
        );
      }
      setCommsFields(values);
    }
  };

  const onChangeCommslocalPathName = (idx, e) => {
    setIsUpdating(true);
    let values = [...commsFields];
    values[idx].updatedBy = parseInt(userId);
    let updatedRows = [...commsRows];
    if (e.target.value !== "") {
      values[idx].crSuggest = "true";
    } else {
      values[idx].crSuggest = "false";
      updatedRows[idx] = {
        ...updatedRows[idx],
        crPathName: e.target.value,
      };
      setCommsRows(updatedRows);
      onUpdateLiveShotSignals(
        token,
        values[idx],
        { crPathName: e.target.value },
        currentRoom
      );
      setCommsFields(values);
    }
    onChangeSignal(inBoundFields, outBoundFields, commsFields);
    values[idx].crPathNameSelected = "false";
  };

  const onChangeOutboundUnilateral = (e, idx) => {
    let values = [...outBoundFields];
    let updatedRows = [...outboundRows];
    let targetValue = e.target.value;
    let checkboxValue;
    if (targetValue && targetValue === "true") {
      checkboxValue = false;
    } else if (targetValue && targetValue === "false") {
      checkboxValue = true;
    } else {
      checkboxValue = false;
    }

    updatedRows[idx] = {
      ...updatedRows[idx],
      uniLateral: checkboxValue,
    };
    values[idx].uniLateral = checkboxValue;
    values[idx].updatedBy = parseInt(userId);
    setOutboundRows(updatedRows);
    setOutBoundFields(values);
    onUpdateLiveShotSignals(
      token,
      values[idx],
      { uniLateral: checkboxValue },
      currentRoom
    );
  };
  const onChangeOutboundTransmissionPath = (e, idx) => {
    let values = [...outBoundFields];
    values[idx].updatedBy = parseInt(userId);
    let updatedRows = [...outboundRows];
    let targetValue = e.target.value;
    values[idx].tx = targetValue;
    onUpdateLiveShotSignals(
      token,
      values[idx],
      { tx: targetValue },
      currentRoom
    );
    updatedRows[idx] = {
      ...updatedRows[idx],
      tx: targetValue,
    };
    setOutboundRows(updatedRows);
    setOutBoundFields(values);
    onChangeSignal(inBoundFields, values, commsFields);
  };

  let routerSourcesObj = [];
  routerSources.forEach((rs) => {
    if (rs.namesetName.includes("SIP")) {
      if (!routerSourcesObj.includes(rs.namesetName)) {
        routerSourcesObj.push(rs.namesetName.replace("SIP ", ""));
      }
    }
  });
  const filteredSipComms = routerSourcesObj.filter((sourcesSip) => {
    return sourcesSip;
  });
  const getCommsSignalTypeId = () => {
    let isIfbRowExists = [];
    let isPlRowExists = [];
    let isPhoRowExists = [];
    let commsLiveshotSignalTypeId = [];

    commsFields.forEach((item) => {
      let transmissionPathName = item.transmissionPathName;
      if (
        transmissionPathName &&
        transmissionPathName.toUpperCase() === "IFB"
      ) {
        isIfbRowExists.push(true);
      } else if (
        transmissionPathName &&
        transmissionPathName.toUpperCase() === "PL"
      ) {
        isPlRowExists.push(true);
      } else if (
        transmissionPathName &&
        transmissionPathName.toUpperCase() === "PHO"
      ) {
        isPhoRowExists.push(true);
      }
    });
    if (isIfbRowExists.length > 0) {
      commsLiveshotSignalTypeId.push("5");
    }
    if (isPlRowExists.length > 0) {
      commsLiveshotSignalTypeId.push("8");
    }
    if (isPhoRowExists.length > 0) {
      commsLiveshotSignalTypeId.push("7");
    }

    return commsLiveshotSignalTypeId.join(",");
  };
  const onPinCommsSync = () => {
    let isValid = handlePinCommsSync();
    if (isValid) {
      getCommsSignalTypeId();
      let pinCommsObj = {};
      pinCommsObj.liveshotId = liveShotId;
      pinCommsObj.roomId = currentRoom.roomId;
      pinCommsObj.canvasId = currentCanvasId;
      onPinCommsSignalSync(token, pinCommsObj);
    }
  };
  return (
    <Fragment>
      {loader}
      <div className="mtcdetails">
        <div className="receives">
          <div className="signals-heading">
            {!isDisable && liveShotId !== 0 && (
              <AntdTooltip title="ADD INBOUND PATH">
                <div
                  type="button"
                  btnType="inbound-create-btn"
                  className="btn-header"
                  onClick={() => {
                    onAddSignal(
                      token,
                      liveShotId,
                      abstractLiveshotId,
                      1,
                      userId,
                      currentRoom,
                      "addInbound"
                    );
                    addRow();
                  }}
                >
                  <InboundPlusIcon className="inboundPlusIcon" />
                </div>
              </AntdTooltip>
            )}
            <div className="align-label" />
            <label>INBOUND</label>
          </div>
          <div className="inboundTableContainer">
            <table className="table table-borderless inboundTable">
              <thead>
                <tr className="inboundTableHeaderRow">
                  <th scope="col" className="empty-header"></th>
                  <th scope="col" className="venueTypeHeaderCell">
                    Venue / VC Type
                  </th>
                  <th scope="col" className="availVcHeaderCell">
                    Avail VC
                  </th>
                  <th scope="col" className="labelHeaderCell">
                    Label
                  </th>
                  <th scope="col" className="txpath">
                    Tx
                  </th>
                  <th scope="col" className="globalpath">
                    Global
                  </th>
                  <th scope="col" className="localHeaderCell">
                    Local
                  </th>
                  <th scope="col" className="extendHeaderCell">
                    Extend
                  </th>
                  <th scope="col" className="info-col"></th>
                  <th scope="col" className="useHeaderCell">
                    Use
                  </th>
                  <th scope="col" className="ownHeaderCell">
                    Own
                  </th>
                </tr>
              </thead>
              <tbody>
                {inBoundFields.map((field, idx) => {
                  let crPathListInBound = null;
                  let globalPathListInBound = null;
                  let filteredCRInBound = null;
                  let crPath = inBoundFieldsNormal.find(
                    (inboundNormal) =>
                      inboundNormal.abstractLiveshotSignalId ===
                      field.liveshotSignalId
                  );
                  let localPath = rows[idx].crPathName;
                  let globalPath = rows[idx].globalPathName;
                  let localPathNameError = getPathClassName(
                    field.crPathNameError
                  );
                  let globalPathNameError = getPathClassName(
                    field.globalPathNameError
                  );
                  let isInboundUseDisabled = !(
                    isArmRouting &&
                    (liveshotStatus === "READY FOR AIR" ||
                      liveshotStatus === "READY FOR PCR") &&
                    localPath &&
                    localPath !== null &&
                    globalPath &&
                    globalPath !== null &&
                    localPathNameError.trim() !== "path-error" &&
                    globalPathNameError.trim() !== "path-error"
                  );

                  if (field.crSuggest === "true") {
                    filteredCRInBound = inBoundCRPath.filter((crPath) => {
                      return crPath
                        .toLowerCase()
                        .includes(field.crPathName.toLowerCase());
                    });
                    if (filteredCRInBound.length > 0) {
                      crPathListInBound = (
                        <SignalSuggestions
                          signalLists={filteredCRInBound}
                          idx={idx}
                          signalName="crPathInBound"
                          selectSignal={setSignals}
                        />
                      );
                    }
                  }

                  if (field.globalSuggest === "true") {
                    let globalPathArray = inBoundExtendGlobalPath;
                    if (field.uniLateral) {
                      globalPathArray = inBoundGlobalPath;
                    }
                    let filteredGlobalInBound = globalPathArray.filter(
                      (globalPath) => {
                        return globalPath
                          .toLowerCase()
                          .includes(field.globalPathName.toLowerCase());
                      }
                    );
                    if (field.availCheck === "true") {
                      filteredGlobalInBound = availInBoundSharedSignals.filter(
                        (globalPath) => {
                          return globalPath
                            .toLowerCase()
                            .includes(field.globalPathName.toLowerCase());
                        }
                      );
                    }

                    if (filteredGlobalInBound.length > 0) {
                      globalPathListInBound = (
                        <SignalSuggestions
                          signalLists={filteredGlobalInBound}
                          idx={idx}
                          signalName="globalPathInBound"
                          selectSignal={setSignals}
                        />
                      );
                    }
                  }
                  if (!isDisable) {
                    inbound_classes = ["globalpathField", "disable-input"];
                  }

                  let inBoundCrPathInfo = null;
                  let crTitle = null;
                  if (crPath) {
                    inBoundCrPathInfo = inBoundCRPath.find(
                      (item) => item === crPath.crPathName
                    );
                  }

                  let isRedBorder = null;
                  if (redBorder.length > 0) {
                    isRedBorder = redBorder.find(
                      (item) => item.liveshotSignalId === field.liveshotSignalId
                    );
                  }

                  let inBoundGlobalPathInfo = null;
                  let globalTitle = null;
                  if (field) {
                    if (field.uniLateral) {
                      inBoundGlobalPathInfo = inBoundGlobalPath.find(
                        (item) => item === field.globalPathName
                      );
                    } else {
                      if (crPath) {
                        inBoundGlobalPathInfo = inBoundExtendGlobalPath.find(
                          (item) => item === crPath.globalPathName
                        );
                      }
                    }
                  }

                  if (!inBoundCrPathInfo && crPath) {
                    crTitle = "Field is empty";
                    if (crPath.crPathName !== "") {
                      crTitle = "Name not found in signal list";
                    }
                  } else if (!isDisable) {
                    inbound_classes = ["globalpathField"];
                  } else {
                    inbound_classes = ["disable-input"];
                  }
                  if (!inBoundGlobalPathInfo) {
                    globalTitle = "Field is empty";
                    if (field.globalPathName) {
                      globalTitle = "Name not found in signal list";
                    }
                  } else if (
                    formInfo &&
                    !formInfo.abstractLiveshot.windowEndTime &&
                    sharedInBoundGlobalPath.includes(inBoundGlobalPathInfo)
                  ) {
                    inbound_glpath_classes = ["red-border"];
                    globalTitle = "Window-end must be set for shared resources";
                  } else if (isRedBorder) {
                    inbound_glpath_classes = ["red-border"];
                    let date = new Date(isRedBorder.controlTime);
                    let hours = "" + date.getHours();
                    if (hours.length < 2) hours = "0" + hours;
                    let minutes = "" + date.getMinutes();
                    if (minutes.length < 2) minutes = "0" + minutes;
                    let endDate = new Date(isRedBorder.windowEndTime);
                    let endHours = "" + endDate.getHours();
                    if (endHours.length < 2) endHours = "0" + endHours;
                    let endMinutes = "" + endDate.getMinutes();
                    if (endMinutes.length < 2) endMinutes = "0" + endMinutes;
                    let userName;
                    const userNameList = userList.find(
                      (element) =>
                        element.sso === parseInt(isRedBorder.updatedBy)
                    );
                    if (userNameList !== undefined) {
                      userName = userNameList.userName;
                    } else {
                      userName = parseInt(isRedBorder.updatedBy);
                    }
                    globalTitle =
                      "This signal is reserved by " +
                      userName +
                      " for " +
                      isRedBorder.liveshotName +
                      " between " +
                      hours +
                      ":" +
                      minutes +
                      "-" +
                      endHours +
                      ":" +
                      endMinutes;
                  } else if (!isDisable) {
                    inbound_glpath_classes = ["globalpathField"];
                  } else {
                    inbound_glpath_classes = ["disable-input"];
                  }
                  let txPathValue = "";
                  if (field.uniLateral) {
                    txPathValue = field.transmissionPathName;
                  } else {
                    txPathValue = crPath ? crPath.transmissionPathName : "";
                  }

                  let availVcDropDown = null;
                  availVcDropDown = field.uniLateral ? (
                    <AvailVcMenu
                      isMenuDisable={
                        readOnly || availCheckDisable
                          ? readOnly || availCheckDisable
                          : isDisable
                      }
                      availTitle={availTitle}
                      field={field}
                      getAvailVC={getAvailVC}
                    />
                  ) : (
                    ""
                  );

                  return (
                    <tr
                      key={`${field.liveshotSignalId}-${idx}`}
                      className={field.liveshotSignalId}
                    >
                      <td>
                        {" "}
                        {!isDisable && (
                          <AntdTooltip title="REMOVE PATH">
                            <div
                              type="button"
                              btnType="circle_btn"
                              onClick={() => {
                                deleteSignal(
                                  field.liveshotSignalId,
                                  crPath ? crPath.liveshotSignalId : null
                                );
                                removeInBoundRow(idx);
                              }}
                            >
                              <MinusIcon className="minusIcon" />
                            </div>
                          </AntdTooltip>
                        )}
                      </td>
                      <td
                        className={`venueTypeCell ${
                          isDisable ? "restricted" : ""
                        }`}
                      >
                        <div
                          className={`venueTypeContainer ${
                            rows && rows[idx] && rows[idx].isShowVenueMenu
                              ? "greenBorder"
                              : ""
                          }`}
                        >
                          <div
                            value={type ? type : ""}
                            className="select-type"
                            ref={(el) => (venueTypeMenu.current[idx] = el)}
                            onChange={onChangeType}
                            onClick={() => {
                              if (!isDisable) {
                                hideVenueTypes(idx);
                              }
                            }}
                          >
                            <div
                              className={`                            
                            ${
                              field.venueType
                                ? "displayNone"
                                : "showPlaceholder wrap-text"
                            } ${
                                rows && rows[idx] && rows[idx].isShowVenueMenu
                                  ? "greenColor"
                                  : ""
                              }`}
                            >
                              {rows &&
                                rows[idx] &&
                                rows[idx].venueTypePlaceholder}
                            </div>
                            {field.venueType && (
                              <div
                                className={`info wrap-text ${
                                  rows && rows[idx] && rows[idx].isShowVenueMenu
                                    ? "greenColor"
                                    : ""
                                }`}
                              >
                                {field.venueType}
                              </div>
                            )}
                            <DownArrow
                              style={
                                rows && rows[idx] && rows[idx].isShowVenueMenu
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                              className={`venuedrop ${
                                !field.venueType ? "grey7Color" : "up-arrow"
                              }`}
                            ></DownArrow>
                            <UpArrow
                              style={
                                !(
                                  rows &&
                                  rows[idx] &&
                                  rows[idx].isShowVenueMenu
                                )
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                              className={`venue-up-icon ${
                                rows && rows[idx] && rows[idx].isShowVenueMenu
                                  ? "green-icon"
                                  : ""
                              }`}
                            ></UpArrow>
                          </div>
                        </div>
                        {rows && rows[idx] && rows[idx].isShowVenueMenu && (
                          <ClickAwayListenerHoc
                            elementToIgnore={"venueTypeOption"}
                            key={`clickawayvt-${idx}`}
                            nodeRef={
                              venueTypeMenu.current &&
                              venueTypeMenu.current[idx]
                            }
                            hideElement={() => {
                              hideDropdownOnClickOutside(rows, setRows, idx, {
                                isShowVenueMenu: false,
                              });
                            }}
                          >
                            <div className="list-container">
                              <ul
                                name="venueTypeOptions"
                                className="ul-list-item-container"
                              >
                                {studioTruckTypes &&
                                  studioTruckTypes.map((item) => (
                                    <li
                                      name="venueTypeOption"
                                      className={
                                        field.venueType == item.studioTypeName
                                          ? "ul-list-item list-item-bg"
                                          : "ul-list-item"
                                      }
                                      key={item.studioTypeId}
                                      value={item.studioTypeId}
                                      onClick={(e) => {
                                        onChangeVenueType(e, idx);
                                        changeInBoundSignal(idx, e);
                                      }}
                                    >
                                      {item.studioTypeName}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </ClickAwayListenerHoc>
                        )}
                      </td>
                      <td
                        className={`availVcCell ${
                          isDisable ? "restricted" : ""
                        }`}
                      >
                        <div
                          className={`availVcContainer ${
                            field.venueType &&
                            field.venueType === "VIDEO CONFERENCE"
                              ? "availVcEnabled"
                              : "availVcDisabled"
                          } ${
                            rows && rows[idx] && rows[idx].showAvailVcMenu
                              ? "greenBorder"
                              : ""
                          }`}
                        >
                          <div
                            value={type ? type : ""}
                            ref={(el) => (availVCTypeMenu.current[idx] = el)}
                            className="select-type"
                            onChange={onChangeType}
                            onClick={() => {
                              if (!isDisable) {
                                let availVc =
                                  rows && rows[idx] && rows[idx].availVc
                                    ? rows && rows[idx] && rows[idx].availVc
                                    : "";
                                hideAvailVcMenu(availVc, idx);
                              }
                            }}
                          >
                            <div
                              className={`wrap-text ${
                                field.type
                                  ? "displayNone"
                                  : field.venueType &&
                                    field.venueType === "VIDEO CONFERENCE"
                                  ? "showPlaceholder"
                                  : "showPlaceholderDisabled"
                              } ${
                                rows && rows[idx] && rows[idx].showAvailVcMenu
                                  ? "greenColor"
                                  : ""
                              }`}
                            >
                              {field.venueType === "VIDEO CONFERENCE"
                                ? rows &&
                                  rows[idx] &&
                                  rows[idx].availPlaceholder
                                : "N/A"}
                            </div>
                            {field.type && (
                              <div
                                className={`info wrap-text ${
                                  rows && rows[idx] && rows[idx].showAvailVcMenu
                                    ? "greenColor"
                                    : ""
                                }`}
                              >
                                {field.type}
                              </div>
                            )}
                            <DownArrow
                              style={
                                rows && rows[idx] && rows[idx].showAvailVcMenu
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                              className={`availDrop ${
                                !(rows && rows[idx] && rows[idx].availVc)
                                  ? "grey7Color"
                                  : "up-arrow"
                              } ${
                                field.venueType &&
                                field.venueType === "VIDEO CONFERENCE"
                                  ? "grey7Color"
                                  : "green-icon"
                              }`}
                            ></DownArrow>
                            <UpArrow
                              style={
                                !(
                                  rows &&
                                  rows[idx] &&
                                  rows[idx].showAvailVcMenu
                                )
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                              className={`venue-up-icon ${
                                rows && rows[idx] && rows[idx].showAvailVcMenu
                                  ? "green-icon"
                                  : ""
                              }`}
                            ></UpArrow>
                          </div>
                        </div>
                        {rows && rows[idx] && rows[idx].showAvailVcMenu && (
                          <ClickAwayListenerHoc
                            elementToIgnore={"availVcOption"}
                            key={`clickawayawailvc-${idx}`}
                            nodeRef={
                              availVCTypeMenu.current &&
                              availVCTypeMenu.current[idx]
                            }
                            hideElement={() => {
                              hideDropdownOnClickOutside(rows, setRows, idx, {
                                showAvailVcMenu: false,
                              });
                            }}
                          >
                            <div className="list-container">
                              <ul
                                name="availVcOptions"
                                className="ul-list-item-container"
                              >
                                {availVcOptions &&
                                  availVcOptions.map((item) => (
                                    <li
                                      name="availVcOption"
                                      className={
                                        rows &&
                                        rows[idx] &&
                                        rows[idx].availVc == item.name
                                          ? "ul-list-item list-item-bg"
                                          : "ul-list-item"
                                      }
                                      key={item.id}
                                      value={item.name}
                                      onClick={(e) => {
                                        onChangeAvailVc(e, idx);
                                        /* -- Comment until the release of 2.1 --
                                 getAvailVC(item.globalPath, field);
                                 */
                                      }}
                                    >
                                      {item.name}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </ClickAwayListenerHoc>
                        )}
                      </td>
                      <td className="labelCell">
                        <DebounceInput
                          debounceTimeout={1500}
                          placeholder="ENTER"
                          element={CustomInput}
                          type="text"
                          name="pathName"
                          value={rows[idx] ? rows[idx].label : ""}
                          className={`inboundOutboundInputFields ${
                            !isDisable ? "inBoundLabelClass" : "disable-input"
                          }`}
                          disabled={isDisable}
                          onChange={(e) => onChangeInboundLabel(e, idx)}
                        />
                      </td>
                      <td className="labelCell">
                        <DebounceInput
                          debounceTimeout={1500}
                          placeholder="ENTER"
                          element={CustomInput}
                          type="text"
                          name="transmissionPathName"
                          autoComplete="off"
                          className={`inboundOutboundInputFields ${
                            !isDisable ? "inboundTxPath" : "disable-input"
                          }`}
                          value={rows[idx].tx}
                          onChange={(e) => onChangeInboundTx(e, idx)}
                          disabled={isDisable}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                      </td>

                      <td className="globalpathCell labelCell">
                        {field.uniLateral && (
                          <DebounceInput
                            title={globalTitle}
                            element={CustomInput}
                            type="text"
                            placeholder="ENTER"
                            name="globalPathName"
                            autoComplete="off"
                            className={
                              `inboundOutboundInputFields globalpathField ${inbound_glpath_classes.join(
                                ""
                              )}` + getPathClassName(field.globalPathNameError)
                            }
                            value={rows[idx].globalPathName || ""}
                            onChange={(e) =>
                              changeInBoundSignal(
                                idx,
                                e,
                                crPath ? crPath.liveshotSignalId : ""
                              )
                            }
                            disabled={isDisable}
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                        )}

                        {!field.uniLateral && (
                          <>
                            <DebounceInput
                              title={globalTitle}
                              element={CustomInput}
                              type="text"
                              placeholder="ENTER"
                              name="globalPathName"
                              key={`globalPathName${field.globalKey}`}
                              autoComplete="off"
                              className={
                                `inboundOutboundInputFields globalpathField ${inbound_glpath_classes.join(
                                  ""
                                )}` +
                                getPathClassName(field.globalPathNameError)
                              }
                              value={rows[idx].globalPathName}
                              onChange={(e) =>
                                changeInBoundSignal(
                                  idx,
                                  e,
                                  crPath ? crPath.liveshotSignalId : ""
                                )
                              }
                              disabled={isDisable}
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                            />
                          </>
                        )}

                        {globalPathListInBound}
                      </td>
                      <td className="localCell labelCell">
                        <DebounceInput
                          title={crTitle}
                          element={CustomInput}
                          type="text"
                          name="crPathName"
                          key={`crPathName${field.crKey}`}
                          placeholder="ENTER"
                          autoComplete="off"
                          className={`inboundOutboundInputFields crPathField ${inbound_classes.join(
                            ""
                          )} ${getPathClassName(field.crPathNameError)}`}
                          value={rows[idx].crPathName}
                          onChange={(e) =>
                            changeInBoundSignal(
                              idx,
                              e,
                              crPath ? crPath.liveshotSignalId : ""
                            )
                          }
                          disabled={isDisable}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        {crPathListInBound}
                      </td>
                      <td
                        className={
                          !field.uniLateral ? "extendCellLocal" : "extendCell"
                        }
                      >
                        {field.uniLateral && (
                          <div className="extendiv">
                            {!isDisable && liveShotId !== 0 && (
                              <AntdTooltip
                                title="Extend signal-path to include local CR resource"
                                align={{ offset: [0, 28] }}
                              >
                                <div
                                  type="button"
                                  btnType="circle_btn"
                                  onClick={() =>
                                    onCreateExtendLiveShotSignal(
                                      token,
                                      liveShotId,
                                      abstractLiveshotId,
                                      1,
                                      userId,
                                      currentRoom
                                    )
                                  }
                                >
                                  <ExtendIcon className="extend" />
                                </div>
                              </AntdTooltip>
                            )}
                          </div>
                        )}
                        {!field.uniLateral && (
                          <span className="textlocal">local</span>
                        )}
                      </td>

                      <td className="inbound-info-cell">
                        <Tooltip
                          message={updateInfo(field)}
                          position={"bottom"}
                          title="Update Info"
                          messageTheme="light-theme"
                          titleTheme="light-theme"
                        >
                          <InfoLiveShotLogo className="update-info-icon" />
                        </Tooltip>
                      </td>
                      <td className="use-radio-input-cell">
                        <span
                          className={`useRadio ${
                            isInboundUseDisabled || isDisable
                              ? "cursorNotAllowed"
                              : "radio-btn-allowed"
                          }`}
                        >
                          <div
                            onClick={() => {
                              handleUseRadioClick(idx, field, crPath);
                            }}
                            className={`useRadioDiv ${
                              isInboundUseDisabled || isDisable
                                ? "cursorNotAllowed disableField"
                                : rows[idx] && rows[idx].routed
                                ? "mint6border"
                                : "hoverState"
                            }`}
                          >
                            <div
                              className={
                                rows[idx] && rows[idx].routed
                                  ? "radioFill activeFill"
                                  : "radioHover"
                              }
                            ></div>
                          </div>
                        </span>
                      </td>
                      <td className="own-radio-input-cell">
                        <span className="ownRadio">
                          <div className="ownRadioDiv" aria-hidden="true"></div>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="sends">
          <div className="signals-heading">
            {!isDisable && liveShotId !== 0 && (
              <AntdTooltip title="ADD OUTBOUND PATH">
                <div
                  type="button"
                  btnType="circle_btn"
                  className="btn-header"
                  onClick={() => {
                    onAddSignal(
                      token,
                      liveShotId,
                      abstractLiveshotId,
                      2,
                      userId,
                      currentRoom,
                      "addOutbound"
                    );
                    addOutboundRow();
                  }}
                >
                  <InboundPlusIcon className="outboundPlusIcon" />
                </div>
              </AntdTooltip>
            )}
            <div className="pl-button">
              {!isDisable && liveShotId !== 0 && (
                <AntdTooltip
                  title="CREATE OUTBOUND BASED ON INBOUND"
                  overlayInnerStyle={{ width: 270 }}
                >
                  <div
                    type="button"
                    btnType="circle_btn"
                    className="btn-header"
                    onClick={() =>
                      !outBoundButton &&
                      onAddSignal(
                        token,
                        liveShotId,
                        abstractLiveshotId,
                        2,
                        userId,
                        currentRoom,
                        "addOutbutton"
                      )
                    }
                  >
                    <OutboundIcon
                      className={`outboundLogo ${
                        outBoundButton ? "restricted" : ""
                      }`}
                    />
                  </div>
                </AntdTooltip>
              )}
            </div>
            <label className="plus-btn">OUTBOUND</label>
          </div>
          <div className="outboundTableContainer">
            <table className="table table-borderless outboundTable">
              <thead>
                <tr className="outboundTableHeaderRow">
                  <th scope="col" className="empty-header"></th>
                  <th scope="col" className="outboundTypeHeaderCell">
                    Type
                  </th>
                  <th scope="col" className="availVcHeaderCell"></th>
                  <th scope="col" className="labelHeaderCell-outbound">
                    Label
                  </th>
                  <th scope="col" className="txpath">
                    Tx
                  </th>

                  <th scope="col" className="globalpath">
                    Global
                  </th>
                  <th scope="col" className="localHeaderCell">
                    Local
                  </th>
                  {<th scope="col" className="unilatHeaderCell"></th>}

                  <th scope="col" className="info-col"></th>
                  <th scope="col" className="ownHeaderCell">
                    Use
                  </th>
                  <th scope="col" className="useHeaderCell">
                    Own
                  </th>
                </tr>
              </thead>
              <tbody>
                {outBoundFields.map((field, idx) => {
                  let crPathListOutBound = null;
                  let globalPathListOutBound = null;
                  let crPath = outBoundFieldsNormal.find(
                    (outboundNormal) =>
                      outboundNormal.abstractLiveshotSignalId ===
                      field.liveshotSignalId
                  );

                  if (field.crSuggest === "true") {
                    const filteredCROutBound = outBoundCRPath.filter((item) => {
                      return item
                        .toLowerCase()
                        .includes(field.crPathName.toLowerCase());
                    });
                    if (filteredCROutBound.length > 0) {
                      crPathListOutBound = (
                        <SignalSuggestions
                          signalLists={filteredCROutBound}
                          idx={idx}
                          signalName="crPathOutBound"
                          selectSignal={setSignals}
                        />
                      );
                    }
                  }

                  if (field.globalSuggest === "true") {
                    let filteredGlobalOutBound = outBoundGlobalPath.filter(
                      (globalPath) => {
                        return (
                          globalPath &&
                          globalPath
                            .toLowerCase()
                            .includes(
                              field.uniLateral
                                ? field.globalPathName &&
                                    field.globalPathName.toLowerCase()
                                : crPath.globalPathName &&
                                    crPath.globalPathName.toLowerCase()
                            )
                        );
                      }
                    );

                    if (field.availCheck === "true") {
                      filteredGlobalOutBound =
                        availOutBoundSharedSignals.filter((globalPath) => {
                          return globalPath
                            .toLowerCase()
                            .includes(
                              field.uniLateral
                                ? field.globalPathName.toLowerCase()
                                : crPath.globalPathName.toLowerCase()
                            );
                        });
                    }

                    if (filteredGlobalOutBound.length > 0) {
                      globalPathListOutBound = (
                        <SignalSuggestions
                          signalLists={filteredGlobalOutBound}
                          idx={idx}
                          signalName="globalPathOutBound"
                          selectSignal={setSignals}
                        />
                      );
                    }
                  }
                  let grabbedInfo = null;
                  let grabStatus = "OWN";
                  tg_classes = ["take-grab", "grab"];
                  const grabbed = grabbedSignals.filter(
                    (item) => item.liveshotSignalId === field.liveshotSignalId
                  );
                  if (grabbed.length > 0) {
                    const grabbedTime = new Date(
                      grabbed[0].controltime
                    ).toLocaleString([], {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      hour12: true,
                    });
                    grabbedInfo = `${grabbed[0].controlledByFullName} in ${grabbed[0].controlRoomCode} at ${grabbedTime}`;
                    if (
                      parseInt(grabbed[0].controlledBy) === parseInt(userId) &&
                      grabbed[0].controlType === 1
                    ) {
                      grabStatus = "OWNED";
                      tg_classes = ["take-grab", "grabbed"];
                    } else {
                      tg_classes = ["take-grab", "inuse"];
                      grabStatus = "OWNED BY";
                    }
                  }

                  let outBoundCrPathInfo = null;
                  let crTitle = null;
                  if (crPath) {
                    outBoundCrPathInfo = outBoundCRPath.find(
                      (item) => item === crPath.crPathName
                    );
                  }

                  let isRedBorder = null;
                  if (redBorder.length > 0) {
                    isRedBorder = redBorder.find(
                      (item) => item.liveshotSignalId === field.liveshotSignalId
                    );
                  }

                  let outBoundGlobalPathInfo = null;
                  let globalTitle = null;
                  if (field) {
                    outBoundGlobalPathInfo = outBoundGlobalPath.find(
                      (item) => item === field.globalPathName
                    );
                  }

                  if (!outBoundCrPathInfo && crPath) {
                    crTitle = "Field is empty";
                    if (crPath.crPathName !== "") {
                      crTitle = "Name not found in signal list";
                    }
                  } else if (!isDisable) {
                    outBound_crPath_classes = ["inboundOutboundInputFields"];
                  } else {
                    outBound_crPath_classes = ["disable-input"];
                  }

                  if (!outBoundGlobalPathInfo) {
                    if (
                      !outBoundGlobalPathInfo &&
                      !field.uniLateral &&
                      crPath &&
                      (crPath.globalPathName === null ||
                        crPath.globalPathName === "")
                    ) {
                      globalTitle = "Field is empty";
                    } else if (field.uniLateral) {
                      globalTitle = "Field is empty";
                      if (field.globalPathName) {
                        globalTitle = "Name not found in signal list";
                      }
                    } else if (!isDisable) {
                      outBound_glPath_classes = ["inboundOutboundInputFields"];
                    } else {
                      outBound_glPath_classes = ["disable-input"];
                    }
                  } else if (isRedBorder) {
                    outBound_glPath_classes = ["red-border"];
                    let date = new Date(isRedBorder.controlTime);
                    let hours = "" + date.getHours();
                    if (hours.length < 2) hours = "0" + hours;
                    let minutes = "" + date.getMinutes();
                    if (minutes.length < 2) minutes = "0" + minutes;
                    let endDate = new Date(isRedBorder.windowEndTime);
                    let endHours = "" + endDate.getHours();
                    if (endHours.length < 2) endHours = "0" + endHours;
                    let endMinutes = "" + endDate.getMinutes();
                    if (endMinutes.length < 2) endMinutes = "0" + endMinutes;
                    let userName;
                    const userNameList = userList.find(
                      (element) =>
                        element.sso === parseInt(isRedBorder.updatedBy)
                    );
                    if (userNameList !== undefined) {
                      userName = userNameList.userName;
                    } else {
                      userName = parseInt(isRedBorder.updatedBy);
                    }
                    globalTitle =
                      "This signal is reserved by " +
                      userName +
                      " for " +
                      isRedBorder.liveshotName +
                      " between " +
                      hours +
                      ":" +
                      minutes +
                      "-" +
                      endHours +
                      ":" +
                      endMinutes;
                  } else if (!isDisable) {
                    outBound_glPath_classes = ["inboundOutboundInputFields"];
                  } else {
                    outBound_glPath_classes = ["disable-input"];
                  }

                  if (
                    sharedOutBoundGlobalPath.includes(outBoundGlobalPathInfo) &&
                    formInfo &&
                    !formInfo.abstractLiveshot.windowEndTime
                  ) {
                    outBound_glPath_classes = ["red-border"];
                    globalTitle = "Window-end must be set for shared resources";
                  }

                  if (
                    outBoundGlobalPathInfo &&
                    !field.uniLateral &&
                    crPath &&
                    (crPath.globalPathName === null ||
                      crPath.globalPathName === "")
                  ) {
                    outBound_glPath_classes = ["red-border"];
                    globalTitle = "Field is empty";
                  }
                  let localPath = field.crPathName;
                  let globalPath = field.globalPathName;
                  let localPathNameError = field.crPathNameError;
                  let globalPathNameError = field.globalPathNameError;
                  let isOutboundOwnDisabled = !(
                    isArmRouting &&
                    (liveshotStatus === "READY FOR AIR" ||
                      liveshotStatus === "READY FOR PCR") &&
                    localPath &&
                    localPath !== null &&
                    globalPath &&
                    globalPath !== null &&
                    !localPathNameError &&
                    !globalPathNameError
                  );
                  let isOwnSelected =
                    outboundRows &&
                    outboundRows[idx] &&
                    outboundRows[idx].routed;
                  return (
                    <tr
                      key={`${field}-${idx}`}
                      className={field.liveshotSignalId}
                    >
                      <td>
                        {" "}
                        {!isDisable && (
                          <AntdTooltip title="REMOVE PATH">
                            <div
                              type="button"
                              btnType="circle_btn removeOutBoundSignal"
                              onClick={() => {
                                deleteSignal(
                                  field.liveshotSignalId,
                                  crPath ? crPath.liveshotSignalId : null
                                );
                                removeOutboundRow(idx);
                              }}
                            >
                              <MinusIcon className="minusIcon" />
                            </div>
                          </AntdTooltip>
                        )}
                      </td>
                      <td className="outboundTypeCell">
                        <div
                          className={`typeDropdownContainer ${
                            outboundRows &&
                            outboundRows[idx] &&
                            outboundRows[idx].isShowOutboundTypeMenu
                              ? "greenBorder"
                              : ""
                          }`}
                        >
                          <div
                            className={`select-type placeholderDiv ${
                              isDisable ? "restricted " : ""
                            }`}
                            ref={(el) => (outBoundTypeMenu.current[idx] = el)}
                            value={type ? type : ""}
                            onChange={onChangeType}
                            onClick={() => {
                              if (!isDisable) {
                                hideOutboundTypes(idx);
                              }
                            }}
                          >
                            <div
                              className={`wrap-text ${
                                field.type ? "displayNone" : "showPlaceholder"
                              } ${
                                outboundRows &&
                                outboundRows[idx] &&
                                outboundRows[idx].isShowOutboundTypeMenu
                                  ? "greenColor"
                                  : ""
                              }`}
                            >
                              {outboundRows &&
                                outboundRows[idx] &&
                                outboundRows[idx].outboundTypePlaceholder}
                            </div>
                            {field.type && (
                              <div
                                className={`wrap-text info ${
                                  outboundRows &&
                                  outboundRows[idx] &&
                                  outboundRows[idx].isShowOutboundTypeMenu
                                    ? "greenColor"
                                    : ""
                                }`}
                              >
                                {field.type}
                              </div>
                            )}
                            <DownArrow
                              style={
                                outboundRows &&
                                outboundRows[idx] &&
                                outboundRows[idx].isShowOutboundTypeMenu
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                              className={`outboundDrop ${
                                !field.type ? "grey7Color" : "up-arrow"
                              }`}
                            ></DownArrow>
                            <UpArrow
                              style={
                                !(
                                  outboundRows &&
                                  outboundRows[idx] &&
                                  outboundRows[idx].isShowOutboundTypeMenu
                                )
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                              className={`${
                                outboundRows &&
                                outboundRows[idx] &&
                                outboundRows[idx].isShowOutboundTypeMenu
                                  ? "venue-up-icon green-icon"
                                  : ""
                              }`}
                            ></UpArrow>
                          </div>
                        </div>
                        {outboundRows &&
                          outboundRows[idx] &&
                          outboundRows[idx].isShowOutboundTypeMenu && (
                            <ClickAwayListenerHoc
                              elementToIgnore={"outboundTypeOption"}
                              key={`clickawayoutbound-${idx}`}
                              nodeRef={
                                outBoundTypeMenu.current &&
                                outBoundTypeMenu.current[idx]
                              }
                              hideElement={() => {
                                hideDropdownOnClickOutside(
                                  outboundRows,
                                  setOutboundRows,
                                  idx,
                                  { isShowOutboundTypeMenu: false }
                                );
                              }}
                            >
                              <div className="list-container">
                                <ul
                                  name="outboundTypeOptions"
                                  className="ul-list-item-container"
                                >
                                  {outBoundTypeArray &&
                                    outBoundTypeArray.map((item) => (
                                      <li
                                        name="outboundTypeOption"
                                        className={
                                          field.type == item
                                            ? "ul-list-item list-item-bg"
                                            : "ul-list-item"
                                        }
                                        key={item}
                                        value={item}
                                        onClick={(e) => {
                                          onChangeOutboundType(e, idx);
                                          changeOutBoundSignal(idx, e);
                                        }}
                                      >
                                        {item}
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </ClickAwayListenerHoc>
                          )}
                      </td>
                      <td className="availVcCell-outbound"></td>
                      <td className="labelCell">
                        <DebounceInput
                          debounceTimeout={1500}
                          element={CustomInput}
                          placeholder="ENTER"
                          type="text"
                          name="pathName"
                          value={
                            outboundRows[idx] ? outboundRows[idx].pathName : ""
                          }
                          className={`inboundOutboundInputFields ${
                            !isDisable ? "edit-input" : "disable-input"
                          }`}
                          disabled={isDisable}
                          onChange={(e) => onChangeOutboundLabel(e, idx)}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                      </td>
                      <td className="labelCell">
                        <DebounceInput
                          debounceTimeout={1500}
                          element={CustomInput}
                          type="text"
                          placeholder="ENTER"
                          name="tx"
                          autoComplete="off"
                          className={`inboundOutboundInputFields
                        ${!isDisable ? "outboundTxPath" : "disable-input"}`}
                          value={outboundRows[idx] ? outboundRows[idx].tx : ""}
                          onChange={(e) =>
                            onChangeOutboundTransmissionPath(e, idx)
                          }
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={isDisable}
                        />
                      </td>
                      <td className="globalpathCell labelCell">
                        {field.uniLateral && (
                          <DebounceInput
                            title={globalTitle}
                            element={CustomInput}
                            type="text"
                            placeholder="ENTER"
                            name="globalPathName"
                            autoComplete="off"
                            className={
                              `inboundOutboundInputFields globalpathField ${outBound_glPath_classes.join(
                                ""
                              )}` + getPathClassName(field.globalPathNameError)
                            }
                            value={
                              outboundRows[idx]
                                ? outboundRows[idx].globalPathName
                                : ""
                            }
                            onChange={(e) =>
                              changeOutBoundSignal(
                                idx,
                                e,
                                crPath ? crPath.liveshotSignalId : ""
                              )
                            }
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            disabled={isDisable}
                          />
                        )}

                        {!field.uniLateral && (
                          <>
                            <DebounceInput
                              title={globalTitle}
                              element={CustomInput}
                              type="text"
                              placeholder="ENTER"
                              name="globalPathName"
                              key={`globalPathName${field.globalKey}`}
                              autoComplete="off"
                              className={
                                `inboundOutboundInputFields globalpathField ${outBound_glPath_classes.join(
                                  ""
                                )}` +
                                getPathClassName(field.globalPathNameError)
                              }
                              value={
                                outboundRows[idx]
                                  ? outboundRows[idx].globalPathName
                                  : ""
                              }
                              onChange={(e) =>
                                changeOutBoundSignal(
                                  idx,
                                  e,
                                  crPath ? crPath.liveshotSignalId : ""
                                )
                              }
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              disabled={isDisable}
                            />
                          </>
                        )}

                        {globalPathListOutBound}
                      </td>
                      <td className="localCell labelCell">
                        <DebounceInput
                          title={crTitle}
                          element={CustomInput}
                          type="text"
                          name="crPathName"
                          key={`crPathName${field.crKey}`}
                          placeholder="ENTER"
                          autoComplete="off"
                          className={
                            `inboundOutboundInputFields crPathField ${outBound_crPath_classes.join(
                              ""
                            )}` + getPathClassName(field.crPathNameError)
                          }
                          value={
                            outboundRows[idx]
                              ? outboundRows[idx].crPathName
                              : ""
                          }
                          onChange={(e) =>
                            changeOutBoundSignal(
                              idx,
                              e,
                              crPath ? crPath.liveshotSignalId : ""
                            )
                          }
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={isDisable}
                        />
                        {crPathListOutBound}
                      </td>
                      <td className="unilateral"></td>
                      <td className="inbound-info-cell">
                        <Tooltip
                          message={updateInfo(field)}
                          position={"bottom"}
                          title="Update Info"
                          messageTheme="light-theme"
                          titleTheme="light-theme"
                        >
                          <InfoLiveShotLogo className="update-info-icon" />
                        </Tooltip>
                      </td>
                      <td className="use-radio-input-cell-outbound">
                        <span className="useRadioOutbound">
                          <span className={`useRadio`}>
                            <div
                              className="useOutboundRadioDiv"
                              aria-hidden="true"
                            ></div>
                          </span>
                        </span>
                      </td>
                      <td className="own-radio-input-cell">
                        <span
                          className={`ownRadio ${
                            isOutboundOwnDisabled || isDisable
                              ? "cursorNotAllowed"
                              : "radio-btn-allowed"
                          }`}
                        >
                          <div
                            onClick={() => {
                              handleOwnOutboundRadioClick(idx, field, crPath);
                            }}
                            className={`ownOutboundRadioDiv ${
                              isOutboundOwnDisabled || isDisable
                                ? "cursorNotAllowed disableField"
                                : isOwnSelected
                                ? "mint6border"
                                : "hoverState"
                            }`}
                          >
                            <div
                              className={
                                isOwnSelected
                                  ? "radioFill activeFill"
                                  : "radioHover"
                              }
                            ></div>
                          </div>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mtcdetails">
        <div className="phones">
          <div className="signals-heading">
            {!isDisable && liveShotId !== 0 && (
              <AntdTooltip title="ADD COMMS PATH">
                <div
                  type="button"
                  btnType="circle_btn"
                  className="btn-header"
                  onClick={() => {
                    onAddSignal(
                      token,
                      liveShotId,
                      abstractLiveshotId,
                      5,
                      userId,
                      currentRoom,
                      "addComms"
                    );
                    addCommsRow("IFB");
                  }}
                >
                  <InboundPlusIcon className="inboundPlusIcon" />
                </div>
              </AntdTooltip>
            )}
            <div className="pl-button">
              {!isDisable && liveShotId !== 0 && (
                <AntdTooltip title="ADD A PL">
                  <div
                    type="button"
                    btnType="circle_btn"
                    className="btn-header"
                    onClick={() => {
                      onAddSignal(
                        token,
                        liveShotId,
                        abstractLiveshotId,
                        8,
                        userId,
                        currentRoom,
                        ""
                      );
                      addCommsRow("PL");
                    }}
                  >
                    <PhoneIcon className="inboundPlusIcon" />
                  </div>
                </AntdTooltip>
              )}
            </div>
            <div className="pl-button">
              {/* -- Comment until the release of 2.1 --
            !isDisable && liveShotId !== 0 && (
              <div
                type="button"
                btnType="circle_btn"
                title="Sync PIN COMMS"
                onClick={() => {
                  onPinCommsSync();
                }}
              >
                <img
                  className="pinCommsSyncIcon"
                  src={pinCommsSyncIcon}
                  alt="Pin Comms Logo"
                />
              </div>
              )*/}
            </div>
            <label>COMMS</label>
          </div>
          <div className="commsTableContainer">
            <table className="table table-borderless commsTable">
              <thead>
                <tr>
                  <th scope="col" className="empty-header"></th>
                  <th scope="col" className="commsTypeHeaderCell">
                    Type
                  </th>
                  <th scope="col" className="commslabelHeaderCell">
                    Label
                  </th>
                  <th scope="col" className="commsSipHeaderCell">
                    SIP
                  </th>
                  <th scope="col" className="commsPinHeaderCell">
                    PIN
                  </th>
                  <th scope="col" className="commsLocalpathnameHeaderCell">
                    Local
                  </th>
                  <th scope="col" className="unilatHeaderCell"></th>
                  <th scope="col" className="commsInfoCol"></th>
                  <th scope="col" className="commsOwnHeaderCell">
                    Use
                  </th>
                  <th scope="col" className="commsUseHeaderCell">
                    Own
                  </th>
                </tr>
              </thead>
              <tbody>
                {commsFields.map((field, idx) => {
                  let firstIfbId = null;
                  let firstPlId = null;
                  let firstPhoId = null;
                  if (field && field.liveshotSignalTypeId === 5 && ifbFlag) {
                    ifbFlag = false;
                    firstIfbId = field.liveshotSignalId;
                  }
                  if (field && field.liveshotSignalTypeId === 8 && plFlag) {
                    plFlag = false;
                    firstPlId = field.liveshotSignalId;
                  }
                  if (field && field.liveshotSignalTypeId === 9 && phoFlag) {
                    phoFlag = false;
                    firstPhoId = field.liveshotSignalId;
                  }
                  let pinValue = "";
                  let commsCRPath = null;
                  let commsGlobalPath = null;
                  let commsPin = null;
                  let crPathListComms = null;
                  let sipPathListComms = null;
                  let globalPathListComms = null;
                  let ifbCrPathInfo = null;
                  let plCrPathInfo = null;
                  let phoCrPathInfo = null;
                  let sipPathInfo = null;
                  let phoTxCrPathInfo = null;
                  let crTitleIFB = null;
                  let crTitlePL = null;
                  let crTitlePHO = null;
                  let crTitlePHOTx = null;
                  let sipTitleValidation = null;
                  let crPath = commsFieldsNormal.find(
                    (commsNormal) =>
                      commsNormal.abstractLiveshotSignalId ===
                      field.liveshotSignalId
                  );

                  if (crPath) {
                    ifbCrPathInfo = ifbCRPath.find(
                      (item) => item === crPath.crPathName
                    );
                    plCrPathInfo = plCRPath.find(
                      (item) => item === crPath.crPathName
                    );
                    phoCrPathInfo = phoCRPath.find(
                      (item) => item === crPath.crPathName
                    );

                    phoTxCrPathInfo = phoTxCRPath.find(
                      (item) => item === crPath.crPathName
                    );
                  }

                  if (!ifbCrPathInfo && crPath) {
                    comms_ifbcrPath_classes = ["red-border"];
                    crTitleIFB = "Field is empty";
                    if (crPath.crPathName !== "") {
                      crTitleIFB = "Name not found in signal list";
                    }
                  } else if (!isDisable) {
                    comms_ifbcrPath_classes = ["edit-input"];
                  } else {
                    comms_ifbcrPath_classes = ["disable-input"];
                  }

                  if (!plCrPathInfo && crPath) {
                    comms_plcrPath_classes = ["red-border"];
                    crTitlePL = "Field is empty";
                    if (crPath.crPathName !== "") {
                      crTitlePL = "Name not found in signal list";
                    }
                  } else if (!isDisable) {
                    comms_plcrPath_classes = ["edit-input"];
                  } else {
                    comms_plcrPath_classes = ["edit-input"];
                  }
                  if (!phoCrPathInfo && crPath) {
                    comms_phocrPath_classes = ["red-border"];
                    crTitlePHO = "Field is empty";
                    if (crPath.crPathName !== "") {
                      crTitlePHO = "Name not found in signal list";
                    }
                  } else if (!isDisable) {
                    comms_phocrPath_classes = ["edit-input"];
                  } else {
                    comms_phocrPath_classes = ["edit-input"];
                  }

                  if (!phoTxCrPathInfo && crPath) {
                    comms_phoTxcrPath_classes = ["red-border"];
                    crTitlePHOTx = "Field is empty";
                    if (crPath.crPathName !== "") {
                      crTitlePHOTx = "Name not found in signal list";
                    }
                  } else if (!isDisable) {
                    comms_phoTxcrPath_classes = ["edit-input"];
                  } else {
                    comms_phoTxcrPath_classes = ["disable-input"];
                  }

                  if (!sipPathInfo && commsRows[idx].sip) {
                    comms_sipPath_classes = ["red-border"];
                    sipTitleValidation = "Field is empty";
                    if (commsRows[idx].sip !== "") {
                      sipTitleValidation = "Name not found in SIP list";
                    }
                  } else if (!isDisable) {
                    comms_sipPath_classes = ["edit-input"];
                  } else {
                    comms_sipPath_classes = ["edit-input"];
                  }

                  let isRedBorder = null;
                  if (redBorder.length > 0) {
                    isRedBorder = redBorder.find(
                      (item) => item.liveshotSignalId === field.liveshotSignalId
                    );
                  }

                  let commsIfbGlobalPathInfo = null;
                  let globalTitleIFB = null;
                  if (field) {
                    commsIfbGlobalPathInfo = ifbGlobalPath.find(
                      (item) => item === field.globalPathName
                    );
                  }
                  let commsPlGlobalPathInfo = null;
                  let globalTitlePL = null;
                  if (field) {
                    commsPlGlobalPathInfo = plGlobalPath.find(
                      (item) => item === field.globalPathName
                    );
                  }
                  let commsPhoGlobalPathInfo = null;
                  let globalTitlePHO = null;
                  if (field) {
                    commsPhoGlobalPathInfo = phoGlobalPath.find(
                      (item) => item === field.globalPathName
                    );
                  }
                  let commsPhoTxGlobalPathInfo = null;
                  let globalTitlePHOTx = null;
                  if (field) {
                    commsPhoTxGlobalPathInfo = phoTxGlobalPath.find(
                      (item) => item === field.globalPathName
                    );
                  }

                  if (!commsIfbGlobalPathInfo && commCapabilityType <= 1) {
                    comms_IfbGLPath_classes = ["red-border"];
                    globalTitleIFB = "Field is empty";
                    if (field && field.globalPathName) {
                      globalTitleIFB = "Name not found in signal list";
                    }
                  } else if (
                    sharedIFBGlobalPath.includes(commsIfbGlobalPathInfo) &&
                    formInfo &&
                    !formInfo.abstractLiveshot.windowEndTime
                  ) {
                    comms_IfbGLPath_classes = ["red-border"];
                    globalTitleIFB =
                      "Window-end must be set for shared resources";
                  } else if (isRedBorder) {
                    comms_IfbGLPath_classes = ["red-border"];
                    let date = new Date(isRedBorder.controlTime);
                    let hours = "" + date.getHours();
                    if (hours.length < 2) hours = "0" + hours;
                    let minutes = "" + date.getMinutes();
                    if (minutes.length < 2) minutes = "0" + minutes;
                    let endDate = new Date(isRedBorder.windowEndTime);
                    let endHours = "" + endDate.getHours();
                    if (endHours.length < 2) endHours = "0" + endHours;
                    let endMinutes = "" + endDate.getMinutes();
                    if (endMinutes.length < 2) endMinutes = "0" + endMinutes;
                    let userName;
                    const userNameList = userList.find(
                      (element) =>
                        element.sso === parseInt(isRedBorder.updatedBy)
                    );
                    if (userNameList !== undefined) {
                      userName = userNameList.userName;
                    } else {
                      userName = parseInt(isRedBorder.updatedBy);
                    }
                    globalTitleIFB =
                      "This signal is reserved by " +
                      userName +
                      " for " +
                      isRedBorder.liveshotName +
                      " between " +
                      hours +
                      ":" +
                      minutes +
                      "-" +
                      endHours +
                      ":" +
                      endMinutes;
                  } else if (!isDisable) {
                    comms_IfbGLPath_classes = ["edit-input"];
                  } else {
                    comms_IfbGLPath_classes = ["disable-input"];
                  }

                  if (!commsPlGlobalPathInfo && commCapabilityType <= 1) {
                    comms_PlGLPath_classes = ["red-border"];
                    globalTitlePL = "Field is empty";
                    if (field.globalPathName) {
                      globalTitlePL = "Name not found in signal list";
                    }
                  } else if (
                    sharedPLGlobalPath.includes(commsPlGlobalPathInfo) &&
                    formInfo &&
                    !formInfo.abstractLiveshot.windowEndTime
                  ) {
                    comms_PlGLPath_classes = ["red-border"];
                    globalTitlePL =
                      "Window-end must be set for shared resources";
                  } else if (isRedBorder) {
                    comms_PlGLPath_classes = ["red-border"];
                    let date = new Date(isRedBorder.controlTime);
                    let hours = "" + date.getHours();
                    if (hours.length < 2) hours = "0" + hours;
                    let minutes = "" + date.getMinutes();
                    if (minutes.length < 2) minutes = "0" + minutes;
                    let endDate = new Date(isRedBorder.windowEndTime);
                    let endHours = "" + endDate.getHours();
                    if (endHours.length < 2) endHours = "0" + endHours;
                    let endMinutes = "" + endDate.getMinutes();
                    if (endMinutes.length < 2) endMinutes = "0" + endMinutes;
                    let userName;
                    const userNameList = userList.find(
                      (element) =>
                        element.sso === parseInt(isRedBorder.updatedBy)
                    );
                    if (userNameList !== undefined) {
                      userName = userNameList.userName;
                    } else {
                      userName = parseInt(isRedBorder.updatedBy);
                    }
                    globalTitlePL =
                      "This signal is reserved by " +
                      userName +
                      " for " +
                      isRedBorder.liveshotName +
                      " between " +
                      hours +
                      ":" +
                      minutes +
                      "-" +
                      endHours +
                      ":" +
                      endMinutes;
                  } else if (!isDisable) {
                    comms_PlGLPath_classes = ["edit-input"];
                  } else {
                    comms_PlGLPath_classes = ["disable-input"];
                  }

                  if (!commsPhoGlobalPathInfo && commCapabilityType <= 1) {
                    comms_PhoGLPath_classes = ["red-border"];
                    globalTitlePHO = "Field is empty";
                    if (field.globalPathName) {
                      globalTitlePHO = "Name not found in signal list";
                    }
                  } else if (
                    sharedPHOGlobalPath.includes(commsPhoGlobalPathInfo) &&
                    formInfo &&
                    !formInfo.abstractLiveshot.windowEndTime
                  ) {
                    comms_PhoGLPath_classes = ["red-border"];
                    globalTitlePHO =
                      "Window-end must be set for shared resources";
                  } else if (isRedBorder) {
                    comms_PhoGLPath_classes = ["red-border"];
                    let date = new Date(isRedBorder.controlTime);
                    let hours = "" + date.getHours();
                    if (hours.length < 2) hours = "0" + hours;
                    let minutes = "" + date.getMinutes();
                    if (minutes.length < 2) minutes = "0" + minutes;
                    let endDate = new Date(isRedBorder.windowEndTime);
                    let endHours = "" + endDate.getHours();
                    if (endHours.length < 2) endHours = "0" + endHours;
                    let endMinutes = "" + endDate.getMinutes();
                    if (endMinutes.length < 2) endMinutes = "0" + endMinutes;
                    let userName;
                    const userNameList = userList.find(
                      (element) =>
                        element.sso === parseInt(isRedBorder.updatedBy)
                    );
                    if (userNameList !== undefined) {
                      userName = userNameList.userName;
                    } else {
                      userName = parseInt(isRedBorder.updatedBy);
                    }
                    globalTitlePHO =
                      "This signal is reserved by " +
                      userName +
                      " for " +
                      isRedBorder.liveshotName +
                      " between " +
                      hours +
                      ":" +
                      minutes +
                      "-" +
                      endHours +
                      ":" +
                      endMinutes;
                  } else if (!isDisable) {
                    comms_PhoGLPath_classes = ["edit-input"];
                  } else {
                    comms_PhoGLPath_classes = ["disable-input"];
                  }

                  if (!commsPhoTxGlobalPathInfo) {
                    comms_phoTxGLPath_classes = ["red-border"];
                    globalTitlePHOTx = "Field is empty";
                    if (field && field.globalPathName) {
                      globalTitlePHOTx = "Name not found in signal list";
                    }
                  } else if (
                    sharedPHOTXGlobalPath.includes(commsPhoTxGlobalPathInfo) &&
                    formInfo &&
                    !formInfo.abstractLiveshot.windowEndTime
                  ) {
                    comms_phoTxGLPath_classes = ["red-border"];
                    globalTitlePHOTx =
                      "Window-end must be set for shared resources";
                  } else if (isRedBorder) {
                    comms_phoTxGLPath_classes = ["red-border"];
                    let date = new Date(isRedBorder.controlTime);
                    let hours = "" + date.getHours();
                    if (hours.length < 2) hours = "0" + hours;
                    let minutes = "" + date.getMinutes();
                    if (minutes.length < 2) minutes = "0" + minutes;
                    let endDate = new Date(isRedBorder.windowEndTime);
                    let endHours = "" + endDate.getHours();
                    if (endHours.length < 2) endHours = "0" + endHours;
                    let endMinutes = "" + endDate.getMinutes();
                    if (endMinutes.length < 2) endMinutes = "0" + endMinutes;
                    let userName;
                    const userNameList = userList.find(
                      (element) =>
                        element.sso === parseInt(isRedBorder.updatedBy)
                    );
                    if (userNameList !== undefined) {
                      userName = userNameList.userName;
                    } else {
                      userName = parseInt(isRedBorder.updatedBy);
                    }
                    globalTitlePHOTx =
                      "This signal is reserved by " +
                      userName +
                      " for " +
                      isRedBorder.liveshotName +
                      " between " +
                      hours +
                      ":" +
                      minutes +
                      "-" +
                      endHours +
                      ":" +
                      endMinutes;
                  } else if (!isDisable) {
                    comms_phoTxGLPath_classes = ["edit-input"];
                  } else {
                    comms_phoTxGLPath_classes = ["disable-input"];
                  }

                  if (field && field.liveshotSignalTypeId === 5) {
                    if (field.crSuggest === "true") {
                      const filteredCRComms = ifbCRPath.filter((crPath) => {
                        if (field.crPathName !== null) {
                          return crPath
                            .toLowerCase()
                            .includes(field.crPathName.toLowerCase());
                        } else {
                          return null;
                        }
                      });
                      if (filteredCRComms.length > 0) {
                        crPathListComms = (
                          <SignalSuggestions
                            signalLists={filteredCRComms}
                            idx={idx}
                            signalName="crPathComms"
                            selectSignal={setSignals}
                          />
                        );
                      }
                    }
                    if (field.globalSuggest === "true") {
                      let filteredGlobalComms = ifbGlobalPath.filter(
                        (globalPath) => {
                          return globalPath
                            .toLowerCase()
                            .includes(field.globalPathName.toLowerCase());
                        }
                      );
                      if (field.availCheck === "true") {
                        filteredGlobalComms = availIFBSharedSignals.filter(
                          (globalPath) => {
                            return globalPath
                              .toLowerCase()
                              .includes(field.globalPathName.toLowerCase());
                          }
                        );
                      }

                      if (filteredGlobalComms.length > 0) {
                        globalPathListComms = (
                          <SignalSuggestions
                            signalLists={filteredGlobalComms}
                            idx={idx}
                            signalName="globalPathComms"
                            selectSignal={setSignals}
                          />
                        );
                      }
                    }

                    if (field.sipSuggest === "true") {
                      let routerSourcesObj = [];
                      routerSources.forEach((rs) => {
                        if (rs.namesetName.includes("SIP")) {
                          if (
                            !routerSourcesObj.includes(
                              rs.namesetName.replace("SIP ", "")
                            )
                          ) {
                            routerSourcesObj.push(
                              rs.namesetName.replace("SIP ", "")
                            );
                          }
                        }
                      });
                      const filteredSipComms = routerSourcesObj.filter(
                        (sourcesSip) => {
                          return sourcesSip.includes(field.sip);
                        }
                      );
                      if (filteredSipComms.length > 0) {
                        sipPathListComms = (
                          <SignalSuggestions
                            signalLists={filteredSipComms}
                            idx={idx}
                            signalName="sipPathComms"
                            selectSignal={setSignals}
                          />
                        );
                      }
                    }

                    commsCRPath = (
                      <Fragment>
                        <DebounceInput
                          title={crTitleIFB}
                          element={CustomInput}
                          type="text"
                          name="crPathName"
                          autoComplete="off"
                          className={comms_ifbcrPath_classes.join("")}
                          value={
                            crPath
                              ? crPath.crPathName
                                ? crPath.crPathName
                                : ""
                              : ""
                          }
                          onChange={(e) =>
                            changeCommsSignal(idx, e, crPath.liveshotSignalId)
                          }
                          disabled={isDisable}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        {crPathListComms}
                      </Fragment>
                    );

                    commsGlobalPath = (
                      <Fragment>
                        {commCapabilityType >= 2 && (
                          <DebounceInput
                            element={CustomInput}
                            title={globalTitleIFB}
                            type="text"
                            name="globalPathName"
                            autoComplete="off"
                            className={comms_IfbGLPath_classes.join("")}
                            value={
                              field
                                ? field.globalPathName
                                  ? field.globalPathName
                                  : ""
                                : ""
                            }
                            onChange={(e) => changeCommsSignal(idx, e)}
                            disabled={isDisable}
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                        )}
                        {globalPathListComms}
                      </Fragment>
                    );
                  }

                  if (field && field.liveshotSignalTypeId === 7) {
                    if (field.crSuggest === "true") {
                      const filteredCRComms = phoTxCRPath.filter((crPath) => {
                        return crPath
                          .toLowerCase()
                          .includes(field.crPathName.toLowerCase());
                      });
                      if (filteredCRComms.length > 0) {
                        crPathListComms = (
                          <SignalSuggestions
                            signalLists={filteredCRComms}
                            idx={idx}
                            signalName="crPathComms"
                            selectSignal={setSignals}
                          />
                        );
                      }
                    }

                    if (field.globalSuggest === "true") {
                      const filteredGlobalComms = phoTxGlobalPath.filter(
                        (globalPath) => {
                          return globalPath
                            .toLowerCase()
                            .includes(field.globalPathName.toLowerCase());
                        }
                      );
                      if (filteredGlobalComms.length > 0) {
                        globalPathListComms = (
                          <SignalSuggestions
                            signalLists={filteredGlobalComms}
                            idx={idx}
                            signalName="globalPathComms"
                            selectSignal={setSignals}
                          />
                        );
                      }
                    }
                    if (field.sipSuggest === "true") {
                      let routerSourcesObj = [];
                      routerSources.forEach((rs) => {
                        if (rs.namesetName.includes("SIP")) {
                          if (
                            !routerSourcesObj.includes(
                              rs.namesetName.replace("SIP ", "")
                            )
                          ) {
                            routerSourcesObj.push(
                              rs.namesetName.replace("SIP ", "")
                            );
                          }
                        }
                      });
                      const filteredSipComms = routerSourcesObj.filter(
                        (sourcesSip) => {
                          return sourcesSip.includes(field.sip);
                        }
                      );
                      if (filteredSipComms.length > 0) {
                        sipPathListComms = (
                          <SignalSuggestions
                            signalLists={filteredSipComms}
                            idx={idx}
                            signalName="sipPathComms"
                            selectSignal={setSignals}
                          />
                        );
                      }
                    }

                    commsGlobalPath = (
                      <Fragment>
                        {commCapabilityType >= 3 && (
                          <DebounceInput
                            element={CustomInput}
                            title={globalTitlePHOTx}
                            type="text"
                            name="globalPathName"
                            placeholder="ENTER"
                            autoComplete="off"
                            className={comms_phoTxGLPath_classes.join("")}
                            value={field ? field.globalPathName : ""}
                            onChange={(e) => changeCommsSignal(idx, e)}
                            disabled={isDisable}
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                        )}
                        {globalPathListComms}
                      </Fragment>
                    );

                    commsCRPath = (
                      <Fragment>
                        {commCapabilityType >= 3 && (
                          <DebounceInput
                            element={CustomInput}
                            title={crTitlePHOTx}
                            type="text"
                            name="crPathName"
                            key={`crPathName${field.crKey}`}
                            placeholder="ENTER"
                            autoComplete="off"
                            className={
                              comms_phoTxcrPath_classes.join("") +
                              getPathClassName(field.crPathNameError)
                            }
                            value={crPath ? crPath.crPathName : ""}
                            onChange={(e) =>
                              changeCommsSignal(idx, e, crPath.liveshotSignalId)
                            }
                            disabled={isDisable}
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                        )}
                        {crPathListComms}
                      </Fragment>
                    );
                  }

                  if (field && field.liveshotSignalTypeId === 8) {
                    if (field.crSuggest === "true") {
                      const filteredCRComms = plCRPath.filter((crPath) => {
                        return crPath
                          .toLowerCase()
                          .includes(field.crPathName.toLowerCase());
                      });

                      if (filteredCRComms.length > 0) {
                        crPathListComms = (
                          <SignalSuggestions
                            signalLists={filteredCRComms}
                            idx={idx}
                            signalName="crPathComms"
                            selectSignal={setSignals}
                          />
                        );
                      }
                    }

                    if (field.globalSuggest === "true") {
                      let filteredGlobalComms = plGlobalPath.filter(
                        (globalPath) => {
                          return globalPath
                            .toLowerCase()
                            .includes(field.globalPathName.toLowerCase());
                        }
                      );
                      if (field.availCheck === "true") {
                        filteredGlobalComms = availPLSharedSignals.filter(
                          (globalPath) => {
                            return globalPath
                              .toLowerCase()
                              .includes(field.globalPathName.toLowerCase());
                          }
                        );
                      }
                      if (filteredGlobalComms.length > 0) {
                        globalPathListComms = (
                          <SignalSuggestions
                            signalLists={filteredGlobalComms}
                            idx={idx}
                            signalName="globalPathComms"
                            selectSignal={setSignals}
                          />
                        );
                      }
                    }
                    if (field.sipSuggest === "true") {
                      let routerSourcesObj = [];
                      routerSources.forEach((rs) => {
                        if (rs.namesetName.includes("SIP")) {
                          if (
                            !routerSourcesObj.includes(
                              rs.namesetName.replace("SIP ", "")
                            )
                          ) {
                            routerSourcesObj.push(
                              rs.namesetName.replace("SIP ", "")
                            );
                          }
                        }
                      });
                      const filteredSipComms = routerSourcesObj.filter(
                        (sourcesSip) => {
                          return sourcesSip.includes(field.sip);
                        }
                      );
                      if (filteredSipComms.length > 0) {
                        sipPathListComms = (
                          <SignalSuggestions
                            signalLists={filteredSipComms}
                            idx={idx}
                            signalName="sipPathComms"
                            selectSignal={setSignals}
                          />
                        );
                      }
                    }

                    commsGlobalPath = (
                      <Fragment>
                        {commCapabilityType >= 2 && (
                          <DebounceInput
                            element={CustomInput}
                            title={globalTitlePL}
                            type="text"
                            name="globalPathName"
                            placeholder="ENTER"
                            autoComplete="off"
                            className={comms_PlGLPath_classes.join("")}
                            value={
                              field
                                ? field.globalPathName
                                  ? field.globalPathName
                                  : ""
                                : ""
                            }
                            onChange={(e) => changeCommsSignal(idx, e)}
                            disabled={isDisable}
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                        )}
                        {globalPathListComms}
                      </Fragment>
                    );

                    commsCRPath = (
                      <Fragment>
                        <DebounceInput
                          element={CustomInput}
                          title={crTitlePL}
                          type="text"
                          name="crPathName"
                          key={`crPathName${field.crKey}`}
                          placeholder="ENTER"
                          autoComplete="off"
                          className={comms_plcrPath_classes.join("")}
                          value={crPath ? crPath.crPathName : ""}
                          onChange={(e) =>
                            changeCommsSignal(idx, e, crPath.liveshotSignalId)
                          }
                          disabled={isDisable}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        {crPathListComms}
                      </Fragment>
                    );
                  }
                  if (field && field.liveshotSignalTypeId === 9) {
                    if (field.crSuggest === "true") {
                      const filteredCRComms = phoCRPath.filter((crPath) => {
                        return crPath
                          .toLowerCase()
                          .includes(field.crPathName.toLowerCase());
                      });
                      if (filteredCRComms.length > 0) {
                        crPathListComms = (
                          <SignalSuggestions
                            signalLists={filteredCRComms}
                            idx={idx}
                            signalName="crPathComms"
                            selectSignal={setSignals}
                          />
                        );
                      }
                    }
                    if (field.sipSuggest === "true") {
                      let routerSourcesObj = [];
                      routerSources.forEach((rs) => {
                        if (rs.namesetName.includes("SIP")) {
                          if (
                            !routerSourcesObj.includes(
                              rs.namesetName.replace("SIP ", "")
                            )
                          ) {
                            routerSourcesObj.push(
                              rs.namesetName.replace("SIP ", "")
                            );
                          }
                        }
                      });
                      const filteredSipComms = routerSourcesObj.filter(
                        (sourcesSip) => {
                          return sourcesSip.includes(field.sip);
                        }
                      );
                      if (filteredSipComms.length > 0) {
                        sipPathListComms = (
                          <SignalSuggestions
                            signalLists={filteredSipComms}
                            idx={idx}
                            signalName="sipPathComms"
                            selectSignal={setSignals}
                          />
                        );
                      }
                    }

                    if (field.globalSuggest === "true") {
                      let filteredGlobalComms = phoGlobalPath.filter(
                        (globalPath) => {
                          return globalPath
                            .toLowerCase()
                            .includes(field.globalPathName.toLowerCase());
                        }
                      );
                      if (field.availCheck === "true") {
                        filteredGlobalComms = availPHOSharedSignals.filter(
                          (globalPath) => {
                            return globalPath
                              .toLowerCase()
                              .includes(field.globalPathName.toLowerCase());
                          }
                        );
                      }
                      if (filteredGlobalComms.length > 0) {
                        globalPathListComms = (
                          <SignalSuggestions
                            signalLists={filteredGlobalComms}
                            idx={idx}
                            signalName="globalPathComms"
                            selectSignal={setSignals}
                          />
                        );
                      }
                    }
                    commsGlobalPath = (
                      <Fragment>
                        {commCapabilityType >= 2 && (
                          <DebounceInput
                            element={CustomInput}
                            title={globalTitlePHO}
                            type="text"
                            name="globalPathName"
                            placeholder="ENTER"
                            autoComplete="off"
                            className={comms_PhoGLPath_classes.join("")}
                            value={
                              field
                                ? field.globalPathName
                                  ? field.globalPathName
                                  : ""
                                : ""
                            }
                            onChange={(e) => changeCommsSignal(idx, e)}
                            disabled={isDisable}
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                        )}
                        {globalPathListComms}
                      </Fragment>
                    );

                    commsCRPath = (
                      <Fragment>
                        <DebounceInput
                          element={CustomInput}
                          title={crTitlePHO}
                          type="text"
                          name="crPathName"
                          key={`crPathName${field.crKey}`}
                          placeholder="ENTER"
                          autoComplete="off"
                          className={comms_phocrPath_classes.join("")}
                          value={crPath ? crPath.crPathName : ""}
                          onChange={(e) =>
                            changeCommsSignal(idx, e, crPath.liveshotSignalId)
                          }
                          disabled={isDisable}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        {crPathListComms}
                      </Fragment>
                    );
                  }
                  if (field.globalPathName) {
                    let pinInfo = globalPins.find(
                      (item) => item.namesetName === field.globalPathName
                    );
                    if (pinInfo) {
                      pinValue = pinInfo.accessPin;
                    }
                  }

                  commsPin = (
                    <Fragment>
                      {commCapabilityType >= 1 && commCapabilityType !== 4 && (
                        <DebounceInput
                          debounceTimeout={1500}
                          element={CustomInput}
                          type="text"
                          name="pin"
                          autoComplete="off"
                          className={
                            !isDisable ? "edit-input" : "disable-input"
                          }
                          value={
                            field.pin || field.pintouched ? field.pin : pinValue
                          }
                          onChange={(e) => changeCommsSignal(idx, e)}
                          disabled={isDisable || commCapabilityType <= 4}
                        />
                      )}
                    </Fragment>
                  );

                  if (firstIfbId === field.liveshotSignalId && ifbPin) {
                    commsPin = (
                      <Fragment>
                        {commCapabilityType >= 1 &&
                          commCapabilityType !== 4 && (
                            <DebounceInput
                              debounceTimeout={1500}
                              element={CustomInput}
                              type="text"
                              name="pin"
                              autoComplete="off"
                              className={
                                !isDisable ? "edit-input" : "disable-input"
                              }
                              value={
                                customIfbPin && customIfbPin !== "a1"
                                  ? customIfbPin
                                  : ""
                              }
                              disabled={isDisable || !ifbPin}
                              onChange={(e) => {
                                setCustomIfbPin(e.target.value);
                                if (e.target.value !== "") {
                                  onChangePinFromSignals(e.target.value, "IFB");
                                } else {
                                  onChangePinFromSignals("a1", "IFB");
                                }
                                onSetAccessPin(
                                  token,
                                  field.globalPathName,
                                  e.target.value
                                );
                              }}
                            />
                          )}
                      </Fragment>
                    );
                  }

                  if (firstPlId === field.liveshotSignalId && plPin) {
                    commsPin = (
                      <Fragment>
                        {commCapabilityType >= 1 &&
                          commCapabilityType !== 4 && (
                            <DebounceInput
                              debounceTimeout={1500}
                              element={CustomInput}
                              type="text"
                              name="pin"
                              placeholder="ENTER"
                              autoComplete="off"
                              className={
                                !isDisable ? "edit-input" : "disable-input"
                              }
                              value={
                                customPlPin && customPlPin !== "a1"
                                  ? customPlPin
                                  : ""
                              }
                              disabled={isDisable || !plPin}
                              onChange={(e) => {
                                setCustomPlPin(e.target.value);
                                if (e.target.value !== "") {
                                  onChangePinFromSignals(e.target.value, "PL");
                                } else {
                                  onChangePinFromSignals("a1", "PL");
                                }
                                onSetAccessPin(
                                  token,
                                  field.globalPathName,
                                  e.target.value
                                );
                              }}
                            />
                          )}
                      </Fragment>
                    );
                  }

                  if (firstPhoId === field.liveshotSignalId && phoPin) {
                    commsPin = (
                      <Fragment>
                        {commCapabilityType >= 1 &&
                          commCapabilityType !== 4 && (
                            <DebounceInput
                              debounceTimeout={300}
                              element={CustomInput}
                              type="text"
                              name="pin"
                              placeholder="ENTER"
                              autoComplete="off"
                              className={
                                !isDisable ? "edit-input" : "disable-input"
                              }
                              value={
                                customPhoPin && customPhoPin !== "a1"
                                  ? customPhoPin
                                  : ""
                              }
                              disabled={isDisable || !phoPin}
                              onChange={(e) => {
                                setCustomPhoPin(e.target.value);
                                if (e.target.value !== "") {
                                  onChangePinFromSignals(e.target.value, "PHO");
                                } else {
                                  onChangePinFromSignals("a1", "PHO");
                                }
                                onSetAccessPin(
                                  token,
                                  field.globalPathName,
                                  e.target.value
                                );
                              }}
                            />
                          )}
                      </Fragment>
                    );
                  }

                  tg_classes = ["take-grab", "grab"];
                  const grabbed = grabbedSignals.filter(
                    (item) => item.liveshotSignalId === field.liveshotSignalId
                  );
                  let grabbedInfo = null;
                  let grabStatus = null;
                  if (grabbed.length > 0) {
                    const grabbedTime = new Date(
                      grabbed[0].controltime
                    ).toLocaleString([], {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      hour12: true,
                    });
                    grabbedInfo = `${grabbed[0].controlledByFullName} in ${grabbed[0].controlRoomCode} at ${grabbedTime}`;
                    if (
                      parseInt(grabbed[0].controlledBy) === parseInt(userId) &&
                      grabbed[0].controlType === 1
                    ) {
                      grabStatus = "OWNED";
                      tg_classes = ["take-grab", "grabbed"];
                    } else {
                      tg_classes = ["take-grab", "inuse"];
                      grabStatus = "OWNED BY";
                    }
                  }
                  let localPath = field.crPathName;
                  let localPathNameError = field.crPathNameError;
                  let isCommsOwnDisabled = !(
                    isArmRouting &&
                    (liveshotStatus === "READY FOR AIR" ||
                      liveshotStatus === "READY FOR PCR") &&
                    localPath &&
                    localPath !== null &&
                    !localPathNameError
                  );
                  let isOwnSelected =
                    commsRows && commsRows[idx] && commsRows[idx].routed;
                  return (
                    <tr
                      key={`${field}-${idx}`}
                      className={field.liveshotSignalId}
                    >
                      <td>
                        {" "}
                        {!isDisable && (
                          <AntdTooltip title="REMOVE COMM">
                            <div
                              type="button"
                              btnType="circle_btn removeCommSignal"
                              onClick={() => {
                                deleteSignal(
                                  field.liveshotSignalId,
                                  crPath ? crPath.liveshotSignalId : null
                                );
                                removeCommsRow(idx);
                              }}
                            >
                              <MinusIcon className="minusIcon" />
                            </div>
                          </AntdTooltip>
                        )}
                      </td>
                      <td className="commsTypeCell">
                        <div
                          className={`commsTypeContainer ${
                            commsRows &&
                            commsRows[idx] &&
                            commsRows[idx].isShowCommsTypeMenu
                              ? "greenBorder"
                              : ""
                          }`}
                        >
                          <div
                            className={`select-type ${
                              isDisable ? "restricted" : ""
                            }`}
                            ref={(el) => (commsTypeMenu.current[idx] = el)}
                            value={type ? type : ""}
                            onChange={onChangeType}
                            onClick={() => {
                              if (!isDisable) {
                                hideCommsTypes(idx);
                              }
                            }}
                          >
                            <div
                              className={`${
                                (commsRows &&
                                  commsRows[idx] &&
                                  commsRows[idx].transmissionPathName) ||
                                (commsRows &&
                                  commsRows[idx] &&
                                  commsRows[idx].isShowCommsTypeMenu)
                                  ? "displayNone"
                                  : "showPlaceholder wrap-text"
                              }`}
                            >
                              {commsRows &&
                                commsRows[idx] &&
                                commsRows[idx].commsTypePlaceholder}
                            </div>
                            <div
                              className={`wrap-text info ${
                                commsRows &&
                                commsRows[idx] &&
                                commsRows[idx].isShowCommsTypeMenu
                                  ? "greenColor"
                                  : ""
                              }`}
                            >
                              {commsRows &&
                                commsRows[idx] &&
                                commsRows[idx].transmissionPathName}
                            </div>
                            <DownArrow
                              style={
                                commsRows &&
                                commsRows[idx] &&
                                commsRows[idx].isShowCommsTypeMenu
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                              className={`outboundDrop ${
                                !(
                                  commsRows &&
                                  commsRows[idx] &&
                                  commsRows[idx].transmissionPathName
                                )
                                  ? "grey7Color"
                                  : "up-arrow"
                              }`}
                            ></DownArrow>
                            <UpArrow
                              style={
                                !(
                                  commsRows &&
                                  commsRows[idx] &&
                                  commsRows[idx].isShowCommsTypeMenu
                                )
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                              className={`${
                                commsRows &&
                                commsRows[idx] &&
                                commsRows[idx].isShowCommsTypeMenu
                                  ? "venue-up-icon green-icon"
                                  : ""
                              }`}
                            ></UpArrow>
                          </div>
                        </div>
                        {commsRows &&
                          commsRows[idx] &&
                          commsRows[idx].isShowCommsTypeMenu && (
                            <ClickAwayListenerHoc
                              elementToIgnore={"commsTypeOption"}
                              key={`clickawaycomms-${idx}`}
                              nodeRef={
                                commsTypeMenu.current &&
                                commsTypeMenu.current[idx]
                              }
                              hideElement={() => {
                                hideDropdownOnClickOutside(
                                  commsRows,
                                  setCommsRows,
                                  idx,
                                  { isShowCommsTypeMenu: false }
                                );
                              }}
                            >
                              <div className="list-container">
                                <ul
                                  name="commsTypeOptions"
                                  className="ul-list-item-container"
                                >
                                  {commsTypeArray &&
                                    commsTypeArray.map((item) => (
                                      <li
                                        name="commsTypeOption"
                                        className={
                                          (commsRows &&
                                            commsRows[idx] &&
                                            commsRows[idx]
                                              .transmissionPathName) == item
                                            ? "ul-list-item list-item-bg"
                                            : "ul-list-item"
                                        }
                                        key={item}
                                        value={item}
                                        onClick={(e) => {
                                          onChangeCommsType(e, idx);
                                          changeCommsSignal(idx, e);
                                        }}
                                      >
                                        {item}
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </ClickAwayListenerHoc>
                          )}
                      </td>
                      <td className="labelCell">
                        <DebounceInput
                          debounceTimeout={1500}
                          element={CustomInput}
                          placeholder="ENTER"
                          type="text"
                          name="pathName"
                          value={commsRows[idx] ? commsRows[idx].pathName : ""}
                          className={`inboundOutboundInputFields ${
                            !isDisable ? "edit-input" : "disable-input"
                          }`}
                          onChange={(e) => onChangeCommsLabel(e, idx)}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={isDisable}
                        />
                      </td>
                      <td className="labelCell">
                        <input
                          pattern="([0-9][0-9][0-9][0-9])"
                          placeholder="ENTER"
                          type="text"
                          maxLength={liveshot_canvas_constants.MAX_LENGTH}
                          name="sip"
                          value={commsRows[idx] ? commsRows[idx].sip : ""}
                          className={`inboundOutboundInputFields`}
                          onChange={(e) => changeCommsSignal(idx, e)}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={true}
                        />
                        {sipPathListComms}
                      </td>
                      <td className="labelCell">
                        <input
                          placeholder="ENTER"
                          pattern="([0-9][0-9][0-9][0-9])"
                          type="text"
                          maxLength={liveshot_canvas_constants.MAX_LENGTH}
                          name="commsPin"
                          value={commsRows[idx] ? commsRows[idx].commsPin : ""}
                          className={`inboundOutboundInputFields ${
                            !isDisable ? "edit-input" : "disable-input"
                          }`}
                          onChange={(e) => onChangeCommsPin(e, idx)}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={true}
                        />
                      </td>
                      <td className="labelCell">
                        <DebounceInput
                          element={CustomInput}
                          placeholder="ENTER"
                          type="text"
                          name="crPathName"
                          key={`crPathName${field.crKey}`}
                          value={
                            commsRows[idx] ? commsRows[idx].crPathName : ""
                          }
                          className={`inboundOutboundInputFields ${
                            !isDisable ? "edit-input" : "disable-input"
                          } ${getPathClassName(field.crPathNameError)}`}
                          onChange={(e) =>
                            changeCommsSignal(
                              idx,
                              e,
                              crPath ? crPath.liveshotSignalId : ""
                            )
                          }
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={isDisable}
                        />
                        {crPathListComms}
                      </td>
                      <td className="unilateral"></td>
                      <td className="inbound-info-cell">
                        <Tooltip
                          message={updateInfo(field)}
                          position={"bottom"}
                          title="Update Info"
                          messageTheme="light-theme"
                          titleTheme="light-theme"
                        >
                          <InfoLiveShotLogo className="update-info-icon" />
                        </Tooltip>
                      </td>
                      <td className="use-radio-input-cell-outbound">
                        <span className="useRadioOutbound">
                          <span className={`useRadio`}>
                            <div
                              className="useOutboundRadioDiv disableField"
                              aria-hidden="true"
                            ></div>
                          </span>
                        </span>
                      </td>
                      <td className="own-radio-input-cell">
                        <span
                          className={`ownRadio ${
                            isCommsOwnDisabled || isDisable
                              ? "cursorNotAllowed"
                              : "radio-btn-allowed"
                          }`}
                        >
                          <div
                            onClick={() => {
                              handleOwnCommsRadioClick(idx, field, crPath);
                            }}
                            className={`ownOutboundRadioDiv ${
                              isCommsOwnDisabled || isDisable
                                ? "cursorNotAllowed disableField"
                                : isOwnSelected
                                ? "mint6border"
                                : "hoverState"
                            }`}
                          >
                            <div
                              className={
                                isOwnSelected
                                  ? "radioFill activeFill"
                                  : "radioHover"
                              }
                            ></div>
                          </div>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    currentUser: state.auth.currentUser,
    currentCanvasId: state.canvasInfo.currentCanvasId,
    currentCanvas: state.canvasInfo.currentCanvas,
    controlRoomList: state.canvasInfo.controlRoomList,
    commCapabilityType: state.canvasInfo.commCapabilityType,
    inBoundCRPath: state.signalsInfo.inBoundCRPath,
    inBoundGlobalPath: state.signalsInfo.inBoundGlobalPath,
    inBoundExtendGlobalPath: state.signalsInfo.inBoundExtendGlobalPath,
    liveShotSignalSelected: state.signalsInfo.liveShotSignalSelected,
    availSignal: state.signalsInfo.signal,
    pinObj: state.signalsInfo.pinNumber,
    outBoundCRPath: state.signalsInfo.outBoundCRPath,
    outBoundGlobalPath: state.signalsInfo.outBoundGlobalPath,
    ifbCRPath: state.signalsInfo.ifbCRPath,
    ifbGlobalPath: state.signalsInfo.ifbGlobalPath,
    plGlobalPath: state.signalsInfo.plGlobalPath,
    plCRPath: state.signalsInfo.plCRPath,
    phoGlobalPath: state.signalsInfo.phoGlobalPath,
    phoCRPath: state.signalsInfo.phoCRPath,
    phoTxCRPath: state.signalsInfo.phoTxCRPath,
    phoTxGlobalPath: state.signalsInfo.phoTxGlobalPath,
    globalPins: state.signalsInfo.globalPins,
    grabbedSignals: state.signalsInfo.grabbedSignals,
    signalMsg: state.signalsInfo.signalMsg,
    showSignalModal: state.signalsInfo.showSignalModal,
    userList: state.user.userList,
    isArmRouting: state.canvasInfo.armRouting,
    redBorder: state.signalsInfo.redBorder,
    sharedInBoundGlobalPath: state.signalsInfo.sharedInBoundGlobalPath,
    sharedOutBoundGlobalPath: state.signalsInfo.sharedOutBoundGlobalPath,
    sharedIFBGlobalPath: state.signalsInfo.sharedIFBGlobalPath,
    sharedPLGlobalPath: state.signalsInfo.sharedPLGlobalPath,
    sharedPHOGlobalPath: state.signalsInfo.sharedPHOGlobalPath,
    sharedPHOTXGlobalPath: state.signalsInfo.sharedPHOTXGlobalPath,
    availInBoundSharedSignals: state.signalsInfo.availInBoundSharedSignals,
    availOutBoundSharedSignals: state.signalsInfo.availOutBoundSharedSignals,
    availIFBSharedSignals: state.signalsInfo.availIFBSharedSignals,
    availPLSharedSignals: state.signalsInfo.availPLSharedSignals,
    availPHOSharedSignals: state.signalsInfo.availPHOSharedSignals,
    loading: state.routerSourceDestination.loading,
    stDefaultPinInfo: state.studioTruckInfo.stDefaultPinInfo,
    routerSources: state.routerSourceDestination.routerSources,
    studioTruckTypes: state.studioTruckInfo.studioTruckTypes,
    studioTruckData: state.studioTruckInfo.studioTruckData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchSelectedSignal: (token, liveshotId, canvasId) =>
      dispatch(actions.fetchSelectedSignal(token, liveshotId, canvasId)),
    onSetAccessPin: (token, path, pin) =>
      dispatch(actions.setAccessPin(token, path, pin)),
    onCreateLiveShotSignal: (
      token,
      lsId,
      ablsId,
      lsSignalTypeId,
      userId,
      room,
      venueType
    ) =>
      dispatch(
        actions.createLiveShotSignal(
          token,
          lsId,
          ablsId,
          lsSignalTypeId,
          userId,
          room,
          venueType
        )
      ),
    onCreateLiveShotSignalFromStudioTruck: (
      token,
      lsId,
      ablsId,
      lsSignalTypeId,
      userId,
      room,
      globalPathName
    ) =>
      dispatch(
        actions.createLiveShotSignalFromStudioTruck(
          token,
          lsId,
          ablsId,
          lsSignalTypeId,
          userId,
          room,
          globalPathName
        )
      ),
    onCreateExtendLiveShotSignal: (
      token,
      lsId,
      ablsId,
      lsSignalTypeId,
      userId,
      room
    ) =>
      dispatch(
        actions.createExtendLiveShotSignal(
          token,
          lsId,
          ablsId,
          lsSignalTypeId,
          userId,
          room
        )
      ),
    onCreateNormalSignal: (
      token,
      lsSignalTypeId,
      lsId,
      abstractLiveshotSignalId,
      room
    ) =>
      dispatch(
        actions.createNormalSignal(
          token,
          lsSignalTypeId,
          lsId,
          abstractLiveshotSignalId,
          room
        )
      ),
    onUpdateLiveShotSignals: (
      token,
      signalInfo,
      signalInfoObj,
      room,
      updateCommGlobal = false,
      needUpdate = false
    ) =>
      dispatch(
        actions.updateLiveShotSignals(
          token,
          signalInfo,
          signalInfoObj,
          room,
          updateCommGlobal,
          needUpdate
        )
      ),
    onPinCommsSignalSync: (token, pinCommsObj) =>
      dispatch(actions.pinCommsSignalSync(token, pinCommsObj)),
    onUpdateUniLateral: (token, signalInfo, room) =>
      dispatch(actions.updateUniLateral(token, signalInfo, room)),
    onUpdateNormalLiveShotSignals: (token, signalInfo, room) =>
      dispatch(actions.updateNormalLiveShotSignals(token, signalInfo, room)),
    onDeleteLiveShotSignal: (token, lsSignalId, normalLsSignalId, room) =>
      dispatch(
        actions.deleteLiveShotSignal(token, lsSignalId, normalLsSignalId, room)
      ),
    onTakeAndGrab: (
      token,
      control,
      userId,
      userFullName,
      abstractSignalInfo,
      normalSignalInfo,
      liveshotId,
      roomId,
      currentRoom,
      globalPathRoomId
    ) =>
      dispatch(
        actions.takeAndGrab(
          token,
          control,
          userId,
          userFullName,
          abstractSignalInfo,
          normalSignalInfo,
          liveshotId,
          roomId,
          currentRoom,
          globalPathRoomId
        )
      ),
    onHideSignalModal: () => dispatch(actions.hideSignalModal()),
    onResetSTDefaultPin: () => dispatch(actions.resetSTDefaultPin()),
    onCreateLiveShotSignalWithValue: (
      token,
      lsId,
      ablsId,
      lsSignalTypeId,
      userId,
      room,
      crPathValue,
      globalPathName
    ) =>
      dispatch(
        actions.createLiveShotSignalWIthValue(
          token,
          lsId,
          ablsId,
          lsSignalTypeId,
          userId,
          room,
          crPathValue,
          globalPathName
        )
      ),
    onRemoveRedBorder: (signalId) =>
      dispatch(actions.removeRedBorder(signalId)),
    onCheckOwnership: (token, signal, formInfo, currentCanvas) =>
      dispatch(actions.checkOwnership(token, signal, formInfo, currentCanvas)),
    onCheckAvailableSignals: (token, signals, formInfo, currentCanvas, type) =>
      dispatch(
        actions.checkAvailableSignals(
          token,
          signals,
          formInfo,
          currentCanvas,
          type
        )
      ),
    onAssignAvailableSignals: (
      token,
      signals,
      formInfo,
      currentCanvas,
      type,
      liveshotSignal,
      currentRoom,
      userId,
      selectedSignals
    ) =>
      dispatch(
        actions.assignAvailableSignals(
          token,
          signals,
          formInfo,
          currentCanvas,
          type,
          liveshotSignal,
          currentRoom,
          userId,
          selectedSignals
        )
      ),
    fetchRouterDestinations: (token) =>
      dispatch(actions.getRouterDestinations(token)),
    onFetchPinBySipNumber: (token, sip) =>
      dispatch(actions.fetchPinBySipNumber(token, sip)),
    showDuplicateLocalPathModal: (res) =>
      dispatch(actions.showSignalModal(res)),
    unOwnLiveshotSignals: (
      token,
      liveshotSignalId,
      field,
      pathKey,
      currentRoom,
      isDuplicate = false,
      grabParams = {}
    ) =>
      dispatch(
        actions.unOwnRoute(
          token,
          liveshotSignalId,
          field,
          pathKey,
          currentRoom,
          isDuplicate,
          grabParams
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveShotSignals);
