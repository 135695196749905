import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "../../shared/UI/Button/Button";
import * as Constants from "../../shared/Constants";
import "./OutBoundManagePills.css";
import ArrowDownIcon from "../../../assets/icons/Arrow-down.svg";

const OutBoundManagePills = (props) => {
  const { isMangeDisable, globalPathName, managementUrl, currentUserRole } =
    props;
  const [isVisibleDropdown, setIsVisibleDropdown] = useState(false);
  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    if (
      !isMangeDisable &&
      (currentUserRole.roleName === Constants.SUPER_ADMIN ||
        currentUserRole.roleName === Constants.OPERATIONS_ADMIN ||
        currentUserRole.roleName === Constants.VIEW_ONLY_ALT)
    ) {
      setIsDisable(false);
    }
  }, [currentUserRole.roleName, isMangeDisable]);
  const onDisplayDropDown = () => {
    setIsVisibleDropdown(!isVisibleDropdown);
  };

  const downloadManagementUrl = (managementUrl, globalPathName) => {
    if (managementUrl) {
      if (managementUrl.substring(0, 3) === "vnc") {
        let manageUrlWithProtocalChange = managementUrl.replace(/vnc/g, "http");
        let urlHostName = new URL(manageUrlWithProtocalChange).host;
        vncUrlClickHandler(globalPathName, urlHostName);
      } else {
        window.open(managementUrl);
      }
    }
  };

  const vncUrlClickHandler = (friendlyName, host) => {
    var vncShortcutText = "ConnMethod=tcp\n";
    vncShortcutText += "FriendlyName=" + friendlyName + "\n";
    vncShortcutText += "Host=" + host + "\n";

    download(vncShortcutText, friendlyName + ".vnc", "text/plain");
  };
  const download = (data, filename, type) => {
    var file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob)
      window.navigator.msSaveOrOpenBlob(file, filename);
    else {
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  };

  let outBoundManageMenuList = null;
  if (isVisibleDropdown === true) {
    const mangaeMenu = [{ id: 1, name: "Manage" }];
    outBoundManageMenuList = (
      <ul className={isVisibleDropdown ? "manage-menu" : "hide-manage"}>
        {mangaeMenu.map((list, index) => {
          return (
            <li
              key={list.id}
              value={list.id}
              onClick={() => {
                downloadManagementUrl(managementUrl, globalPathName);
                onDisplayDropDown();
              }}
            >
              {list.name}{" "}
            </li>
          );
        })}
      </ul>
    );
  }

  let disabledTitle = "";
  if (isDisable) {
    disabledTitle = "Signal must be owned by you to be managed";
  }

  return (
    <div className="manage-btns">
      {/*<Button
        type="button"
        btnType="pill comms-btn"
        disabled={isDisable}
        onClick={onDisplayDropDown}
        title={disabledTitle}
      >
        {globalPathName}
        <img src={ArrowDownIcon} alt="arrow down icon" />
  </Button>
  {outBoundManageMenuList}*/}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutBoundManagePills);
