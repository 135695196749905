import * as actionTypes from "./actionTypes";
import axios from "../../axios";
import * as actions from "../../store/actions/index";
import * as Constants from "../../components/shared/Constants";
import React from "react";
import { liveshot_canvas_constants } from "../../constants/PageConstants";

export const searchLivShotSuccess = (resdata) => {
  return {
    type: actionTypes.SEARCH_LIVESHOT_SUCCESS,
    searchList: resdata,
  };
};

export const cityStateSuccess = (resdata) => {
  return {
    type: actionTypes.CITY_STATE_SUCCESS,
    cityList: resdata,
  };
};

export const liveshotNamesSuccess = (resdata) => {
  return {
    type: actionTypes.LIVE_SHOT_NAMES_SUCCESS,
    liveshotNamesList: resdata,
  };
};

export const closeSearchModalResult = () => {
  return {
    type: actionTypes.CLOSE_SEARCH_MODAL,
    showSearch: false,
  };
};

export const openSearchModalResult = () => {
  return {
    type: actionTypes.OPEN_SEARCH_MODAL,
    showSearch: true,
  };
};

export const openSearchModal = () => {
  return (dispatch) => {
    dispatch(openSearchModalResult());
  };
};

export const closeSearchModal = () => {
  return (dispatch) => {
    dispatch(closeSearchModalResult());
  };
};

export const showLoader = () => {
  return {
    type: actionTypes.SHOW_LOADER,
    showLoader: true,
  };
};

export const closeLoader = () => {
  return {
    type: actionTypes.CLOSE_LOADER,
    showLoader: false,
  };
};

export const showSearchErrorModal = (resdata) => {
  return {
    type: actionTypes.SHOW_SEARCH_ERROR_MODAL,
    errorMsg: resdata,
    showModal: true,
  };
};
export const hideSearchErrorModal = () => {
  return {
    type: actionTypes.HIDE_SEARCH_ERROR_MODAL,
    showModal: false,
    errorMsg: {},
  };
};

export const searchLiveShot = (token) => {
  return (dispatch) => {
    axios
      .get(`liveshot-api/abstract/`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        const fetchedLiveShots = [];
        for (let key in res.data) {
          const LiveShot = res.data[key];
          if (LiveShot.softDelete === false) {
            fetchedLiveShots.push({
              ...LiveShot,
            });
          }
        }
        dispatch(searchLivShotSuccess(fetchedLiveShots));
        dispatch(actions.closeLoader());
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        if (typeof err.response !== "undefined" && err.response.data.message) {
          dispatch(
            showSearchErrorModal({
              resultData: err.response.data.message,
              statusCode: err.response.status,
            })
          );
        } else {
          dispatch(
            showSearchErrorModal({
              resultData: Constants.LIVESHOT_SEARCH_FAILURE_MSG,
              statusCode: 0,
            })
          );
        }
        console.log("error ", err);
      });
  };
};

//Submit Abstract Liveshot

export const submitAbstractLiveshot = (
  absLiveShotObj,
  token,
  openErrorBar,
  canvasId,
  abstractLiveshotId
) => {
  return (dispatch) => {
    dispatch(actions.toggleLoader());
    axios
      .post(
        `liveshot-api/abstract/liveshot/${absLiveShotObj.liveshotId}`,
        absLiveShotObj,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        if (res.status === 201) {
          dispatch(actions.searchLiveShot(token));
          openErrorBar(liveshot_canvas_constants.SUBMIT_SUCCESS_MESSAGE, false);
          dispatch(actions.fetchCanvasList(token));
          if (abstractLiveshotId == 0) {
            dispatch(
              actions.fetchSelectedSignal(
                token,
                absLiveShotObj.liveshotId,
                canvasId
              )
            );
            dispatch(actions.getLiveShotData(absLiveShotObj.liveshotId, token));
          }
        }
        dispatch(actions.toggleLoader());
      })
      .catch((err) => {
        openErrorBar(liveshot_canvas_constants.SUBMIT_ERROR_MESSAGE, true);
        dispatch(actions.toggleLoader());
      });
  };
};

export const liveShotNameSuggestions = (token) => {
  return (dispatch) => {
    axios
      .get(`liveshot-api/abstract/names?softDelete=all`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(liveshotNamesSuccess(res.data));
      });
  };
};

export const cityStateSuggestion = (token) => {
  return (dispatch) => {
    axios
      .get(`liveshot-api/city/`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(cityStateSuccess(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        //if (err.response.status === 401) {
        // dispatch(actions.getRefreshToken());
        //}
      });
  };
};

export const saveCityState = (cityStateInfo, token) => {
  return (dispatch) => {
    axios
      .post(`liveshot-api/city/`, cityStateInfo, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        // dispatch(cityStateSuccess(res.data));
        //dispatch(cityStateSuggestion(token));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};
