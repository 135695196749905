import SockJS from "sockjs-client";
import { Client } from "@stomp/stompjs";

class SocketConnection {
  constructor(baseURL, topic, messageHandler) {
    this.baseURL = baseURL;
    this.messageHandler = messageHandler;
    this.topic = topic;
    this.client = null;
    this.subscription = null;

    this.connect(this.baseURL, this.topic, this.messageHandler);
  }

  connect(baseURL, topic, messageHandler) {
    this.client = new Client({
      brokerURL: baseURL,
      connectHeaders: { "Access-Control-Allow-Origin": "*" },
      debug: (str) => {
        // console.log("debug:", str);
      },
      reconnectDelay: 500,
      heartbeatIncoming: 2000,
      heartbeatOutgoing: 2000,
      logRawCommunication: false,
      webSocketFactory: () => {
        return SockJS(baseURL);
      },
      onStompError: (frame) => {
        console.log("Stomp Error", frame);
      },
      onConnect: (frame) => {
        console.log("Stomp Connect", frame);
        this.subscription = this.client.subscribe(topic, (msg) => {
          messageHandler(msg.body);
        });
      },
      onDisconnect: (frame) => {
        console.log("Stomp Disconnect", frame);
      },
      onWebSocketClose: (frame) => {
        console.log("Stomp WebSocket Closed", frame);
      },
      onWebSocketError: (frame) => {
        console.log("Stomp WebSocket Error", frame);
      },
    });
  }

  subscribe() {
    this.client.activate();
  }

  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
}

export default SocketConnection;
