import { useSelector } from "react-redux";
import { checkPermission } from "../components/shared/utils/userUtils";
import * as Constants from "../components/shared/Constants";

export const useUser = () => {
  const currentUserRole = useSelector((state) => state.user.currentUserRole);

  const takeAllPerm = checkPermission(
    currentUserRole,
    Constants.LIVESHOT_SIGNAL_TAKE_CONTROL
  );
  const grabAllPerm = checkPermission(
    currentUserRole,
    Constants.LIVESHOT_SIGNAL_GRAB_CONTROL
  );
  const deleteBlockPerm = checkPermission(
    currentUserRole,
    Constants.DELETE_BLOCK_PERMISSION
  );
  const updateBlockPerm = checkPermission(
    currentUserRole,
    Constants.UPDATE_BLOCK_PERMISSION
  );
  const addBlockPerm = checkPermission(
    currentUserRole,
    Constants.CREATE_BLOCK_PERMISSION
  );

  const updateLiveshotPerm = checkPermission(
    currentUserRole,
    Constants.UPDATE_LIVESHOT_PERMISSION
  );
  const updateLiveshotStatusPerm = checkPermission(
    currentUserRole,
    Constants.UPDATE_LIVESHOT_STATUS_PERMISSION
  );
  const createSignalPerm = checkPermission(
    currentUserRole,
    Constants.CREATE_LIVESHOT_SIGNAL
  );
  const deleteLiveshotPerm = checkPermission(
    currentUserRole,
    Constants.DELETE_LIVESHOT_PERMISSION
  );
  const manageLiveshotSignalPerm = checkPermission(
    currentUserRole,
    Constants.MANAGE_LIVESHOT_SIGNAL_PERMISSION
  );
  const takeSignalPerm = checkPermission(
    currentUserRole,
    Constants.LIVESHOT_SIGNAL_TAKE_CONTROL
  );
  const grabSignalPerm = checkPermission(
    currentUserRole,
    Constants.LIVESHOT_SIGNAL_GRAB_CONTROL
  );
  const createCanvasPerm = checkPermission(
    currentUserRole,
    Constants.CREATE_CANVAS_PERMISSION
  );
  const createShowPermissionData = checkPermission(
    currentUserRole,
    Constants.CREATE_SHOW_PERMISSION
  );
  const createLiveshotPerm = checkPermission(
    currentUserRole,
    Constants.CREATE_LIVESHOT_PERMISSION
  );
  const readRouterSignalDetailsPermission = checkPermission(
    currentUserRole,
    Constants.CREATE_DROP_IMPORT_PERMISSION
  );
  const readControlTrackerPermission = checkPermission(
    currentUserRole,
    Constants.READ_CONTROL_TRACKER_PERMISSION
  );
  const updateCanvasPerm = checkPermission(
    currentUserRole,
    Constants.UPDATE_CANVAS_PERMISSION
  );
  const readControlRoomDetailsPermission = checkPermission(
    currentUserRole,
    Constants.READ_CONTROL_ROOM_DETAILS_PERMISSION
  );

  return {
    currentUserRole,
    takeAllPerm,
    grabAllPerm,
    deleteBlockPerm,
    updateBlockPerm,
    addBlockPerm,
    updateLiveshotPerm,
    updateLiveshotStatusPerm,
    createSignalPerm,
    deleteLiveshotPerm,
    manageLiveshotSignalPerm,
    takeSignalPerm,
    grabSignalPerm,
    createCanvasPerm,
    createShowPermissionData,
    createLiveshotPerm,
    readRouterSignalDetailsPermission,
    readControlTrackerPermission,
    updateCanvasPerm,
    readControlRoomDetailsPermission,
  };
};
