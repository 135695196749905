import * as actionTypes from "../../store/actions/actionTypes";

const initialState = {
  studioTruckList: [],
  studioTruckData: "",
  studioTruchTypes: [],
  stDefaultPinInfo: null,
};

const resetStudioTruchDataSuccess = (state, action) => {
  return Object.assign({}, state, {
    studioTruckData: action.studioTruckData,
  });
};

const fetchStudioTruckListSuccess = (state, action) => {
  return Object.assign({}, state, {
    studioTruckList: action.studioTruckList,
  });
};

const fetchStudioTruckTypeSuccess = (state, action) => {
  return Object.assign({}, state, {
    studioTruckTypes: action.studioTruckTypes,
  });
};

const fetchStudioTruckDataSuccess = (state, action) => {
  return Object.assign({}, state, {
    studioTruckData: action.studioTruckData,
  });
};

const setStudioTruckDefalutPinSuccess = (state, action) => {
  return Object.assign({}, state, {
    stDefaultPinInfo: action.info,
  });
};

const resetStudioTruckDefalutPinSuccess = (state, action) => {
  return Object.assign({}, state, {
    stDefaultPinInfo: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_STUDIOTRUCK_LIST_SUCCESS:
      return fetchStudioTruckListSuccess(state, action);
    case actionTypes.FETCH_STUDIOTRUCK_DATA_SUCCESS:
      return fetchStudioTruckDataSuccess(state, action);
    case actionTypes.RESET_STUDIOTRUCK_DATA:
      return resetStudioTruchDataSuccess(state, action);
    case actionTypes.FETCH_STUDIOTRUCK_TYPES_SUCCESS:
      return fetchStudioTruckTypeSuccess(state, action);
    case actionTypes.SET_ST_DEFAULT_PIN:
      return setStudioTruckDefalutPinSuccess(state, action);
    case actionTypes.RESET_ST_DEFAULT_PIN:
      return resetStudioTruckDefalutPinSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
