import React from "react";
import "./CloseIconComponent.css";

const CloseIconComponent = ({ onCloseModal, classNames }) => {
  return (
    <>
      <svg
        type="button"
        onClick={onCloseModal}
        className={`closeIcon ${classNames}`}
        width="22"
        height="22"
        fill=""
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.8301 7.16983C15.2532 7.5929 15.2532 8.27883 14.8301 8.70189L12.532 11L14.8301 13.2981C15.2532 13.7212 15.2532 14.4071 14.8301 14.8302C14.4071 15.2532 13.7211 15.2532 13.2981 14.8302L11 12.5321L8.70186 14.8302C8.27879 15.2532 7.59287 15.2532 7.1698 14.8302C6.74673 14.4071 6.74673 13.7212 7.1698 13.2981L9.4679 11L7.1698 8.70189C6.74673 8.27882 6.74673 7.5929 7.1698 7.16983C7.59287 6.74676 8.27879 6.74676 8.70186 7.16983L11 9.46792L13.2981 7.16983C13.7211 6.74676 14.4071 6.74676 14.8301 7.16983Z"
          fill=""
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.8333 11C21.8333 16.9831 16.983 21.8333 11 21.8333C5.01687 21.8333 0.166626 16.9831 0.166626 11C0.166626 5.01691 5.01687 0.166656 11 0.166656C16.983 0.166656 21.8333 5.01691 21.8333 11ZM19.6666 11C19.6666 15.7865 15.7864 19.6667 11 19.6667C6.21349 19.6667 2.33329 15.7865 2.33329 11C2.33329 6.21352 6.21349 2.33332 11 2.33332C15.7864 2.33332 19.6666 6.21352 19.6666 11Z"
          fill=""
        />
      </svg>
    </>
  );
};

export default CloseIconComponent;
