import React from "react";
import { Tooltip } from "antd";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/font-plus-icon.svg";

export const TabComponent = ({
  tabs,
  activeTab,
  onTabClick,
  onPlusIconClick,
  isModalOpen,
}) => {
  return (
    <div className="row router_buttons_container">
      <div className="router_buttons">
        {tabs.map((tab) => {
          return (
            <button
              key={tab.label}
              onClick={() => {
                onTabClick(tab.label);
              }}
              className={
                activeTab === tab.label ? "active ag-header-container" : null
              }
            >
              {tab.label}
              {tab.isPlusIcon && (
                <Tooltip
                  placement="bottom"
                  title={tab.toolTip}
                  className={"canvas-tooltip"}
                  arrow={false}
                  align={{ offset: [0, 10] }}
                >
                  <PlusIcon
                    className={`plus-icon ${
                      activeTab !== tab.label
                        ? "disabledPlusIcon"
                        : "active-icon"
                    }`}
                    data-testid="plus-icon"
                    onClick={() => onPlusIconClick()}
                  />
                </Tooltip>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};
