//Prod Env Message
export const env_constants = {
  STAGE_MESSAGE:
    "You are in the LSM 2.0 Staging Environment which interacts with production resources. This environment is only to be used for UAT.",
  PROD_MESSAGE:
    "You are in the New LSM 2.0 Production environment. This environment is not currently active. Please continue to use LSM 1.0 (liveshot.vprod.io) until the cutover to 2.0 has been finalized.",
};
//Liveshot Canvas Page constants.
export const liveshot_canvas_constants = {
  SUBMIT_SUCCESS_MESSAGE: "Submitted to Liveshot list successfully.",
  MAX_LENGTH: 4,
  SUBMIT_ERROR_MESSAGE:
    "Submission has failed for this liveshot. Please try again later.",
  ABS_SUBMIT_ERROR_MESSAGE:
    "Adding this liveshot had failed. Please try again later.",
  ABS_VENUE_SUBMIT_ERROR_MESSAGE: "Please enter a Venue Name.",
  PIN_COMMS_ERROR_MESSAGE: "Please provide a hit time and Venue Name to sync.",
};

//Venue Page Tab constants.
export const venue_page_constants = {
  VENUES_TAB: "VENUES",
  PIN_LOOKUP_TABLE_TAB: "PIN LOOKUP TABLE",
  SIP_PIN_ERROR_MESSAGE: "Both SIP and PIN must be entered.",
  MAX_LENGTH: 4,
  PIN_DELETE_CONFIRMATION_MESSAGE:
    "You are about to delete a PIN. Are you sure you want to continue this action?",
  VENUE_DELETE_CONFIRMATION_MESSAGE:
    "You are about to delete a Venue. Are you sure you want to continue this action?",
  BANNER_MESSAGE: `To add multiple venues/pins via CSV, download the existing Venue/Pin database with the "SAVE CSV" function. Add your venues/pins with the relevant data, then use the "UPLOAD CSV" function to update the Venue/Pin database. A new Venue/Pin CSV will overwrite all existing Venue/Pin Data. Please ensure that the CSV you wish to upload is accurate and contains all data you wish to retain from previous CSV downloads.`,
};
//Source destination Tab constants.
export const source_destination_page_constants = {
  SOURCES_TAB: "SOURCES",
  DESTINATIONS_TAB: "DESTINATIONS",
  SIGNAL_TYPES_TAB: "SIGNAL TYPES",
  ROOMS_TAB: "ROOMS",
  USERS_TAB: "USERS",
  USERS_DELETE_CONFIRMATION_MESSAGE:
    "You are about to delete a User. Are you sure you want to continue this action?",
  INCORRECT_SSO_ERROR_MESSAGE: "Please enter a valid SSO number",
  SSO_SUCCESS_MESSAGE: "User SSO ID uploaded successfully.",
};
export const BLOCK_DELETE_CONFIRMATION_MESSAGE =
  "You are about to delete a Block. Are you sure you want to continue this action?";
export const DELETE_LIVESHOT_MESSAGE =
  "You are about to delete a Liveshot. Are you sure you want to continue this action?";

export const VENUE_TYPES = [
  "VIDEO CONFERENCE",
  "TRUCK",
  "AD HOC FEED",
  "30R LOCATION",
];

export const UPLOAD_MESSAGE = {
  SUCCESS: "File has been uploaded successfully.",
  FAILED:
    "The upload for this document has failed. Please check the file type or document and try again later.",
  INVALID:
    "The upload was successful. However, input errors were discovered during upload, so not all rows were uploaded successfully. A CSV with the errors highlighted per row is being generated, please correct the rows in question to capture all intended Venue uploads.",
};

export const MESSAGE_TEXT = {
  INVALID_EMAIL:
    "Email must be entered in a valid format,  xxxxxxxx@xxxxx.xxxx",
  INVALID_PHONE:
    "Phone number must be entered in one of these formats: XXXXXXXXXX, XXX XXX XXXX, XXX-XXX-XXXX, +44 XXXX XXXXXX, (XXX)-XXX-XXXX, (XXX) XXX XXXX",
};
