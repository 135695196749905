import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import "./LiveShotContainer.css";
import HostStudioObject from "../../components/liveshot/HostStudioObject/HostStudioObject";
import Spinner from "../../components/shared/UI/Spinner/Spinner";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import useMousetrap from "react-hook-mousetrap";
import BlockContainer from "../BlockContainer/BlockContainer";
import { changeTimeInHours } from "../../components/shared/utility";
import { removeDuplicates } from "../../components/shared/utility";
import { useUser } from "../../hooks/useUser";

const LiveShotContainer = (props) => {
  const {
    addBlockPerm: createBlockPerm,
    updateLiveshotPerm,
    updateBlockPerm,
    takeAllPerm,
    grabAllPerm,
  } = useUser();
  const {
    normalLiveShotTypes,
    normalLiveShots,
    loading,
    onCreateBlock,
    onUpdateBlock,
    onLiveshotUpdate,
    onDeleteBlock,
    token,
    currentCanvasId,
    currentUser,
    currentUserRole,
    onSaveLiveshotsInfo,
    onSetActiveLiveshot,
    onSetNextActiveLiveshot,
    onSetPressedKey,
    searchList,
    routingCapabilityType,
    liveShotSignalSelected,
    currentCanvas,
    controlRoomList,
    shortCut,
    isArmRouting,
    currentShow,
    onStoreArrPosition,
    arrPosition,
    streamDeckOn,
    fetchPinsInfo,
    fetchRouterSources,
    sipList,
    onAddSipList,
    onFetchPinBySipNumber,
    formattedRouterSource,
    sipState,
  } = props;
  const [blockOrder, setBlockOrder] = useState([]);
  const [liveshotOrder, setLiveshotOrder] = useState({});
  const [liveshotFullLists, setLiveshotFullLists] = useState([]);
  const [liveshotLists, setLiveshotLists] = useState({});
  const [liveshotPrevSeq, setLiveshotPrevSeq] = useState({});
  const [blockPrevSeq, setBlockPrevSeq] = useState({});
  const [takeGrabBtn, setTakeGrabBtn] = useState(false);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [totalLiveshots, setTotalLiveshots] = useState([]);

  let userFullName = currentUser.firstName + " " + currentUser.lastName;
  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userFullName =
      process.env.REACT_APP_AUTH_OVERRIDE_FIRSTNAME +
      " " +
      process.env.REACT_APP_AUTH_OVERRIDE_LASTNAME;
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }

  const [createdBy] = useState(userId);
  const [updatedBy] = useState(userId);

  useMousetrap(["down", "up"], (e) => {
    e.preventDefault();
    onSetPressedKey(null);
    if (!shortCut) {
      return;
    }

    let newPosition = arrPosition;
    if (e.key === "ArrowDown" && totalLiveshots.length > arrPosition) {
      newPosition++;
    } else if (e.key === "ArrowUp" && arrPosition > 1) {
      newPosition--;
    }

    onStoreArrPosition(newPosition);
  });

  useMousetrap(
    ["u", "o", "U", "O", "e", "E", "f", "F", "enter", "backspace"],
    (e) => {
      e.preventDefault();
      if (
        e.key === "u" ||
        e.key === "U" ||
        e.key === "o" ||
        e.key === "O" ||
        e.key === "e" ||
        e.key === "E" ||
        e.key === "F" ||
        e.key === "f" ||
        e.key === "Enter" ||
        e.key === "Backspace"
      ) {
        onSetPressedKey(e.key);
        setTimeout(() => {
          onSetPressedKey(null);
        }, 300);
      }
    }
  );
  useEffect(() => {
    sipList.map((sip) => {
      if (!sipState.hasOwnProperty(sip)) {
        onFetchPinBySipNumber(token, sip, true);
      }
    });
  }, [sipList, onFetchPinBySipNumber]);

  useEffect(() => {
    const sipArray = new Set([]);
    const sipComms = removeDuplicates(liveShotSignalSelected).filter(
      (signal) =>
        signal.liveshotSignalTypeId === 5 ||
        signal.liveshotSignalTypeId === 7 ||
        signal.liveshotSignalTypeId === 8 ||
        signal.liveshotSignalTypeId === 9
    );
    sipComms.map((val) => {
      if (val.sip && formattedRouterSource.includes(val.sip)) {
        sipArray.add(val.sip);
      }
    });
    onAddSipList([...sipArray]);
  }, [onAddSipList, liveShotSignalSelected, formattedRouterSource]);

  useEffect(() => {
    let blockLists = [];
    let lsOrder = {};
    let liveshotFull = [];
    let lsLists = {};
    let seqList = {};
    let blockSeq = {};
    if (normalLiveShots && normalLiveShots.length > 0) {
      normalLiveShots
        .filter((normalLiveShot) => {
          return normalLiveShot.softDelete !== true;
        })
        .forEach((normalLiveShot, index) => {
          blockLists.push(normalLiveShot.blockId);
          blockSeq[normalLiveShot.blockId] = normalLiveShot.sequenceNo;
          const liveshots = normalLiveShot.liveshots;
          const order = [];
          const prevSeq = {};
          liveshots.forEach((liveshot) => {
            order.push(liveshot.liveshotId);
            liveshotFull.push(liveshot);
            lsLists[liveshot.liveshotId] = liveshot;
            prevSeq[liveshot.liveshotId] = liveshot.sequenceNo;
          });
          lsOrder[normalLiveShot.blockId] = order;
          seqList[normalLiveShot.blockId] = prevSeq;
        });
      setBlockOrder(blockLists);
      setLiveshotOrder(lsOrder);
      setLiveshotFullLists(liveshotFull);
      setLiveshotLists(lsLists);
      setLiveshotPrevSeq(seqList);
      setBlockPrevSeq(blockSeq);
    }
  }, [normalLiveShots]);

  useEffect(() => {
    let lsInfo = {};
    lsInfo.liveshotLs = liveshotLists;
    lsInfo.blockorder = blockOrder;
    onSaveLiveshotsInfo(lsInfo);
  }, [onSaveLiveshotsInfo, liveshotLists, blockOrder]);

  useEffect(() => {
    if (routingCapabilityType >= 2) {
      setTakeGrabBtn(true);
    } else {
      setTakeGrabBtn(false);
    }
  }, [routingCapabilityType, takeGrabBtn]);

  useEffect(() => {
    if (currentCanvas && controlRoomList.length > 0) {
      let room = controlRoomList.find(
        (list) => list.roomId === currentCanvas.roomId
      );
      if (room) {
        setCurrentRoom(room);
      }
    }
  }, [controlRoomList, currentCanvas, currentRoom]);

  useEffect(() => {
    let liveshots = [];
    if (normalLiveShots.length > 0) {
      for (let key in normalLiveShots) {
        if (normalLiveShots[key].liveshots.length > 0) {
          for (let k in normalLiveShots[key].liveshots) {
            const liveshotId = normalLiveShots[key].liveshots[k].liveshotId;
            liveshots.push(liveshotId);
          }
        }
      }
    }
    setTotalLiveshots(liveshots);
  }, [normalLiveShots]);

  useEffect(() => {
    if (streamDeckOn) {
      onStoreArrPosition(1);
    } else {
      onStoreArrPosition(0); // Resets Selected LiveShot Index For Keyboard Only
    }
  }, [currentCanvasId, onStoreArrPosition, streamDeckOn]);

  useEffect(() => {
    onSetActiveLiveshot(totalLiveshots[arrPosition - 1]);
    onSetNextActiveLiveshot(totalLiveshots[arrPosition]);
  }, [
    arrPosition,
    totalLiveshots,
    onSetActiveLiveshot,
    onSetNextActiveLiveshot,
  ]); //Loads LiveShot Selection Every Time Canvas LiveShots are Loaded or Changed

  useEffect(() => {
    fetchPinsInfo(token);
  }, [token, fetchPinsInfo]);

  useEffect(() => {
    fetchRouterSources(token);
  }, [token, fetchRouterSources]);

  const dragEnd = (result) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }

    if (normalLiveShotTypes && normalLiveShotTypes.length > 0) {
      return;
    }

    /*if (destination.droppableId === "bottom-space") {
      const [blockName, seqNo] = getLastLabel();
      const canvas_id = normalLiveShots[0].canvasId;
      const liveshot_id = liveshotLists[parseInt(draggableId)].liveshotId;
      if (createBlockPerm) {
        onCreateBlock(
          createdBy,
          canvas_id,
          blockName,
          seqNo,
          liveshot_id,
          token
        );
      }
      return; 
    }*/

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "block") {
      // UI
      const oldBlockOrder = Array.from(blockOrder);
      const newBlockOrder = Array.from(blockOrder);
      newBlockOrder.splice(source.index, 1);
      newBlockOrder.splice(destination.index, 0, parseInt(draggableId));
      setBlockOrder(newBlockOrder);

      // API
      let blockSeq = { ...blockPrevSeq };
      if (source.index > destination.index) {
        // move block up
        const start = destination.index;
        const end = source.index;
        let start_seq = blockSeq[oldBlockOrder[start]];
        for (let i = start; i < end; i++) {
          let next_seq = blockSeq[oldBlockOrder[i + 1]];
          let block = normalLiveShots.find(
            (element) => element.blockId === oldBlockOrder[i]
          );
          let blockId = block.blockId;
          let payload = { sequenceNo: next_seq };
          blockSeq[oldBlockOrder[i]] = next_seq;
          if (updateBlockPerm) {
            onUpdateBlock(updatedBy, blockId, payload, currentCanvasId, token);
          }
        }

        let last_block = normalLiveShots.find(
          (element) => element.blockId === oldBlockOrder[end]
        );
        let last_blockId = last_block.blockId;
        let last_payload = { sequenceNo: start_seq };
        blockSeq[oldBlockOrder[end]] = start_seq;
        setBlockPrevSeq(blockSeq);
        if (updateBlockPerm) {
          onUpdateBlock(
            updatedBy,
            last_blockId,
            last_payload,
            currentCanvasId,
            token
          );
        }
      }

      if (source.index < destination.index) {
        // move block down
        const start = source.index;
        const end = destination.index;
        let end_seq = blockSeq[oldBlockOrder[end]];
        for (let i = start + 1; i <= end; i++) {
          let prev_seq = blockSeq[oldBlockOrder[i - 1]];
          let block = normalLiveShots.find(
            (element) => element.blockId === oldBlockOrder[i]
          );
          let blockId = block.blockId;
          let payload = { sequenceNo: prev_seq };
          //blockSeq[oldBlockOrder[i]] = prev_seq;
          if (updateBlockPerm) {
            onUpdateBlock(updatedBy, blockId, payload, currentCanvasId, token);
          }
        }

        let start_block = normalLiveShots.find(
          (element) => element.blockId === oldBlockOrder[start]
        );
        let start_blockId = start_block.blockId;
        let start_payload = { sequenceNo: end_seq };
        blockSeq[oldBlockOrder[start]] = end_seq;
        // setBlockPrevSeq(blockSeq);
        if (updateBlockPerm) {
          onUpdateBlock(
            updatedBy,
            start_blockId,
            start_payload,
            currentCanvasId,
            token
          );
        }
      }
      return;
    }

    if (type === "liveshot" && destination.droppableId === source.droppableId) {
      // liveshot dnd within the same block
      // UI
      const newLiveshotOrder = Array.from(
        liveshotOrder[parseInt(destination.droppableId)]
      );
      newLiveshotOrder.splice(source.index, 1);
      newLiveshotOrder.splice(destination.index, 0, parseInt(draggableId));
      const newColumn = {
        ...liveshotOrder,
        [parseInt(destination.droppableId)]: newLiveshotOrder,
      };

      setLiveshotOrder(newColumn);

      // api
      const block = normalLiveShots.find(
        (element) => element.blockId === parseInt(destination.droppableId)
      );
      let blockSeq = { ...liveshotPrevSeq };
      if (source.index > destination.index) {
        // sift down
        const start = destination.index;
        const end = source.index;
        const liveshotorder = liveshotOrder[parseInt(destination.droppableId)];
        const newSeq = { ...blockSeq[parseInt(destination.droppableId)] };
        let start_seq = newSeq[liveshotorder[start]];
        for (let i = start; i < end; i++) {
          let next_seq = newSeq[liveshotorder[i + 1]];
          let cur_ls = liveshotLists[liveshotorder[i]];
          let liveshotId = cur_ls.liveshotId;
          let payload = { sequenceNo: next_seq };
          newSeq[liveshotorder[i]] = next_seq;
          if (updateLiveshotPerm) {
            onLiveshotUpdate(
              updatedBy,
              currentCanvasId,
              liveshotId,
              payload,
              token
            );
          }
        }

        let last_liveshotId = liveshotLists[liveshotorder[end]].liveshotId;
        let last_payload = { sequenceNo: start_seq };
        newSeq[liveshotorder[end]] = start_seq;
        blockSeq[block.blockId] = newSeq;
        setLiveshotPrevSeq(blockSeq);
        if (updateLiveshotPerm) {
          onLiveshotUpdate(
            updatedBy,
            currentCanvasId,
            last_liveshotId,
            last_payload,
            token
          );
        }
      }

      if (source.index < destination.index) {
        // sift up
        const start = source.index;
        const end = destination.index;
        const liveshotorder = liveshotOrder[parseInt(destination.droppableId)];
        const newSeq = { ...blockSeq[parseInt(destination.droppableId)] };
        let end_seq = newSeq[liveshotorder[end]];
        for (let i = start + 1; i <= end; i++) {
          let prev_seq = newSeq[liveshotorder[i - 1]];
          let cur_ls = liveshotLists[liveshotorder[i]];
          let liveshotId = cur_ls.liveshotId;
          let payload = { sequenceNo: prev_seq };
          newSeq[liveshotorder[i]] = prev_seq;
          if (updateLiveshotPerm) {
            onLiveshotUpdate(
              updatedBy,
              currentCanvasId,
              liveshotId,
              payload,
              token
            );
          }
        }

        let start_liveshotId = liveshotLists[liveshotorder[start]].liveshotId;
        let start_payload = { sequenceNo: end_seq };
        if (updateLiveshotPerm) {
          onLiveshotUpdate(
            updatedBy,
            currentCanvasId,
            start_liveshotId,
            start_payload,
            token
          );
        }
        newSeq[liveshotorder[start]] = end_seq;
        blockSeq[block.blockId] = newSeq;
        setLiveshotPrevSeq(blockSeq);
      }
      return;
    }

    // moving from one block to another
    // UI
    const oldLiveshotOrder = { ...liveshotOrder };
    const srcLiveShotOrder = Array.from(
      liveshotOrder[parseInt(source.droppableId)]
    );
    srcLiveShotOrder.splice(source.index, 1);

    const destLiveShotOrder = Array.from(
      liveshotOrder[parseInt(destination.droppableId)]
    );
    destLiveShotOrder.splice(destination.index, 0, parseInt(draggableId));

    const newLiveshotOrders = {
      ...liveshotOrder,
      [parseInt(source.droppableId)]: srcLiveShotOrder,
      [parseInt(destination.droppableId)]: destLiveShotOrder,
    };

    setLiveshotOrder(newLiveshotOrders);
    // remove block when empty

    // API
    const src_block = normalLiveShots.find(
      (element) => element.blockId === parseInt(source.droppableId)
    );
    const dest_block = normalLiveShots.find(
      (element) => element.blockId === parseInt(destination.droppableId)
    );
    const src_liveshotOrder = oldLiveshotOrder[src_block.blockId];
    const src_prevSeq = liveshotPrevSeq[src_block.blockId];
    const dest_liveshotOrder = oldLiveshotOrder[dest_block.blockId];
    const dest_prevSeq = liveshotPrevSeq[dest_block.blockId];

    const cur_liveshot = liveshotLists[src_liveshotOrder[source.index]];

    const src_sequence = Object.keys(src_prevSeq).filter(
      (seq) => parseInt(seq) !== cur_liveshot.liveshotId
    );

    if (src_sequence.length > 0) {
      src_sequence.map((seq, index) => {
        const liveshot = liveshotLists[seq];
        if (liveshot.sequenceNo !== index + 1 && updateLiveshotPerm) {
          onLiveshotUpdate(
            updatedBy,
            currentCanvasId,
            liveshot.liveshotId,
            { sequenceNo: index + 1 },
            token,
            false
          );
        }
      });
    }

    if (Object.keys(dest_prevSeq).length === 0) {
      dest_prevSeq[cur_liveshot.liveshotId] = 0;
      delete src_prevSeq[cur_liveshot.liveshotId];
      liveshotPrevSeq[src_block.blockId] = src_prevSeq;
      liveshotPrevSeq[dest_block.blockId] = dest_prevSeq;
      setLiveshotPrevSeq(liveshotPrevSeq);

      const payload = {
        blockId: dest_block.blockId,
        sequenceNo: 0,
      };
      if (updateLiveshotPerm) {
        onLiveshotUpdate(
          updatedBy,
          currentCanvasId,
          cur_liveshot.liveshotId,
          payload,
          token
        );
      }
      return;
    }

    if (destination.index !== dest_liveshotOrder.length) {
      // insert to top or middle
      let start_seq = dest_prevSeq[dest_liveshotOrder[destination.index]];
      for (let i = destination.index; i < dest_liveshotOrder.length - 1; i++) {
        let liveshot = liveshotLists[dest_liveshotOrder[i]];
        let next_seq = dest_prevSeq[dest_liveshotOrder[i + 1]];
        let payload = {
          sequenceNo: next_seq,
        };

        dest_prevSeq[dest_liveshotOrder[i]] = next_seq;
        if (updateLiveshotPerm) {
          onLiveshotUpdate(
            updatedBy,
            currentCanvasId,
            liveshot.liveshotId,
            payload,
            token,
            false
          );
        }
      }
      let target_payload = {
        sequenceNo: start_seq,
        blockId: dest_block.blockId,
      };
      dest_prevSeq[cur_liveshot.liveshotId] = start_seq;
      if (updateLiveshotPerm) {
        onLiveshotUpdate(
          updatedBy,
          currentCanvasId,
          cur_liveshot.liveshotId,
          target_payload,
          token
        );
      }

      let last_liveshotName = dest_liveshotOrder[dest_liveshotOrder.length - 1];
      let last_liveshot = liveshotLists[last_liveshotName];
      let last_payload = {
        sequenceNo: dest_prevSeq[last_liveshotName] + 1,
      };
      dest_prevSeq[last_liveshotName] = dest_prevSeq[last_liveshotName] + 1;

      delete src_prevSeq[cur_liveshot.liveshotId];

      liveshotPrevSeq[src_block.blockId] = src_prevSeq;
      liveshotPrevSeq[dest_block.blockId] = dest_prevSeq;
      setLiveshotPrevSeq(liveshotPrevSeq);
      if (updateLiveshotPerm) {
        onLiveshotUpdate(
          updatedBy,
          currentCanvasId,
          last_liveshot.liveshotId,
          last_payload,
          token
        );
      }
    } else {
      // insert into bottom
      let last_liveshotName = dest_liveshotOrder[dest_liveshotOrder.length - 1];
      let last_payload = {
        sequenceNo: dest_prevSeq[last_liveshotName] + 1,
        blockId: dest_block.blockId,
      };

      dest_prevSeq[cur_liveshot.liveshotId] =
        dest_prevSeq[last_liveshotName] + 1;

      delete src_prevSeq[cur_liveshot.liveshotId];

      liveshotPrevSeq[src_block.blockId] = src_prevSeq;
      liveshotPrevSeq[dest_block.blockId] = dest_prevSeq;
      setLiveshotPrevSeq(liveshotPrevSeq);
      if (updateLiveshotPerm) {
        onLiveshotUpdate(
          updatedBy,
          currentCanvasId,
          cur_liveshot.liveshotId,
          last_payload,
          token
        );
      }
    }
    return;
  };

  const getLastLabel = () => {
    let lastCharacter = "";
    let lastSequenceNo = 0;

    normalLiveShots.forEach((block) => {
      if (!block.softDelete) {
        let blockName = block.blockName;
        blockName.split(" ");
        if (blockName[0] > lastCharacter) {
          lastCharacter = blockName[0];
        }

        let sequenceNo = block.sequenceNo;
        if (sequenceNo > lastSequenceNo) {
          lastSequenceNo = sequenceNo;
        }
      }
    });

    lastCharacter = String.fromCharCode(lastCharacter.charCodeAt(0) + 1);

    return [lastCharacter, lastSequenceNo + 1];
  };

  const onRemoveBlock = (block_id) => {
    const block = normalLiveShots.find(
      (element) => element.blockId === block_id
    );
    const canvas_id = normalLiveShots[0].canvasId;
    onDeleteBlock(block_id, canvas_id, updatedBy, token);

    // change blockOrder
    let newBlockOrder = Array.from(blockOrder);
    let index = newBlockOrder.indexOf(block.blockId);
    newBlockOrder.splice(index, 1);
    setBlockOrder(newBlockOrder);

    let newLiveshotOrder = { ...liveshotOrder };
    delete newLiveshotOrder[block.blockId];
    setLiveshotOrder(newLiveshotOrder);

    let newLiveshotPrevSeq = { ...liveshotPrevSeq };
    delete newLiveshotPrevSeq[block.blockId];
    setLiveshotPrevSeq(newLiveshotPrevSeq);

    let newBlockPrevSeq = { ...blockPrevSeq };
    delete newBlockPrevSeq[block.blockId];
    setBlockPrevSeq(newBlockPrevSeq);
  };

  const onAddBlock = () => {
    const [blockName, seqNo] = getLastLabel();
    const canvas_id = normalLiveShots[0].canvasId;
    const liveshot_id = null;
    if (createBlockPerm) {
      onCreateBlock(createdBy, canvas_id, blockName, seqNo, liveshot_id, token);
    }
  };

  let lsContainer = <Spinner />;
  let hostStudioBlankObjs = null;
  let normalLiveShotsList = null;

  const liveshotTypeValue = (type) => {
    switch (type) {
      case "Local Host":
        return 1;
      case "Beauty Shot":
        return 2;
      case "Local Guest":
        return 3;
      case "Remote Host":
        return 4;
      case "Remote Guest":
        return 5;
      case "Remote Reporter":
        return 6;
      case "Remote Event":
        return 7;
      case "Other":
        return 8;
      case "Edit/Playback":
        return 9;
      default:
        return 8;
    }
  };

  //start related to hittime changes

  let n = liveshotFullLists.length;
  let currentLiveshot = [];
  let nextLiveshot = [];
  let outofOrderLS = [];
  if (liveshotFullLists && liveshotFullLists.length > 1) {
    for (let i = 0; i < n - 1; i++) {
      currentLiveshot = liveshotFullLists[i];
      nextLiveshot = liveshotFullLists[i + 1];
      if (currentLiveshot.hitTime !== null && nextLiveshot.hitTime !== null) {
        if (
          changeTimeInHours(currentLiveshot.hitTime, currentShow.startTime) >
          changeTimeInHours(nextLiveshot.hitTime, currentShow.startTime)
        ) {
          outofOrderLS.push(liveshotFullLists[i]);
        }
      }
    }
  }
  //end related to hittime changes

  if (normalLiveShots && normalLiveShots.length > 0) {
    normalLiveShotsList = blockOrder.map((blockId, index) => {
      const blockIndex = normalLiveShots.findIndex(
        (element) => element.blockId === blockId
      );
      if (blockIndex < 0) {
        return;
      }
      const block = normalLiveShots[blockIndex];
      const order = liveshotOrder[block.blockId];
      let lastBlock = false;
      if (index === blockOrder.length - 1) {
        lastBlock = true;
      }
      return (
        <BlockContainer
          key={block.blockId}
          type="normal"
          block={block}
          index={index}
          liveshotsOrder={order}
          liveshotFullLists={liveshotFullLists}
          normalLiveShotTypes={normalLiveShotTypes}
          removeBlock={onRemoveBlock}
          addBlock={onAddBlock}
          isLastBlock={lastBlock}
          outofOrderLS={outofOrderLS}
          currentUserRole={currentUserRole}
          searchList={searchList}
          isDisable={createBlockPerm}
        />
      );
    });
  } else if (normalLiveShotTypes.length > 0 && normalLiveShots.length === 0) {
    {
      /* Normal obj */
    }
    normalLiveShotsList = normalLiveShotTypes.map((normalType, index) => (
      <HostStudioObject
        key={index}
        isBlockLiveshot={true}
        type={liveshotTypeValue(normalType)}
        draggableType="normal"
        newliveshot={true}
        searchList={searchList}
        isNewShotAdded={false}
        blockPerm={createBlockPerm}
      />
    ));
  }
  let activeNormalLiveShots = normalLiveShots.filter(
    (item) => item.softDelete !== true
  );
  if (normalLiveShotTypes.length > 0 && activeNormalLiveShots.length === 0) {
    {
      /* Normal obj */
    }
    normalLiveShotsList = normalLiveShotTypes.map((normalType, index) => (
      <HostStudioObject
        key={index}
        isBlockLiveshot={true}
        type={liveshotTypeValue(normalType)}
        draggableType="normal"
        newliveshot={true}
        searchList={searchList}
        isNewShotAdded={false}
        blockPerm={createBlockPerm}
      />
    ));
  }

  let notAllowTakeAll = false;
  let notAllowGrabAll = false;
  if (isArmRouting === false || !takeAllPerm) {
    notAllowTakeAll = true;
  }
  if (isArmRouting === false || !grabAllPerm) {
    notAllowGrabAll = true;
  }

  if (!loading) {
    lsContainer = (
      <Fragment>
        <DragDropContext onDragEnd={(result) => dragEnd(result)}>
          <div className="Container">
            <div id="studiohostrow">{hostStudioBlankObjs}</div>
            <div id="normal-blocks-row">
              <Droppable droppableId="all-blocks" type="block">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {normalLiveShotsList}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            {/*<div id="drag-bottom">
              <Droppable droppableId="bottom-space" type="liveshot">
                {(provided) => (
                  <div
                    className="drag-drop-bottom-space"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div> */}
          </div>
        </DragDropContext>
      </Fragment>
    );
  }
  return lsContainer;
};

const mapStateToProps = (state) => {
  return {
    normalLiveShotTypes: state.liveShotContainer.normalLiveShotTypes,
    normalLiveShots: state.liveShotContainer.normalLiveShots,
    loading: state.liveShotContainer.loading,
    token: state.auth.accessToken,
    currentCanvasId: state.canvasInfo.currentCanvasId,
    currentUser: state.auth.currentUser,
    currentUserRole: state.user.currentUserRole,
    shortCut: state.canvasInfo.shortCut,
    streamDeckOn: state.canvasInfo.streamDeckOn,
    showDate: state.canvasInfo.showDate,
    currentShow: state.showInfo.currentShow,
    searchList: state.searchListInfo.searchList,
    liveShotSignalSelected: state.signalsInfo.liveShotSignalSelected,
    currentCanvas: state.canvasInfo.currentCanvas,
    routingCapabilityType: state.canvasInfo.routingCapabilityType,
    controlRoomList: state.canvasInfo.controlRoomList,
    isArmRouting: state.canvasInfo.armRouting,
    arrPosition: state.liveShotContainer.arrPosition,
    formattedRouterSource: state.routerSourceDestination.formattedRouterSource,
    sipState: state.signalsInfo.sipState,
    sipList: state.signalsInfo.sipList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateBlock: (
      createdBy,
      canvas_id,
      block_name,
      sequence_no,
      liveshot_id,
      token
    ) =>
      dispatch(
        actions.createBlock(
          createdBy,
          canvas_id,
          block_name,
          sequence_no,
          liveshot_id,
          token
        )
      ),
    onUpdateBlock: (updatedBy, block_id, payload, currentCanvasId, token) =>
      dispatch(
        actions.updateBlock(
          updatedBy,
          block_id,
          payload,
          currentCanvasId,
          token
        )
      ),
    onLiveshotUpdate: (
      updatedBy,
      currentCanvasId,
      liveshot_id,
      payload,
      token,
      refetchCanvas
    ) =>
      dispatch(
        actions.updateLiveshot(
          updatedBy,
          currentCanvasId,
          liveshot_id,
          payload,
          token,
          refetchCanvas
        )
      ),
    onDeleteBlock: (block_id, canvas_id, userId, token) =>
      dispatch(actions.deleteBlock(block_id, canvas_id, userId, token)),
    onCheckCanvas: (token, date, showId) =>
      dispatch(actions.canvasCheck(token, date, showId)),
    onSaveLiveshotsInfo: (lsInfo) =>
      dispatch(actions.saveLiveshotsInfo(lsInfo)),
    onSetActiveLiveshot: (id) => dispatch(actions.setActiveLiveshot(id)),
    onSetNextActiveLiveshot: (id) =>
      dispatch(actions.setNextActiveLiveshot(id)),
    onSetPressedKey: (key) => dispatch(actions.setPressedKey(key)),
    onStoreArrPosition: (arrPosition) =>
      dispatch(actions.onStoreArrPosition(arrPosition)),
    fetchPinsInfo: (token) => dispatch(actions.getPinTableInfo(token)),
    fetchRouterSources: (token) => dispatch(actions.getRouterSources(token)),
    onAddSipList: (arr) => dispatch(actions.addSipList(arr)),
    onFetchPinBySipNumber: (token, sip, initialLoad) =>
      dispatch(actions.fetchPinBySipNumber(token, sip, initialLoad)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveShotContainer);
