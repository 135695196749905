import React from "react";
import { connect } from "react-redux";
import "./NotificationPanel.css";
import GrabNotificationBox from "../../modals/notificationBoxes/grabNotificationBox/GrabNotificationBox";
import ReleaseNotificationBox from "../../modals/notificationBoxes/releaseNotificationBox/ReleaseNotificationBox";
import moment from "moment";

const NotificationPanel = (props) => {
  const {
    notificationList,
    removeNotification,
    userList,
    searchList,
    normalLiveShots,
  } = props;

  const notificationShow = notificationList.map((notification, index) => {
    let dateObj = notification.addedOn;
    let titleMsg = moment(dateObj).format("ddd MMM Do HH:mm:ss");
    if (notification.type === "GRABREQUEST") {
      return (
        <div key={index} className="notification-item">
          <GrabNotificationBox
            grabNotification={notification}
            removeNotification={removeNotification}
            index={index}
            title={titleMsg}
          ></GrabNotificationBox>
        </div>
      );
    } else if (notification.type === "GRABRELEASE") {
      return (
        <div key={index} className="notification-item">
          <ReleaseNotificationBox
            releaseNotification={notification}
            removeNotification={removeNotification}
            index={index}
            title={titleMsg}
          ></ReleaseNotificationBox>
        </div>
      );
    } else if (notification.type === "LIVESHOT") {
      const userNameList = userList.find(
        (element) => element.sso === parseInt(notification.data.updatedBy)
      );

      let msg = `${moment(dateObj).fromNow()} : ${
        userNameList.userName
      } updated the hit for "${
        notification.data.abstractLiveshot.abstractLiveshotName
      }" on the visible canvas`;

      return (
        <div key={index} className="notification-item">
          <div className="row grab-container">
            <div className="grab-detail" title={titleMsg}>
              <div className="grab-text">{msg}</div>
              <div className="remove-btn">
                <button
                  onClick={() => removeNotification(index)}
                  type="button"
                  className="close"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (notification.type === "ABSTRACTLIVESHOT") {
      const userNameList = userList.find(
        (element) => element.sso === parseInt(notification.data.updatedBy)
      );

      let msg = `${moment(dateObj).fromNow()} : ${
        userNameList.userName
      } (editing for ${notification.data.inUseRoomCode}) updated liveshot "${
        notification.data.abstractLiveshotName
      }"`;

      return (
        <div key={index} className="notification-item">
          <div className="row grab-container">
            <div className="grab-detail" title={titleMsg}>
              <div className="grab-text">{msg}</div>
              <div className="remove-btn">
                <button
                  onClick={() => removeNotification(index)}
                  type="button"
                  className="close"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (
      notification.type === "LIVESHOTSIGNAL" &&
      (notification.data.abstractLiveshotId !== 0 ||
        notification.method === "PATCH")
    ) {
      let liveshots = [];
      let abstractInfo = null;
      let absLsName = null;
      if (notification.data.abstractLiveshotId !== 0) {
        abstractInfo = searchList.find(
          (data) =>
            data.abstractLiveshotId === notification.data.abstractLiveshotId
        );
        if (abstractInfo) absLsName = abstractInfo.abstractLiveshotName;
      } else {
        if (normalLiveShots.length > 0) {
          liveshots = liveshots.concat(normalLiveShots[0].liveshots);
        }
        abstractInfo = liveshots.find(
          (data) => data.liveshotId === notification.data.liveshotId
        );
        if (abstractInfo)
          absLsName = abstractInfo.abstractLiveshot.abstractLiveshotName;
      }

      const userNameList = userList.find(
        (element) => element.sso === parseInt(notification.data.updatedBy)
      );

      let msg = `${moment(dateObj).fromNow()} : ${
        userNameList.userName
      } (editing for ${
        notification.data.inUseRoomCode
      }) updated a signal for "${absLsName}"`;

      return (
        <div key={index} className="notification-item">
          <div className="row grab-container">
            <div className="grab-detail" title={titleMsg}>
              <div className="grab-text">{msg}</div>
              <div className="remove-btn">
                <button
                  onClick={() => removeNotification(index)}
                  type="button"
                  className="close"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (
      notification.type === "CANVAS" ||
      notification.type === "BLOCK"
    ) {
      const userNameList = userList.find(
        (element) => element.sso === parseInt(notification.data.updatedBy)
      );

      let msg = `${moment(dateObj).fromNow()} : ${
        userNameList.userName
      } updated the visible canvas`;

      return (
        <div key={index} className="notification-item">
          <div className="row grab-container">
            <div className="grab-detail" title={titleMsg}>
              <div className="grab-text">{msg}</div>
              <div className="remove-btn">
                <button
                  onClick={() => removeNotification(index)}
                  type="button"
                  className="close"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  });

  return (
    <div className="Notificationbox_container">
      <div className="closebtn">
        <button
          onClick={props.closeNotificationPanel}
          type="button"
          className="close"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <button
        className="clear-msg btns"
        onClick={() => removeNotification("all")}
      >
        CLEAR
      </button>
      <h4>Notifications</h4>
      <div className="notification-container">{notificationShow}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userList: state.user.userList,
    searchList: state.searchListInfo.searchList,
  };
};

export default connect(mapStateToProps)(NotificationPanel);
