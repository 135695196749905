import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";

import "./RoleChange.css";

const ViewRoleAs = (props) => {
  const {
    token,
    onFetchRoleList,
    roleList,
    currentUserRoleId,
    onChangeUserRole,
  } = props;

  const [currentRole, setCurrentRole] = useState(currentUserRoleId);

  useEffect(() => {
    onFetchRoleList(token);
  }, [token, onFetchRoleList]);

  const setUserRole = (e) => {
    if (e.target.value !== "") {
      setCurrentRole(e.target.value);
      onChangeUserRole(Number(e.target.value), token);
    }
  };

  let role_dropdown = roleList.map((role) => (
    <option key={role.roleId} value={role.roleId}>
      {role.roleName}
    </option>
  ));

  return process.env.REACT_APP_ENV_NAME === "develop" ? (
    <select
      className="role_dropdown"
      value={currentRole}
      onChange={setUserRole}
    >
      <option>--View Role As--</option>
      {role_dropdown}
    </select>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    roleList: state.user.roleList,
    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchRoleList: (token) => dispatch(actions.fetchRoleList(token)),
    onChangeUserRole: (roleId, token) =>
      dispatch(actions.changeUserRole(roleId, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewRoleAs);
