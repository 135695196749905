import * as actionTypes from "../actions/actionTypes";

const initialState = {
  normalLiveShotTypes: [],
  normalLiveShots: [],
  blankCanvasShow: false,
  loading: false,
  isFold: false,
  liveshotsInfo: {},
  newLiveshot: false,
  selectedBlockId: 0,
  arrPosition: 0,
  liveShotInfo: {},
};

const getLiveshotInfo = (state, action) => {
  return Object.assign({}, state, {
    liveshotInfo: action.liveshotInfo,
  });
};

const setLiveshotsInfo = (state, action) => {
  return Object.assign({}, state, {
    liveshotsInfo: action.liveshotsInfo,
  });
};

const addNormalLiveShotType = (state, action) => {
  return Object.assign({}, state, {
    normalLiveShotTypes: state.normalLiveShotTypes.concat(
      action.normalLiveShotTypes
    ),
    blankCanvasShow: false,
    loading: false,
  });
};

const getNormalLiveShots = (state, action) => {
  return Object.assign({}, state, {
    normalLiveShotTypes: [],
    normalLiveShots: action.normalLiveShots,
    blankCanvasShow: false,
    loading: false,
  });
};

const getLiveShots = (state, action) => {
  return Object.assign({}, state, {
    normalLiveShotTypes: [],
    normalLiveShots: action.normalLiveShots,
    blankCanvasShow:
      action.normalLiveShots.filter((item) => item.liveshots.length > 0)
        .length >
      (0).length >
      0
        ? false
        : true,
    loading: false,
  });
};

const setBlankCanvas = (state, action) => {
  return Object.assign({}, state, {
    normalLiveShotTypes: [],
    normalLiveShots: [],
    blankCanvasShow: true,
    loading: false,
  });
};

const setLoadingStart = (state, action) => {
  return Object.assign({}, state, { loading: true });
};

const setLoadingEnd = (state, action) => {
  return Object.assign(state, { loading: false });
};

const setFold = (state, action) => {
  return Object.assign(state, { isFold: true });
};

const setUnfold = (state, action) => {
  return Object.assign(state, { isFold: false });
};

const startLSCreationSuccess = (state, action) => {
  return Object.assign({}, state, {
    newLiveshot: true,
    selectedBlockId: action.blockId,
  });
};

const resetLSCreationSuccess = (state, action) => {
  return Object.assign({}, state, {
    newLiveshot: false,
  });
};

const onStoreArrPosition = (state, action) => {
  return Object.assign({}, state, {
    arrPosition: action.arrPosition,
  });
};

const updateNormalLiveshotStatus = (state, action) => {
  const updatedLiveshot = action.normalLiveShots || [];
  const currentNormalLiveshot = state.normalLiveShots;
  const updatedBlock = currentNormalLiveshot.map((block) => {
    if (block.blockId === updatedLiveshot.blockId) {
      const liveshots = (block.liveshots || []).map((liveshot) => {
        if (liveshot.liveshotId === updatedLiveshot.liveshotId) {
          return updatedLiveshot;
        }
        return liveshot;
      });
      block.liveshots = liveshots;
    }
    return block;
  });
  return Object.assign({}, state, {
    normalLiveShots: updatedBlock,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NORMAL_LIVESHOT_TYPE:
      return addNormalLiveShotType(state, action);
    case actionTypes.NORMAL_LIVESHOTS:
      return getNormalLiveShots(state, action);
    case actionTypes.LIVESHOTS:
      return getLiveShots(state, action);
    case actionTypes.NO_LIVESHOTS:
      return setBlankCanvas(state, action);
    case actionTypes.LOADING_START:
      return setLoadingStart(state, action);
    case actionTypes.LOADING_END:
      return setLoadingEnd(state, action);
    case actionTypes.MAKE_FOLD:
      return setFold(state, action);
    case actionTypes.MAKE_UNFOLD:
      return setUnfold(state, action);
    case actionTypes.LIVESHOTS_INFO:
      return setLiveshotsInfo(state, action);
    case actionTypes.LIVESHOT_INFO:
      return getLiveshotInfo(state, action);
    case actionTypes.START_LIVESHOT_CREATION:
      return startLSCreationSuccess(state, action);
    case actionTypes.RESET_LIVESHOT_CREATION:
      return resetLSCreationSuccess(state, action);
    case actionTypes.STORE_ARR_POSITION:
      return onStoreArrPosition(state, action);
    case actionTypes.UPDATE_NORMAL_LIVESHOTS:
      return updateNormalLiveshotStatus(state, action);
    default:
      return state;
  }
};

export default reducer;
