import { ROLE_PERMISSION } from "../Constants";

export const checkPermission = (userRole, permissionDesc) => {
  if (userRole) {
    const permissionList = userRole.permission || [];
    const rolePerm = (userRole && ROLE_PERMISSION[userRole.roleId]) || [];
    const hasToolPerm = rolePerm.includes(permissionDesc);
    const userPerm = permissionList.find(
      (usr) => usr.appPermissionDesc === permissionDesc
    );
    if (userPerm || hasToolPerm) {
      return true;
    }
  }
  return false;
};
