import * as actionTypes from "./actionTypes";
import axios from "../../axios";
import * as Constants from "../../components/shared/Constants";
import * as actions from "../../store/actions/index";

export const saveShowSuccess = (resdata) => {
  return {
    type: actionTypes.SHOWSAVE_OBJECT_SUCCESS,
    successMsg: resdata,
    showModal: false,
  };
};

// export const closeShowModalResult = () => {
//   return {
//     type: actionTypes.CLOSE_SHOW_MODAL,
//     showModal: false,
//   };
// };

export const deleteShowResult = (res) => {
  return {
    type: actionTypes.DELTE_SHOW_SUCCESS,
    successMsg: res,
    showModal: false,
  };
};

// export const closeShowModal = (token) => {
//   return (dispatch) => {
//     dispatch(closeShowModalResult());
//     dispatch(actions.fetchShowList(token));
//   };
// };

export const saveShow = (showObj, token) => {
  return (dispatch) => {
    axios
      .post("liveshot-api/show/", showObj, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch(
            saveShowSuccess({
              resultData: Constants.SHOW_SAVE_SUCCESS_MSG,
              statusCode: res.status,
            })
          );
          dispatch(actions.fetchShowList(token));
        }
      })
      .catch((err) => {
        dispatch(
          saveShowSuccess({
            resultData: Constants.SHOW_SAVE_FAILURE_MSG,
            statusCode: err.status,
          })
        );
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const updateShow = (showObj, token) => {
  return (dispatch) => {
    axios
      .put("liveshot-api/show/", showObj, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            saveShowSuccess({
              resultData: Constants.SHOW_UPDATE_SUCCESS_MSG,
              statusCode: res.status,
            })
          );
          dispatch(actions.fetchShowList(token));
        }
      })
      .catch((err) => {
        dispatch(
          saveShowSuccess({
            resultData: Constants.SHOW_UPDATE_FAILURE_MSG,
            statusCode: err.status,
          })
        );
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const deleteShow = (token, Id) => {
  return (dispatch) => {
    axios
      .delete(`liveshot-api/show/${Id}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            saveShowSuccess({
              resultData: Constants.DELETE_SHOW_SUCCESS_MSG,
              statusCode: res.status,
            })
          );
          dispatch(actions.fetchShowList(token));
          dispatch(actions.resetShowData());
        }
      })
      .catch((err) => {
        dispatch(
          saveShowSuccess({
            resultData: Constants.DELETE_SHOW_FAILURE_MSG,
            statusCode: err.status,
          })
        );
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};
