import React from "react";
import { Tooltip } from "antd";
import "./Tooltip.css";

export const AntdTooltip = ({
  title,
  children,
  placement = "bottom",
  ...props
}) => {
  return (
    <Tooltip
      className={"canvas-tooltip"}
      placement={placement}
      title={title}
      trigger={"hover"}
      arrow={false}
      align={{ offset: [0, 10] }}
      {...props}
    >
      <span>{children}</span>
    </Tooltip>
  );
};
