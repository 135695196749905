import * as actionTypes from "../../store/actions/actionTypes";

const initialState = {
  currentCanvas: null,
  currentCanvasId: null,
  canvasList: [],
  controlRoomList: [],
  canvasMsg: {},
  showCreateCanvas: false,
  showDate: {},
  nullShowExist: false,
  routingCapabilityType: 0,
  commCapabilityType: 0,
  isAddedFromTemplateDate: false,
  armRouting: false,
  shortCut: false,
  streamDeckOn: false,
  activeLiveshot: null,
  nextActiveLiveshot: null,
  pressedKey: null,
  absLiveshotPreference: [],
};

const addCurrentCanvas = (state, action) => {
  return Object.assign({}, state, {
    currentCanvas: action.currentCanvas,
    currentCanvasId: action.currentCanvasId,
  });
};

const fetchCanvasListSuccess = (state, action) => {
  return Object.assign({}, state, {
    canvasList: action.canvasList,
  });
};

const fetchControlRoomListSuccess = (state, action) => {
  return Object.assign({}, state, {
    controlRoomList: action.controlRoomList,
  });
};

const canvasCheckSuccess = (state, action) => {
  return Object.assign({}, state, {
    canvasMsg: action.canvasMsg,
    showCreateCanvas: action.showCreateCanvas,
  });
};

const closeCreateCanvasModal = (state, action) => {
  return Object.assign({}, state, {
    showCreateCanvas: action.showCreateCanvas,
  });
};

const seletedShowDate = (state, action) => {
  return Object.assign({}, state, {
    showDate: action.showDate,
  });
};

const addCapabilitySuccess = (state, action) => {
  return Object.assign({}, state, {
    routingCapabilityType: action.routingCapabilityType,
    commCapabilityType: action.commCapabilityType,
  });
};

const checkNullShowDate = (state, action) => {
  return Object.assign({}, state, {
    nullShowExist: action.nullShowExist,
  });
};

const liveshotAddedFromTemplateOrDate = (state, action) => {
  return Object.assign({}, state, { isAddedFromTemplateOrDate: true });
};

const resetAddedFromTemplateOrDate = (state, action) => {
  return Object.assign({}, state, { isAddedFromTemplateOrDate: false });
};

const settingArmRouting = (state, action) => {
  return Object.assign({}, state, { armRouting: action.armRouting });
};

const setToggleKeyboard = (state, action) => {
  return Object.assign({}, state, { shortCut: !state.shortCut });
};

const setToggleStreamDeck = (state, action) => {
  return Object.assign({}, state, { streamDeckOn: !state.streamDeckOn });
};

const setActiveLiveshot = (state, action) => {
  return Object.assign({}, state, { activeLiveshot: action.liveshotId });
};

const setNextActiveLiveshot = (state, action) => {
  return Object.assign({}, state, { nextActiveLiveshot: action.liveshotId });
};

const setPressedKey = (state, action) => {
  return Object.assign({}, state, { pressedKey: action.key });
};
const canvasTemplateSelected = (state, action) => {
  return Object.assign({}, state, {
    canvasTemplateSelected: action.canvasTemplateSelected,
  });
};

const setAbsLsPreference = (state, action) => {
  return Object.assign({}, state, { absLiveshotPreference: action.preference });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CURRENT_CANVAS:
      return addCurrentCanvas(state, action);
    case actionTypes.FETCH_CANVAS_LIST_SUCCESS:
      return fetchCanvasListSuccess(state, action);
    case actionTypes.FETCH_CONTROLROOM_LIST_SUCCESS:
      return fetchControlRoomListSuccess(state, action);
    case actionTypes.CANVAS_CHECK_SUCCESS:
      return canvasCheckSuccess(state, action);
    case actionTypes.CLOSE_CREATE_CANVAS_MODAL:
      return closeCreateCanvasModal(state, action);
    case actionTypes.SHOW_DATE:
      return seletedShowDate(state, action);
    case actionTypes.ADD_CAPABILITY:
      return addCapabilitySuccess(state, action);
    case actionTypes.NULL_SHOWDATE_CHECK:
      return checkNullShowDate(state, action);
    case actionTypes.LIVESHOT_ADDED_FROM_TEMPLATE_OR_DATE:
      return liveshotAddedFromTemplateOrDate(state, action);
    case actionTypes.RESET_ADDED_FROM_TEMPLATE_OR_DATE:
      return resetAddedFromTemplateOrDate(state, action);
    case actionTypes.ARM_ROUTING:
      return settingArmRouting(state, action);
    case actionTypes.SET_ABS_LS_PREFERENCE:
      return setAbsLsPreference(state, action);
    case actionTypes.KEY_SHORT_CUT:
      return setToggleKeyboard(state, action);
    case actionTypes.DEVICE_STREAM_DECK:
      return setToggleStreamDeck(state, action);
    case actionTypes.SET_ACTIVE_LIVESHOT:
      return setActiveLiveshot(state, action);
    case actionTypes.SET_NEXT_ACTIVE_LIVESHOT:
      return setNextActiveLiveshot(state, action);
    case actionTypes.SET_PRESSED_KEY:
      return setPressedKey(state, action);
    case actionTypes.CANVAS_TEMPLATE_SELECTED:
      return canvasTemplateSelected(state, action);
    default:
      return state;
  }
};

export default reducer;
