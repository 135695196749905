import React, { useState } from "react";
import Button from "../shared/UI/Button/Button";
import { ReactComponent as DownArrow } from "../../assets/icons/dropdown-arrow.svg";

const AvailVcMenu = (props) => {
  const { isMenuDisable, availTitle, getAvailVC, field } = props;
  const [isVisibleDropdown, setIsVisibleDropdown] = useState(false);
  const onDisplayDropDown = () => {
    setIsVisibleDropdown(!isVisibleDropdown);
  };

  let availVCMenuList = null;
  if (isVisibleDropdown === true) {
    let availVCMenu = [
      { id: 1, name: "Skype" },
      { id: 2, name: "Web" },
      { id: 3, name: "Qlink" },
    ];
    availVCMenuList = (
      <ul className={isVisibleDropdown ? "avail-menu" : "hide-availVc"}>
        {availVCMenu.map((list, index) => {
          return (
            <li
              key={list.id}
              value={list.id}
              onClick={() => {
                getAvailVC(list.name, field);
                onDisplayDropDown();
              }}
            >
              {list.name}{" "}
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <div>
      <Button
        type="button"
        btnType="availVc"
        onClick={onDisplayDropDown}
        disabled={isMenuDisable}
      >
        AVAIL VC
        <DownArrow className="down-arrow" />
      </Button>
      {availVCMenuList}
    </div>
  );
};

export default AvailVcMenu;
