import React, {
  Fragment,
  useState,
  useEffect,
  setState,
  setStyle,
} from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactBootstrap, { Navbar, Nav } from "react-bootstrap";
import Backdrop from "../../shared/UI/Backdrop/Backdrop";
import Dropdown from "react-bootstrap/Dropdown";
import NotificationPanel from "../../panels/notificationpanel/NotificationPanel";
import Modal from "../../shared/UI/Modal/Modal";
import ManageShow from "../../modals/manageshow/ManageShow";
import * as actions from "../../../store/actions/index";
import * as Constants from "../../../components/shared/Constants";
import Show_icon from "../../../assets/icons/Show-icon.svg";
import "./NavigationHeader.css";
import mousetrap from "mousetrap";
import { checkPermission } from "../../shared/utils/userUtils";
import { useUser } from "../../../hooks/useUser";
import NavbarTimer from "../NavbarTimer";
import { env_constants } from "../../../constants/PageConstants";
import NotificationBarComponent from "../../shared/UI/NotificationBarComponent/NotificationBarComponent";
import Venue_icon from "../../../assets/icons/navbar/Venue_Icon.svg";
import Source_destinations_icon from "../../../assets/icons/source-destinations-icon.svg";
import Resource_tracker_icon from "../../../assets/icons/navbar/resource-tracker.svg";
import Tif_tally_icon from "../../../assets/icons/navbar/tif-tally.svg";
import Arrivals_board_icon from "../../../assets/icons/navbar/arrivals-board.svg";
import User_icon from "../../../assets/icons/navbar/user-icon.svg";
import Provide_feedback_icon from "../../../assets/icons/provide-feedback-icon.svg";
import Support_icon from "../../../assets/icons/support-icon.svg";
import Triangle_poly_icon from "../../../assets/icons/navbar/triangle-poly.svg";

const NavigationHeader = (props) => {
  const {
    showNotification,
    onShowNotificationModal,
    onCloseNotificationModal,
    normalLiveShotTypes,
    onResetShowData,
    shortCut,
    token,
    onFetchCurrentUserRole,
    currentUser,
    currentUserRole,
    normalLiveShots,
  } = props;
  const { createShowPermissionData } = useUser();
  let homeLink = null;

  const [notificationList, setNotificationList] = useState([]);
  const [createShow, setCreateShow] = useState(false);
  const [isKeyPressed, setIsKeyPressed] = useState(true);
  const [showRefreshModal, setShowRefreshModal] = useState(false);
  const [manageToolsDropdown, setManageToolsDropdown] = useState(false);
  const [adminToolsDropdown, setAdminToolsDropdown] = useState(false);
  const [show, setShow] = useState(false);
  const [createShowPermission, setCreateShowPermission] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorBar, setShowErrorBar] = useState(false);
  const [isProdBanner, setIsProdBanner] = useState(false);
  const [userProfileText, setUserProfileText] = useState("");
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const openErrorBar = (message) => {
    setErrorMessage(message);
    setShowErrorBar(true);
  };
  const closeErrorBar = () => {
    setShowErrorBar(false);
  };

  const onShowNotification = () => {
    onShowNotificationModal();
  };

  const onCloseNotification = () => {
    onCloseNotificationModal();
  };
  useEffect(() => {
    mousetrap.bind("escape", (e) => {
      e.preventDefault();
      console.log(e);
      onCloseNotification();
    });
  });
  useEffect(() => {
    if (currentUserRole) {
      setCreateShowPermission(createShowPermissionData);
    }
  }, [currentUserRole]);

  let roleId = currentUserRole.roleId;

  let prodEnvMessage = null;
  useEffect(() => {
    if (!isProdBanner) {
      if (["prod"].includes(process.env.REACT_APP_ENV_NAME)) {
        openErrorBar(env_constants.PROD_MESSAGE, true);
      }
      setIsProdBanner(true);
    }
  }, [isProdBanner]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.aliasName && currentUser.aliasName.trim() != "") {
        setUserProfileText(currentUser.aliasName.trim());
      } else {
        let userName = "";
        if (currentUser.firstName && currentUser.firstName.trim() != "") {
          userName = currentUser.firstName.trim();
        }
        if (currentUser.lastName && currentUser.lastName.trim() != "") {
          userName = userName + " " + currentUser.lastName.trim();
        }
        setUserProfileText(userName != "" ? userName : currentUser.sso);
      }
    }
  }, [currentUser]);

  const onManageShows = () => {
    onResetShowData();
    setCreateShow(true);
  };

  const onCancelManageShows = () => {
    setCreateShow(false);
  };
  const removeNotification = (index) => {
    if (index === "all") {
      setNotificationList([]);
    } else {
      let list = Array.from(notificationList);
      list.splice(index, 1);
      setNotificationList(list);
    }
  };

  let notificationPanel = null;
  if (showNotification) {
    notificationPanel = (
      <Fragment>
        <Backdrop show={showNotification} clicked={onCloseNotification} />
        <NotificationPanel
          closeNotificationPanel={onCloseNotification}
          notificationList={notificationList}
          removeNotification={removeNotification}
          normalLiveShots={normalLiveShots}
        />
      </Fragment>
    );
  }

  return (
    <Navbar
      expand="lg"
      className="navbar-background"
      data-spy="affix"
      data-offset-top="70"
    >
      <div className="navbar-branding">
        <Navbar.Brand className="navbar-title">
          <Link className="navbar-title" to="/">
            Live Shot Manager
          </Link>
        </Navbar.Brand>
        <NavbarTimer />
      </div>
      <Modal
        show={createShow}
        modalClosed={onCancelManageShows}
        className="manageshowsmodal"
      >
        {createShow && <ManageShow modalClosed={onCancelManageShows} />}
      </Modal>

      <Nav className="me-auto">
        <Fragment>
          {notificationPanel}
          {roleId === 1 || roleId === 5 ? (
            <Dropdown
              onMouseLeave={() => setAdminToolsDropdown(false)}
              onMouseOver={() => setAdminToolsDropdown(true)}
              className="dropdown-navbar"
            >
              <Dropdown.Toggle
                onClick={() => setAdminToolsDropdown((current) => !current)}
              >
                <div className="tools-text-navbtn">
                  <span className="tools-text">Admin Tools</span>
                </div>
              </Dropdown.Toggle>
              {adminToolsDropdown ? (
                <Navbar expand="lg" className="admin-tools-nav-bg">
                  <img
                    className="triangle-poly-admin"
                    alt="triangle-poly-menu"
                    src={Triangle_poly_icon}
                  />
                  <Dropdown.Menu
                    className="admin-dropdown-menu"
                    show={adminToolsDropdown}
                    isOpen={true}
                  >
                    <Dropdown.Item className="admin-tools-dropdown-item-bar">
                      <div
                        className="admin-tools-dropdown-item-inline"
                        onClick={createShowPermission ? onManageShows : null}
                      >
                        <i className="nav-dropdown-item-margin">
                          <img alt="manage_shows_icon" src={Show_icon} />
                        </i>
                        <p className="nav-dropdown-text-menu">MANAGE SHOWS</p>
                      </div>
                    </Dropdown.Item>
                    {props.venueAddressperm ? (
                      <Dropdown.Item className="admin-tools-dropdown-item-bar">
                        <Link
                          className="admin-tools-dropdown-item-inline"
                          to="/venue-address-book"
                        >
                          <i className="nav-dropdown-item-margin">
                            <img
                              className="navbar-btn-dropdown-img"
                              alt="venue_address_icon"
                              src={Venue_icon}
                            />
                          </i>
                          <p className="nav-dropdown-text-menu">
                            VENUE ADDRESS BOOK
                          </p>
                        </Link>
                      </Dropdown.Item>
                    ) : null}
                    <Dropdown.Item className="admin-tools-dropdown-item-bar">
                      <Link
                        className="admin-tools-dropdown-item-inline"
                        to="/source-destination"
                      >
                        <i className="nav-dropdown-item-margin">
                          <img
                            className="navbar-btn-dropdown-img"
                            alt="source_destinations_icon"
                            src={Source_destinations_icon}
                          />
                        </i>
                        <p className="nav-dropdown-text-menu">
                          SOURCE DESTINATIONS
                        </p>
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Navbar>
              ) : null}
            </Dropdown>
          ) : null}

          {checkPermission(currentUserRole, "Management Tools") ? (
            <Dropdown
              onMouseLeave={() => setManageToolsDropdown(false)}
              onMouseOver={() => setManageToolsDropdown(true)}
              className="dropdown-navbar"
            >
              <Dropdown.Toggle
                onClick={() => setManageToolsDropdown((current) => !current)}
              >
                <div className="tools-text-navbtn">
                  <span className="tools-text">Management Tools</span>
                </div>
              </Dropdown.Toggle>
              {manageToolsDropdown ? (
                <Navbar expand="lg" className="manage-tools-nav-bg">
                  <img
                    className="triangle-poly-manage"
                    alt="triangle-poly-menu"
                    src={Triangle_poly_icon}
                  />
                  <Dropdown.Menu
                    className="manage-dropdown-menu"
                    show={manageToolsDropdown}
                  >
                    {Constants.CONTROL_TRACKER_PERMISSION.includes(roleId) ? (
                      <Dropdown.Item className="manage-tools-dropdown-item-bar">
                        <Link
                          className="admin-tools-dropdown-item-inline"
                          to="/resource-tracker"
                        >
                          <i className="nav-dropdown-item-margin">
                            <img
                              className="navbar-btn-dropdown-img"
                              alt="resource_tracker_icon"
                              src={Resource_tracker_icon}
                            />
                          </i>

                          <p className="nav-dropdown-text-menu">
                            RESOURCE TRACKER
                          </p>
                        </Link>
                      </Dropdown.Item>
                    ) : null}
                    <Dropdown.Item
                      className="manage-tools-dropdown-item-bar-arrivals manage-tools-dropdown-item-inline"
                      onClick={(e) =>
                        (window.location =
                          process.env.REACT_APP_ARRIVALS_BOARD_URL)
                      }
                    >
                      <i className="nav-dropdown-item-margin">
                        <img
                          className="navbar-btn-dropdown-img"
                          alt="arrivals_board_icon"
                          src={Arrivals_board_icon}
                        />
                      </i>
                      <p className="nav-dropdown-text-menu">ARRIVALS BOARD</p>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="manage-tools-dropdown-item-bar manage-tools-dropdown-item-inline"
                      onClick={(e) =>
                        (window.location = `${process.env.REACT_APP_TIF_TALLY_URL}${props.controlRoomId}`)
                      }
                    >
                      <i className="nav-dropdown-item-margin">
                        <img
                          className="navbar-btn-dropdown-img"
                          alt="tif_tally_icon"
                          src={Tif_tally_icon}
                        />
                      </i>
                      <p className="nav-dropdown-text-menu">TIF TALLY</p>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Navbar>
              ) : null}
            </Dropdown>
          ) : null}
          <Dropdown
            className="dropdown-navbar profile-dropdown"
            onToggle={(show) => {
              setIsProfileDropdownOpen(show);
            }}
          >
            <Dropdown.Toggle className="profile-dropdown-toggle">
              <div className="user-dropdown-icon-style">
                <div
                  className={`navbar-btn-img-user ${
                    isProfileDropdownOpen
                      ? "user-img-active"
                      : "user-img-inactive"
                  }`}
                >
                  <i className="userProfile-icon" alt="user_options_icon">
                    <img src={User_icon} />
                  </i>
                </div>
                <div className="userProfile-text">{userProfileText}</div>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="user-dropdown-menu">
              <div className="user-menu-container-dropdown">
                <Dropdown.Item
                  className="user-dropdown-item"
                  href={process.env.REACT_APP_FEEDBACK_URL}
                >
                  <p className="nav-dropdown-text">PROVIDE FEEDBACK</p>
                  <i className="nav-dropdown-item-margin">
                    <img
                      className="navbar-btn-dropdown-img"
                      alt="provide_feedback_icon"
                      src={Provide_feedback_icon}
                    />
                  </i>
                </Dropdown.Item>
                <Dropdown.Item
                  className="user-dropdown-item"
                  href={"tel:" + process.env.REACT_APP_MTCE_PHONE}
                >
                  <p className="nav-dropdown-text">
                    SUPPORT: {process.env.REACT_APP_MTCE_PHONE}
                  </p>
                  <i className="nav-dropdown-item-margin-support">
                    <img
                      className="navbar-btn-dropdown-img"
                      alt="support_icon"
                      src={Support_icon}
                    />
                  </i>
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </Fragment>
      </Nav>
      {showErrorBar && (
        <NotificationBarComponent
          errorMessage={errorMessage}
          closeErrorBar={closeErrorBar}
          isError={true}
        ></NotificationBarComponent>
      )}
    </Navbar>
  );
};

const mapStateToProps = (state) => {
  return {
    currentCanvas: state.canvasInfo.currentCanvas,
    currentCanvasId: state.canvasInfo.currentCanvasId,
    canvasList: state.canvasInfo.canvasList,
    showList: state.showInfo.showList,
    showNotification: state.notificationInfo.showNotification,
    token: state.auth.accessToken,
    networkList: state.showInfo.networkList,
    currentShow: state.showInfo.currentShow,
    showCreateCanvas: state.canvasInfo.showCreateCanvas,
    controlRoomList: state.canvasInfo.controlRoomList,
    currentUser: state.auth.currentUser,
    currentUserRole: state.user.currentUserRole,
    shortCut: state.canvasInfo.shortCut,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResetShowData: () => dispatch(actions.resetShowData()),
    onToggleKeyboard: () => dispatch(actions.keyShortcut()),
    onShowNotificationModal: () => dispatch(actions.openNotificationModal()),
    onCloseNotificationModal: () => dispatch(actions.closeNotificationModal()),
    onFetchCurrentUserRole: (sso, token) =>
      dispatch(actions.fetchCurrentUserRole(sso, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationHeader);
