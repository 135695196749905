import React from "react";
import "./Tooltip.css";
import { Tooltip as AntdTooltip } from "antd";

const Tooltip = (props) => {
  const content = (
    <div className={`tooltip-message-${props.messageTheme}`}>
      <div className={`tooltip-title-${props.titleTheme}`}>{props.title}</div>
      <div className={`tooltip-content-${props.messageStyle}`}>
        {props.message}
      </div>
    </div>
  );

  return props.baseToolTip ? (
    <div className={`tooltip-bubble`}>
      <div className={`tooltip-message-${props.messageTheme}`}>
        <div className={`tooltip-title-${props.titleTheme}`}>{props.title}</div>
        <div className={`tooltip-content-${props.messageStyle}`}>
          {props.message}
        </div>
      </div>
    </div>
  ) : (
    <AntdTooltip
      title={content}
      placement={props.position}
      arrow={false}
      autoAdjustOverflow
      overlayClassName="update-info"
    >
      {props.children}
    </AntdTooltip>
  );
};

export default Tooltip;
