import React from "react";
import "./UI/commanModal/CommanModal.css";
import infoLogo from "../../assets/icons/Info.svg";
import closeIcon from "../../assets/icons/Close.svg";
import { useState } from "react";
import CloseIconComponent from "./UI/CloseIconComponent/CloseIconComponent";

const ErrorMessage = (props) => {
  const [closebg, setClosebg] = useState(false);

  const handleClosebtn = () => {
    setClosebg(true);
  };
  return (
    <div className="popup-modal">
      <div className="modal-content info-message-container">
        <div className="info-message-container-inner">
          <div className="info-message-header">
            <img className="info-msg-logo" alt="info" src={infoLogo} />
            <span className="failure-msg">Info Message</span>
            <CloseIconComponent onCloseModal={props.closeModal} />
          </div>
          <div>
            <span className="failure-message">{props.message}</span>
          </div>
          <div className="info-message-footer">
            <button
              type="button"
              className={
                closebg
                  ? "btn btn-default info-message-closebtn"
                  : "info-message-closebtn"
              }
              onClick={() => {
                handleClosebtn();
                props.closeModal();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
