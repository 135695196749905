import * as actionTypes from "../../store/actions/actionTypes";

const initialState = {
  successMsg: {},
  showModal: false,
};

const resetStudioTruckMsgSuccess = (state, action) => {
  return Object.assign({}, state, {
    successMsg: action.successMsg,
    showModal: action.showModal,
  });
};

const saveStudioTruckSuccess = (state, action) => {
  return Object.assign({}, state, {
    successMsg: action.successMsg,
    showModal: action.showModal,
  });
};

const closeStudioTruckModalResult = (state, action) => {
  return Object.assign({}, state, { showModal: false });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STUDIOTRUCKSAVE_OBJECT_SUCCESS:
      return saveStudioTruckSuccess(state, action);
    case actionTypes.CLOSE_SHOW_MODAL:
      return closeStudioTruckModalResult(state, action);
    case actionTypes.RESET_STUDIOTRUCK_SUCCESS_MSG:
      return resetStudioTruckMsgSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
