import React, { memo } from "react";
import "./LiveshotNameSuggestion.css";

const LiveshotNameSuggestion = (props) => {
  const {
    LSNameLists,
    closeSuggestion,
    showsuggestion,
    selectLiveshotName,
    optionField,
  } = props;
  return (
    <div
      className={
        showsuggestion && LSNameLists.length > 0
          ? "list-container"
          : "hide-suggestion"
      }
    >
      <ul
        className={`suggestions ${LSNameLists.length > 3 ? "scroll-pad" : ""}`}
      >
        {LSNameLists.map((option, index) => {
          let suggestion = optionField ? option[optionField] : option;
          const isSelected =
            props.selectedList &&
            props.selectedList === (suggestion || "").toUpperCase();
          return (
            <li
              name={props.name ? props.name : "suggestions"}
              className={isSelected && "selected-list"}
              key={index}
              onClick={() => {
                selectLiveshotName(suggestion.toUpperCase(), option);
                closeSuggestion(false);
              }}
            >
              {(suggestion || "").toUpperCase()}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default memo(LiveshotNameSuggestion);
