import React from "react";
import "./Blank.css";

const Blank = () => (
  <div className="blankbox">
    <h1>Blank canvas</h1>
  </div>
);

export default Blank;
