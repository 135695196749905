import React from "react";
import infoLogo from "../../../assets/icons/Info.svg";
import "../../shared/UI/commanModal/CommanModal";
import CloseIconComponent from "../../shared/UI/CloseIconComponent/CloseIconComponent";
import Backdrop from "../../shared/UI/Backdrop/Backdrop";

const ControlRoomCheckModal = (props) => {
  const {
    show,
    fromControlRoomDetails,
    toControlRoomDetails,
    onUpdateConfirm,
    onUpdateCancel,
    onCloseModal,
  } = props;

  return (
    show && (
      <>
        <Backdrop show={show} clicked={onCloseModal} />
        <div className="popup-modal">
          <div className="modal-content info-message-container-cr">
            <div className="info-message-container-inner-cr">
              <div className="info-message-header">
                <img className="info-msg-logo" alt="info" src={infoLogo} />
                <span className="failure-msg">Info Message</span>
                <CloseIconComponent onCloseModal={onCloseModal} />
              </div>
            </div>
            <div className="modal-body">
              <div className="ctrlroom-text">
                <span className="failure-message-cr">
                  Control room has been changed. Would you also like to update
                  all {fromControlRoomDetails.roomCode} CR path signals to "
                  {toControlRoomDetails.roomCode}
                  ..." for "{toControlRoomDetails.roomName}"?
                </span>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className={"info-message-nobtn"}
                onClick={() => onUpdateCancel(toControlRoomDetails)}
              >
                No
              </button>
              <button
                type="button"
                className={"info-message-closebtn"}
                onClick={() => onUpdateConfirm(toControlRoomDetails)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default ControlRoomCheckModal;
