import React from "react";
import { Input } from "antd";

const { TextArea } = Input;

export const CustomInput = (props) => {
  return (
    <TextArea
      autoSize={{ minRows: 1, maxRows: 3 }}
      {...props}
      className={
        props.overrideStyle
          ? props.className
          : "input-field" + " " + props.className
      }
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    />
  );
};
