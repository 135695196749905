import React, { useState, useEffect } from "react";
import Button from "../../shared/UI/Button/Button";
import "./ShowDropDown.css";
import SearchList from "./SearchList";
import { SHOW_TIME_DETAILS } from "../../shared/Constants";
import { timeInHours, timeNight } from "../../shared/utility";
import displayLogo from "../../../assets/icons/LSM_display.svg";
import Select from "react-select";
import invertedArrow from "../../../utils/dropdownArrow/dropdownArrow";
import { Input } from "antd";
import { ReactComponent as SearchInputIcon } from "../../../assets/icons/search-input-icon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/Close-icon-nofill.svg";

const ShowDropDown = (props) => {
  const { list, selectedShow, clearSearchTerm } = props;

  let networkList = props.networkList && props.networkList.length > 0;
  let defaultNetwork = networkList
    ? {
        value: props.networkList[0].networkId,
        key: props.networkList[0].networkId,
        label: props.networkList[0].networkName,
      }
    : "";
  const [showTimeFilter, setShowTimeFilter] = useState("");
  const [filteredData, setFiltredData] = useState(list);
  const [searchTerm, setSearchTerm] = useState("");

  const [searchNetwork, setSearchNetwork] = useState(1);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [dropdownIsOpen, setOpenDropdown] = useState(false);

  useEffect(() => {
    if (clearSearchTerm) setSearchTerm("");
  }, [clearSearchTerm]);

  const onUpdateSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const onNetworkSearch = (chosenValue) => {
    setSearchNetwork(chosenValue.value);
    setSelectedNetwork(chosenValue);
  };

  useEffect(() => {
    let filteredData = list.filter((show) => {
      return (
        (!searchNetwork || show.networkId === Number(searchNetwork)) &&
        (!showTimeFilter || filterByTime(showTimeFilter, show)) &&
        (!searchTerm ||
          show.showName.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    });
    setFiltredData(filteredData);
  }, [list, searchNetwork, showTimeFilter, searchTerm]);

  const filterByTime = (showTimeFilter, show) => {
    if (showTimeFilter == "WEEKEND") {
      return show.occursSat || show.occursSun;
    } else if (showTimeFilter == "OVERNIGHT") {
      return (
        SHOW_TIME_DETAILS.OVERNIGHT_START_TIME <= timeInHours(show.startTime) ||
        ("00:00" <= timeInHours(show.startTime) &&
          timeInHours(show.startTime) <= SHOW_TIME_DETAILS.OVERNIGHT_END_TIME)
      );
    } else {
      let startTime = SHOW_TIME_DETAILS[`${showTimeFilter}_START_TIME`];
      let endTime = SHOW_TIME_DETAILS[`${showTimeFilter}_END_TIME`];
      return (
        startTime <= timeInHours(show.startTime) &&
        timeInHours(show.startTime) <= endTime
      );
    }
  };

  const onClickFilter = (value) => {
    setShowTimeFilter(value == showTimeFilter ? "" : value);
  };

  return (
    <div className="container">
      <div className="select-ls-header-row">
        <img
          src={displayLogo}
          alt="display logo"
          className="search-display-logo"
        />
        <label className="search-header-title">Select Show</label>
        <CloseIcon onClick={props.modalClosed} className="search-closeicon" />
      </div>
      <div className="select-ls-buttons-row">
        <div>
          <Select
            className={
              dropdownIsOpen
                ? "shownetwork green-bg black-text"
                : selectedNetwork || props.networkList.length > 0
                ? "shownetwork white-text grey-bg"
                : !selectedNetwork && dropdownIsOpen
                ? "shownetwork green-bg black-text"
                : "shownetwork grey-text grey-bg"
            }
            classNamePrefix="shows-dropdown"
            onChange={onNetworkSearch}
            onMenuOpen={() => setOpenDropdown(true)}
            onMenuClose={() => setOpenDropdown(false)}
            styles={dropdownIsOpen ? invertedArrow : ""}
            cache={{ "": [{ label: "", value: null, disabled: true }] }}
            value={selectedNetwork ? selectedNetwork : defaultNetwork}
            options={props.networkList.map((option) => ({
              value: option.networkId,
              key: option.networkId,
              label: option.networkName,
            }))}
          />
        </div>
        <Button
          btnType={`showtime ${showTimeFilter == "MORNING" ? "selected" : ""}`}
          onClick={() => onClickFilter("MORNING")}
        >
          MORNING
        </Button>
        <Button
          btnType={`showtime ${showTimeFilter == "DAYTIME" ? "selected" : ""}`}
          onClick={() => onClickFilter("DAYTIME")}
        >
          DAYTIME
        </Button>
        <Button
          btnType={`showtime ${
            showTimeFilter == "PRIMETIME" ? "selected" : ""
          }`}
          onClick={() => onClickFilter("PRIMETIME")}
        >
          PRIMETIME
        </Button>
        <Button
          btnType={`showtime ${
            showTimeFilter == "OVERNIGHT" ? "selected" : ""
          }`}
          onClick={() => onClickFilter("OVERNIGHT")}
        >
          OVERNIGHT
        </Button>
        <Button
          btnType={`showtime ${showTimeFilter == "WEEKEND" ? "selected" : ""}`}
          onClick={() => onClickFilter("WEEKEND")}
        >
          WEEKEND
        </Button>
      </div>
      <div className="show-dropdown-search">
        <Input
          placeholder="SEARCH"
          className={`common-search-input ${searchTerm ? "filled" : ""}`}
          prefix={<SearchInputIcon className="search-input-icon" />}
          value={searchTerm || ""}
          onChange={onUpdateSearch}
        />
      </div>
      <div className="showlist">
        <SearchList
          filteredList={filteredData}
          selectedShowID={(id) => selectedShow(id)}
        />
      </div>
    </div>
  );
};

export default ShowDropDown;
