import {
  source_destination_page_constants,
  venue_page_constants,
} from "./PageConstants";
//Venue management page tab details.
export const Venue_Tabs_Array = [
  {
    label: venue_page_constants.VENUES_TAB,
    isPlusIcon: true,
    toolTip: "ADD VENUE",
  },
  {
    label: venue_page_constants.PIN_LOOKUP_TABLE_TAB,
    isPlusIcon: true,
    toolTip: "ADD PIN",
  },
];
//Source Destinations page tab details.
export const Source_Destination_Tabs_Array = [
  { label: source_destination_page_constants.SOURCES_TAB, isPlusIcon: false },
  {
    label: source_destination_page_constants.DESTINATIONS_TAB,
    isPlusIcon: false,
  },
  {
    label: source_destination_page_constants.SIGNAL_TYPES_TAB,
    isPlusIcon: false,
  },
  { label: source_destination_page_constants.ROOMS_TAB, isPlusIcon: false },
  {
    label: source_destination_page_constants.USERS_TAB,
    isPlusIcon: true,
    toolTip: "ADD USER",
  },
];
