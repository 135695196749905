import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { Draggable, Droppable } from "react-beautiful-dnd";
import useMousetrap from "react-hook-mousetrap";
import Button from "../../components/shared/UI/Button/Button";
import { removeDuplicates } from "../../components/shared/utility";
import { ReactComponent as ArrowIcon } from "../../assets/icons/block-header-arrow.svg";
import { ReactComponent as GrabberIcon } from "../../assets/icons/block-header-grabber.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/block-header-plus.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/block-header-trash.svg";
import { ReactComponent as Plus_icon } from "../../assets/icons/add-icon.svg";
import HostStudioObject from "../../components/liveshot/HostStudioObject/HostStudioObject";
import Searchbox from "../../utils/searchbox/Searchbox";
import "../../utils/searchbox/searchitem/Searchitem.css";
import Modal from "../../components/shared/UI/Modal/Modal";
import { ConfirmationModal } from "../../components/shared/UI/ConfirmationModal/ConfirmationModalPopup";
import { BLOCK_DELETE_CONFIRMATION_MESSAGE } from "../../constants/PageConstants";
import { useUser } from "../../hooks/useUser";
import { AntdTooltip } from "../../components/shared/UI/Tooltip/AntdTooltip";

const BlockContainer = (props) => {
  const {
    takeAllPerm,
    grabAllPerm,
    deleteBlockPerm,
    updateBlockPerm,
    addBlockPerm,
  } = useUser();
  const {
    block,
    index,
    type,
    liveshotsOrder,
    liveshotFullLists,
    normalLiveShotTypes,
    removeBlock,
    onUpdateBlock,
    isLastBlock,
    token,
    currentUser,
    outofOrderLS,
    onResetLSCreation,
    currentUserRole,
    searchList,
    onStartLSCreation,
    addBlock,
    liveShotSignalSelected,
    onTakeAllCanvasBlock,
    onGrabAllCanvasBlock,
    currentCanvas,
    routingCapabilityType,
    controlRoomList,
    isArmRouting,
    inBoundCRPath,
    outBoundCRPath,
    ifbCRPath,
    plCRPath,
    phoCRPath,
    shortCut,
    lsAddedFromSearch,
    newLiveShotId,
    isDisable,
  } = props;

  const [takeGrabBtn, setTakeGrabBtn] = useState(false);
  const [createNewLS, setCreateNewLS] = useState(false);
  const [lsType, setLsType] = useState("");
  const [isError] = useState(false);
  const [showErrorBar, setShowErrorBar] = useState(false);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [isNewLiveShotsAdded, setIsNewLiveShotsAdded] = useState(false);
  const [isDeleteIconClicked, setIsDeleteIconClicked] = useState(false);
  const [confirmationDeleteMessage, setConfirmationDeleteMessage] =
    useState(null);

  let userFullName = currentUser.firstName + " " + currentUser.lastName;
  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userFullName =
      process.env.REACT_APP_AUTH_OVERRIDE_FIRSTNAME +
      " " +
      process.env.REACT_APP_AUTH_OVERRIDE_LASTNAME;
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }

  const onAddLiveShot = (event) => {
    const target = event.target.getAttribute("type");
    setLsType(target);
    setCreateNewLS(true);
    onResetLSCreation();
  };
  useMousetrap(["l", "L", "t", "T"], (e) => {
    e.preventDefault();
    if ((e.key === "l" || e.key === "L") && shortCut) {
      createLiveShot();
    }
    if ((e.key === "t" || e.key === "T") && shortCut) {
      scrollTop();
    }
  });
  const cancelDelete = () => {
    setIsDeleteIconClicked(false);
  };
  const scrollTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  useEffect(() => {
    if (lsAddedFromSearch) {
      setIsNewLiveShotsAdded(true);
    } else {
      setIsNewLiveShotsAdded(false);
    }
  }, [lsAddedFromSearch]);
  useEffect(() => {
    if (routingCapabilityType >= 2) {
      setTakeGrabBtn(true);
    } else {
      setTakeGrabBtn(false);
    }
  }, [routingCapabilityType, takeGrabBtn]);

  useEffect(() => {
    if (currentCanvas && controlRoomList.length > 0) {
      let room = controlRoomList.find(
        (list) => list.roomId === currentCanvas.roomId
      );
      if (room) {
        setCurrentRoom(room);
      }
    }
  }, [controlRoomList, currentCanvas, currentRoom]);

  const [updatedBy] = useState(userId);
  const [blockLetter, setBlockLetter] = useState(
    block.blockName.replace(" BLOCK", "").replace(" Block", "").toUpperCase()
  );

  let normalLiveShotLists = null;

  if (liveshotsOrder && liveshotsOrder.length > 0) {
    normalLiveShotLists = liveshotsOrder.map((liveshotId, index) => {
      const liveshotIndex = liveshotFullLists.findIndex(
        (element) => element.liveshotId === liveshotId
      );
      const liveshot = liveshotFullLists[liveshotIndex];
      {
        /* Normal obj */
      }
      return (
        <HostStudioObject
          isBlockLiveshot={true}
          key={liveshot.liveshotId}
          id={liveshot.liveshotId}
          blockId={block.blockId}
          type={liveshot.abstractLiveshot.liveshotTypeId}
          formInfo={liveshot}
          index={index}
          newliveshot={false}
          outofOrderLS={outofOrderLS}
          currentUserRole={currentUserRole}
          isNewShotAdded={
            liveshot.liveshotId === newLiveShotId ? isNewLiveShotsAdded : false
          }
          blockPerm={isDisable}
        />
      );
    });
  }

  const openErrorBar = (message) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const closeErrorBar = () => {
    setShowErrorBar(false);
  };

  const liveshotTypeValue = (type) => {
    switch (type) {
      case "Local Host":
        return 1;
      case "Beauty Shot":
        return 2;
      case "Local Guest":
        return 3;
      case "Remote Host":
        return 4;
      case "Remote Guest":
        return 5;
      case "Remote Reporter":
        return 6;
      case "Remote Event":
        return 7;
      case "Other":
        return 8;
      case "Edit/Playback":
        return 9;
      default:
        return 8;
    }
  };

  /* Normal Obj */
  let normalLiveShotObjs = null;
  if (normalLiveShotTypes && normalLiveShotTypes.length > 0) {
    normalLiveShotObjs = normalLiveShotTypes.map(
      (normalLiveShotType, index) => {
        let typeValue = liveshotTypeValue(normalLiveShotType);
        return (
          <HostStudioObject
            key={index}
            isBlockLiveshot={true}
            type={typeValue}
            newliveshot={true}
            blockId={block.blockId}
            searchList={searchList}
            isNewShotAdded={false}
            blockPerm={isDisable}
          />
        );
      }
    );
  }
  const deleteBlock = () => {
    removeBlock(block.blockId);
  };
  const onClickRemoveBlock = () => {
    setIsDeleteIconClicked(true);
    setConfirmationDeleteMessage(BLOCK_DELETE_CONFIRMATION_MESSAGE);
  };

  const onClickAddBlock = () => {
    addBlock();
  };

  const changeBlockName = (event) => {
    setBlockLetter(event.target.value);
  };

  const onClickSaveBlock = (event) => {
    event.preventDefault();
    if (blockLetter && blockLetter !== "") {
      const blockId = block.blockId;
      const newBlockName = blockLetter;
      const payload = {
        blockName: newBlockName,
        updatedBy: userId,
      };
      onUpdateBlock(updatedBy, blockId, payload, block.canvasId, token);
    }
  };

  const createLiveShot = () => {
    onStartLSCreation(block.blockId);
  };

  const onClickTakeAll = (key) => {
    let showMessage = false;
    if (liveShotSignalSelected.length > 0) {
      if (liveshotsOrder && liveshotFullLists.length > 0) {
        if (liveshotFullLists.length > 0) {
          let normalLiveshot = liveshotFullLists.filter(
            (liveShot) =>
              liveShot.softDelete !== true &&
              liveShot.blockId === block.blockId &&
              (liveShot.readyForPcr === true || liveShot.readyForAir === true)
          );
          let abstractArray = [];
          let normalArray = [];
          let signalArr,
            normalSignalInfo,
            outBoundFields,
            outBoundFieldsNormal,
            commsFields,
            commsFieldsNormal;
          for (let index in normalLiveshot) {
            signalArr = removeDuplicates(liveShotSignalSelected).filter(
              (signal) =>
                signal.abstractLiveshotId ===
                  normalLiveshot[index].abstractLiveshot.abstractLiveshotId &&
                signal.liveshotSignalTypeId === 1
            );
            normalSignalInfo = removeDuplicates(liveShotSignalSelected).filter(
              (signal) =>
                signal.liveshotId === normalLiveshot[index].liveshotId &&
                signal.liveshotSignalTypeId === 0
            );
            for (let key in signalArr) {
              const signal = signalArr[key];
              const normalSignal = normalSignalInfo.find(
                (item) =>
                  item.abstractLiveshotSignalId === signal.liveshotSignalId
              );
              normalArray.push(signal);
              normalSignal && abstractArray.push(normalSignal);
            }
          }
          let liveshotWithOutGlobalPath = normalArray.filter(
            (signal) => signal.liveshotId === 0 && signal.globalPathName === ""
          );
          let liveshotNormalSignal = normalArray.filter(
            (signal) => signal.liveshotId === 0 && signal.globalPathName
          );
          let liveshotAbstractUnilateralSignal = normalArray.filter(
            (signal) => signal.liveshotId === 0 && signal.uniLateral === false
          );
          let liveshotSignalNormal = liveshotNormalSignal.concat(
            liveshotAbstractUnilateralSignal
          );
          let liveshotAbstractWithOutGlobalPath = abstractArray.filter(
            (signal) =>
              signal.abstractLiveshotId === 0 && signal.crPathName === ""
          );
          let liveshotAbstractSignal = abstractArray.filter(
            (signal) =>
              signal.abstractLiveshotId === 0 && signal.crPathName !== ""
          );
          let liveshotSignalAbstarct = liveshotAbstractSignal.filter(
            (signal, ind) =>
              ind ===
              liveshotAbstractSignal.findIndex(
                (elem) =>
                  elem.liveshotSignalTypeId === signal.liveshotSignalTypeId &&
                  elem.crPathName === signal.crPathName
              )
          );
          let liveshotSignalAbstarctDuplicate = liveshotAbstractSignal.filter(
            (signal, ind) =>
              ind !==
              liveshotAbstractSignal.findIndex(
                (elem) => elem.crPathName === signal.crPathName
              )
          );
          let liveshotSignalFiltered = liveshotSignalNormal.concat(
            liveshotSignalAbstarct,
            liveshotWithOutGlobalPath,
            liveshotAbstractWithOutGlobalPath
          );
          let liveshotSignalDuplicates = liveshotSignalAbstarctDuplicate;
          if (liveshotSignalDuplicates.length > 0) {
            for (let key in liveshotSignalDuplicates) {
              let duplicateSignal = liveshotSignalDuplicates[key];
              if (duplicateSignal.liveshotId === 0) {
                let signal = liveShotSignalSelected.filter(
                  (signal) =>
                    signal.abstractLiveshotSignalId ===
                    duplicateSignal.liveshotSignalId
                );
                duplicateSignal.crPathName = signal[0].crPathName;
              } else {
                let signal = liveShotSignalSelected.filter(
                  (signal) =>
                    signal.liveshotSignalId ===
                    duplicateSignal.abstractLiveshotSignalId
                );
                duplicateSignal.globalPathName = signal[0].globalPathName;
              }
            }
          }

          for (let index in normalLiveshot) {
            signalArr = removeDuplicates(liveShotSignalSelected).filter(
              (signal) =>
                signal.abstractLiveshotId ===
                  normalLiveshot[index].abstractLiveshot.abstractLiveshotId &&
                signal.liveshotSignalTypeId === 1
            );
            normalSignalInfo = removeDuplicates(liveShotSignalSelected).filter(
              (signal) =>
                signal.liveshotId === normalLiveshot[index].liveshotId &&
                signal.liveshotSignalTypeId === 0
            );
            for (let key in signalArr) {
              const signal = signalArr[key];
              const normalSignal =
                normalSignalInfo.find(
                  (item) =>
                    item.abstractLiveshotSignalId === signal.liveshotSignalId
                ) || {};
              let inBoundCrPathInfo = inBoundCRPath.find(
                (item) => item === normalSignal.crPathName
              );
              if (normalSignal.crPathName === "" || !inBoundCrPathInfo) {
                showMessage = true;
              }
            }
            outBoundFields = removeDuplicates(liveShotSignalSelected).filter(
              (signal) =>
                signal.abstractLiveshotId ===
                  normalLiveshot[index].abstractLiveshot.abstractLiveshotId &&
                signal.liveshotSignalTypeId === 2
            );
            outBoundFieldsNormal = removeDuplicates(
              liveShotSignalSelected
            ).filter(
              (signal) =>
                signal.liveshotId === normalLiveshot[index].liveshotId &&
                signal.liveshotSignalTypeId === 0
            );
            for (let key in outBoundFields) {
              const signal = outBoundFields[key];
              const normalSignal =
                outBoundFieldsNormal.find(
                  (item) =>
                    item.abstractLiveshotSignalId === signal.liveshotSignalId
                ) || {};

              let outBoundCrPathInfo =
                normalSignal &&
                outBoundCRPath.find((item) => item === normalSignal.crPathName);
              if (normalSignal.crPathName === "" || !outBoundCrPathInfo) {
                showMessage = true;
              }
            }
            commsFields = removeDuplicates(liveShotSignalSelected).filter(
              (signal) =>
                signal.abstractLiveshotId ===
                  normalLiveshot[index].abstractLiveshot.abstractLiveshotId &&
                (signal.liveshotSignalTypeId === 5 ||
                  signal.liveshotSignalTypeId === 8 ||
                  signal.liveshotSignalTypeId === 9)
            );
            commsFieldsNormal = removeDuplicates(liveShotSignalSelected).filter(
              (signal) =>
                signal.liveshotId === normalLiveshot[index].liveshotId &&
                signal.liveshotSignalTypeId === 0
            );
            for (let key in commsFields) {
              const signal = commsFields[key];
              const normalSignal =
                commsFieldsNormal.find(
                  (item) =>
                    item.abstractLiveshotSignalId === signal.liveshotSignalId
                ) || {};
              let commCrPathInfo = true;
              const commCRArray = [...ifbCRPath, ...plCRPath, ...phoCRPath];
              commCrPathInfo = commCRArray.find(
                (item) => item === normalSignal.crPathName
              );

              if (normalSignal.crPathName === "" || !commCrPathInfo) {
                showMessage = true;
              }
            }
          }

          if (key === "take") {
            onTakeAllCanvasBlock(
              token,
              userId,
              userFullName,
              normalLiveshot,
              liveShotSignalSelected,
              currentCanvas.roomId,
              currentRoom,
              liveshotSignalDuplicates,
              showMessage
            );
          } else {
            onGrabAllCanvasBlock(
              token,
              userId,
              userFullName,
              normalLiveshot,
              liveShotSignalSelected,
              currentCanvas.roomId,
              showMessage,
              currentRoom,
              currentCanvas,
              outBoundFieldsNormal,
              commsFieldsNormal
            );
          }
        }
      }
    }
  };

  let notAllowTakeAll = false;
  let notAllowGrabAll = false;
  if (isArmRouting === false || !takeAllPerm) {
    notAllowTakeAll = true;
  }
  if (isArmRouting === false || !grabAllPerm) {
    notAllowGrabAll = true;
  }

  let blockTakeAndGrab = null;
  if (takeGrabBtn) {
    blockTakeAndGrab = (
      <div className="normalblock-Take-Grab">
        <div className="title-block">
          <span>{blockLetter}</span> Block
        </div>
        <div className="take-grab-btns">
          <Button
            type="button"
            btnType="takeall"
            onClick={() => onClickTakeAll("take")}
            disabled={notAllowTakeAll}
          >
            USE
          </Button>
          <Button
            type="button"
            btnType="graball"
            onClick={() => onClickTakeAll("grab")}
            disabled={notAllowGrabAll}
          >
            OWN
          </Button>
        </div>
      </div>
    );
  }

  let blockAddButton = false;
  if (addBlockPerm && isLastBlock === true) {
    blockAddButton = true;
  }
  let lsContainer =
    type === "normal" ? (
      <>
        <Modal
          show={createNewLS}
          modalClosed={() => setCreateNewLS(false)}
          className="createliveshotmodal"
          classOuterBox={createNewLS ? "add-ls-outer-box" : ""}
        >
          <Searchbox
            blockId={block.blockId}
            type={lsType}
            closeErrorBar={closeErrorBar}
            openErrorBar={openErrorBar}
            isError={isError}
            modalClosed={() => setCreateNewLS(false)}
            currentUserRole={currentUserRole}
          />
        </Modal>
        <ConfirmationModal
          isModalOpen={isDeleteIconClicked}
          cancelDelete={cancelDelete}
          deleteRow={deleteBlock}
          message={confirmationDeleteMessage}
        ></ConfirmationModal>

        <Draggable
          draggableId={block.blockId.toString()}
          index={index}
          isDragDisabled={!isDisable}
        >
          {(provided, snapshot) => (
            <div
              id={block.blockId}
              className="normalblockwrapper"
              {...provided.draggableProps}
              ref={provided.innerRef}
              style={{
                borderColor: snapshot.isDragging ? "#87D1AE" : "#1D1D1D",
                ...provided.draggableProps.style,
              }}
            >
              <div className="block-header-container">
                <div className="normalblockheader">
                  <div
                    {...provided.dragHandleProps}
                    className={!isDisable ? "hide-add-icon" : "grabber-btn"}
                  >
                    <AntdTooltip title="MOVE BLOCK" className="block-icon">
                      <GrabberIcon />
                    </AntdTooltip>
                  </div>
                  <div className="scrolltop_btn" onClick={scrollTop}>
                    <AntdTooltip title="TOP OF PAGE" className="block-icon">
                      <ArrowIcon />
                    </AntdTooltip>
                  </div>
                  <div
                    className={
                      !deleteBlockPerm ? "hide-delete-icon" : "remove-block"
                    }
                    onClick={onClickRemoveBlock}
                  >
                    <AntdTooltip title="DELETE BLOCK" className="block-icon">
                      <TrashIcon />
                    </AntdTooltip>
                  </div>
                  <div
                    className={!blockAddButton ? "hide-add-icon" : "add-block"}
                    onClick={onClickAddBlock}
                  >
                    <AntdTooltip title="ADD A BLOCK" className="block-icon">
                      <PlusIcon />
                    </AntdTooltip>
                  </div>
                  <div className="block-label-container">
                    <div className="block-section">
                      <span className="blocks">Block Label: </span>
                    </div>
                    <div id="block-name-div">
                      <input
                        type="text"
                        className="block-name"
                        value={blockLetter}
                        disabled={!isDisable}
                        onChange={changeBlockName}
                        maxLength="4"
                      />
                    </div>

                    <div
                      className="save-block-name-div"
                      onClick={onClickSaveBlock}
                    >
                      <input
                        className="save-block-name-text"
                        type="button"
                        value="SAVE"
                        disabled={!updateBlockPerm}
                      />
                    </div>
                  </div>
                  <div className="addliveshotbtnblock">
                    <AntdTooltip title="ADD LIVESHOT TO BLOCK">
                      <Button
                        id="createLiveShot"
                        onClick={onAddLiveShot}
                        disabled={!isDisable}
                      >
                        <Plus_icon
                          alt="Add Liveshot Btn"
                          className="add-plus-icon"
                        />
                      </Button>
                    </AntdTooltip>
                  </div>
                </div>
                {blockTakeAndGrab}
              </div>
              <Droppable droppableId={block.blockId.toString()} type="liveshot">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="drop-container"
                  >
                    {normalLiveShotLists}
                    {isLastBlock ? normalLiveShotObjs : null}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          )}
        </Draggable>
      </>
    ) : (
      <Draggable draggableId={block.blockId.toString()} index={index}>
        {(provided) => (
          <div
            className="mainblockwrapper"
            {...provided.draggableProps}
            ref={provided.innerRef}
          ></div>
        )}
      </Draggable>
    );

  return lsContainer;
};

const mapStateToProps = (state) => {
  return {
    normalLiveShotTypes: state.liveShotContainer.normalLiveShotTypes,
    loading: state.liveShotContainer.loading,
    token: state.auth.accessToken,
    currentUser: state.auth.currentUser,
    liveShotSignalSelected: state.signalsInfo.liveShotSignalSelected,
    currentCanvas: state.canvasInfo.currentCanvas,
    routingCapabilityType: state.canvasInfo.routingCapabilityType,
    controlRoomList: state.canvasInfo.controlRoomList,
    isArmRouting: state.canvasInfo.armRouting,
    inBoundCRPath: state.signalsInfo.inBoundCRPath,
    outBoundCRPath: state.signalsInfo.outBoundCRPath,
    ifbCRPath: state.signalsInfo.ifbCRPath,
    plCRPath: state.signalsInfo.plCRPath,
    phoCRPath: state.signalsInfo.phoCRPath,
    shortCut: state.canvasInfo.shortCut,
    lsAddedFromSearch: state.liveShotInfo.lsAddedFromSearch,
    newLiveShotId: state.liveShotInfo.newLiveShotId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateBlock: (updatedBy, block_id, payload, canvasId, token) =>
      dispatch(
        actions.updateBlock(updatedBy, block_id, payload, canvasId, token)
      ),
    onResetLSCreation: () => dispatch(actions.resetLSCreation()),
    onStartLSCreation: (blockId) => dispatch(actions.startLSCreation(blockId)),
    onTakeAllCanvasBlock: (
      token,
      userId,
      userFullName,
      normalLiveshot,
      liveShotSignalSelected,
      roomId,
      currentRoom,
      liveshotSignalDuplicates,
      showMessage
    ) =>
      dispatch(
        actions.takeAllCanvasBlock(
          token,
          userId,
          userFullName,
          normalLiveshot,
          liveShotSignalSelected,
          roomId,
          currentRoom,
          liveshotSignalDuplicates,
          showMessage
        )
      ),
    onGrabAllCanvasBlock: (
      token,
      userId,
      userFullName,
      liveshots,
      liveShotSignalSelected,
      roomId,
      showMessage,
      currentRoom,
      currentCanvas,
      outBoundFieldsNormal,
      commsFieldsNormal
    ) =>
      dispatch(
        actions.grabAllCanvasBlock(
          token,
          userId,
          userFullName,
          liveshots,
          liveShotSignalSelected,
          roomId,
          showMessage,
          currentRoom,
          currentCanvas,
          outBoundFieldsNormal,
          commsFieldsNormal
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockContainer);
