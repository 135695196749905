import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import "./DuplicateLiveshot.css";
import { ReactComponent as CopyIcon } from "../../../assets/icons/liveshot-copy.svg";
import { AntdTooltip } from "../../shared/UI/Tooltip/AntdTooltip";

const DuplicateLiveshot = (props) => {
  const {
    liveshotInfo,
    currentCanvasId,
    token,
    currentUser,
    onCreateDuplicateLiveShot,
  } = props;

  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }

  const dulicateLiveShot = () => {
    const copyData = {
      abstractLiveshot: {},
    };
    copyData.blockId = 0;
    copyData.liveshotId = liveshotInfo.liveshotId;
    copyData.hitTime = null;
    copyData.needsMediaTrafficReview = false;
    copyData.readyForAir = false;
    copyData.record = false;
    copyData.sequenceNo = 0;
    copyData.canceled = false;
    copyData.canceledBy = 0;
    copyData.canceledOn = null;
    copyData.createdBy = userId;
    copyData.updatedBy = userId;
    copyData.softDelete = false;
    copyData.abstractLiveshot.abstractLiveshotId =
      liveshotInfo.abstractLiveshot.abstractLiveshotId;
    onCreateDuplicateLiveShot(currentCanvasId, copyData, token);
  };
  return (
    <div className="duplicateLS">
      <AntdTooltip
        title="DUPLICATE"
        placement="right"
        align={{ offset: [10, 0] }}
      >
        <CopyIcon onClick={dulicateLiveShot} />
      </AntdTooltip>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    currentUser: state.auth.currentUser,
    currentCanvasId: state.canvasInfo.currentCanvasId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateDuplicateLiveShot: (currentCanvasValue, formData, token) =>
      dispatch(
        actions.createDuplicateLiveShot(currentCanvasValue, formData, token)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DuplicateLiveshot);
