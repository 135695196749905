import React from "react";
import "./Label.css";

const Label = ({
  id = "",
  disableHover = false,
  labelText,
  isActive,
  icon: IconComponent,
}) => {
  let className = "label-container";
  if (isActive) {
    className += " active";
  }
  if (disableHover) {
    className += " no-hover";
  }

  return (
    <label htmlFor={id} className={className}>
      <div className="label-icon">
        <IconComponent />
      </div>
      <span>{labelText}</span>
    </label>
  );
};

export default Label;
