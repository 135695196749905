import React from "react";

const SignalSuggestions = (props) => {
  const { signalLists, idx, signalName, selectSignal } = props;
  return (
    <div className="list-container">
      <ul className="signal-suggestion-list">
        {signalLists
          .sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          })
          .map((list, index) => {
            return (
              <li
                key={index}
                onClick={() => selectSignal(list, idx, signalName)}
              >
                {list}{" "}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default SignalSuggestions;
