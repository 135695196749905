import * as actionTypes from "../../store/actions/actionTypes";

const initialState = {
  searchList: [],
  showSearch: false,
  cityList: "",
  liveshotNamesList: "",
  showLoader: false,
  showSearchErrorModal: false,
};

const closeSearchModalResult = (state, action) => {
  return Object.assign({}, state, { showSearch: action.showSearch });
};
const openSearchModalResult = (state, action) => {
  return Object.assign({}, state, { showSearch: action.showSearch });
};

const searchLiveshotListSuccess = (state, action) => {
  return Object.assign({}, state, {
    searchList: action.searchList,
  });
};

const cityStateSuccess = (state, action) => {
  return Object.assign({}, state, {
    cityList: action.cityList,
  });
};

const liveshotNamesSuccess = (state, action) => {
  return Object.assign({}, state, {
    liveshotNamesList: action.liveshotNamesList,
  });
};

const showLoader = (state, action) => {
  return Object.assign({}, state, { showLoader: action.showLoader });
};

const closeLoader = (state, action) => {
  return Object.assign({}, state, { showLoader: action.showLoader });
};

const showModal = (state, action) => {
  return Object.assign({}, state, {
    errorMsg: action.errorMsg,
    showSearchErrorModal: action.showModal,
  });
};

const hideModal = (state, action) => {
  return Object.assign({}, state, {
    showSearchErrorModal: action.showModal,
    errorMsg: action.errorMsg,
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_LIVESHOT_SUCCESS:
      return searchLiveshotListSuccess(state, action);
    case actionTypes.CLOSE_SEARCH_MODAL:
      return closeSearchModalResult(state, action);
    case actionTypes.OPEN_SEARCH_MODAL:
      return openSearchModalResult(state, action);
    case actionTypes.CITY_STATE_SUCCESS:
      return cityStateSuccess(state, action);
    case actionTypes.LIVE_SHOT_NAMES_SUCCESS:
      return liveshotNamesSuccess(state, action);
    case actionTypes.CLOSE_LOADER:
      return closeLoader(state, action);
    case actionTypes.SHOW_LOADER:
      return showLoader(state, action);
    case actionTypes.SHOW_SEARCH_ERROR_MODAL:
      return showModal(state, action);
    case actionTypes.HIDE_SEARCH_ERROR_MODAL:
      return hideModal(state, action);
    default:
      return state;
  }
};

export default reducer;
