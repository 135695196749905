import React, { useState, useRef, useEffect, memo } from "react";
import Select from "react-select";

// React-select is interactive element inside Draggable. So it prevent onClick event.so, Implemented temporary solution to overcome this issue.Ref issue link: https://github.com/JedWatson/react-select/issues/5176.

//In Future, we can refactor to antd select(that works fine inside Draggable)

const SelectDropdown = (props) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };
  const handleClick = (e) => {
    setOpen(!open);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <span
      ref={ref}
      onClick={!props.isDisabled ? handleClick : undefined}
      style={{ width: "12%" }}
    >
      <Select
        id="status-dropdown"
        title="Select to Liveshot status"
        menuIsOpen={open}
        {...props}
        styles={{
          container: (provided) => ({
            ...provided,
            width: "100%",
          }),
          ...(open && {
            dropdownIndicator: (styles) => ({
              ...styles,
              rotate: "180deg",
            }),
          }),
        }}
      ></Select>
    </span>
  );
};

export default memo(SelectDropdown);
