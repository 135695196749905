import * as actionTypes from "../actions/actionTypes";

const initialState = {
  venueAddressInfo: [],
  venueAddressDetails: [],
  venueAddressDetailsUpload: [],
  showModal: false,
  signalMsg: "",
};

const venueAddressSuccess = (state, action) => {
  return Object.assign({}, state, {
    venueAddressInfo: action.venueAddressInfo,
  });
};
const venueAddressDetailsSuccess = (state, action) => {
  return Object.assign({}, state, {
    venueAddressDetails: action.venueAddressDetails,
  });
};
const uploadvenueAddressDetailsSuccess = (state, action) => {
  return Object.assign({}, state, {
    uploadvenueAddress: action.uploadvenueAddress,
  });
};

const showModal = (state, action) => {
  return Object.assign({}, state, {
    signalMsg: action.signalMsg,
    showModal: action.showModal,
  });
};

const hideModal = (state, action) => {
  return Object.assign({}, state, {
    showModal: action.showModal,
    signalMsg: action.signalMsg,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_VENUE_DATA:
      return venueAddressSuccess(state, action);
    case actionTypes.FETCH_VENUE_DETAILS:
      return venueAddressDetailsSuccess(state, action);
    case actionTypes.UPLOAD_VENUE_DETAILS:
      return uploadvenueAddressDetailsSuccess(state, action);
    case actionTypes.SHOW_MODAL:
      return showModal(state, action);
    case actionTypes.HIDE_MODAL:
      return hideModal(state, action);
    default:
      return state;
  }
};

export default reducer;
