import * as actionTypes from "../../store/actions/actionTypes";
import { addAvailableSignal } from "../actions/signalsInfo";

const initialState = {
  inBoundCRPath: [],
  inBoundGlobalPath: [],
  inBoundExtendGlobalPath: [],
  liveShotSignalSelected: [],
  selectedCanvas: null,
  outBoundCRPath: [],
  outBoundGlobalPath: [],
  pinNumber: null,
  signals: null,
  ifbCRPath: [],
  plCRPath: [],
  ifbGlobalPath: [],
  plGlobalPath: [],
  phoGlobalPath: [],
  phoCRPath: [],
  phoTxCRPath: [],
  phoTxGlobalPath: [],
  grabbedSignals: [],
  globalPins: [],
  showSignalModal: false,
  signalMsg: {},
  pinMsg: {},
  showModal: false,
  showErrorModal: false,
  showBorderMsg: false,
  redBorder: [],
  availInBoundSharedSignals: [],
  availOutBoundSharedSignals: [],
  availIFBSharedSignals: [],
  availPLSharedSignals: [],
  availPHOSharedSignals: [],
  sharedInBoundGlobalPath: [],
  sharedOutBoundGlobalPath: [],
  sharedIFBGlobalPath: [],
  sharedPHOTXGlobalPath: [],
  sharedPLGlobalPath: [],
  sharedPHOGlobalPath: [],
  showCrPathMsg: false,
  pinError: null,
  sipState: {},
  sipList: [],
};

const resetSignalsInfoSuccess = (state, action) => {
  return Object.assign({}, state, {
    liveShotSignalSelected: action.liveShotSignalSelected,
    selectedCanvas: action.selectedCanvas,
    grabbedSignals: action.grabbedSignals,
    globalPins: action.globalPins,
    showSignalModal: action.showSignalModal,
    signalMsg: action.signalMsg,
    redBorder: action.redBorder,
    //inBoundCRPath: action.inBoundCRPath,
    //inBoundGlobalPath: action.inBoundGlobalPath,
    //outBoundCRPath: action.outBoundCRPath,
    //outBoundGlobalPath: action.outBoundGlobalPath,
    //ifbCRPath: action.ifbCRPath,
    //ifbGlobalPath: action.ifbGlobalPath,
    //plCRPath: action.plCRPath,
    //plGlobalPath: action.plGlobalPath,
    //phoGlobalPath: action.phoGlobalPath,
    //phoCRPath: action.phoCRPath,
  });
};

const addRedBorder = (state, action) => {
  return Object.assign({}, state, {
    redBorder: state.redBorder.concat(action.signal),
  });
};

const removeRedBorder = (state, action) => {
  return Object.assign({}, state, {
    redBorder: state.redBorder.filter(
      (signal) => signal.liveshotSignalId !== action.signalId
    ),
  });
};

const removeInBoundAvailSharedSignalSuccess = (state, action) => {
  return Object.assign({}, state, {
    availInBoundSharedSignals: [],
  });
};

const removeOutBoundAvailSharedSignalSuccess = (state, action) => {
  return Object.assign({}, state, {
    availOutBoundSharedSignals: [],
  });
};

const removeIFBAvailSharedSignalSuccess = (state, action) => {
  return Object.assign({}, state, {
    availIFBSharedSignals: [],
  });
};

const removePLAvailSharedSignalSuccess = (state, action) => {
  return Object.assign({}, state, {
    availPLSharedSignals: [],
  });
};

const removePHOAvailSharedSignalSuccess = (state, action) => {
  return Object.assign({}, state, {
    availPHOSharedSignals: [],
  });
};

const addPinNumberSuccess = (state, action) => {
  return Object.assign({}, state, {
    pinNumber: action,
  });
};

const addAvailableSignalSuccess = (state, action) => {
  return Object.assign({}, state, {
    signal: action,
  });
};

const addPHOAvailSharedSignalSuccess = (state, action) => {
  return Object.assign({}, state, {
    availPHOSharedSignals: action.signals,
  });
};

const addPLAvailSharedSignalSuccess = (state, action) => {
  return Object.assign({}, state, {
    availPLSharedSignals: action.signals,
  });
};

const addIFBAvailSharedSignalSuccess = (state, action) => {
  return Object.assign({}, state, {
    availIFBSharedSignals: action.signals,
  });
};

const addInBoundAvailSharedSignalSuccess = (state, action) => {
  return Object.assign({}, state, {
    availInBoundSharedSignals: action.signals,
  });
};

const addOutBoundAvailSharedSignalSuccess = (state, action) => {
  return Object.assign({}, state, {
    availOutBoundSharedSignals: action.signals,
  });
};

const addInBoundCRPath = (state, action) => {
  return Object.assign({}, state, {
    inBoundCRPath: action.crPath,
  });
};

const addPHOTXCRPath = (state, action) => {
  return Object.assign({}, state, {
    phoTxCRPath: action.crPath,
  });
};

const addPHOTXGlobalPath = (state, action) => {
  return Object.assign({}, state, {
    phoTxGlobalPath: action.globalPath,
  });
};

const addInBoundGlobalPath = (state, action) => {
  return Object.assign({}, state, {
    inBoundGlobalPath: action.globalPath,
  });
};

const addInBoundExtendGlobalPath = (state, action) => {
  return Object.assign({}, state, {
    inBoundExtendGlobalPath: action.globalPath,
  });
};

const addSharedInBoundGlobalPath = (state, action) => {
  return Object.assign({}, state, {
    sharedInBoundGlobalPath: action.globalPath,
  });
};

const addSharedOutBoundGlobalPath = (state, action) => {
  return Object.assign({}, state, {
    sharedOutBoundGlobalPath: action.globalPath,
  });
};

const addSharedIFBGlobalPath = (state, action) => {
  return Object.assign({}, state, {
    sharedIFBGlobalPath: action.globalPath,
  });
};

const addSharedPHOTXGlobalPath = (state, action) => {
  return Object.assign({}, state, {
    sharedPHOTXGlobalPath: action.globalPath,
  });
};

const addSharedPLGlobalPath = (state, action) => {
  return Object.assign({}, state, {
    sharedPLGlobalPath: action.globalPath,
  });
};

const addSharedPHOGlobalPath = (state, action) => {
  return Object.assign({}, state, {
    sharedPHOGlobalPath: action.globalPath,
  });
};

const addOutBoundCRPath = (state, action) => {
  return Object.assign({}, state, {
    outBoundCRPath: action.crPath,
  });
};

const addOutBoundGlobalPath = (state, action) => {
  return Object.assign({}, state, {
    outBoundGlobalPath: action.globalPath,
  });
};

const addIFBCRPathSuccess = (state, action) => {
  return Object.assign({}, state, {
    ifbCRPath: action.crPath,
  });
};

const addPLCRPathSuccess = (state, action) => {
  return Object.assign({}, state, {
    plCRPath: action.crPath,
  });
};

const addPHOCRPathSuccess = (state, action) => {
  return Object.assign({}, state, {
    phoCRPath: action.crPath,
  });
};

const addIFBGlobalPathSuccess = (state, action) => {
  return Object.assign({}, state, {
    ifbGlobalPath: action.globalPath,
  });
};

const addPLGlobalPathSuccess = (state, action) => {
  return Object.assign({}, state, {
    plGlobalPath: action.globalPath,
  });
};

const addPHOGlobalPathSuccess = (state, action) => {
  return Object.assign({}, state, {
    phoGlobalPath: action.globalPath,
  });
};

const setSelectedSignals = (state, action) => {
  if (
    state.selectedCanvas !== null &&
    state.selectedCanvas !== action.canvasId
  ) {
    state.liveShotSignalSelected = [];
  }
  return Object.assign({}, state, {
    liveShotSignalSelected: state.liveShotSignalSelected.concat(
      action.liveShotSignals
    ),
    selectedCanvas: action.canvasId,
  });
};

const addLiveShotSignal = (state, action) => {
  return Object.assign({}, state, {
    liveShotSignalSelected: state.liveShotSignalSelected.concat(action.signal),
  });
};

const removeLiveShotSignal = (state, action) => {
  return Object.assign({}, state, {
    liveShotSignalSelected: state.liveShotSignalSelected.filter(
      (signal) => signal.liveshotSignalId !== action.signal.liveshotSignalId
    ),
  });
};

const removeDeletedLiveshotSignal = (state, action) => {
  const liveshotSignal = [];
  state.liveShotSignalSelected.map((signal) => {
    if (action.signal.includes(signal.liveshotId)) {
      liveshotSignal.push(signal);
    }
  });
  return Object.assign({}, state, {
    liveShotSignalSelected: liveshotSignal,
  });
};

const updateLiveShotSignal = (state, action) => {
  return Object.assign({}, state, {
    liveShotSignalSelected: state.liveShotSignalSelected.map((signal) =>
      signal.liveshotSignalId === action.signal.liveshotSignalId
        ? action.signal
        : signal
    ),
  });
};

const addGrabbedSignalSuccess = (state, action) => {
  return Object.assign({}, state, {
    grabbedSignals: state.grabbedSignals.concat({
      controlledBy: action.res.controlledBy,
      controlledByFullName: action.res.controlledByFullName,
      controlType: action.res.controlType,
      controltime: action.res.controltime,
      controlRoomCode: action.res.controlRoomCode,
      liveshotSignalId: action.res.liveshotSignal.liveshotSignalId,
    }),
  });
};

const addGlobalPinSuccess = (state, action) => {
  return Object.assign({}, state, {
    globalPins: state.globalPins.concat({
      accessPin: action.res[0].accessPin,
      namesetName: action.res[0].namesetName,
      participants: action.res[0].participants,
    }),
  });
};

const showSignalModalSuccess = (state, action) => {
  return Object.assign({}, state, {
    showSignalModal: action.showSignalModal,
    signalMsg: action.signalMsg,
    showCrPathMsg: action.showCrPathMsg,
  });
};

const hideSignalModalSuccess = (state, action) => {
  return Object.assign({}, state, {
    showSignalModal: action.showSignalModal,
    signalMsg: action.signalMsg,
  });
};

const showErrorModal = (state, action) => {
  return Object.assign({}, state, {
    signalErrorMsg: action.signalMsg,
    showModal: action.showModal,
  });
};

const showPinErrorModal = (state, action) => {
  return Object.assign({}, state, {
    pinErrorMsg: action.pinMsg,
    showModal: action.showModal,
  });
};

const showSignalErrorModal = (state, action) => {
  return Object.assign({}, state, {
    signalMsg: action.signalMsg,
    showErrorModal: action.showErrorModal,
    showBorderMsg: action.showBorderMsg,
  });
};

const hideSignalErrorModal = (state, action) => {
  return Object.assign({}, state, {
    showErrorModal: action.showModal,
    signalMsg: action.signalMsg,
  });
};

const hideErrorModal = (state, action) => {
  return Object.assign({}, state, {
    showModal: action.showModal,
    signalErrorMsg: action.signalMsg,
  });
};

const setPinErrorSuccess = (state, action) => {
  return Object.assign({}, state, {
    pinError: action.error,
  });
};

const resetPinErrorSuccess = (state, action) => {
  return Object.assign({}, state, {
    pinError: null,
  });
};

const addSipState = (state, action) => {
  return Object.assign({}, state, {
    sipState: { ...state.sipState, ...action.sipState },
  });
};

const addSipList = (state, action) => {
  return Object.assign({}, state, {
    sipList: action.sipList,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CRPATHINBOUND:
      return addInBoundCRPath(state, action);
    case actionTypes.AVAILABLE_SIGNAL:
      return addAvailableSignalSuccess(state, action);
    case actionTypes.PHOTX_CR_PATH:
      return addPHOTXCRPath(state, action);
    case actionTypes.PHOTX_GLOBAL_PATH:
      return addPHOTXGlobalPath(state, action);
    case actionTypes.GLOBALPATHINBOUND:
      return addInBoundGlobalPath(state, action);
    case actionTypes.EXTENDGLOBALPATHINBOUND:
      return addInBoundExtendGlobalPath(state, action);
    case actionTypes.SHAREDGLOBALPATHINBOUND:
      return addSharedInBoundGlobalPath(state, action);
    case actionTypes.SHAREDGLOBALPATHOUTBOUND:
      return addSharedOutBoundGlobalPath(state, action);
    case actionTypes.SHAREDGLOBALPATHIFB:
      return addSharedIFBGlobalPath(state, action);
    case actionTypes.SHAREDGLOBALPATHPHOTX:
      return addSharedPHOTXGlobalPath(state, action);
    case actionTypes.SHAREDGLOBALPATHPL:
      return addSharedPLGlobalPath(state, action);
    case actionTypes.SHAREDGLOBALPATHPHO:
      return addSharedPHOGlobalPath(state, action);
    case actionTypes.FETCH_PIN_NUMBER:
      return addPinNumberSuccess(state, action);
    case actionTypes.FETCH_SIP_STATE:
      return addSipState(state, action);
    case actionTypes.FETCHSIGNALS:
      return setSelectedSignals(state, action);
    case actionTypes.ADD_SIGNAL:
      return addLiveShotSignal(state, action);
    case actionTypes.REMOVE_SIGNAL:
      return removeLiveShotSignal(state, action);
    case actionTypes.CRPATHOUTBOUND:
      return addOutBoundCRPath(state, action);
    case actionTypes.GLOBALPATHOUTBOUND:
      return addOutBoundGlobalPath(state, action);
    case actionTypes.IFB_CR_PATH:
      return addIFBCRPathSuccess(state, action);
    case actionTypes.PL_CR_PATH:
      return addPLCRPathSuccess(state, action);
    case actionTypes.PHO_CR_PATH:
      return addPHOCRPathSuccess(state, action);
    case actionTypes.IFB_GLOBAL_PATH:
      return addIFBGlobalPathSuccess(state, action);
    case actionTypes.PL_GLOBAL_PATH:
      return addPLGlobalPathSuccess(state, action);
    case actionTypes.RESET_SIGNALS_INFO:
      return resetSignalsInfoSuccess(state, action);
    case actionTypes.PHO_GLOBAL_PATH:
      return addPHOGlobalPathSuccess(state, action);
    case actionTypes.ADD_GRABBED_SIGNAL:
      return addGrabbedSignalSuccess(state, action);
    case actionTypes.ADD_GLOBAL_PINS:
      return addGlobalPinSuccess(state, action);
    case actionTypes.SHOW_SIGNAL_MODAL:
      return showSignalModalSuccess(state, action);
    case actionTypes.HIDE_SIGNAL_MODAL:
      return hideSignalModalSuccess(state, action);
    case actionTypes.SHOW_SIGNAL_ERROR_MODAL:
      return showErrorModal(state, action);
    case actionTypes.HIDE_SIGNAL_ERROR_MODAL:
      return hideErrorModal(state, action);
    case actionTypes.SHOW_SIGNAL_ERROR_VALUES_MODAL:
      return showSignalErrorModal(state, action);
    case actionTypes.HIDE_SIGNAL_ERROR_VALUE_MODAL:
      return hideSignalErrorModal(state, action);
    case actionTypes.ADDREDBORDER:
      return addRedBorder(state, action);
    case actionTypes.REMOVEREDBORDER:
      return removeRedBorder(state, action);
    case actionTypes.ADDINBOUNDAVAILSHAREDSIGNALS:
      return addInBoundAvailSharedSignalSuccess(state, action);
    case actionTypes.ADDOUTBOUNDAVAILSHAREDSIGNALS:
      return addOutBoundAvailSharedSignalSuccess(state, action);
    case actionTypes.ADDIFBAVAILSHAREDSIGNALS:
      return addIFBAvailSharedSignalSuccess(state, action);
    case actionTypes.ADDPLAVAILSHAREDSIGNALS:
      return addPLAvailSharedSignalSuccess(state, action);
    case actionTypes.ADDPHOAVAILSHAREDSIGNALS:
      return addPHOAvailSharedSignalSuccess(state, action);
    case actionTypes.REMOVEPHOAVAILSHAREDSIGNALS:
      return removePHOAvailSharedSignalSuccess(state, action);
    case actionTypes.REMOVEIFBAVAILSHAREDSIGNALS:
      return removeIFBAvailSharedSignalSuccess(state, action);
    case actionTypes.REMOVEPLAVAILSHAREDSIGNALS:
      return removePLAvailSharedSignalSuccess(state, action);
    case actionTypes.REMOVEINBOUNDAVAILSHAREDSIGNALS:
      return removeInBoundAvailSharedSignalSuccess(state, action);
    case actionTypes.REMOVEOUTBOUNDAVAILSHAREDSIGNALS:
      return removeOutBoundAvailSharedSignalSuccess(state, action);
    case actionTypes.SETPINERROR:
      return setPinErrorSuccess(state, action);
    case actionTypes.RESETPINERROR:
      return resetPinErrorSuccess(state, action);
    case actionTypes.ADD_SIP_LIST:
      return addSipList(state, action);
    case actionTypes.UPDATE_LIVESHOT_SIGNAL:
      return updateLiveShotSignal(state, action);
    case actionTypes.REMOVE_DELETED_LIVESHOT_SIGNAL:
      return removeDeletedLiveshotSignal(state, action);
    default:
      return state;
  }
};

export default reducer;
