import * as actionTypes from "../actions/actionTypes";

const initialState = {
  roleList: [],
  currentUserRole: "",
  userFacilityId: "",
  userList: [],
  refreshModal: false,
  userRole: "",
};

const fetchRoleListSuccess = (state, action) => {
  return Object.assign({}, state, {
    roleList: action.roleList,
  });
};

const userListSuccess = (state, action) => {
  return Object.assign({}, state, {
    userList: action.userList,
  });
};

const setCurrentUserRole = (state, action) => {
  return Object.assign({}, state, {
    currentUserRole: action.currentUserRole,
  });
};

const setUserRole = (state, action) => {
  return Object.assign({}, state, {
    userRole: action.userRole,
  });
};

const setCurrentUserFacility = (state, action) => {
  return Object.assign({}, state, {
    userFacilityId: action.userFacilityId,
  });
};

const setPermission = (state, action) => {
  return Object.assign({}, state, {
    currentUserRole: Object.assign({}, state.currentUserRole, {
      permission: action.permission,
    }),
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ROLE_LIST:
      return fetchRoleListSuccess(state, action);
    case actionTypes.SET_CURRENT_USER_ROLE:
      return setCurrentUserRole(state, action);
    case actionTypes.SET_USER_PERMISSION:
      return setPermission(state, action);
    case actionTypes.USER_LIST:
      return userListSuccess(state, action);
    case actionTypes.SET_CURRENT_USER_FACILITY:
      return setCurrentUserFacility(state, action);
    case actionTypes.SET_USER_ROLE:
      return setUserRole(state, action);
    default:
      return state;
  }
};

export default reducer;
