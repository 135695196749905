import axios from "../../axios";
import * as actionTypes from "../actions/actionTypes";
import * as Constants from "../../components/shared/Constants";
import * as actions from "../../store/actions/index";

export const saveStudioTruckSuccess = (resdata) => {
  return {
    type: actionTypes.STUDIOTRUCKSAVE_OBJECT_SUCCESS,
    successMsg: resdata,
    showModal: false,
  };
};

export const resetSuccessMsg = () => {
  return {
    type: actionTypes.RESET_STUDIOTRUCK_SUCCESS_MSG,
    successMsg: {},
    showModal: false,
  };
};

export const closeStudioTruckModalResult = () => {
  return {
    type: actionTypes.CLOSE_SHOW_MODAL,
    showModal: false,
  };
};
export const closeStudioTruckModal = (token) => {
  return (dispatch) => {
    dispatch(closeStudioTruckModalResult());
    dispatch(actions.fetchStudioTruckList(token));
  };
};
export const saveStudioTruck = (studioTruckObj, token, lsId) => {
  return (dispatch) => {
    axios
      .post("liveshot-api/studioTruck/", studioTruckObj, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        res.data.liveShotId = lsId;
        dispatch(
          saveStudioTruckSuccess({
            resultData: Constants.STUDIOTRUCK_SAVE_SUCCESS_MSG,
            statusCode: res.status,
            studioData: res.data,
          })
        );
        dispatch(actions.getvenueAddressInfo(token));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        dispatch(
          saveStudioTruckSuccess({
            resultData: Constants.STUDIOTRUCK_SAVE_FAILURE_MSG,
            statusCode: err.status,
          })
        );
      });
  };
};

export const updateStudioTruck = (studioTruckObj, token, lsId) => {
  return (dispatch) => {
    axios
      .put("liveshot-api/studioTruck/", studioTruckObj, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        if (res.status === 200) {
          res.data.liveShotId = lsId;
          dispatch(
            saveStudioTruckSuccess({
              resultData: Constants.STUDIOTRUCK_UPDATE_SUCCESS_MSG,
              statusCode: res.status,
              studioData: res.data,
            })
          );
          dispatch(actions.getvenueAddressInfo(token));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        dispatch(
          saveStudioTruckSuccess({
            resultData: Constants.STUDIOTRUCK_UPDATE_FAILURE_MSG,
            statusCode: err.status,
          })
        );
      });
  };
};

export const patchStudioTruck = (studioTruckObj, token, stId) => {
  return (dispatch) => {
    axios
      .patch(`liveshot-api/studioTruck/${stId}`, studioTruckObj, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(actions.getvenueAddressInfo(token));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const deleteStudioTruck = (token, Id) => {
  return (dispatch) => {
    axios
      .delete(`liveshot-api/studioTruck/${Id}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        console.log("StudioTruck deleted");
        dispatch(actions.getvenueAddressInfo(token));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        console.log("StudioTruck delete failed");
      });
  };
};
