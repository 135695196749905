import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import * as actions from "../../../store/actions/index";
import NavigationHeader from "../../../components/navigation/NavigationHeader/NavigationHeader";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/DeleteIcon.svg";
import { ReactComponent as Restore } from "../../../assets/icons/Restore.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/Close-darkbg.svg";
import "./SourceDestinationStatus.css";
import Backdrop from "../../../components/shared/UI/Backdrop/Backdrop";
import "../../../components/shared/CommonCss/CommonCss.css";
import { TabComponent } from "../../../components/shared/UI/TabComponent/TabComponent";
import { Source_Destination_Tabs_Array } from "../../../constants/TabArray";
import { source_destination_page_constants } from "../../../constants/PageConstants";
import {
  SourceDestination_DestinationsColumnDefs,
  SourceDestination_RoomsColumnDefs,
  SourceDestination_SignalTypesColumnDefs,
  SourceDestination_SourceColumnDefs,
  SourceDestination_UsersColumnDefs,
} from "../../../constants/ColumnHeaderArray";
import { ConfirmationModal } from "../../../components/shared/UI/ConfirmationModal/ConfirmationModalPopup";
import NotificationBarComponent from "../../../components/shared/UI/NotificationBarComponent/NotificationBarComponent";
import { useUser } from "../../../hooks/useUser";
import RoleDropdown from "../../../components/shared/UI/Select/RoleDropdown";
import Modal from "../../../components/shared/UI/common/Modal.js";
import Loader from "../../../components/shared/UI/Loader/Loader.js";

const SourceDestinationStatus = (props) => {
  const gridRef = useRef(null);
  const { readRouterSignalDetailsPermission } = useUser();
  const {
    token,
    currentUser,
    currentUserRole,
    fetchRouterSources,
    routerSources,
    fetchRouterDestinations,
    routerDestinations,
    onDownloadRouterDetails,
    onUploadSourceDestination,
    onImportSourceDestination,
    loading,
    signalMsg,
    showModal,
    onHideModal,
    fetchSignalTypes,
    signalTypeDetails,
    fetchRoomDetails,
    roomDetails,
    userList,
    onDeleteUser,
    roleList,
    onUpdateUserRole,
    onRestoreUser,
    onFetchUserList,
    onUploadNewUserInfo,
    onFetchRoleList,
  } = props;
  const [selectedTab, setSelectedTab] = useState("sources");
  const [importDrop, setImportDrop] = useState(true);
  const [addUserSso, setAddUserSso] = useState();
  const [addNewUser, setAddNewUser] = useState(false);
  const [userIcon, setUserIcon] = useState(false);
  const [ssoSubmitBtn, setSsoSubmitBtn] = useState(false);
  const [activeTab, setAcvtiveTab] = useState(
    Source_Destination_Tabs_Array[0].label
  );
  const [isDeleteIconClicked, setIsDeleteIconClicked] = useState(false);
  const [confirmationDeleteMessage, setConfirmationDeleteMessage] =
    useState(null);
  const [currentUserRowId, setCurrentUserRowId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorBar, setShowErrorBar] = useState(false);
  const [isSsoError, setIsSsoError] = useState(false);

  let userId =
    process.env.REACT_APP_AUTH_ENABLED !== "true"
      ? process.env.REACT_APP_AUTH_OVERRIDE_ID
      : currentUser.sso;

  let roleRedirect = null;
  if (currentUserRole && ![1, 5].includes(currentUserRole.roleId)) {
    roleRedirect = <Redirect to="/" />;
  }

  useEffect(() => {
    if (readRouterSignalDetailsPermission) {
      setImportDrop(false);
    }
  }, [currentUserRole, importDrop]);

  useEffect(() => {
    if (roleList.length === 0) {
      onFetchRoleList(token);
    }
  }, [token, onFetchRoleList]);

  useEffect(() => {
    fetchRouterSources(token);
    fetchRouterDestinations(token);
    fetchSignalTypes(token);
    fetchRoomDetails(token);
    onFetchUserList(token);
  }, [
    token,
    fetchRouterSources,
    fetchRouterDestinations,
    fetchSignalTypes,
    fetchRoomDetails,
    onFetchUserList,
  ]);

  const addUser = () => {
    setAddNewUser(true);
    setSsoSubmitBtn(false);
    setShowErrorBar(false);
    setAddUserSso("");
  };

  const handleUserSso = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setAddUserSso(value);
    if (value.length === 9) {
      setSsoSubmitBtn(true);
    } else {
      setSsoSubmitBtn(false);
    }
  };

  const cancelUser = () => {
    setAddNewUser(false);
    setShowErrorBar(false);
  };

  const setUserRole = (roleId, data) => {
    if (roleId !== "") {
      onUpdateUserRole(token, data.sso, roleId);
    }
  };
  const handleDeleteClick = (data) => {
    setIsDeleteIconClicked(true);
    setConfirmationDeleteMessage(
      source_destination_page_constants.USERS_DELETE_CONFIRMATION_MESSAGE
    );
    setCurrentUserRowId(data);
  };
  const deleteRow = () => {
    onDeleteUser(token, currentUserRowId.sso);
    setCurrentUserRowId("");
    setIsDeleteIconClicked(false);
  };

  const cancelDelete = () => {
    setIsDeleteIconClicked(false);
  };

  const updateRow = (data) => {
    const userData = {};
    userData.sso = data.sso;
    userData.userName = data.userName;
    userData.userEmail = data.userName;
    if (data.softDelete) {
      userData.softDelete = false;
    }
    userData.latestLogin = data.latestLogin;
    userData.latestHeartbeat = data.latestHeartbeat;
    onRestoreUser(token, userData);
  };

  const getRoleList = (role) => {
    // Sorting role list based on roleId
    role.sort((a, b) => {
      return b.roleId - a.roleId;
    });
    return role.map((option) => ({
      value: option.roleId,
      key: option.roleId,
      label: option.roleName,
    }));
  };

  const roleDropdownRenderer = (params) => {
    return params.data.role ? (
      <RoleDropdown
        onChange={(roleId) => setUserRole(roleId, params.data)}
        value={params.data.role.roleId}
        options={getRoleList(params.roleList)}
        key={"role_" + params.data.role.roleId}
      />
    ) : null;
  };

  const deleteIconRenderer = (params) => {
    return params.data && params.data.softDelete ? (
      <Restore
        className="restore-row"
        onClick={() =>
          setTimeout(() => {
            updateRow(params.data);
          })
        }
      />
    ) : (
      <DeleteIcon
        className="trashIcon"
        onClick={() => {
          setTimeout(() => {
            handleDeleteClick(params.data);
          });
        }}
      />
    );
  };

  let columnDefs = SourceDestination_SourceColumnDefs;
  let rowData = routerSources;
  let defaultColDef = {
    flex: 1,
    sortable: true,
    filter: true,
    floatingFilter: true,
  };
  if (activeTab === source_destination_page_constants.DESTINATIONS_TAB) {
    columnDefs = SourceDestination_DestinationsColumnDefs;
    rowData = routerDestinations;
  } else if (activeTab === source_destination_page_constants.SIGNAL_TYPES_TAB) {
    columnDefs = SourceDestination_SignalTypesColumnDefs;
    rowData = signalTypeDetails;
  } else if (activeTab === source_destination_page_constants.ROOMS_TAB) {
    columnDefs = SourceDestination_RoomsColumnDefs;
    rowData = roomDetails;
  } else if (activeTab === source_destination_page_constants.USERS_TAB) {
    columnDefs = SourceDestination_UsersColumnDefs;
    rowData = userList;
  }

  const getColDef = () => {
    return columnDefs.map((column) => {
      if (column.headerName === "ROLE") {
        column.cellRendererParams = { roleList: roleList };
      }
      return column;
    });
  };

  let tableView = (
    <AgGridReact
      ref={gridRef}
      columnDefs={getColDef()}
      applyColumnDefOrder={true}
      components={{
        deleteIconRenderer: deleteIconRenderer,
        roleDropdownRenderer: roleDropdownRenderer,
      }}
      defaultColDef={defaultColDef}
      rowData={rowData}
      suppressColumnVirtualisation={process.env.REACT_TEST_ENV === "true"}
    ></AgGridReact>
  );
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleCsvUpload = (event) => {
    const fileUploaded = event.target.files[0];
    onUploadSourceDestination(token, fileUploaded, activeTab, userId);
    event.target.value = null;
  };
  const openErrorBar = (message) => {
    setErrorMessage(message);
    setShowErrorBar(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const closeErrorBar = () => {
    setShowErrorBar(false);
  };
  const handleNewUser = () => {
    if (addUserSso.length === 9) {
      const uploadUserSso = addUserSso;
      onUploadNewUserInfo(token, uploadUserSso);
      setIsSsoError(false);
      openErrorBar(source_destination_page_constants.SSO_SUCCESS_MESSAGE);
      setAddNewUser(false);
    } else {
      setAddNewUser(true);
      setIsSsoError(true);
      openErrorBar(
        source_destination_page_constants.INCORRECT_SSO_ERROR_MESSAGE
      );
    }
  };

  const performImports = () => {
    let result = window.confirm(
      "All Room and Type assignments will be lost for Magnum Signals, please save a CSV copy of the signal list before triggering a Router Import."
    );
    if (result) {
      onImportSourceDestination(token);
    }
  };

  let modal = null;
  if (showModal) {
    let content;
    let isSuccess = [200, 201].includes(signalMsg.statusCode);
    let title = isSuccess ? "Upload Successful" : "Error";
    if (isSuccess) {
      content = "File has been uploaded successfully.";
    } else {
      content = "Upload failed. status code : " + signalMsg.statusCode;
    }
    if (signalMsg.statusCode === 400) {
      content =
        "Upload failed. Please confirm the file/format are correct and try again.";
    }
    if (signalMsg.statusCode === 500) {
      content =
        "The upload for this document has failed. Please check the file type or document and try again later.";
    }
    if (!signalMsg.statusCode) {
      title = "504 Error";
      content =
        "The server is taking too long to respond.  Please try again later.";
    }

    modal = (
      <Modal
        showModal={showModal}
        onModalClose={onHideModal}
        title={title}
        content={content}
        type={isSuccess ? "success" : "error"}
      />
    );
  }

  let loader = <Loader loading={loading} />;

  const handleTabClick = (value) => {
    // resetting the column state to reset sorting and filter values of ag data grid
    if (gridRef.current) {
      gridRef.current.api.resetColumnState();
      gridRef.current.api.setFilterModel(null);
      gridRef.current.api.onFilterChanged();
    }
    setShowErrorBar(false);
    setAcvtiveTab(value);
  };
  const handlePlusIconClick = () => {
    if (activeTab === source_destination_page_constants.USERS_TAB) {
      addUser();
    }
  };
  return (
    <Fragment>
      {loader}
      <div className="sd-container container">
        {roleRedirect}
        {modal}
        {showErrorBar && (
          <NotificationBarComponent
            isError={isSsoError}
            errorMessage={errorMessage}
            closeErrorBar={closeErrorBar}
          ></NotificationBarComponent>
        )}
        <div
          className={`righticons_wrapper ${
            isDeleteIconClicked ? "opacity-10 nonClickable" : "opacity-100"
          }`}
        >
          <NavigationHeader
            routerSignalDetailsPerm={true}
            controlTrackerPerm={true}
            venueAddressperm={true}
          />
        </div>
        <ConfirmationModal
          isModalOpen={isDeleteIconClicked}
          cancelDelete={cancelDelete}
          deleteRow={deleteRow}
          message={confirmationDeleteMessage}
        ></ConfirmationModal>
        {addNewUser && (
          <>
            <Backdrop show={true} clicked={cancelUser} />
            <div className="popup-modal adduser-container">
              <div className="adduser row">
                <img
                  className="Addperson-image"
                  alt="Add person"
                  src={require("../../../assets/icons/Person.svg").default}
                />
                <h4 className="user-label"> Add New User </h4>
                <CloseIcon className="close-icon" onClick={cancelUser} />
              </div>
              <div className="mandatory-sso">
                <label className="mandatory_icon">*</label>
                <span className="sso-number">SSO Number :</span>
              </div>
              <input
                className="sso-input"
                placeholder="ENTER USER SSO NUMBER"
                value={addUserSso}
                onChange={handleUserSso}
              ></input>
              <div className="AddUser-modal-icons-row">
                <input
                  className="Adduser-cancel-icon"
                  type="button"
                  value="CANCEL"
                  onClick={cancelUser}
                />
                <input
                  className={ssoSubmitBtn ? "Submit-icon" : "User-Submit-icon"}
                  disabled={!ssoSubmitBtn}
                  type="button"
                  value="SUBMIT"
                  onClick={handleNewUser}
                />
              </div>
            </div>
          </>
        )}
        {/* {ssoIdMessage && (
          <div className="popup-modal ">
            <button
              alt="Close Logo"
              className="close-icon"
              type="button"
              onClick={cancelUser}
            >
              X
            </button>
            <div className="ssoIdMsg">{ssoIdMsg}</div>
          </div>
        )} */}
        <div
          className={`source-mgt-header-row ${
            isDeleteIconClicked ? "opacity-10 nonClickable" : "opacity-100"
          }`}
        >
          <div className="source-mgt-header-inner">
            <div className="source-header-label">
              <img
                src={require("../../../assets/icons/Source.svg").default}
                alt="source-icon"
              />
              <span>Source Destinations</span>
            </div>
            {currentUserRole.roleId !== 5 && (
              <button
                className="source-upload-btn"
                disabled={currentUserRole.roleId === 5}
                onClick={handleClick}
              >
                <img
                  src={require("../../../assets/icons/Upload.svg").default}
                  alt="upload icon"
                />
                <span>UPLOAD CSV</span>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  id="upload-button"
                  style={{ display: "none" }}
                  disabled={currentUserRole.roleId === 5}
                  onChange={handleCsvUpload}
                />
              </button>
            )}
            <button
              className="source-save-btn"
              onClick={() => onDownloadRouterDetails(token, activeTab)}
            >
              <img
                src={require("../../../assets/icons/Save.svg").default}
                alt="save-icon"
              />
              <span>SAVE CSV</span>
            </button>
            {/*<button
              className="source-import-btn Import"
              title="Import"
              onClick={performImports}
              disabled={importDrop}
            >
              <img
                src={require("../../../assets/icons/Download.svg")}
                alt="import-icon"
              />
              <span>IMPORT</span>
        </button>*/}
          </div>
        </div>
        <TabComponent
          tabs={Source_Destination_Tabs_Array}
          activeTab={activeTab}
          onTabClick={handleTabClick}
          onPlusIconClick={handlePlusIconClick}
          isModalOpen={addNewUser || isDeleteIconClicked}
        />
        <div
          className={`ag-theme-alpine-dark sd_table_container table_container ${
            isDeleteIconClicked ? "opacity-10 nonClickable" : "opacity-100"
          }`}
        >
          {tableView}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    currentUser: state.auth.currentUser,
    currentUserRole: state.user.currentUserRole,
    routerSources: state.routerSourceDestination.routerSources,
    routerDestinations: state.routerSourceDestination.routerDestinations,
    loading: state.routerSourceDestination.loading,
    signalMsg: state.routerSourceDestination.signalMsg,
    showModal: state.routerSourceDestination.showModal,
    signalTypeDetails: state.routerSourceDestination.signalTypeDetails,
    roomDetails: state.routerSourceDestination.roomDetails,
    userList: state.user.userList,
    roleList: state.user.roleList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRouterSources: (token) => dispatch(actions.getRouterSources(token)),
    fetchRouterDestinations: (token) =>
      dispatch(actions.getRouterDestinations(token)),
    onDownloadRouterDetails: (token, activeTab) => {
      dispatch(actions.downloadSourceDestinationDetails(token, activeTab));
    },
    onUploadSourceDestination: (token, file, activeTab, userId) => {
      dispatch(
        actions.uploadSourceDestinationDetails(token, file, activeTab, userId)
      );
    },
    onUploadNewUserInfo: (token, ssoNumber) => {
      if (ssoNumber !== undefined) {
        dispatch(actions.uploadNewUserInfo(token, ssoNumber));
      }
    },
    onImportSourceDestination: (token) =>
      dispatch(actions.importSourceDestination(token)),
    onHideModal: () => dispatch(actions.hideModal()),
    fetchSignalTypes: (token) => dispatch(actions.getSignalTypeDetails(token)),
    fetchPinModalInfo: (token) => dispatch(actions.getPinModalInfo(token)),
    fetchRoomDetails: (token) => dispatch(actions.getRoomDetails(token)),
    onDeleteUser: (token, sso) => dispatch(actions.deleteUser(token, sso)),
    onUpdateUserRole: (token, sso, roleId) =>
      dispatch(actions.updateUserRole(token, sso, roleId)),
    onRestoreUser: (token, userData) =>
      dispatch(actions.updateUser(token, userData)),
    onFetchUserList: (token) => dispatch(actions.fetchUserList(token)),
    onDownloadPinDetails: (token) =>
      dispatch(actions.downloadPinDetails(token)),
    onFetchRoleList: (token) => dispatch(actions.fetchRoleList(token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SourceDestinationStatus);
