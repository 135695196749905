import * as actionTypes from "../../store/actions/actionTypes";

const initialState = {
  successMsg: {},
  showModal: false,
};

const saveShowSuccess = (state, action) => {
  return Object.assign({}, state, {
    successMsg: action.successMsg,
    showModal: action.showModal,
  });
};

const deleteShowResult = (state, action) => {
  return Object.assign({}, state, {
    successMsg: action.successMsg,
    showModal: action.showModal,
  });
};

// const closeShowModalResult = (state, action) => {
//   return Object.assign({}, state, { showModal: false });
// };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOWSAVE_OBJECT_SUCCESS:
      return saveShowSuccess(state, action);
    // case actionTypes.CLOSE_SHOW_MODAL:
    //   return closeShowModalResult(state, action);
    case actionTypes.DELTE_SHOW_SUCCESS:
      return deleteShowResult(state, action);
    default:
      return state;
  }
};

export default reducer;
