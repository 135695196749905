import * as actionTypes from "./actionTypes";

export const closeNotificationModalResult = () => {
  return {
    type: actionTypes.CLOSE_NOTIFICATION_MODAL,
    showNotification: false,
  };
};

export const openNotificationModalResult = () => {
  return {
    type: actionTypes.OPEN_NOTIFICATION_MODAL,
    showNotification: true,
  };
};

export const openNotificationModal = () => {
  return (dispatch) => {
    dispatch(openNotificationModalResult());
  };
};

export const closeNotificationModal = () => {
  return (dispatch) => {
    dispatch(closeNotificationModalResult());
  };
};

export const showNotificationBar = (notificationBarDetails) => {
  return {
    type: actionTypes.SHOW_NOTIFICATION_BAR,
    notificationBarDetails: notificationBarDetails,
  };
};

export const showCommonPopupModal = (commonPopupModalDetails) => {
  return {
    type: actionTypes.SHOW_COMMON_POPUP_MODAL,
    commonPopupModalDetails: commonPopupModalDetails,
  };
};
