import * as actionTypes from "./actionTypes";
import * as actions from "../../store/actions/index";
import axios from "../../axios";
import { removeDuplicates } from "../../components/shared/utility";
import moment from "moment-timezone";

const OUTBOUND_SIGNALTYPE = "outbound";
const OUTBOUND_BUTTONTYPE = "grabOutbound";
const COMMS_SIGNALTYPE = "comms";
const COMMS_BUTTONTYPE = "grabComms";
const COMMS_SIGNALID_ARRAY = [5, 7, 8, 9];
const OUTBOUND_COMMS_SIGNALIS_ARRAY = [2, 5, 7, 8, 9];
const confirmDuplicateRouteUse = (
  field,
  obj,
  btnType,
  skipDuplicate = false
) => {
  const {
    token,
    userId,
    userFullName,
    liveshotId,
    crPath,
    roomId,
    room,
    idx = 0,
    signalDetails = [],
    signalArr,
    liveShotSignalSelected,
    dispatch,
  } = obj;
  let confirmedSignalDetails = [...signalDetails];
  if (!skipDuplicate) {
    let duplicateIdx = -1;
    let currentLocalPath = field.crPathName;
    duplicateIdx = liveShotSignalSelected.findIndex(
      (obj) =>
        obj.crPathName === currentLocalPath &&
        obj.liveshotSignalId !== field.liveshotSignalId &&
        obj.routed
    );
    delete field.isSkipDuplicate;
    if (duplicateIdx > -1) {
      liveShotSignalSelected[duplicateIdx].routed = false;
      confirmedSignalDetails = signalDetails.filter(
        (signal) =>
          signal.liveshotSignalId !==
          liveShotSignalSelected[duplicateIdx].liveshotSignalId
      );
      dispatch(
        updateLiveShotSignals(
          token,
          liveShotSignalSelected[duplicateIdx],
          { routed: false },
          room,
          false,
          true
        )
      );
    }
  }
  dispatch(
    takeAll(
      token,
      userId,
      userFullName,
      signalArr,
      crPath,
      liveshotId,
      roomId,
      room,
      "",
      liveShotSignalSelected,
      skipDuplicate ? idx + 1 : idx,
      confirmedSignalDetails
    )
  );
};
const confirmDuplicateRoute = (
  field,
  obj,
  btnType,
  skipDuplicate = false,
  isUse
) => {
  if (isUse) {
    confirmDuplicateRouteUse(field, obj, btnType, (skipDuplicate = false));
  } else {
    const {
      token,
      userId,
      userFullName,
      liveshotId,
      crPath,
      roomId,
      room,
      idx = 0,
      signalDetails = [],
      signalsArr,
      liveShotSignalSelected,
      dispatch,
    } = obj;
    let confirmedSignalDetails = [...signalDetails];
    let currentCanvas = field.currentCanvas ? field.currentCanvas : "";
    let outBoundFieldsNormal = field.outBoundFieldsNormal;
    let commsFieldsNormal = field.commsFieldsNormal;
    delete field.currentCanvas;
    delete field.outBoundFieldsNormal;
    delete field.commsFieldsNormal;

    if (!skipDuplicate) {
      let duplicateIdx = -1;
      let currentPath = field.crPathName;
      let signalType = field.signalType ? field.signalType : "";
      delete field.signalType;
      currentPath =
        signalType === OUTBOUND_SIGNALTYPE
          ? field.globalPathName
          : field.crPathName;

      delete field.isSkipDuplicate;

      duplicateIdx = liveShotSignalSelected.findIndex((obj) => {
        let objPath =
          signalType === OUTBOUND_SIGNALTYPE
            ? obj.globalPathName
            : obj.crPathName;
        return (
          objPath === currentPath &&
          obj.liveshotSignalId !== field.liveshotSignalId &&
          obj.routed
        );
      });
      if (duplicateIdx > -1) {
        liveShotSignalSelected[duplicateIdx].routed = false;
        confirmedSignalDetails = signalDetails.filter(
          (signal) =>
            signal.liveshotSignalId !==
            liveShotSignalSelected[duplicateIdx].liveshotSignalId
        );
      }
      let grabParams = {
        btnType: btnType,
        userId: userId,
        userFullName: userFullName,
        field: signalsArr,
        crPath: crPath,
        liveShotId: liveShotSignalSelected[duplicateIdx].liveShotId,
        roomId: currentCanvas.roomId,
        currentRoom: room,
        isGrabAll: true,
        liveShotSignalSelected: liveShotSignalSelected,
        currentCanvas: currentCanvas,
        idx: idx,
        skipDuplicate: skipDuplicate,
        confirmedSignalDetails: confirmedSignalDetails,
      };
      dispatch(
        unOwnRoute(
          token,
          liveShotSignalSelected[duplicateIdx].liveshotSignalId,
          liveShotSignalSelected[duplicateIdx],
          signalType,
          room,
          true,
          grabParams,
          signalsArr,
          outBoundFieldsNormal,
          commsFieldsNormal
        )
      );
    } else {
      dispatch(
        grabAll(
          token,
          userId,
          userFullName,
          outBoundFieldsNormal,
          commsFieldsNormal,
          signalsArr,
          roomId,
          room,
          "",
          liveShotSignalSelected,
          currentCanvas,
          idx + 1,
          confirmedSignalDetails
        )
      );
    }
  }
};

export const resetSignalsInfo = (crPath) => {
  return {
    type: actionTypes.RESET_SIGNALS_INFO,
    redBorder: [],
    inBoundCRPath: [],
    inBoundGlobalPath: [],
    liveShotSignalSelected: [],
    selectedCanvas: null,
    outBoundCRPath: [],
    outBoundGlobalPath: [],
    ifbCRPath: [],
    ifbGlobalPath: [],
    plCRPath: [],
    plGlobalPath: [],
    phoGlobalPath: [],
    phoCRPath: [],
    globalPins: [],
    grabbedSignals: [],
    showSignalModal: false,
    signalMsg: {},
  };
};

export const addSipList = (arr) => {
  return {
    type: actionTypes.ADD_SIP_LIST,
    sipList: arr,
  };
};

export const updateLiveShotObj = (signalObj) => {
  return {
    type: actionTypes.UPDATE_LIVESHOT_SIGNAL,
    signal: signalObj,
  };
};

export const refetchSipsStatus = (token, sipList, formattedRouterSource) => {
  return async (dispatch) => {
    sipList.map((sip, index) => {
      if (formattedRouterSource.includes(sip)) {
        dispatch(fetchPinBySipNumber(token, sip, true));
      }
    });
  };
};

export const setPinError = (error) => {
  return {
    type: actionTypes.SETPINERROR,
    error,
  };
};

export const resetPinError = () => {
  return {
    type: actionTypes.RESETPINERROR,
  };
};

export const addRedBorder = (signal) => {
  return {
    type: actionTypes.ADDREDBORDER,
    signal: signal,
  };
};

export const removeRedBorder = (signalId) => {
  return {
    type: actionTypes.REMOVEREDBORDER,
    signalId: signalId,
  };
};

export const addInBoundAvailSharedSignals = (signals) => {
  return {
    type: actionTypes.ADDINBOUNDAVAILSHAREDSIGNALS,
    signals: signals,
  };
};

export const addOutBoundAvailSharedSignals = (signals) => {
  return {
    type: actionTypes.ADDOUTBOUNDAVAILSHAREDSIGNALS,
    signals: signals,
  };
};

export const addIFBAvailSharedSignals = (signals) => {
  return {
    type: actionTypes.ADDIFBAVAILSHAREDSIGNALS,
    signals: signals,
  };
};

export const addPLAvailSharedSignals = (signals) => {
  return {
    type: actionTypes.ADDPLAVAILSHAREDSIGNALS,
    signals: signals,
  };
};

export const addPHOAvailSharedSignals = (signals) => {
  return {
    type: actionTypes.ADDPHOAVAILSHAREDSIGNALS,
    signals: signals,
  };
};

export const removeInBoundAvailSharedSignals = () => {
  return {
    type: actionTypes.REMOVEINBOUNDAVAILSHAREDSIGNALS,
  };
};

export const removeOutBoundAvailSharedSignals = () => {
  return {
    type: actionTypes.REMOVEINBOUNDAVAILSHAREDSIGNALS,
  };
};

export const removeIFBAvailSharedSignals = () => {
  return {
    type: actionTypes.REMOVEIFBAVAILSHAREDSIGNALS,
  };
};

export const removePLAvailSharedSignals = () => {
  return {
    type: actionTypes.REMOVEPLAVAILSHAREDSIGNALS,
  };
};

export const removePHOAvailSharedSignals = () => {
  return {
    type: actionTypes.REMOVEPHOAVAILSHAREDSIGNALS,
  };
};

export const addAvailableSignal = (signal) => {
  return {
    type: actionTypes.AVAILABLE_SIGNAL,
    signal: signal,
  };
};

export const addInBoundCRPath = (crPath) => {
  return {
    type: actionTypes.CRPATHINBOUND,
    crPath: crPath,
  };
};

export const addPHOTXCRPath = (crPath) => {
  return {
    type: actionTypes.PHOTX_CR_PATH,
    crPath: crPath,
  };
};

export const addPHOTXGlobalPath = (globalPath) => {
  return {
    type: actionTypes.PHOTX_GLOBAL_PATH,
    globalPath: globalPath,
  };
};

export const addInBoundGlobalPath = (globalPath) => {
  return {
    type: actionTypes.GLOBALPATHINBOUND,
    globalPath: globalPath,
  };
};

export const addInBoundExtendGlobalPath = (globalPath) => {
  return {
    type: actionTypes.EXTENDGLOBALPATHINBOUND,
    globalPath: globalPath,
  };
};

export const addSharedInBoundGlobalPath = (globalPath) => {
  return {
    type: actionTypes.SHAREDGLOBALPATHINBOUND,
    globalPath: globalPath,
  };
};

export const addSharedOutBoundGlobalPath = (globalPath) => {
  return {
    type: actionTypes.SHAREDGLOBALPATHOUTBOUND,
    globalPath: globalPath,
  };
};

export const addSharedIFBGlobalPath = (globalPath) => {
  return {
    type: actionTypes.SHAREDGLOBALPATHIFB,
    globalPath: globalPath,
  };
};

export const addSharedPHOTXGlobalPath = (globalPath) => {
  return {
    type: actionTypes.SHAREDGLOBALPATHPHOTX,
    globalPath: globalPath,
  };
};

export const addSharedPLGlobalPath = (globalPath) => {
  return {
    type: actionTypes.SHAREDGLOBALPATHPL,
    globalPath: globalPath,
  };
};

export const addSharedPHOGlobalPath = (globalPath) => {
  return {
    type: actionTypes.SHAREDGLOBALPATHPHO,
    globalPath: globalPath,
  };
};

export const addGrabbedSignal = (res) => {
  return {
    type: actionTypes.ADD_GRABBED_SIGNAL,
    res: res,
  };
};

export const addGlobalPins = (res) => {
  return {
    type: actionTypes.ADD_GLOBAL_PINS,
    res: res,
  };
};

export const showSignalModal = (res, crPathMsg) => {
  return {
    type: actionTypes.SHOW_SIGNAL_MODAL,
    showSignalModal: true,
    signalMsg: res,
    showCrPathMsg: crPathMsg,
  };
};

export const hideSignalModal = () => {
  return {
    type: actionTypes.HIDE_SIGNAL_MODAL,
    showSignalModal: false,
    signalMsg: {},
  };
};

export const setCurrentSignals = (canvasId, signals) => {
  return {
    type: actionTypes.FETCHSIGNALS,
    canvasId: canvasId,
    liveShotSignals: signals,
  };
};

export const addSignal = (signal) => {
  return {
    type: actionTypes.ADD_SIGNAL,
    signal: signal,
  };
};

export const removeSignal = (signal) => {
  return {
    type: actionTypes.REMOVE_SIGNAL,
    signal: signal,
  };
};

export const removeDeletedSignal = (signal) => {
  return {
    type: actionTypes.REMOVE_DELETED_LIVESHOT_SIGNAL,
    signal: signal,
  };
};

export const addPinNumber = (res) => {
  return {
    type: actionTypes.FETCH_PIN_NUMBER,
    pinNumber: res,
  };
};

export const addOutBoundCRPath = (crPath) => {
  return {
    type: actionTypes.CRPATHOUTBOUND,
    crPath: crPath,
  };
};

export const addOutBoundGlobalPath = (globalPath) => {
  return {
    type: actionTypes.GLOBALPATHOUTBOUND,
    globalPath: globalPath,
  };
};

export const addIFBCRPath = (crPath) => {
  return {
    type: actionTypes.IFB_CR_PATH,
    crPath: crPath,
  };
};

export const addPLCRPath = (crPath) => {
  return {
    type: actionTypes.PL_CR_PATH,
    crPath: crPath,
  };
};

export const addPHOCRPath = (crPath) => {
  return {
    type: actionTypes.PHO_CR_PATH,
    crPath: crPath,
  };
};

export const addIFBGlobalPath = (globalPath) => {
  return {
    type: actionTypes.IFB_GLOBAL_PATH,
    globalPath: globalPath,
  };
};

export const addPLGlobalPath = (globalPath) => {
  return {
    type: actionTypes.PL_GLOBAL_PATH,
    globalPath: globalPath,
  };
};

export const addPHOGlobalPath = (globalPath) => {
  return {
    type: actionTypes.PHO_GLOBAL_PATH,
    globalPath: globalPath,
  };
};

export const showSignalErrorModal = (resdata) => {
  return {
    type: actionTypes.SHOW_SIGNAL_ERROR_MODAL,
    signalMsg: resdata,
    showModal: true,
  };
};

export const showSignalErrorValuesModal = (resdata, showMessage) => {
  return {
    type: actionTypes.SHOW_SIGNAL_ERROR_VALUES_MODAL,
    signalMsg: resdata,
    showErrorModal: true,
    showBorderMsg: showMessage,
  };
};

export const hideSignalErrorValueModal = () => {
  return {
    type: actionTypes.HIDE_SIGNAL_ERROR_VALUE_MODAL,
    showModal: false,
    signalMsg: {},
  };
};

export const hideSignalErrorModal = () => {
  return {
    type: actionTypes.HIDE_SIGNAL_ERROR_MODAL,
    showModal: false,
    signalMsg: {},
  };
};

export const addSipState = (res) => {
  return {
    type: actionTypes.FETCH_SIP_STATE,
    sipState: res,
  };
};

export const fetchCRPathForInBoundSignal = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/crpaths?signalType=1&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addInBoundCRPath(res.data));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchIFBCRPathSignal = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/crpaths?signalType=5&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addIFBCRPath(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchPLCRPathSignal = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/crpaths?signalType=8&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addPLCRPath(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchPHOCRPathSignal = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/crpaths?signalType=9&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addPHOCRPath(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchPHOTxGlobalPath = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/globalpaths?signalType=7&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addPHOTXGlobalPath(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchPHOTxCRPath = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/crpaths?signalType=7&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addPHOTXCRPath(res.data));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchIFBGlobalPathSignal = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/globalpaths?signalType=5&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addIFBGlobalPath(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchPLGlobalPathSignal = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/globalpaths?signalType=8&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addPLGlobalPath(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchPHOGlobalPathSignal = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/globalpaths?signalType=9&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addPHOGlobalPath(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchSharedGlobalInBoundSignal = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/globalpaths?shared=true&signalType=1&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addSharedInBoundGlobalPath(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchSharedGlobalOutBoundSignal = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/globalpaths?shared=true&signalType=2&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addSharedOutBoundGlobalPath(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchSharedGlobalIFBSignal = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/globalpaths?shared=true&signalType=5&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addSharedIFBGlobalPath(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchSharedGlobalPHOTXSignal = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/globalpaths?shared=true&signalType=7&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addSharedPHOTXGlobalPath(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchSharedGlobalPLSignal = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/globalpaths?shared=true&signalType=8&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addSharedPLGlobalPath(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchSharedGlobalPHOSignal = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/globalpaths?shared=true&signalType=9&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addSharedPHOGlobalPath(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const checkOwnership = (token, signal, formInfo, currentCanvas) => {
  return async (dispatch) => {
    await axios
      .get(
        `liveshot-api/liveshotSignal/ownership?globalPath=${signal.globalPathName}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        if (res.data.length > 0) {
          let absStartTime = formInfo.abstractLiveshot.windowStartTime;
          let startTime = moment.tz(
            currentCanvas.showDate + " " + absStartTime,
            "YYYY-MM-DD hh:mm a",
            "America/New_York"
          );
          let absEndTime = formInfo.abstractLiveshot.windowEndTime;
          let endTime = moment.tz(
            currentCanvas.showDate + " " + absEndTime,
            "YYYY-MM-DD hh:mm a",
            "America/New_York"
          );

          let check = false;
          const result = res.data;
          for (let key in result) {
            const signalData = result[key];
            if (signal.liveshotSignalId !== signalData.liveshotSignalId) {
              let controlTime = new Date(signalData.controlTime);
              let controlWindowEndTime = new Date(signalData.windowEndTime);
              let endTimeStr = endTime.format();

              let flag = false;
              // Overlap
              if (
                moment(controlTime).isSameOrAfter(startTime) &&
                moment(controlTime).isSameOrBefore(endTime)
              )
                flag = true;
              // Overlap
              else if (
                moment(controlWindowEndTime).isSameOrAfter(startTime) &&
                moment(controlWindowEndTime).isSameOrBefore(endTime)
              )
                flag = true;
              // Coincide
              else if (
                moment(startTime).isSameOrAfter(controlTime) &&
                moment(endTime).isSameOrBefore(controlWindowEndTime)
              )
                flag = true;
              const signalInfo = {};
              signalInfo.liveshotSignalId = signal.liveshotSignalId;
              signalInfo.liveshotName = signalData.liveshotName;
              signalInfo.createdBy = signalData.createdBy;
              signalInfo.updatedBy = signalData.updatedBy;
              signalInfo.controlTime = controlTime;
              signalInfo.windowEndTime = signalData.windowEndTime;
              if (flag) {
                dispatch(addRedBorder(signalInfo));
                check = true;
              }
            }
          }
          if (!check) {
            dispatch(removeRedBorder(signal.liveshotSignalId));
          }
        }
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const checkAvailableSignals = (
  token,
  signals,
  formInfo,
  currentCanvas,
  type
) => {
  return async (dispatch) => {
    dispatch(actions.toggleLoader());
    let signaltypeId;
    if (type === "InBound") {
      signaltypeId = 1;
      dispatch(removeInBoundAvailSharedSignals());
    }
    if (type === "OutBound") {
      signaltypeId = 2;
      dispatch(removeOutBoundAvailSharedSignals());
    }
    if (type === "IFB") {
      signaltypeId = 5;
      dispatch(removeIFBAvailSharedSignals());
    }
    if (type === "PL") {
      signaltypeId = 8;
      dispatch(removePLAvailSharedSignals());
    }
    if (type === "PHO") {
      signaltypeId = 9;
      dispatch(removePHOAvailSharedSignals());
    }
    const availableSignals = [];
    await axios
      .get(
        `liveshot-api/liveshotSignal/ownership?includePast=true&liveshotSignalTypeId=${signaltypeId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        let ownerShip = res.data;
        for (let key in signals) {
          let signal = signals[key];
          let ownershipSignal = ownerShip.filter(
            (ownerShip) => ownerShip.globalPathName === signal
          );
          if (ownershipSignal.length > 0) {
            let absTime = formInfo.hitTime;
            let hitTime;
            if (absTime) {
              let dateTime = new Date(
                currentCanvas.showDate + " " + absTime
              ).toISOString();
              hitTime = new Date(Date.parse(dateTime));
            } else {
              hitTime = new Date();
            }
            hitTime = hitTime.toLocaleString("en-US", { hour12: false });
            let check = false;
            for (let key in ownershipSignal) {
              const signalData = ownershipSignal[key];
              let controlTime = new Date(signalData.controlTime);
              controlTime = controlTime.toLocaleString("en-US", {
                hour12: false,
              });
              if (controlTime && hitTime) {
                if (new Date(controlTime) < new Date(hitTime)) {
                  check = true;
                }
              }
            }
            if (check) {
              availableSignals.push(signal);
            }
          } else {
            availableSignals.push(signal);
          }
        }
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
    if (type === "InBound")
      dispatch(addInBoundAvailSharedSignals(availableSignals));
    if (type === "OutBound")
      dispatch(addOutBoundAvailSharedSignals(availableSignals));
    if (type === "IFB") dispatch(addIFBAvailSharedSignals(availableSignals));
    if (type === "PL") dispatch(addPLAvailSharedSignals(availableSignals));
    if (type === "PHO") dispatch(addPHOAvailSharedSignals(availableSignals));
    dispatch(actions.toggleLoader());
  };
};

export const fetchGlobalPathForInBoundSignal = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/globalpaths?signalType=1&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addInBoundGlobalPath(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchGlobalPathExtendInBoundSignal = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/globalpaths?signalType=1&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addInBoundExtendGlobalPath(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchSelectedSignal = (token, liveShotId, canvasId) => {
  return (dispatch) => {
    axios
      .get(`liveshot-api/liveshotSignal/liveshot/${liveShotId}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        let signalArr = [];
        for (let key in res.data) {
          const signals = res.data[key];
          signalArr.push({
            ...signals,
          });
        }
        dispatch(setCurrentSignals(canvasId, signalArr));
        const abstractSignalsArr = signalArr.filter(
          (signalItem) => signalItem.liveshotId === 0
        );
        if (abstractSignalsArr.length > 0) {
          dispatch(getGrabbedStatus(token, abstractSignalsArr));
          dispatch(actions.getAccessPin(token, abstractSignalsArr));
        }
        signalArr = [];
        //console.log("pin:", signalArr);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        console.log("error", err);
      });
  };
};

export const getAccessPinFromAvailSIP = (token, globalPathName) => {
  return async (dispatch) => {
    await axios
      .get(`liveshot-api/liveshotSignal/getSIP/?path=${globalPathName}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        if (res.data.length > 0) dispatch(addGlobalPins(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const dropSummarySIP = (token, sip) => {
  return async (dispatch) => {
    await axios
      .patch(`liveshot-api/liveshotSignal/hangup/sipNumber/${sip}`, null, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchPinBySipNumber(token, sip));
        }
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const getAccessPin = (token, signalArr) => {
  //console.log("signalArr:", signalArr);
  return async (dispatch) => {
    for (let key in signalArr) {
      const signal = signalArr[key];
      if (
        signal.globalPathName &&
        (signal.liveshotSignalTypeId === 5 ||
          signal.liveshotSignalTypeId === 7 ||
          signal.liveshotSignalTypeId === 8 ||
          signal.liveshotSignalTypeId === 9)
      ) {
        await axios
          .get(
            `liveshot-api/liveshotSignal/getSIP/?path=${signal.globalPathName}`,
            {
              withCredentials: true,
              headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
            }
          )
          .then((res) => {
            if (res.data.length > 0) dispatch(addGlobalPins(res.data));
          })
          .catch((err) => {
            console.log("error ", err);
            if (err.response.status === 401) {
              dispatch(actions.getRefreshToken());
            }
            const res = {};
            res.accessPin = "-";
            res.namesetName = signal.globalPathName;
            dispatch(addGlobalPins([res]));
          });
      }
    }
  };
};

export const setAccessPin = (token, path, pin) => {
  return async (dispatch) => {
    await axios
      .patch(
        `liveshot-api/liveshotSignal/setAccessPinByPath/?path=${path}`,
        { accessPin: pin },
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        console.log("rsp:", res);
      })
      .catch((err) => {
        if (err.response.status === 500) {
          if (err.response.data.message.includes("is not Available")) {
            dispatch(setPinError(path + " " + "is not Available"));
          } else {
            dispatch(setPinError(err.response.data.message));
          }
        }
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const getGrabbedStatus = (token, signalArr) => {
  return async (dispatch) => {
    for (let key in signalArr) {
      const signal = signalArr[key];
      await axios
        .get(
          `liveshot-api/liveshotSignal/${signal.liveshotSignalId}?status=true`,
          {
            withCredentials: true,
            headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
          }
        )
        .then((res) => {
          if (res.data.controlled === true) {
            if (
              res.data.liveshotSignal.liveshotSignalId !== undefined &&
              res.data.liveshotSignal.liveshotSignalId !== ""
            ) {
              dispatch(addGrabbedSignal(res.data));
            }
          }
        })
        .catch((err) => {
          console.log("error ", err);
          if (err.response.status === 401) {
            dispatch(actions.getRefreshToken());
          }
        });
    }
  };
};

export const fetchCRPathForOutBoundSignal = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/crpaths?signalType=2&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addOutBoundCRPath(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchPinBySipNumber = (token, sip, initialLoad = false) => {
  return (dispatch) => {
    axios
      .get(`liveshot-api/liveshotSignal/getPinNumber/${sip}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        if (res.status === 200 && res.data) {
          const response = res.data;
          const sipState = response.participants;
          if (!initialLoad) {
            dispatch(addPinNumber(response));
          }
          dispatch(addSipState({ [sip]: Number(sipState) > 0 }));
        } else {
          dispatch(addSipState({ [sip]: false }));
        }
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        if (err.response.status === 404 && !initialLoad) {
          dispatch(addPinNumber(""));
        }
        dispatch(addSipState({ [sip]: false }));
      });
  };
};

export const pinCommsSignalSync = (token, pinCommsObj) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/synchronizeVenuePin?liveshotId=${pinCommsObj.liveshotId}&roomId=${pinCommsObj.roomId}&canvasId=${pinCommsObj.canvasId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addSignal(res.data));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const updateLiveShotSignals = (
  token,
  { ...signalInfo },
  signalInfoObj,
  room,
  updateCommGlobal,
  needUpdate = false
) => {
  delete signalInfo.crSuggest;
  delete signalInfo.globalSuggest;
  delete signalInfo.pathNameSelected;
  delete signalInfo.crPathNameSelected;
  delete signalInfo.globalPathNameSelected;
  signalInfo.crPathName = null;
  signalInfoObj.updatedBy = signalInfo.updatedBy;
  signalInfoObj.roomId = signalInfo.roomId;

  return (dispatch) => {
    axios
      .patch(
        `liveshot-api/liveshotSignal/${signalInfo.liveshotSignalId}?roomId=${signalInfo.roomId}`,
        signalInfoObj,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        if (updateCommGlobal) {
          dispatch(actions.getAccessPin(token, [res.data]));
        }
        let hasUpdateKey = Object.keys(signalInfoObj).findIndex((key) =>
          ["globalPathName", "crPathName", "sip", "pin"].includes(key)
        );
        if (needUpdate || hasUpdateKey > -1) {
          dispatch(actions.updateLiveShotObj(res.data));
        }
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const updateUniLateral = (token, { ...signalInfo }, room) => {
  const data = {};
  data.uniLateral = signalInfo.uniLateral;
  data.updatedBy = signalInfo.updatedBy;
  signalInfo.crPathName = null;
  return (dispatch) => {
    axios
      .put(
        `liveshot-api/liveshotSignal/${signalInfo.liveshotSignalId}?roomId=${room.roomId}`,
        data,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        console.log("UniLateral Updated!");
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const resetSignals = (token, { ...signalInfo }, canvasId) => {
  return (dispatch) => {
    axios
      .put(
        `liveshot-api/liveshot/reset/${signalInfo.abstractLiveshot.abstractLiveshotId}/${signalInfo.liveshotId}?updatedBy=${signalInfo.updatedBy}`,
        null,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        console.log("Reset scuccessful!");
        // Updating Liveshot signals
        if (res.data && res.status === 200) {
          let signalArr = [];
          for (let key in res.data) {
            const signals = res.data[key];
            signalArr.push({
              ...signals,
            });
          }
          dispatch(setCurrentSignals(canvasId, signalArr));
        }
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const updateNormalLiveShotSignals = (token, liveshotSignal, room) => {
  const normalSignal = {};
  if (liveshotSignal.liveshotSignalTypeId !== "undefined") {
    normalSignal.liveshotSignalTypeId = 0;
  }
  if (liveshotSignal.transmissionPathName !== "undefined") {
    normalSignal.transmissionPathName = liveshotSignal.transmissionPathName;
  }
  if (liveshotSignal.crPathName !== "undefined") {
    normalSignal.crPathName = liveshotSignal.crPathName;
  }
  if (liveshotSignal.globalPathName !== "undefined") {
    normalSignal.globalPathName = liveshotSignal.globalPathName;
  }
  normalSignal.roomId = liveshotSignal.roomId;
  normalSignal.updatedBy = liveshotSignal.updatedBy;
  return (dispatch) => {
    axios
      .patch(
        `liveshot-api/liveshotSignal/${liveshotSignal.liveshotSignalId}?roomId=${room.roomId}`,
        normalSignal,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {})
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const updateNormalLiveShotSignalsWithRefresh = (
  token,
  normalSignals,
  room,
  currentCanvasId,
  userId
) => {
  return (dispatch) => {
    for (let index in normalSignals) {
      let signal = normalSignals[index];
      const liveshotSignal = {};
      liveshotSignal.liveshotSignalId = signal.liveshotSignalId;
      liveshotSignal.roomId = room.roomId;
      liveshotSignal.updatedBy = parseInt(userId);
      liveshotSignal.crPathName = signal.crPathName;
      const normalSignal = {};
      if (liveshotSignal.liveshotSignalTypeId !== "undefined") {
        normalSignal.liveshotSignalTypeId = 0;
      }
      if (liveshotSignal.crPathName !== "undefined") {
        normalSignal.crPathName = liveshotSignal.crPathName;
      }
      normalSignal.roomId = liveshotSignal.roomId;
      normalSignal.updatedBy = liveshotSignal.updatedBy;

      axios
        .patch(
          `liveshot-api/liveshotSignal/${liveshotSignal.liveshotSignalId}?roomId=${room.roomId}`,
          normalSignal,
          {
            withCredentials: true,
            headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
          }
        )
        .then((res) => {})
        .catch((err) => {
          console.log("error ", err);
          if (err.response.status === 401) {
            dispatch(actions.getRefreshToken());
          }
        });
    }
    dispatch(actions.getHostStudioLiveShots(currentCanvasId, token));
  };
};

export const createLiveShotSignal = (
  token,
  lsId,
  ablsId,
  lsSignalTypeId,
  createdBy,
  room,
  venueType
) => {
  return async (dispatch) => {
    let txPath = "";
    if (lsSignalTypeId === 5) {
      txPath = "IFB";
    } else if (lsSignalTypeId === 8) {
      txPath = "PL";
    } else if (lsSignalTypeId === 9) {
      txPath = "PHO";
    }
    let abstractLiveshotSignalId = null;
    const abstractSignalInfo = {};
    abstractSignalInfo.liveshotSignalTypeId = lsSignalTypeId;
    abstractSignalInfo.abstractLiveshotId = ablsId;
    abstractSignalInfo.liveshotId = lsId;
    abstractSignalInfo.transmissionPathName = txPath;
    abstractSignalInfo.roomId = room.roomId;
    abstractSignalInfo.createdBy = createdBy;
    abstractSignalInfo.updatedBy = createdBy;
    if (venueType) {
      abstractSignalInfo.venueType = venueType;
    }
    await axios
      .post(`liveshot-api/liveshotSignal/`, abstractSignalInfo, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(addSignal(res.data));
        abstractLiveshotSignalId = res.data.liveshotSignalId;
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        if (err.response) {
          dispatch(
            showSignalErrorModal({
              resultData: err.response.data.message,
              statusCode: err.response.status,
            })
          );
        }
      });
    /*
    const normalSignalInfo = {};
    normalSignalInfo.abstractLiveshotSignalId = abstractLiveshotSignalId;
    normalSignalInfo.abstractLiveshotId = ablsId;
    normalSignalInfo.liveshotSignalTypeId = 0;
    // normalSignalInfo.liveshotSignalTypeId = lsSignalTypeId;
    normalSignalInfo.liveshotId = lsId;
    normalSignalInfo.crPathName = "";
    normalSignalInfo.transmissionPathName = txPath;
    normalSignalInfo.roomId = room.roomId;
    normalSignalInfo.createdBy = createdBy;
    normalSignalInfo.updatedBy = createdBy;

    // abstractSignalInfo.liveshotSignalTypeId = lsSignalTypeId;
    // abstractSignalInfo.abstractLiveshotId = ablsId;
    // abstractSignalInfo.transmissionPathName = txPath;
    // abstractSignalInfo.roomId = room.coreId;
    // abstractSignalInfo.createdBy = createdBy;
    // abstractSignalInfo.updatedBy = createdBy;

    await axios
      .post(`liveshot-api/liveshotSignal/`, normalSignalInfo, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(addSignal(res.data));
        console.log("normal signal created:", res.data);
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        if (err.response) {
          dispatch(
            showSignalErrorModal({
              resultData: err.response.data.message,
              statusCode: err.response.status,
            })
          );
        }
      });*/
  };
};

export const createLiveShotSignalFromStudioTruck = (
  token,
  lsId,
  ablsId,
  lsSignalTypeId,
  createdBy,
  room,
  globalPathName
) => {
  return async (dispatch) => {
    let txPath = "";
    if (lsSignalTypeId === 5) {
      txPath = "IFB";
    } else if (lsSignalTypeId === 8) {
      txPath = "PL";
    } else if (lsSignalTypeId === 9) {
      txPath = "PHO";
    }
    let abstractLiveshotSignalId = null;
    const abstractSignalInfo = {};
    abstractSignalInfo.liveshotSignalTypeId = lsSignalTypeId;
    abstractSignalInfo.abstractLiveshotId = ablsId;
    abstractSignalInfo.liveshotId = lsId;
    abstractSignalInfo.transmissionPathName = txPath;
    abstractSignalInfo.globalPathName = globalPathName;
    abstractSignalInfo.roomId = room.roomId;
    abstractSignalInfo.createdBy = createdBy;
    abstractSignalInfo.updatedBy = createdBy;
    await axios
      .post(`liveshot-api/liveshotSignal/`, abstractSignalInfo, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(addSignal(res.data));
        abstractLiveshotSignalId = res.data.liveshotSignalId;
        dispatch(actions.getAccessPin(token, [res.data]));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        if (err.response) {
          dispatch(
            showSignalErrorModal({
              resultData: err.response.data.message,
              statusCode: err.response.status,
            })
          );
        }
      });
    const normalSignalInfo = {};
    normalSignalInfo.abstractLiveshotSignalId = abstractLiveshotSignalId;
    normalSignalInfo.liveshotSignalTypeId = 0;
    normalSignalInfo.liveshotId = lsId;
    normalSignalInfo.transmissionPathName = txPath;
    normalSignalInfo.crPathName = "";
    normalSignalInfo.roomId = room.roomId;
    normalSignalInfo.createdBy = createdBy;
    await axios
      .post(`liveshot-api/liveshotSignal/`, normalSignalInfo, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(addSignal(res.data));
        console.log("normal signal created:", res.data);
      });
  };
};

export const createAbstractLiveShotSignal = (
  token,
  lsId,
  ablsId,
  lsSignalTypeId,
  createdBy,
  room
) => {
  return async (dispatch) => {
    let txPath = "";
    if (lsSignalTypeId === 5) {
      txPath = "IFB";
    } else if (lsSignalTypeId === 8) {
      txPath = "PL";
    } else if (lsSignalTypeId === 9) {
      txPath = "PHO";
    }
    //let abstractLiveshotSignalId = null;
    const abstractSignalInfo = {};
    abstractSignalInfo.liveshotSignalTypeId = lsSignalTypeId;
    abstractSignalInfo.abstractLiveshotId = ablsId;
    abstractSignalInfo.transmissionPathName = txPath;
    abstractSignalInfo.createdBy = createdBy;
    await axios
      .post(
        `liveshot-api/liveshotSignal/?roomId=${room.roomId}`,
        abstractSignalInfo,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addSignal(res.data));
        //abstractLiveshotSignalId = res.data.liveshotSignalId;
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        if (err.response) {
          dispatch(
            showSignalErrorModal({
              resultData: err.response.data.message,
              statusCode: err.response.status,
            })
          );
        }
      });
  };
};

export const createExtendLiveShotSignal = (
  token,
  lsId,
  ablsId,
  lsSignalTypeId,
  createdBy,
  room
) => {
  return async (dispatch) => {
    let txPath = "";
    if (lsSignalTypeId === 5) {
      txPath = "IFB";
    } else if (lsSignalTypeId === 8) {
      txPath = "PL";
    } else if (lsSignalTypeId === 9) {
      txPath = "PHO";
    }
    let abstractLiveshotSignalId = null;
    const abstractSignalInfo = {};
    abstractSignalInfo.liveshotSignalTypeId = lsSignalTypeId;
    abstractSignalInfo.abstractLiveshotId = ablsId;
    abstractSignalInfo.liveshotId = lsId;
    abstractSignalInfo.transmissionPathName = txPath;
    abstractSignalInfo.roomId = room.roomId;
    abstractSignalInfo.uniLateral = false;
    abstractSignalInfo.roomId = room.roomId;
    abstractSignalInfo.createdBy = createdBy;
    abstractSignalInfo.updatedBy = createdBy;
    await axios
      .post(`liveshot-api/liveshotSignal/`, abstractSignalInfo, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(addSignal(res.data));
        abstractLiveshotSignalId = res.data.liveshotSignalId;
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        if (err.response) {
          dispatch(
            showSignalErrorModal({
              resultData: err.response.data.message,
              statusCode: err.response.status,
            })
          );
        }
      });
    /*const normalSignalInfo = {};
    normalSignalInfo.abstractLiveshotSignalId = abstractLiveshotSignalId;
    normalSignalInfo.liveshotSignalTypeId = 0;
    normalSignalInfo.liveshotId = lsId;
    normalSignalInfo.crPathName = "";
    normalSignalInfo.transmissionPathName = "";
    normalSignalInfo.roomId = room.roomId;
    normalSignalInfo.createdBy = createdBy;
    await axios
      .post(`liveshot-api/liveshotSignal/`, normalSignalInfo, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(addSignal(res.data));
        console.log("normal signal created:", res.data);
      });*/
  };
};

export const createLiveShotSignalWIthValue = (
  token,
  lsId,
  ablsId,
  lsSignalTypeId,
  createdBy,
  room,
  crPathName,
  globalPathName
) => {
  return async (dispatch) => {
    let txPath = "";
    if (lsSignalTypeId === 5) {
      txPath = "IFB";
    } else if (lsSignalTypeId === 8) {
      txPath = "PL";
    } else if (lsSignalTypeId === 9) {
      txPath = "PHO";
    } else if (lsSignalTypeId === 2) {
      txPath = "";
    }
    let abstractLiveshotSignalId = null;
    const abstractSignalInfo = {};
    abstractSignalInfo.liveshotSignalTypeId = lsSignalTypeId;
    abstractSignalInfo.liveshotId = lsId;
    abstractSignalInfo.abstractLiveshotId = ablsId;
    abstractSignalInfo.transmissionPathName = txPath;
    abstractSignalInfo.roomId = room.roomId;
    abstractSignalInfo.createdBy = createdBy;
    abstractSignalInfo.updatedBy = createdBy;
    if (crPathName && crPathName != "") {
      abstractSignalInfo.crPathName = crPathName;
    }
    if (globalPathName) {
      abstractSignalInfo.globalPathName = globalPathName;
    }
    await axios
      .post(`liveshot-api/liveshotSignal/`, abstractSignalInfo, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(addSignal(res.data));
        abstractLiveshotSignalId = res.data.liveshotSignalId;
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        if (err.response) {
          dispatch(
            showSignalErrorModal({
              resultData: err.response.data.message,
              statusCode: err.response.status,
            })
          );
        }
      });
  };
};

export const createNormalSignal = (token, lsId, userId, signalArr, room) => {
  return async (dispatch) => {
    for (let key in signalArr) {
      const signal = signalArr[key];
      const normalSignalInfo = {};
      normalSignalInfo.liveshotSignalTypeId = 0;
      normalSignalInfo.liveshotId = lsId;
      normalSignalInfo.abstractLiveshotSignalId = signal.liveshotSignalId;
      normalSignalInfo.crPathName = "";
      normalSignalInfo.transmissionPathName = "";
      normalSignalInfo.roomId = room.roomId;
      normalSignalInfo.createdBy = parseInt(userId);
      await axios
        .post(`liveshot-api/liveshotSignal/`, normalSignalInfo, {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        })
        .then((res) => {
          dispatch(addSignal(res.data));
        })
        .catch((err) => {
          console.log("error ", err);
          if (err.response.status === 401) {
            dispatch(actions.getRefreshToken());
          }
        });
    }
  };
};

export const deleteLiveShotSignal = (
  token,
  lsSignalId,
  normalLsSignalId,
  room
) => {
  return (dispatch) => {
    axios
      .delete(
        `liveshot-api/liveshotSignal/${lsSignalId}?roomId=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(removeSignal(res.data));
        dispatch(removeSignal({ liveshotSignalId: normalLsSignalId }));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });

    /* if (normalLsSignalId !== null) {
      axios
        .delete(`liveshot-api/liveshotSignal/${normalLsSignalId}`, {
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        })
        .then((res) => {
          console.log("del:", res.data);
          dispatch(removeSignal(res.data));
        })
        .catch((err) => {
          console.log("error ", err);
        });
    } */
  };
};

export const fetchGlobalPathForOutBoundSignal = (token, room) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/globalpaths?signalType=2&room=${room.roomId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        dispatch(addOutBoundGlobalPath(res.data));
      })
      .catch((err) => {
        console.log("error", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};
const overrideCanvasSignal = (props, obj, skipOverride) => {
  let {
    token,
    userId,
    userFullName,
    liveshotId,
    crPath,
    roomId,
    room,
    idx = 0,
    signalDetails = [],
    signalsArr,
    liveShotSignalSelected,
    dispatch,
    currentCanvas = {},
    outBoundFieldsNormal,
    commsFieldsNormal,
  } = obj;
  let confirmedSignalDetails = [...signalDetails];
  let signalType = props.signalType;

  if (skipOverride) {
    if (signalDetails.length > 0 && idx === signalsArr.length - 1) {
      let modalObj = {};
      modalObj.status = 200;
      modalObj.control = "grabAll";
      modalObj.signals = signalDetails;
      modalObj.signalType = signalType;
      dispatch(showSignalModal(modalObj));
    } else {
      dispatch(
        grabAll(
          token,
          userId,
          userFullName,
          outBoundFieldsNormal,
          commsFieldsNormal,
          signalsArr,
          roomId,
          room,
          "",
          liveShotSignalSelected,
          currentCanvas,
          idx + 1,
          confirmedSignalDetails
        )
      );
    }
  } else {
    let response = props.response.data;
    let signalToOverride = response.liveshotSignal;
    let overrideSignalId = signalToOverride.liveshotSignalId;
    let isGrabAll = props.isGrabAll;
    delete props.isGrabAll;
    let grabParams = {};

    if (isGrabAll) {
      grabParams = {
        btnType: "grab",
        userId: userId,
        userFullName: userFullName,
        field: signalsArr,
        crPath: crPath,
        liveShotId: props.liveshotId,
        roomId: roomId,
        currentRoom: room,
        isGrabAll: true,
        liveShotSignalSelected: liveShotSignalSelected,
        currentCanvas: currentCanvas,
        idx: idx,
        skipDuplicate: false,
        confirmedSignalDetails: confirmedSignalDetails,
      };
    } else {
      grabParams = {
        btnType: "grab",
        userId: userId,
        userFullName: userFullName,
        field: props,
        crPath: crPath,
        liveShotId: props.liveshotId,
        roomId: roomId,
        currentRoom: room,
      };
    }
    dispatch(
      unOwnRoute(
        token,
        overrideSignalId,
        signalToOverride,
        props.signalType,
        (room = {}),
        true,
        grabParams
      )
    );
  }
};
export const takeAndGrab = (
  token,
  control,
  userId,
  userFullName,
  abstractSignalInfo,
  normalSignalInfo,
  liveshotId,
  roomId,
  room,
  globalPathRoomId
) => {
  control =
    control === OUTBOUND_BUTTONTYPE || control === COMMS_BUTTONTYPE
      ? "grab"
      : control;
  const grabPayload = {
    grabbedBy: userId,
    grabbedByFullName: userFullName,
    roomId: roomId,
  };
  const payload =
    control === "take"
      ? grabPayload
      : {
          ...grabPayload,
          requesterControlRoom: room.roomCode,
          globalPathRoomId: room.coreId,
        };
  return (dispatch) => {
    dispatch(actions.toggleLoader());
    let uri = `liveshot-api/liveshotSignal/control/${abstractSignalInfo.liveshotSignalId}?type=${control}`;
    axios
      .post(uri, payload, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(actions.toggleLoader());
        if (res.status === 200 && control === "take") {
          let remoteLabelControlUrl = room.remoteLabelControlUrl;
          abstractSignalInfo.control = control;
          abstractSignalInfo.status = res.status;
          dispatch(showSignalModal(abstractSignalInfo));
          dispatch(
            updateLiveShotSignals(
              token,
              abstractSignalInfo,
              { routed: true },
              room,
              false,
              true
            )
          );
          if (
            remoteLabelControlUrl &&
            remoteLabelControlUrl !== null &&
            remoteLabelControlUrl !== ""
          ) {
            dispatch(
              remoteLabelControlRequest(
                token,
                abstractSignalInfo,
                normalSignalInfo,
                userId,
                userFullName,
                roomId,
                liveshotId
              )
            );
          }
        }
        if ([200, 202].includes(res.status) && control === "grab") {
          // TODO- Will Check & remove in future
          // dispatch(addGrabbedSignal(res.data));
          let remoteLabelControlUrl = room.remoteLabelControlUrl;
          abstractSignalInfo.control = control;
          abstractSignalInfo.status = res.status;
          dispatch(showSignalModal(abstractSignalInfo));
          dispatch(
            updateLiveShotSignals(
              token,
              abstractSignalInfo,
              { routed: true },
              room,
              false,
              true
            )
          );
          if (
            remoteLabelControlUrl &&
            remoteLabelControlUrl !== null &&
            remoteLabelControlUrl !== ""
          ) {
            dispatch(
              remoteLabelControlRequest(
                token,
                abstractSignalInfo,
                normalSignalInfo,
                userId,
                userFullName,
                roomId,
                liveshotId
              )
            );
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 409) {
          abstractSignalInfo.control = control;
          abstractSignalInfo.status = err.response.status;
          abstractSignalInfo.response = err.response;
          abstractSignalInfo.crPath = {
            token,
            userId,
            userFullName,
            roomId,
            room,
            crPath: normalSignalInfo,
            dispatch,
          };
          abstractSignalInfo.overrideCanvasSignal = overrideCanvasSignal;
          dispatch(showSignalModal(abstractSignalInfo));
          dispatch(actions.toggleLoader());
        } else {
          abstractSignalInfo.control = control;
          abstractSignalInfo.status = err.response.status;
          dispatch(showSignalModal(abstractSignalInfo));
          dispatch(actions.toggleLoader());
          dispatch(
            updateLiveShotSignals(
              token,
              abstractSignalInfo,
              { routed: false },
              room,
              false,
              true
            )
          );
          if (err.response.status === 401) {
            dispatch(actions.getRefreshToken());
          }
          console.log("error ", err.response);
        }
      });
  };
};

export const grabFromNotification = (
  token,
  control,
  userId,
  userFullName,
  abstractSignalInfo,
  normalSignalInfo,
  liveshotId,
  currentCanvas
) => {
  const payload = {
    grabbedBy: userId,
    grabbedByFullName: userFullName,
    roomId: currentCanvas.roomId,
    liveshotId: liveshotId,
  };
  return (dispatch) => {
    axios
      .post(
        `liveshot-api/liveshotSignal/control/${normalSignalInfo.liveshotSignalId}/${abstractSignalInfo.liveshotSignalId}?type=${control}`,
        payload,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        console.log("grabbed from Notification:", res.data);
        //dispatch(actions.getHostStudioLiveShots(currentCanvas.canvasId, token));
      })
      .catch((err) => {
        console.log("err:", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const takeAll = (
  token,
  userId,
  userFullName,
  signalArr,
  normalSignalInfo,
  liveshotId,
  roomId,
  room,
  crPathMsg,
  liveShotSignalSelected,
  idx = 0,
  signalStatus = []
) => {
  return async (dispatch) => {
    dispatch(actions.toggleLoader());

    const signalDetails = signalStatus;
    let cacheIndex = idx;
    let enableModal = false;
    let iteratedKey = 0;

    for (let key = idx; key < signalArr.length; key++) {
      const signal = signalArr[key];
      iteratedKey = key;
      const normalSignal = normalSignalInfo.find(
        (item) => item.abstractLiveshotSignalId === signal.liveshotSignalId
      );
      let url = `liveshot-api/liveshotSignal/control/${signal.liveshotSignalId}?type=take`;
      let liveShotSignals = removeDuplicates(liveShotSignalSelected).filter(
        (signal) => signal.liveshotSignalTypeId === 1
      );
      let hasValidRoute =
        !signal.globalPathNameError &&
        !signal.crPathNameError &&
        !signal.routed;
      let isDuplicate = liveShotSignals.some((obj) => {
        return (
          obj.crPathName === signal.crPathName &&
          obj.routed &&
          obj.liveshotSignalId !== signal.liveshotSignalId
        );
      });
      if (isDuplicate && hasValidRoute) {
        enableModal = true;
        cacheIndex = key;
        break;
      }
      // TODO - Will check & remove in future while on refactor
      // let url = `liveshot-api/liveshotSignal/control/${normalSignal.liveshotSignalId}/${signal.liveshotSignalId}?type=take`;
      // if (signal.uniLateral === false) {
      if (hasValidRoute) {
        await axios
          .post(
            url,
            {
              grabbedBy: userId,
              grabbedByFullName: userFullName,
              roomId: roomId,
            },
            {
              withCredentials: true,
              headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              dispatch(
                updateLiveShotSignals(
                  token,
                  signal,
                  { routed: true },
                  room,
                  false,
                  true
                )
              );
              let remoteLabelControlUrl = room.remoteLabelControlUrl;
              signal.control = "take";
              signal.status = res.status;
              if (
                remoteLabelControlUrl &&
                remoteLabelControlUrl !== null &&
                remoteLabelControlUrl !== ""
              ) {
                dispatch(
                  remoteLabelControlRequest(
                    token,
                    signal,
                    normalSignal,
                    userId,
                    userFullName,
                    roomId,
                    signal.liveshotId
                  )
                );
              }
              signal.status = 200;
              signal.control = "take";
              signal.routed = true;
              signalArr[key].routed = true;
              signalDetails.push(signal);
            }
          })
          .catch((err) => {
            console.log("error ", err.response);
            dispatch(
              updateLiveShotSignals(
                token,
                signal,
                { routed: false },
                room,
                false,
                true
              )
            );
            if (err.response && err.response.status === 401) {
              dispatch(actions.getRefreshToken());
            }
            signal.status = 500;
            signal.control = "take";
            signal.routed = false;
            signalArr[key].routed = false;
            signalDetails.push(signal);
          });
      }
    }
    dispatch(actions.toggleLoader());
    if (enableModal) {
      let index = parseInt(cacheIndex);
      let signalInfo = signalArr[index];
      signalInfo.isDuplicate = true;
      signalInfo.crPath = {
        token,
        userId,
        userFullName,
        liveshotId: signalInfo.liveshotId,
        roomId,
        room,
        crPath: normalSignalInfo,
        idx: index,
        signalDetails,
        signalArr,
        liveShotSignalSelected,
        dispatch,
      };
      signalInfo.confirmDuplicateRoute = confirmDuplicateRouteUse;
      signalInfo.btnType = "take";
      signalInfo.isUse = true;
      signalInfo.isSkipDuplicate = enableModal;

      dispatch(showSignalModal(signalInfo));
    } else if (
      signalDetails.length > 0 &&
      (iteratedKey === signalArr.length - 1 ||
        parseInt(idx) === signalArr.length)
    ) {
      let modalObj = {};
      modalObj.status = 200;
      modalObj.control = "takeAll";
      modalObj.signals = signalDetails;
      dispatch(showSignalModal(modalObj));
    }
  };
};

export const releaseControltracker = (controlTrackerId, token, userId) => {
  return (dispatch) => {
    axios
      .delete(
        `liveshot-api/controlTracker/${controlTrackerId}?deletedBySSO=${userId}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        console.log("control tracker released:", controlTrackerId);
      })
      .catch((err) => {
        console.log("err:", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};
export const grabAll = (
  token,
  userId,
  userFullName,
  outBoundFieldsNormal,
  commsFieldsNormal,
  signalsArr,
  roomId,
  room,
  crPathMsg,
  liveShotSignalSelected,
  currentCanvas,
  idx = 0,
  signalStatus = []
) => {
  return async (dispatch) => {
    dispatch(actions.toggleLoader());
    const signalDetails = signalStatus;
    let enableModal = false;
    let cacheIndex = idx;
    let iteratedKey = 0;
    let isOverrideSignalExists = false;
    let errorResponse;

    let signalType = "";
    let btnType = "";
    let normalSignalArr = [];
    for (let key = idx; key < signalsArr.length; key++) {
      const signal = signalsArr[key];
      let liveshotId = signal.liveshotId;
      iteratedKey = key;
      let url = `liveshot-api/liveshotSignal/control/${signal.liveshotSignalId}?type=grab`;
      let commsCondition =
        !signal.crPathNameError && !signal.crPathNameError && !signal.routed;
      let outboundCondition =
        !signal.globalPathNameError &&
        !signal.globalPathNameError &&
        !signal.crPathNameError &&
        !signal.routed;
      let grabCondition = false;

      let outBoundLiveShotSignals = removeDuplicates(
        liveShotSignalSelected
      ).filter((signal) => signal.liveshotSignalTypeId === 2);
      let commsLiveShotSignals = removeDuplicates(
        liveShotSignalSelected
      ).filter((signal) =>
        COMMS_SIGNALID_ARRAY.includes(signal.liveshotSignalTypeId)
      );

      let isOutboundDuplicate = outBoundLiveShotSignals.some((obj) => {
        return (
          obj.globalPathName === signal.globalPathName &&
          obj.routed &&
          obj.liveshotSignalId !== signal.liveshotSignalId
        );
      });
      let isCommsDuplicate = commsLiveShotSignals.some((obj) => {
        return (
          obj.crPathName === signal.crPathName &&
          obj.routed &&
          obj.liveshotSignalId !== signal.liveshotSignalId
        );
      });

      if (signal.liveshotSignalTypeId === 2) {
        grabCondition = outboundCondition;
        signalType = OUTBOUND_SIGNALTYPE;
        btnType = OUTBOUND_BUTTONTYPE;
        normalSignalArr = outBoundFieldsNormal;
      } else if (COMMS_SIGNALID_ARRAY.includes(signal.liveshotSignalTypeId)) {
        grabCondition = commsCondition;
        signalType = COMMS_SIGNALTYPE;
        btnType = COMMS_BUTTONTYPE;
        normalSignalArr = commsFieldsNormal;
      }
      if (
        (isOutboundDuplicate && outboundCondition) ||
        (isCommsDuplicate && commsCondition)
      ) {
        enableModal = true;
        cacheIndex = key;
        break;
      }
      if (grabCondition) {
        await axios
          .post(
            url,
            {
              globalPathRoomId: room.coreId,
              grabbedBy: userId,
              grabbedByFullName: userFullName,
              roomId: roomId,
              liveshotId: liveshotId,
            },
            {
              withCredentials: true,
              headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
            }
          )
          .then((res) => {
            if ([200, 202].includes(res.status)) {
              dispatch(
                updateLiveShotSignals(
                  token,
                  signal,
                  { routed: true },
                  room,
                  false,
                  true
                )
              );
              dispatch(addGrabbedSignal(res.data));
            }
            if (res.status === 200 || res.status === 202) {
              signal.status = res.status;
              signal.control = "grab";
              signal.routed = true;
              signalsArr[key].routed = true;
              signalDetails.push(signal);
            }
          })
          .catch((err) => {
            if (err.response.status === 409) {
              errorResponse = err;
              isOverrideSignalExists = true;
            } else {
              console.log("error ", err.response);
              if (err.response) {
                signal.control = "grab";
                signal.status = 500;
                signal.routed = false;
                signalsArr[key].routed = false;
                signalDetails.push(signal);
              }
            }
          });
      }
      if (isOverrideSignalExists) {
        break;
      }
    }
    dispatch(actions.toggleLoader());

    if (isOverrideSignalExists) {
      let signal = signalsArr[iteratedKey];
      signal.control = "grab";
      signal.status = errorResponse.response.status;
      signal.response = errorResponse.response;
      signal.signalType = signalType;
      signal.isSkipOverride = isOverrideSignalExists;
      signal.crPath = {
        token,
        userId,
        userFullName,
        roomId,
        room,
        crPath: normalSignalArr,
        idx: iteratedKey,
        signalDetails,
        signalsArr,
        liveShotSignalSelected,
        dispatch,
        currentCanvas,
        outBoundFieldsNormal,
        commsFieldsNormal,
      };
      signal.isGrabAll = true;
      signal.overrideCanvasSignal = overrideCanvasSignal;

      dispatch(showSignalModal(signal));
    }
    if (enableModal) {
      let index = parseInt(cacheIndex);
      let signalInfo = signalsArr[index];
      signalInfo.isDuplicate = true;

      signalInfo.crPath = {
        token,
        userId,
        userFullName,
        liveshotId: signalInfo.liveshotId,
        roomId,
        room,
        crPath: normalSignalArr,
        idx: index,
        signalDetails,
        signalsArr: signalsArr,
        liveShotSignalSelected,
        dispatch,
      };
      signalInfo.confirmDuplicateRoute = confirmDuplicateRoute;
      signalInfo.btnType = btnType;
      signalInfo.signalType = signalType;
      signalInfo.currentCanvas = currentCanvas;
      signalInfo.isSkipDuplicate = enableModal;
      signalInfo.isUse = false;
      signalInfo.outBoundFieldsNormal = outBoundFieldsNormal;
      signalInfo.commsFieldsNormal = commsFieldsNormal;
      dispatch(showSignalModal(signalInfo));
    } else if (
      signalDetails.length > 0 &&
      (iteratedKey === signalsArr.length - 1 ||
        parseInt(idx) === signalsArr.length) &&
      isOverrideSignalExists === false
    ) {
      let modalObj = {};
      modalObj.status = 200;
      modalObj.control = "grabAll";
      modalObj.signals = signalDetails;
      modalObj.signalType = signalType;
      dispatch(showSignalModal(modalObj));
    }
  };
};

export const grabAllCanvasBlock = (
  token,
  userId,
  userFullName,
  liveshots = [],
  liveShotSignalSelected,
  roomId,
  showMessage,
  currentRoom,
  currentCanvas,
  outBoundFieldsNormal,
  commsFieldsNormal,
  idx = 0
) => {
  return async (dispatch) => {
    let signalsArr = [];
    let index = idx;
    if (index < liveshots.length) {
      liveshots.map((liveshot) => {
        let liveshotSignal = removeDuplicates(liveShotSignalSelected).filter(
          (signal) => {
            return (
              signal.abstractLiveshotId ===
                liveshot.abstractLiveshot.abstractLiveshotId &&
              signal.liveshotId === liveshot.liveshotId &&
              signal.liveshotSignalTypeId === 2
            );
          }
        );
        let liveshotSignalComms = removeDuplicates(
          liveShotSignalSelected
        ).filter((signal) => {
          return (
            signal.abstractLiveshotId ===
              liveshot.abstractLiveshot.abstractLiveshotId &&
            signal.liveshotId === liveshot.liveshotId &&
            COMMS_SIGNALID_ARRAY.includes(signal.liveshotSignalTypeId)
          );
        });
        signalsArr = [...signalsArr, ...liveshotSignal, ...liveshotSignalComms];
      });

      dispatch(
        grabAll(
          token,
          userId,
          userFullName,
          outBoundFieldsNormal,
          commsFieldsNormal,
          signalsArr,
          roomId,
          currentRoom,
          "",
          liveShotSignalSelected,
          currentCanvas,
          idx,
          []
        )
      );
    }
  };
};
export const takeAllCanvasBlock = (
  token,
  userId,
  userFullName,
  liveshots = [],
  liveShotSignalSelected,
  roomId,
  room,
  liveshotSignalDuplicates,
  showMessage,
  idx = 0
) => {
  return async (dispatch) => {
    let signalArr = [],
      normalSignalInfo = [];
    let index = idx;
    if (index < liveshots.length) {
      liveshots.map((liveshot) => {
        let liveshotSignal = removeDuplicates(liveShotSignalSelected).filter(
          (signal) => {
            return (
              signal.abstractLiveshotId ===
                liveshot.abstractLiveshot.abstractLiveshotId &&
              signal.liveshotId === liveshot.liveshotId &&
              signal.liveshotSignalTypeId === 1
            );
          }
        );
        signalArr = [...signalArr, ...liveshotSignal];
        // TODO- Will remove the below condition once app is stable
        // normalSignalInfo = removeDuplicates(liveShotSignalSelected).filter(
        //   (signal) =>
        //     signal.liveshotId === liveshots[index].liveshotId &&
        //     signal.liveshotSignalTypeId === 0
        // );
      });

      dispatch(
        takeAll(
          token,
          userId,
          userFullName,
          signalArr,
          normalSignalInfo,
          "",
          roomId,
          room,
          "",
          liveShotSignalSelected,
          0
        )
      );
    }
    // TODO - Will remove in future
    //   if (liveshotSignalDuplicates) {
    //     if (liveshotSignalDuplicates.length > 0) {
    //       const signalObj = {};
    //       signalObj.status = 0;
    //       signalObj.control = "take duplicate";
    //       signalObj.signals = liveshotSignalDuplicates;
    //       signalError.push(signalObj);
    //     }
    //   }
    //   if (signalError.length > 0) {
    //     dispatch(showSignalErrorValuesModal(signalError, showMessage));
    //   }
  };
};

export const updateLiveShotSignalsWithCheckOwnership = (
  token,
  formInfo,
  currentCanvas,
  { ...signalInfo },
  room,
  updateCommGlobal
) => {
  delete signalInfo.crSuggest;
  delete signalInfo.globalSuggest;
  delete signalInfo.pathNameSelected;
  delete signalInfo.crPathNameSelected;
  delete signalInfo.globalPathNameSelected;
  return (dispatch) => {
    axios
      .put(
        `liveshot-api/liveshotSignal/${signalInfo.liveshotSignalId}?roomId=${room.roomId}`,
        signalInfo,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        // if (updateCommGlobal) {
        //   dispatch(actions.getAccessPin(token, [res.data]));
        // }
        dispatch(checkOwnership(token, signalInfo, formInfo, currentCanvas));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const remoteLabelControlRequest = (
  token,
  abstractSignalInfo,
  normalSignalInfo,
  userId,
  userFullName,
  roomId,
  liveshotId
) => {
  return (dispatch) => {
    let label;
    if (abstractSignalInfo.label) {
      label = abstractSignalInfo.label;
    } else {
      label = "";
    }
    const payload = {
      grabbedBy: userId,
      grabbedByFullName: userFullName,
      roomId: roomId,
      liveshotId: liveshotId,
    };
    let uri = `liveshot-api/liveshotSignal/control/${abstractSignalInfo.liveshotSignalId}?type=remoteLabelControl&label=${label}`;
    axios
      .post(uri, payload, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {})
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const setHookStatus = (token, globalPath) => {
  return (dispatch) => {
    axios
      .get(
        `liveshot-api/liveshotSignal/setHookStatusByPath/?path=${globalPath}&hook=reset`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const assignAvailableSignals = (
  token,
  signals,
  formInfo,
  currentCanvas,
  type,
  liveshotSignal,
  currentRoom,
  userId,
  selectedSignals
) => {
  return async (dispatch) => {
    dispatch(actions.toggleLoader());
    const availableSignals = [];
    let signalValue;
    let signaltypeID;
    if (type === "InBound") {
      signaltypeID = 1;
    }
    if (type === "OutBound") {
      signaltypeID = 2;
    }
    if (liveshotSignal.transmissionPathName === "IFB") {
      signaltypeID = 5;
    } else if (liveshotSignal.transmissionPathName === "PL") {
      signaltypeID = 8;
    } else if (liveshotSignal.transmissionPathName === "PHO") {
      signaltypeID = 9;
    }
    await axios
      .get(
        `liveshot-api/liveshotSignal/ownership?includePast=true&liveshotSignalTypeId=${signaltypeID}`,
        {
          withCredentials: true,
          headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        }
      )
      .then((res) => {
        let ownerShip = res.data;
        let signalFilteredValue = [];
        let windowTime;
        for (let key in signals) {
          let signal = signals[key];
          let ownershipSignal = ownerShip.filter(
            (ownerShip) =>
              ownerShip.globalPathName === signal &&
              ownerShip.liveshotSignalId !== liveshotSignal.liveshotSignalId
          );
          let liveshotSignalOwnerShip = ownershipSignal.filter(
            (signal, ind) =>
              ind ===
              ownershipSignal.findIndex(
                (elem) => elem.liveshotSignalId === signal.liveshotSignalId
              )
          );
          if (liveshotSignalOwnerShip.length > 0) {
            let absTime = formInfo.abstractLiveshot.windowEndTime;
            if (absTime) {
              let dateTime = new Date(
                currentCanvas.showDate + " " + absTime
              ).toISOString();
              windowTime = new Date(Date.parse(dateTime));
            } else {
              windowTime = new Date();
            }
            windowTime = windowTime.toLocaleString("en-US", { hour12: false });
            for (let key in liveshotSignalOwnerShip) {
              const signalData = liveshotSignalOwnerShip[key];
              let windowEndTime = new Date(signalData.windowEndTime);
              windowEndTime = windowEndTime.toLocaleString("en-US", {
                hour12: false,
              });
              if (windowEndTime && windowTime) {
                if (new Date(windowEndTime) < new Date(windowTime)) {
                  signalFilteredValue.push(signalData);
                }
              }
            }
          } else {
            availableSignals.push(signal);
          }
        }
        if (availableSignals.length > 0) {
          signalValue = availableSignals[0];
        } else {
          if (signalFilteredValue.length > 0) {
            if (signalFilteredValue.length > 1) {
              signalFilteredValue = signalFilteredValue.filter(
                (item) => !selectedSignals.includes(item.globalPathName)
              );
              let closestSignalData = signalFilteredValue.reduce((a, b) => {
                let secondValue = new Date(b.windowEndTime);
                secondValue = secondValue.toLocaleString("en-US", {
                  hour12: false,
                });
                let firstValue = new Date(a.windowEndTime);
                firstValue = firstValue.toLocaleString("en-US", {
                  hour12: false,
                });
                return Math.abs(new Date(windowTime) - new Date(secondValue)) <
                  Math.abs(new Date(windowTime) - new Date(firstValue))
                  ? b
                  : a;
              });
              availableSignals.push(closestSignalData.globalPathName);
              signalValue = closestSignalData.globalPathName;
            } else {
              availableSignals.push(signalFilteredValue[0].globalPathName);
              signalValue = signalFilteredValue[0].globalPathName;
            }
          }
        }
        let signalAvailable = availableSignals.includes(
          liveshotSignal.globalPathName
        );
        if (signalAvailable) {
          let index = availableSignals.indexOf(liveshotSignal.globalPathName);
          if (index >= 0 && index < availableSignals.length - 1) {
            let nextItem = availableSignals[index + 1];
            signalValue = nextItem;
            dispatch(addAvailableSignal(signalValue));
          }
        } else {
          dispatch(addAvailableSignal(signalValue));
        }
        liveshotSignal.updatedBy = parseInt(userId);
        liveshotSignal.globalPathName = signalValue;
        liveshotSignal.globalSuggest = "false";
        liveshotSignal.globalPathNameSelected = "true";
        liveshotSignal.roomId = currentRoom.roomId;
        dispatch(
          getAccessPinFromAvailSIP(token, liveshotSignal.globalPathName)
        );
        dispatch(
          updateLiveShotSignalsWithCheckOwnership(
            token,
            formInfo,
            currentCanvas,
            liveshotSignal,
            currentRoom
          )
        );
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
    dispatch(actions.toggleLoader());
  };
};
export const unOwnRoute = (
  token,
  liveshotSignalId,
  abstractSignalInfo,
  signalType,
  room,
  isDuplicate = false,
  grabParams = {},
  signalsArr = [],
  outBoundFieldsNormal = [],
  commsFieldsNormal = []
) => {
  return (dispatch) => {
    axios
      .delete(`/liveshot-api/controlTracker/unOwn/${liveshotSignalId}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(
          updateLiveShotSignals(
            token,
            abstractSignalInfo,
            { routed: false },
            room,
            false,
            true
          )
        );
        if (res.data.controlType === 0) {
          if (isDuplicate) {
            if (
              grabParams.hasOwnProperty("isGrabAll") &&
              grabParams.isGrabAll === true
            ) {
              dispatch(
                grabAll(
                  token,
                  grabParams.userId,
                  grabParams.userFullName,
                  outBoundFieldsNormal,
                  commsFieldsNormal,
                  grabParams.field,
                  grabParams.roomId,
                  grabParams.currentRoom,
                  "",
                  grabParams.liveShotSignalSelected,
                  grabParams.currentCanvas,
                  grabParams.skipDuplicate
                    ? grabParams.idx + 1
                    : grabParams.idx,
                  grabParams.confirmedSignalDetails
                )
              );
            } else {
              dispatch(
                takeAndGrab(
                  token,
                  grabParams.btnType,
                  grabParams.userId,
                  grabParams.userFullName,
                  grabParams.field,
                  grabParams.crPath,
                  grabParams.liveShotId,
                  grabParams.roomId,
                  grabParams.currentRoom
                )
              );
            }
          } else {
            if ([200, 202].includes(res.status)) {
              abstractSignalInfo.control = "unOwn";
              abstractSignalInfo.status = res.status;
              abstractSignalInfo.signalType = signalType;
              dispatch(showSignalModal(abstractSignalInfo));
            }
          }
        }
      })
      .catch((err) => {
        abstractSignalInfo.control = "grab";
        abstractSignalInfo.status = err.response.status;
        dispatch(showSignalModal(abstractSignalInfo));

        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};
