import axios from "../../axios";
import * as actionTypes from "./actionTypes";
import { format } from "date-fns";

export const authSuccess = (data) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    accessToken: data.access_token,
    currentUser: {
      sso: data.sso,
      firstName: data.firstName,
      lastName: data.lastName,
      userEmail: data.userEmail,
    },
  };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authEnd = () => {
  return {
    type: actionTypes.AUTH_END,
  };
};
let refreshTokenInterval;

const setRefreshTokenInterval = (dispatch) => {
  refreshTokenInterval && clearInterval(refreshTokenInterval);
  let refreshToken = localStorage.getItem("refresh_token");
  if (refreshToken) {
    let userInfo = JSON.parse(localStorage.getItem("currentUser"));
    let expirationTime;
    if (userInfo && userInfo.expires_at) {
      let actualExpiration = new Date(userInfo.expires_at + "Z");
      // get new token 10 mins before expiration
      expirationTime = new Date(actualExpiration - 10 * 60000);
      console.log(
        `Auth token expire at ${getDateTime(
          actualExpiration
        )} - to fetch new token at ${getDateTime(expirationTime)}`
      );
    }
    refreshTokenInterval = setInterval(() => {
      if (expirationTime && expirationTime.getTime() <= Date.now()) {
        console.log(
          `!! Auth token expiration limit reached - current time : ${getDateTime()}`
        );
        dispatch(getRefreshToken());
        refreshTokenInterval && clearInterval(refreshTokenInterval);
      }
    }, 5000);
  }
};

export const authCheck = () => {
  return (dispatch) => {
    dispatch(authStart());
    let userInfo = JSON.parse(localStorage.getItem("currentUser"));
    let uniqueKeyId =
      localStorage.getItem("id") || process.env.REACT_APP_AUTH_OVERRIDE_TOKEN;
    if (!uniqueKeyId) {
      console.log("Auth token not found in local storage");
      dispatch(authRemove());
      return;
    }
    axios
      .get(`/auth/token?id=${uniqueKeyId}`, { withCredentials: true })
      .then((response) => {
        localStorage.setItem("currentUser", JSON.stringify(response.data));
        localStorage.setItem("refresh_token", response.data.refresh_token);
        dispatch(authSuccess(response.data));
        setRefreshTokenInterval(dispatch);
      })
      .catch((err) => {
        dispatch(authEnd());
        console.log("error ", err);
        if (
          err.response &&
          (err.response.status === 401 || err.response.status === 500)
        ) {
          if (userInfo) {
            dispatch(getRefreshToken());
          } else {
            dispatch(authRemove());
          }
        } else {
          dispatch(authRemove());
        }
      });
  };
};

export const getRefreshToken = () => {
  return (dispatch) => {
    let accessToken = localStorage.getItem("id");
    let refreshToken = localStorage.getItem("refresh_token");
    let userInfo = JSON.parse(localStorage.getItem("currentUser"));
    if (refreshToken && refreshToken != "") {
      console.log(
        `Fetching new access token with refresh token : ${refreshToken} at ${getDateTime()}`
      );
      dispatch(authStart());
      axios
        .get(
          `/auth/refresh-token?refresh-token=${refreshToken}&id=${accessToken}`,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          console.log(
            `New access token fetched successfully - response ${JSON.stringify(
              response.data
            )}`
          );
          let updated_auth_data = { ...userInfo, ...response.data };
          localStorage.setItem(
            "currentUser",
            JSON.stringify(updated_auth_data)
          );
          localStorage.setItem("refresh_token", response.data.refresh_token);
          localStorage.setItem("id", response.data.access_token);
          dispatch(authSuccess(updated_auth_data));
          setRefreshTokenInterval(dispatch);
        })
        .catch((err) => {
          dispatch(authEnd());
          console.log("error ", err);
          if (
            err.response.status &&
            (err.response.status === 401 || err.response.status === 500)
          ) {
            console.log(
              `Failed to fetch new access token with refresh token : ${refreshToken} response : ${JSON.stringify(
                err.response
              )} at ${getDateTime()}`
            );
            dispatch(authRemove());
          }
        });
    } else {
      console.log(
        `Cannot fetch new access token, no refresh token found - ${getDateTime()}`
      );
      refreshTokenInterval && clearInterval(refreshTokenInterval);
    }
  };
};

export const authRemove = () => {
  console.log(`Removing auth details at ${getDateTime()}`);
  localStorage.removeItem("id");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("currentUser");
  return {
    type: actionTypes.AUTH_REMOVE,
  };
};

const getDateTime = (date) => {
  return format(date || new Date(), "yyyy-MM-dd hh:mm:ss a");
};
