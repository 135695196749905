import axios from "../../axios";
import * as actions from "./index";
import * as actionTypes from "./actionTypes";
import { DateFormater, delay } from "../../components/shared/utility";
import * as Constants from "../../components/shared/Constants";
import { venue_page_constants } from "../../constants/PageConstants";

export const toggleLoader = () => {
  return {
    type: actionTypes.TOGGLE_LOADER,
  };
};

export const venueAddressSuccess = (res) => {
  return {
    type: actionTypes.FETCH_VENUE_DATA,
    venueAddressInfo: res,
  };
};

export const venueAddressDetailsSuccess = (res) => {
  return {
    type: actionTypes.FETCH_VENUE_DETAILS,
    venueAddressDetails: res,
  };
};

export const uploadvenueAddressDetailsSuccess = (res) => {
  return {
    type: actionTypes.UPLOAD_VENUE_DETAILS,
    uploadvenueAddress: res,
  };
};

export const downloadVenueAddressDetails = (token, activeTab) => {
  return (dispatch) => {
    dispatch(toggleLoader());
    let venuedetails = [];
    let url =
      activeTab === venue_page_constants.VENUES_TAB
        ? "liveshot-api/studioTruck/download"
        : "/liveshot-api/pin/export";
    axios
      .get(url, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(toggleLoader());
        downloadCsv(res.data, activeTab);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        dispatch(toggleLoader());
      });
    dispatch(venueAddressDetailsSuccess(venuedetails));
  };
};

export const showModal = (resdata) => {
  return {
    type: actionTypes.SHOW_MODAL,
    signalMsg: resdata,
    showModal: true,
  };
};

export const hideModal = () => {
  return {
    type: actionTypes.HIDE_MODAL,
    showModal: false,
    signalMsg: {},
  };
};

export const downloadCsv = (data, activeTab) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", activeTab + ".csv");
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const uploadvenueAddressDetails = (token, file, activeTab, userId) => {
  return (dispatch) => {
    dispatch(toggleLoader());
    let uploadFormData = new FormData();
    uploadFormData.append("file", file);
    let url =
      activeTab === venue_page_constants.VENUES_TAB
        ? "liveshot-api/studioTruck/upload"
        : `/liveshot-api/pin/import?updatedBy=${userId}`;

    axios
      .post(url, uploadFormData, {
        withCredentials: true,
        headers: {
          Authorization: token !== "" ? `Bearer ${token}` : "",
        },
      })
      .then((res) => {
        dispatch(
          showModal({
            resultData: Constants.UPLOAD_VENUE_DETAILS,
            signalMsg: { statusCode: res.status },
            statusCode: res.status,
          })
        );
        if (res.status === 202 && res.data) {
          downloadCsv(res.data, activeTab);
        }
        dispatch(actions.getPinModalInfo(token));
        dispatch(toggleLoader());
      })
      .catch((err) => {
        dispatch(toggleLoader());
        dispatch(
          showModal({
            resultData: err.response.data.message,
            statusCode: err.response.status,
          })
        );
      });
  };
};

export const getvenueAddressInfo = (token) => {
  return async (dispatch) => {
    dispatch(actions.toggleLoader());
    let mainArray = [];
    let ownerShip = [];
    await axios
      .get("liveshot-api/studioTruck/", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : " " },
      })
      .then((response) => {
        dispatch(actions.toggleLoader());
        mainArray = response.data;
        dispatch(venueAddressSuccess(response.data));
      })
      .catch((err) => {
        dispatch(actions.toggleLoader());
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
    await delay(500);
    /* let realArray = [];
    for (let key in mainArray) {
      let signalInfo = mainArray[key];
      realArray.push({ ...signalInfo, id: parseInt(key) + 1 });
    }
    console.log("realArray:", realArray); */
    dispatch(venueAddressSuccess(mainArray));
  };
};
