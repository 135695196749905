import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

export const getRouter = (routes = [], routePath = "/") => {
  return (
    <Switch>
      {routes.map((val, key) => {
        return (
          <Route key={val.path} path={val.path} exact component={val.element} />
        );
      })}
      <Redirect to={routePath} />
    </Switch>
  );
};
