import React from "react";
import "./LabelledCheckbox.css";
import Label from "./Label";

const LabelledCheckbox = ({
  id,
  isChecked,
  setIsChecked,
  label,
  disableCheckBehaviour = false,
  iconSrc: IconComponent,
}) => {
  return (
    <div className="labelled-cb-container">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={setIsChecked}
        id={id}
        hidden
        disabled={disableCheckBehaviour}
      />
      <Label
        id={id}
        labelText={label}
        isActive={isChecked}
        icon={IconComponent}
      />
    </div>
  );
};

export default LabelledCheckbox;
