import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "../../axios";
import { format } from "date-fns";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const NavbarTimer = (props) => {
  //UI and API Rev IDs
  const [apiRev, setApiRev] = useState("unk");
  const [uiRev, setUiRev] = useState("unk");

  const envInfo = "Environment: " + process.env.REACT_APP_ENV_NAME;
  let revInfo = "UI Rev: " + uiRev + ",  API Rev: " + apiRev;
  const date = format(new Date(), "iii, MMM do");
  const timeFormat = "h:mm:ss A";

  const [dateTime, setDateTime] = useState(
    date + ", " + moment().format(timeFormat)
  );

  //Tooltip on current time for environment and Rev Info
  const renderTooltip = (props) => (
    <Tooltip className="tooltip-styles" multiline="true" {...props}>
      {envInfo} {revInfo}
    </Tooltip>
  );

  useEffect(() => {
    const uiRev = process.env.REACT_APP_REV_ID;
    setUiRev(uiRev);
    axios.get("liveshot-api/actuator/info").then((res) => {
      const fetchedInfo = res.data;
      setApiRev(fetchedInfo["git"]["commit"]["id"]);
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDateTime(date + ", " + moment().format(timeFormat));
    }, 1000);
    return () => clearInterval(intervalId);
  });

  return (
    <div className="navbar-time">
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <span>{dateTime}</span>
      </OverlayTrigger>
    </div>
  );
};

export default NavbarTimer;
