import * as actionTypes from "./actionTypes";

export const sockJsConnect = () => {
  return {
    type: actionTypes.SOCKJS_CONNECT,
  };
};

export const sockJsSubscribe = (payload) => {
  return {
    type: actionTypes.SOCKJS_SUBSCRIBE,
    payload,
  };
};

export const sockJsUnsubscribe = () => {
  return {
    type: actionTypes.SOCKJS_UNSUBSCRIBE,
  };
};

export const sockJsNonGrab = (payload) => {
  return {
    type: actionTypes.SOCKJS_NON_GRAB,
    payload,
  };
};
