import React, { useState, memo } from "react";
import { Select } from "antd";
import { ReactComponent as DownOutlined } from "../../../../assets/icons/down-icon.svg";
import { ReactComponent as UpOutlined } from "../../../../assets/icons/up-icon.svg";
import "./select-style.css";

const RoleDropdown = ({ onChange, value, options, key }) => {
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    setOpen(!open);
  };

  return (
    <Select
      className={`role-select ${open ? "menu-open" : "menu-close"}`}
      suffixIcon={open ? <UpOutlined /> : <DownOutlined />}
      onDropdownVisibleChange={handleClick}
      onSelect={onChange}
      notFoundContent={<span>No Option found</span>}
      value={value}
      options={options}
      listHeight={280}
      listItemHeight={10}
      key={key}
      popupClassName="popup-menu"
    />
  );
};

RoleDropdown.defaultProps = {
  onChange: () => {},
  value: "",
  options: [],
  key: "roleId",
};

export default memo(RoleDropdown);
