import * as actionTypes from "./actionTypes";
import * as actions from "../../store/actions/index";
import axios from "../../axios";

export const fetchShowListSuccess = (lists) => {
  return {
    type: actionTypes.FETCH_SHOW_LIST_SUCCESS,
    showList: lists,
  };
};

export const fetchNetworkListSuccess = (lists) => {
  return {
    type: actionTypes.FETCH_NETWORK_LIST_SUCCESS,
    networkList: lists,
  };
};

export const fetchFacilityListSuccess = (lists) => {
  return {
    type: actionTypes.FETCH_FACILITY_LIST_SUCCESS,
    facilityList: lists,
  };
};

export const resetShowData = () => {
  return {
    type: actionTypes.RESET_SHOW_DATA,
  };
};

export const fetchShowDataSuccess = (obj) => {
  return {
    type: actionTypes.FETCH_SHOW_DATA_SUCCESS,
    showData: obj,
  };
};

export const updateCurrentShowSuccess = (res) => {
  return {
    type: actionTypes.UPDATE_SHOW,
    currentShow: res,
  };
};

export const fetchShowList = (token) => {
  return (dispatch) => {
    axios
      .get("liveshot-api/show/", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        const fetchedShowList = [];
        for (let key in res.data) {
          fetchedShowList.push({
            ...res.data[key],
          });
        }
        const activeShows = fetchedShowList.filter(
          (item) => item.softDelete !== true
        );
        dispatch(fetchShowListSuccess(activeShows));
        /*  if (activeShows.length > 0) {
          dispatch(actions.updateCurrentShow(token, activeShows[0].showId));
          dispatch(actions.fetchCurrentCanvas(token, activeShows[0].showId));
        } */
      })
      .catch((err) => {
        dispatch(actions.noLiveShots()); /* possible typo? */
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchNetworkList = (token) => {
  return (dispatch) => {
    axios
      .get("liveshot-api/network/", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        const fetchedNetworkList = [];
        for (let key in res.data) {
          fetchedNetworkList.push({
            ...res.data[key],
          });
        }
        dispatch(fetchNetworkListSuccess(fetchedNetworkList));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchShowData = (token, Id) => {
  return (dispatch) => {
    axios
      .get(`liveshot-api/show/${Id}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(fetchShowDataSuccess(res.data));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        console.log(err);
      });
  };
};

export const updateCurrentShow = (token, showId) => {
  return (dispatch) => {
    axios
      .get(`liveshot-api/show/${showId}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(updateCurrentShowSuccess(res.data));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        console.log(err);
      });
  };
};

export const fetchFacilityList = (token) => {
  return (dispatch) => {
    axios
      .get("liveshot-api/facility/", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        const fetchedFacilityList = [];
        for (let key in res.data) {
          fetchedFacilityList.push({
            ...res.data[key],
          });
        }
        dispatch(fetchFacilityListSuccess(fetchedFacilityList));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};
