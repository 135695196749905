import * as actionTypes from "./actionTypes";
import * as actions from "../../store/actions/index";
import axios from "../../axios";

export const fetchStudioTruckListSuccess = (lists) => {
  return {
    type: actionTypes.FETCH_STUDIOTRUCK_LIST_SUCCESS,
    studioTruckList: lists,
  };
};

export const fetchStudioTruckTypesSuccess = (lists) => {
  return {
    type: actionTypes.FETCH_STUDIOTRUCK_TYPES_SUCCESS,
    studioTruckTypes: lists,
  };
};

export const resetStudioTruckData = () => {
  return {
    type: actionTypes.RESET_STUDIOTRUCK_DATA,
    studioTruckData: "",
  };
};

export const fetchStudioTruckDataSuccess = (obj) => {
  return {
    type: actionTypes.FETCH_STUDIOTRUCK_DATA_SUCCESS,
    studioTruckData: obj,
  };
};

export const setSTDefaultPin = (obj) => {
  return {
    type: actionTypes.SET_ST_DEFAULT_PIN,
    info: obj,
  };
};

export const resetSTDefaultPin = () => {
  return {
    type: actionTypes.RESET_ST_DEFAULT_PIN,
  };
};

export const fetchStudioTruckList = (token) => {
  return (dispatch) => {
    axios
      .get("liveshot-api/studioTruck/", {
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        const fetchedStudioTruckList = [];
        for (let key in res.data) {
          fetchedStudioTruckList.push({
            ...res.data[key],
          });
        }
        dispatch(fetchStudioTruckListSuccess(fetchedStudioTruckList));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        console.log("error ", err);
      });
  };
};

export const fetchStudioTruckData = (token, Id, lsId, setPin) => {
  return (dispatch) => {
    axios
      .get(`liveshot-api/studioTruck/${Id}`, {
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        res.data.liveShotId = lsId;
        dispatch(actions.resetSuccessMsg());
        dispatch(fetchStudioTruckDataSuccess(res.data));
        //if (setPin) dispatch(setSTDefaultPin(res.data));
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchStudioTruckTypes = (token) => {
  return (dispatch) => {
    axios
      .get("liveshot-api/studioTruckType/", {
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        const fetchedStudioTruckTypes = [];
        for (let key in res.data) {
          fetchedStudioTruckTypes.push({
            ...res.data[key],
          });
        }
        dispatch(fetchStudioTruckTypesSuccess(fetchedStudioTruckTypes));
      })
      .catch((err) => {
        /* if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        } */
        console.log("error ", err);
      });
  };
};
