import * as actionTypes from "../actions/actionTypes";

const initialState = {
  resourceTrackerInfo: [],
  allSourceDestination: {},
  allSIP: [],
};

const resourceTrackerSuccess = (state, action) => {
  return Object.assign({}, state, {
    resourceTrackerInfo: action.resourceTrackerInfo,
    allSourceDestination: action.allSourceDestination,
  });
};

const setAllSIPSuccess = (state, action) => {
  return Object.assign({}, state, {
    allSIP: action.sip,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_RESOURCETRACKER_DATA:
      return resourceTrackerSuccess(state, action);
    case actionTypes.FETCH_ALL_SIP:
      return setAllSIPSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
