import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import socketMiddleware from "./middleware/socketMiddleware";

import liveShotContainerReducer from "./reducers/liveShotContainer";
import canvasInfoReducer from "./reducers/canvasInfo";
import liveShotInfoReducer from "./reducers/liveShotOperations";
import liveShotSearchReducer from "./reducers/liveShotSearch";
import authReducer from "./reducers/auth";
import showInfoReducer from "./reducers/showInfo";
import userReducer from "./reducers/user";
import showOperationReducer from "./reducers/showOperations";
import studioTruckInfoReducer from "./reducers/studioTruckInfo";
import studioTruckOperationReducer from "./reducers/studioTruckOperations";
import signalsInfoReducer from "./reducers/signalsInfo";
import notificationReducer from "./reducers/notification";
import websocketReducer from "./reducers/websocket";
import resourceTrackerReducer from "./reducers/resourceTrackerInfo";
import routerSourceDestinationReducer from "./reducers/routerSourceDestination";
import venueAddressReducer from "./reducers/venueAddressInfo";
import notifyReducer from "react-redux-notify";
import controlRoomInfoReducer from "./reducers/controlRoomInfo";

const rootReducer = combineReducers({
  liveShotContainer: liveShotContainerReducer,
  canvasInfo: canvasInfoReducer,
  liveShotInfo: liveShotInfoReducer,
  searchListInfo: liveShotSearchReducer,
  notificationInfo: notificationReducer,
  showInfo: showInfoReducer,
  auth: authReducer,
  user: userReducer,
  showOperations: showOperationReducer,
  studioTruckInfo: studioTruckInfoReducer,
  studioTruckOperations: studioTruckOperationReducer,
  signalsInfo: signalsInfoReducer,
  websocketInfo: websocketReducer,
  resourceTracker: resourceTrackerReducer,
  venueAddressBook: venueAddressReducer,
  routerSourceDestination: routerSourceDestinationReducer,
  notifications: notifyReducer,
  controlRoomInfo: controlRoomInfoReducer,
});

const composeEnhancers =
  process.env.REACT_APP_ENV_NAME === "local" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;

const url = `${process.env.REACT_APP_BASE_URL}lsm-websocket`;
const middleware = [thunk, socketMiddleware(url)];

const store = () =>
  createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

export default store;
